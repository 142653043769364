import { graphql, useStaticQuery } from 'gatsby'

import type { ScratchPageContent } from '../local_modules/types/CMS'

const useCmsScratchPage = () => {
  const data = useStaticQuery(graphql`
    query CmsScrathQuery {
      cmsScratchPage {
        sections {
          id
          name
          data
        }
      }
    }
  `)

  const scratchPageContent: ScratchPageContent | undefined =
    data?.cmsScratchPage?.sections?.[0]?.data

  return { scratchPageContent }
}

export default useCmsScratchPage
