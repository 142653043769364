import { graphql, useStaticQuery } from 'gatsby'

import type { MaintenanceStore } from '../local_modules/types/CMS'

const useCmsMaintenanceStore = () => {
  const data = useStaticQuery(graphql`
    query CmsMaintenanceStoreQuery {
      cmsDisableStore {
        sections {
          data
        }
      }
    }
  `)

  const maintenanceStore: MaintenanceStore | undefined =
    data?.cmsDisableStore?.sections?.[0]?.data

  return {
    maintenanceStore,
  }
}

export default useCmsMaintenanceStore
