import React from 'react'

import { TelesalesTermsInput } from '../../../telesales/TelesalesTermsInput'
import ArrowIcon from '../../../../assets/ArrowIcon'

import type { HandleTerms } from '../../../../pages/TelesalesPage'

import './telesales-edit-list.scss'
import { NotFoundIcon } from '../../../../assets/NotFoundIcon'
import { HAS_LOCAL_STORAGE } from '../../../../constants'
import { CleanMultipleSearch } from '../../MultipleSearch/CleanMultipleSearch'
import { MultipleSearchBadge } from '../../MultipleSearch/MultipleSearchBadge'
import { MultipleSearchCounter } from '../../MultipleSearch/MultipleSearchCounter'

interface Props {
  handleReturn: () => void
  handleTerms: HandleTerms
}

export const TelesalesEditList = ({ handleReturn, handleTerms }: Props) => {
  const { terms, setTerms } = handleTerms

  const hasTerms = terms.length > 0

  return (
    <div className="telesales-edit-container grid-content">
      <button onClick={handleReturn} className="return-button">
        <ArrowIcon rotate="Right" color="#00289C" width="8" />
        <a href="#">Voltar</a>
      </button>

      <h1>Minha lista de compras</h1>

      <ul>
        <li>
          <strong>Adicione</strong> os produtos na sua lista de compras;
        </li>
        <li>
          Para adicionar <strong>mais de um produto</strong> busque por vírgula
          (ex: carne, macarrão, leite);
        </li>
        <li>
          <strong>Pesquise todos</strong> os produtos de uma só vez.
        </li>
      </ul>

      <TelesalesTermsInput handleTerms={handleTerms} />

      <MultipleSearchCounter
        currentQuantity={handleTerms.terms.length}
        maxQuantity={30}
      />

      {hasTerms ? (
        <>
          <div className="telesales-badge-section">
            {terms.map((item) => (
              <MultipleSearchBadge
                term={item}
                multipleTerms={terms}
                setTMultipleTerms={setTerms}
                key={item}
              />
            ))}
          </div>

          <CleanMultipleSearch
            text="Apagar todos os produtos"
            setTMultipleTerms={setTerms}
          />
        </>
      ) : (
        <div className="telesales-empty-section">
          <div className="telesales-empty-subcontainer">
            <NotFoundIcon />
          </div>
        </div>
      )}

      <div className="telesales-button-container">
        <button
          className="telesales-search-button"
          disabled={!hasTerms}
          onClick={() => {
            if (!HAS_LOCAL_STORAGE) {
              return
            }

            localStorage.setItem('telesalesTerms', terms.toString())

            handleReturn()
          }}
        >
          Buscar produtos
        </button>
      </div>
    </div>
  )
}
