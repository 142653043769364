import React, { useCallback, useEffect, useState } from 'react'

import { useLocalStorageContext } from '../../../../contexts/LocalStorageContext'
import { useRegionalizationContext } from '../../../../contexts/RegionalizationContext'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { cartStore } from '../../../../sdk/cart'
import BuyButton from '../../../ui/BuyButton'
import { QuantitySelector } from '../../../ui/QuantitySelector'

import type { CartItem } from '../../../../sdk/cart'

interface ProductDetailsActionsProps {
  cartProductId: string
  sku: string
  unitMultiplier: number
  measurementUnit: string
  cartProductQty: number
  buyProps: CartItem
  buyDisabled: boolean
  setOpenClubModal: (openClubModal: boolean) => void
  clubPrice?: number
  hasClub: boolean | undefined
  isClubClient: boolean | undefined
  location: string
  hasPesavelUnd?: boolean
  buttonText?: string
  spotPriceCustom: number
}

export const ProductDetailsActions = ({
  cartProductId,
  sku,
  unitMultiplier,
  measurementUnit,
  cartProductQty,
  buyProps,
  buyDisabled,
  setOpenClubModal,
  clubPrice,
  hasClub,
  isClubClient,
  location,
  hasPesavelUnd,
  buttonText = 'Adicionar ao Carrinho',
  spotPriceCustom,
}: ProductDetailsActionsProps) => {
  const [modalView, setModalViews] = useState<boolean | undefined>(false)
  const { inCart, addItem } = cartStore
  const { getLocalStorageItem } = useLocalStorageContext()
  const { regionalized, setOpenRegionModal, setProduct } =
    useRegionalizationContext()

  const { addItemEvent } = useAnalyticsEvents()

  const quantitySelectorHeavyProps = {
    measurementUnit:
      unitMultiplier * 1000 * cartProductQty >= 1000 ? 'kg' : 'g',
    unitMultiplier,
  }

  const canOpenModal = !!(hasClub && clubPrice && !modalView && !isClubClient)

  const handleAddProduct = useCallback(() => {
    if (regionalized) {
      addItem(buyProps)
      addItemEvent(buyProps, location)

      // open club modal just 1 time
      if (canOpenModal) {
        setOpenClubModal(true)
      }

      return
    }

    setProduct?.(buyProps)
    setOpenRegionModal?.(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionalized, canOpenModal, buyProps])

  useEffect(() => {
    setModalViews(getLocalStorageItem?.().clubModalViewed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClub, clubPrice])

  if (inCart(cartProductId)) {
    return (
      <>
        {measurementUnit && spotPriceCustom > 0 ? (
          <div className="quantity-selector-container">
            <div className="product-add-text-pdp">
              <p>Produto adicionado</p>
            </div>
            <QuantitySelector
              cartProductId={cartProductId}
              sku={sku}
              {...(measurementUnit !== 'un' &&
                !hasPesavelUnd &&
                quantitySelectorHeavyProps)}
              min={0}
              initial={cartProductQty}
              hasPesavelUnd={hasPesavelUnd}
              location={location}
            />
          </div>
        ) : (
          <div className="buy-button-container">
            {buyDisabled && <span>Produto indisponível</span>}
            <BuyButton disabled={buyDisabled} onClick={handleAddProduct}>
              {buttonText}
            </BuyButton>
          </div>
        )}
      </>
    )
  }

  return (
    <div className="buy-button-container">
      {buyDisabled && <span>Produto indisponível</span>}
      <BuyButton disabled={buyDisabled} onClick={handleAddProduct}>
        {buttonText}
      </BuyButton>
    </div>
  )
}
