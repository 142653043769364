import axios from 'axios'
import { navigate } from 'gatsby'
import React, { useState } from 'react'

import { useClubContext } from '../../contexts/ClubContext'
import { useStoreContext } from '../../contexts/StoreContext'
import { navigateUsingWindow } from '../../utils/navigation/navigateUsingWindow'
import { Banner } from '../ui/Banner'

import type { MouseEvent } from 'react'

export interface ScratchBannerProps {
  banner: {
    alt: string
    src: string
    srcMobile: string
  }

  isActive: boolean
}

interface Props {
  data?: ScratchBannerProps
}

const PAGE_PATH = '/aceitar-termos-promocao'

export const ScratchBanner = ({ data }: Props) => {
  const [isLoadingScratchRedirect, setIsLoadingScratchRedirect] =
    useState(false)

  const { banner, isActive } = data ?? {}
  const { alt, src, srcMobile } = banner ?? {}
  const { isClubClient } = useClubContext()
  const { secureSubdomain, scratchPageContent } = useStoreContext()

  const isVisible = scratchPageContent?.isActive && isActive

  const handleScratch = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (!isClubClient) {
      navigateUsingWindow(`${secureSubdomain}/login?returnUrl=${PAGE_PATH}`)

      return
    }

    if (!isLoadingScratchRedirect) {
      try {
        setIsLoadingScratchRedirect(true)

        const { data } = await axios.get<{ url: string }>(
          '/api/scratch/scratchLink',
          {
            params: {
              promotionId: scratchPageContent?.id,
              random: Math.random(),
            },
          }
        )

        setIsLoadingScratchRedirect(false)
        navigateUsingWindow(data.url)
      } catch {
        setIsLoadingScratchRedirect(false)
        navigate(PAGE_PATH)
      }
    }
  }

  if (!isVisible || !src || !srcMobile) {
    return null
  }

  return (
    <Banner
      href="/"
      src={src}
      srcMobile={srcMobile}
      alt={alt}
      bannerWidth={720}
      bannerHeight={240}
      bannerDeskWidth={1216}
      bannerDeskHeight={336}
      handleClick={handleScratch}
      customStyle={isLoadingScratchRedirect ? { cursor: 'wait' } : {}}
    />
  )
}
