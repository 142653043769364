import type { HighlightBrands } from '../types/CMS'
import type { ProductsData } from '../types/RepeatOrder'

export const separateSkus = (highlightBrands?: HighlightBrands) => {
  return (
    highlightBrands?.highLightItems?.map((item) => item.productSkus).flat() ??
    []
  )
}

export const separateProductsByBrand = (
  highlightBrands: HighlightBrands,
  products: ProductsData['products']
) => {
  return highlightBrands?.highLightItems?.map(({ logo, href, productSkus }) => {
    const currentBrandProducts =
      products?.filter((item) => productSkus.includes(item.sku)) ?? []

    return {
      logo,
      href,
      productSkus:
        currentBrandProducts.length >= 4
          ? currentBrandProducts.slice(0, 4)
          : [], // 4 products per brand or nothing
    }
  })
}
