import React from 'react'
import './storage-details-flag.scss'

interface StorageDetailsFlagProps {
  title: string
  storage: string
}

export const StorageDetailsFlag = ({
  title,
  storage,
}: StorageDetailsFlagProps) => {
  return (
    <div className="storage-details-flag-container">
      <p className="storage-details-flag-title">{title}</p>
      <p className="storage-details-flag-storage">{storage}</p>
    </div>
  )
}
