import { Link } from 'gatsby'
import React from 'react'

import { DynamicTitle } from '../../../common/DynamicTitle'

import type { ProductShelfContainerProps } from '../ProductShelfContainer/ProductShelfContainer'

interface ProductShelfHeaderProps
  extends Omit<ProductShelfContainerProps, 'children' | 'variant'> {
  titleColor?: string
  title: string
  hasSeeAll: boolean
  link: string
  handleSeeAll: () => void
  description: string
  titleLevel: number
  dataStoreLink: boolean
}

export const ProductShelfHeader = ({
  type,
  titleColor,
  title,
  hasSeeAll,
  link,
  handleSeeAll,
  description,
  titleLevel,
}: ProductShelfHeaderProps) => {
  if (type === 'club')
    return (
      <div className="carousel-with-see-more-header">
        <div className="carousel-with-see-more-row">
          <h2 style={{ color: `${titleColor}` }}>{title}</h2>
          {hasSeeAll && (
            <Link
              className="carousel-with-see-more-header-link"
              to={link}
              onClick={handleSeeAll}
              style={{ color: `${titleColor}` }}
            >
              Ver Tudo
            </Link>
          )}
        </div>
        {description && <h4>{description}</h4>}
      </div>
    )

  return (
    <div>
      <div className="carousel-product-header">
        <DynamicTitle
          className="carousel-product-header-title"
          titleLevel={titleLevel - 1}
          title={title}
          titleColor={titleColor}
        />

        {link && (
          <Link to={link} onClick={handleSeeAll} data-store-link>
            Ver Tudo
          </Link>
        )}
      </div>
    </div>
  )
}
