import { Modal, Button } from '@faststore/ui'
import React, { useState, useEffect } from 'react'

import { CloseIcon } from '../../../../../assets/CloseIcon'
import { GlassIcon } from '../../../../../assets/GlassIcon'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import { Image } from '../../../../ui/Image'

interface ProductImage {
  alternateName: string
  url: string
}

interface TelesalesModalImageProps {
  images?: ProductImage[]
}

export const TelesalesModalImage = ({ images }: TelesalesModalImageProps) => {
  const [width, setWidth] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  if (images?.length === 0) {
    return <></>
  }

  return (
    <div className="telesales-modal__images">
      {images && (
        <>
          <Image
            key={images[0].alternateName}
            objectFit="contain"
            baseUrl={images[0].url}
            layout="constrained"
            sourceWidth={width <= 1280 ? 126 : 256}
            sourceHeight={width <= 1280 ? 126 : 256}
            backgroundColor="var(--color-grayScale-faded)"
            options={{
              fitIn: true,
            }}
            alt={images[0].alternateName}
            loading="lazy"
          />
          <Button
            className="zoom"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <GlassIcon />
          </Button>
          <Modal className="modal-image" isOpen={modalOpen}>
            <div className="modal-info">
              <Button onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </Button>
            </div>
            <Image
              baseUrl={images[0].url}
              sourceWidth={720}
              aspectRatio={1}
              width={768}
              breakpoints={[250, 360, 480, 720]}
              layout="constrained"
              backgroundColor="var(--color-grayScale-faded)"
              options={{
                fitIn: true,
              }}
              alt={images[0].alternateName}
              loading="lazy"
              objectFit="contain"
            />
          </Modal>
        </>
      )}
    </div>
  )
}
