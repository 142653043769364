import axios from 'axios'
import { get } from 'idb-keyval'
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react'

import { HAS_WINDOW, MAX_SHELFS_CAROUSEL } from '../constants'
import { useSession } from '../sdk/session'

import type { MultipleSearchedProductsProps } from '../types/Product'

interface MultipleSearchContextProps {
  children?: React.ReactNode
  multipleSearchedProducts?: MultipleSearchedProductsProps[]
  hasPagination?: boolean
  nextPage?: () => void
  loading?: boolean
  addSearchTerms?: (terms: string[]) => void
  deleteTermUrl?: () => void
  setCurrentPage?: (page: number) => void
  setMultipleSearchedProducts?: (
    multipleSearchedProducts: MultipleSearchedProductsProps[]
  ) => void
  currentSelectedTerm?: string
  setCurrentSelectedTerm?: (currentSelectedTerm: string) => void
  menuVisibleTerms?: string[]
  setSearchedTerms?: (searchedTerms: string[]) => void
  searchedTerms?: string[]
  menuAutoNavigation?: (index: number, sectionName: string) => void
  setIsProductsView?: (view: boolean) => void
  isProductsView?: boolean
}

const MultipleSearchContext = createContext<MultipleSearchContextProps | null>(
  null
)

export const MultiplePageContextProvider = ({
  children,
}: MultipleSearchContextProps) => {
  const [isProductsView, setIsProductsView] = useState(false)
  const [multipleSearchedProducts, setMultipleSearchedProducts] = useState<
    MultipleSearchedProductsProps[]
  >([])

  const [currentPage, setCurrentPage] = useState(0)
  const [searchedTerms, setSearchedTerms] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [menuVisibleTerms, setMenuVisibleTerms] = useState<string[]>([])
  const [preventChannel, setPreventChannel] = useState('')
  const [currentSelectedTerm, setCurrentSelectedTerm] = useState(
    searchedTerms[0] ?? ''
  )

  const currentSession = useSession()

  const getMultipleSearchedProducts = useCallback(
    async (terms: string[], trigger?: () => void) => {
      try {
        setLoading(true)

        const currentChannel = await get<{ channel: string }>(
          'fs::session'
        ).then((session) => {
          return session?.channel
        })

        const { data }: { data: MultipleSearchedProductsProps[] } =
          await axios.post('/api/multipleSearch', {
            searchedTerms: terms,
            channel: currentChannel,
            builder: 'faststore',
          })

        setLoading(false)
        trigger?.()

        return data
      } catch (err) {
        console.error('Error in getMultipleSearchedProducts function', err)
      }
    },
    []
  )

  const pagination = (terms: string[]) => {
    const currentPagination = terms.reduce((result: string[][], _, index) => {
      if (index % MAX_SHELFS_CAROUSEL === 0) {
        result.push(searchedTerms.slice(index, index + MAX_SHELFS_CAROUSEL))
      }

      return result
    }, [])

    return currentPagination
  }

  const maxPages =
    pagination(searchedTerms).length > 0
      ? pagination(searchedTerms).length - 1
      : 0

  const hasPagination = useMemo(
    () => maxPages > 0 && maxPages !== currentPage,
    [currentPage, maxPages]
  )

  const nextPage = useCallback(async () => {
    if (maxPages === currentPage) {
      return
    }

    const multipleProducts = await getMultipleSearchedProducts(
      pagination(searchedTerms)[currentPage + 1]
    )

    const nextPageAction = () => {
      if (!multipleProducts?.length) {
        return
      }

      setMultipleSearchedProducts((prevState) => [
        ...prevState,
        ...multipleProducts,
      ])

      setMenuVisibleTerms((prevState) => [
        ...prevState,
        ...pagination(searchedTerms)[currentPage + 1],
      ])

      setCurrentPage(currentPage + 1)
    }

    nextPageAction()
  }, [maxPages, currentPage, searchedTerms])

  const setTermsInUrl = (terms: string[]) => {
    const { search } = window.location
    const params = new URLSearchParams(search)

    params.set('terms', terms.join(','))
    window.history.pushState({}, '', `?${params.toString()}`)
  }

  const addSearchTerms = async (terms: string[]) => {
    if (terms.length < 1) {
      setSearchedTerms([])

      return
    }

    try {
      const currentTerms = terms.slice(0, MAX_SHELFS_CAROUSEL)
      const multipleProducts = await getMultipleSearchedProducts(currentTerms)

      if (!multipleProducts?.length) {
        return
      }

      setSearchedTerms(terms)
      setMenuVisibleTerms(currentTerms)
      setTermsInUrl(terms)
      setMultipleSearchedProducts(multipleProducts)
      setIsProductsView(true)
    } catch (err) {
      console.error('Error in addSearchTerms function', err)
    }
  }

  const deleteTermUrl = useCallback(() => {
    if (!HAS_WINDOW) {
      return
    }

    const url = new URL(window.location.href)

    url.searchParams.delete('terms')
    history.pushState({}, '', url)
  }, [])

  const menuAutoNavigation = useCallback(
    (index: number, sectionName: string) => {
      if (!HAS_WINDOW) {
        return
      }

      const carouselElement = document.querySelector('.carousel-container')

      if (!carouselElement) {
        return
      }

      const navegationDisplacement = Array.from(
        carouselElement.querySelectorAll('li')
      )
        .map((item) => item.offsetWidth)
        .slice(1, index)
        .reduce((widthTotal, item) => widthTotal + item, 0)

      carouselElement.scroll(navegationDisplacement, 0)
      setCurrentSelectedTerm?.(sectionName)
    },
    []
  )

  useEffect(() => {
    if (!searchedTerms?.length || !multipleSearchedProducts?.length) {
      return
    }

    const setRegionalizedProducts = async (terms: string[]) => {
      const currentTerms = terms.slice(0, MAX_SHELFS_CAROUSEL)
      const multipleProducts = await getMultipleSearchedProducts(currentTerms)

      setMultipleSearchedProducts(multipleProducts ?? [])
    }

    setRegionalizedProducts(searchedTerms)
  }, [preventChannel])

  useEffect(() => {
    if (preventChannel === currentSession.channel) {
      return
    }

    setPreventChannel(currentSession.channel ?? '')
  }, [currentSession])

  const contextValues = useMemo(
    () => ({
      hasPagination,
      isProductsView,
      setIsProductsView,
      nextPage,
      loading,
      multipleSearchedProducts,
      setCurrentPage,
      addSearchTerms,
      deleteTermUrl,
      setMultipleSearchedProducts,
      currentSelectedTerm,
      setSearchedTerms,
      setCurrentSelectedTerm,
      menuAutoNavigation,
      searchedTerms,
      menuVisibleTerms,
    }),
    [
      isProductsView,
      hasPagination,
      loading,
      multipleSearchedProducts,
      currentSelectedTerm,
      searchedTerms,
      menuVisibleTerms,
    ]
  )

  return (
    <MultipleSearchContext.Provider value={contextValues}>
      {children}
    </MultipleSearchContext.Provider>
  )
}

export const useMultipleSearchContext = () => {
  const context = useContext(MultipleSearchContext)

  if (!context) {
    throw new Error(
      'useMultipleSearchContext must be used within a MultipleSearchContextProvider'
    )
  }

  return context
}
