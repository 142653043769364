import { graphql, useStaticQuery } from 'gatsby'

const useCmsFaq = () => {
  const data = useStaticQuery(graphql`
    query CmsFaqQuery {
      cmsFaq {
        sections {
          data
        }
      }
    }
  `)

  const faqData = data?.cmsFaq?.sections?.[0].data

  return { faqData }
}

export default useCmsFaq
