import { navigate } from 'gatsby'
import { navigateUsingWindow } from './navigateUsingWindow'

// Credentials
export const goToLogin = (loginUrl: string, returnUrl?: string) => {
  const returnQuery = returnUrl ? `?returnUrl=${returnUrl}` : ''

  navigateUsingWindow(`${loginUrl}${returnQuery}`)
}

// Club Card Plus
export const goToBenefitsClubCardPlus = () => {
  navigate(`/cartao/beneficios-cartao`)
}

// Club
export const goToClub = () => {
  navigate(`/clube`)
}
