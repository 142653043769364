import axios from 'axios'
import React, { createContext, useContext, useState, useCallback } from 'react'

import type { PropsWithChildren } from 'react'

interface CardContextProps {
  balance?: Balance
  getBalance?: () => Promise<void>
  pointsUrl?: PointsUrl
  getPointsUrl?: () => Promise<void>
}

export type Balance = QueryStatus<number>
export type PointsUrl = QueryStatus<string>

interface QueryStatus<T> {
  loading: boolean
  error: boolean
  data: T | undefined
}

const CardContext = createContext<CardContextProps | undefined>(undefined)

export const CardContextProvider = ({
  children,
}: PropsWithChildren<CardContextProps>) => {
  const [balance, setBalance] = useState<Balance>({
    loading: false,
    error: false,
    data: undefined,
  })

  const [pointsUrl, setPointsUrl] = useState<PointsUrl>({
    loading: false,
    error: false,
    data: undefined,
  })

  // Use this method before call some card service
  const getToken = useCallback(async () => {
    await axios.get('/api/loyalty/points/createToken', {
      params: { platform: 'web' },
    })
  }, [])

  const getBalance = useCallback(async () => {
    setBalance({ loading: true, error: false, data: undefined })

    try {
      await getToken()

      const { data } = await axios.post<{ balance: number }>(
        '/api/loyalty/points/balance',
        null,
        { params: { platform: 'web' } }
      )

      setBalance({ loading: false, error: false, data: data.balance })
    } catch {
      setBalance({ loading: false, error: true, data: undefined })
    }
  }, [getToken])

  const getPointsUrl = useCallback(async () => {
    setPointsUrl({ loading: true, error: false, data: undefined })

    try {
      const { data } = await axios.get<{ url: string }>(
        '/api/loyalty/marketplace/url'
      )

      setPointsUrl({ loading: false, error: false, data: data.url })
    } catch {
      setPointsUrl({ loading: false, error: true, data: undefined })
    }
  }, [])

  return (
    <CardContext.Provider
      value={{ balance, getBalance, pointsUrl, getPointsUrl }}
    >
      {children}
    </CardContext.Provider>
  )
}

export const useCardContext = () => {
  const context = useContext(CardContext)

  if (!context) {
    throw new Error('useCardContext must be used within a CardContextProvider')
  }

  return context
}
