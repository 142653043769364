import React from 'react'

import { WarningIcon } from '../../../../assets/WarningIcon'

import './multiple-search-message.scss'

interface MultipleSearchMessageProps {
  text: string
}

export const MultipleSearchMessage = ({ text }: MultipleSearchMessageProps) => {
  return (
    <div className="multiple-search-message-container">
      <div className="multiple-search-message-icon">
        <WarningIcon />
      </div>

      <span className="multiple-search-message-text">{text}</span>
    </div>
  )
}
