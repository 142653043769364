import axios from 'axios'

export const getCookieClub = async () => {
  try {
    await axios.get(`/api/loyalty/customer/conditions`)

    return
  } catch (err) {
    throw new Error('Get Cookie club data: ', err)
  }
}
