import React from 'react'

import { DynamicTitle } from '../../../common/DynamicTitle'
import { Image } from '../../Image'

import type { ImageWithAlt } from '../../../../types/ClubCard/ClubCardBenefits'

import './points-card.scss'

interface PointsCardProps {
  text: string
  icon: ImageWithAlt
  image: ImageWithAlt
  points: number
  className?: string
}

export const PointsCard = ({
  text,
  icon,
  image,
  points,
  className = '',
}: PointsCardProps) => {
  return (
    <div className={`pointsCard ${className}`}>
      <div className="pointsCard-image">
        <Image alt={image?.alt} baseUrl={image?.src} width={114} height={80} />
      </div>
      <div className="pointsCard-title">
        <DynamicTitle className="pointsCard-text" titleLevel={3} title={text} />
      </div>
      <div className="pointsCard-points">
        <Image
          alt={icon?.alt}
          baseUrl={icon?.src}
          width={16}
          height={13}
          objectFit="contain"
        />
        <p className="pointsCard-points-text">{points}</p>
      </div>
    </div>
  )
}
