import React from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import Select from '../../../ui/Select'
import './tabloid-select.scss'

interface TabloidSelectProps {
  title: string
  activeTabloid: string
  setActiveTabloid: (activeTabloid: string) => void
  tabloidsOptions: string[]
}

export const TabloidSelect = ({
  title,
  activeTabloid,
  setActiveTabloid,
  tabloidsOptions = [],
}: TabloidSelectProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()

  const OPTIONS: Record<string, string> = tabloidsOptions.reduce(
    (acc, item) => {
      acc[item] = item

      return acc
    },
    {} as Record<string, string>
  )

  return (
    <div className="tabloid-regionalization-container">
      <h1 className="tabloid-regionalization-text">{title}</h1>
      <Select
        className={
          activeTabloid
            ? 'tabloid-regionalization-select'
            : 'tabloid-regionalization-select defaultValue'
        }
        value={activeTabloid === '' ? 'default' : activeTabloid}
        onChange={(e) => {
          genericTaggingEvent('cidade-tabloide', {
            click_text: e.target.value,
          })

          setActiveTabloid?.(e.target.value)
        }}
        labelText="Escolher cidade"
        options={OPTIONS}
        id="tabloid-regionalization-select"
      />
    </div>
  )
}
