import { HAS_WINDOW } from '../constants'
import { useClubContext } from '../contexts/ClubContext'
import { useStoreContext } from '../contexts/StoreContext'
import { useCart } from '../sdk/cart'
import { formatAccountNameSF } from '../utils/formatAccountName'

import type { CartItem } from '../sdk/cart'
import type { ProductsQueryResponse } from '../types/Product'

export const useSalesforceEvents = () => {
  const { items } = useCart()
  const { account } = useStoreContext()
  const affiliate = formatAccountNameSF(account)
  const { userInfos } = useClubContext()

  const identity = {
    user: {
      attributes: {
        eventType: 'identity',
        isAnonymous: !userInfos || !userInfos.document ? 1 : 0,
        identityAffiliate: affiliate,
        identityCustomerCPF: userInfos?.document ?? '',
        firstName: userInfos?.givenName ?? '',
        lastName: userInfos?.familyName ?? '',
      },
    },
  }

  const viewProductDetailsSalesforce = (item: CartItem) => {
    if (HAS_WINDOW) {
      window?.SalesforceInteractions?.sendEvent({
        interaction: {
          name: 'View Item',
          lineItem: {
            catalogObjectType: 'Product',
            catalogObjectId: item.id,
            quantity: item.quantity,
            price: item?.price ?? null,
            currency: 'BRL',
            attributes: {
              itemName: item.itemOffered.name,
              itemCode: `${affiliate}_${item.itemOffered.sku}`,
              itemCategory: item.itemOffered.categoryTree,
              itemAffiliate: affiliate,
            },
          },
        },
        ...identity,
      })
    }
  }

  const addItemEventSalesforce = (item: CartItem) => {
    SalesforceInteractions?.sendEvent({
      interaction: {
        name: 'Add To Cart',
        lineItem: {
          catalogObjectType: 'Product',
          catalogObjectId: item.id,
          quantity: item.quantity,
          price: item?.price ?? null,
          currency: 'BRL',
          attributes: {
            itemName: item.itemOffered.name,
            itemCode: `${affiliate}_${item.itemOffered.sku}`,
            itemCategory: item.itemOffered.categoryTree,
            itemAffiliate: affiliate,
          },
        },
      },
      ...identity,
    })
  }

  const removeItemEventSalesforce = (sku: string) => {
    const itemRemoved = items.find(
      (item) => item.id.replace(/:.*$/, '') === sku
    )

    SalesforceInteractions?.sendEvent({
      interaction: {
        name: 'Remove From Cart',
        lineItem: {
          catalogObjectType: 'Product',
          catalogObjectId: itemRemoved?.id,
          quantity: itemRemoved?.quantity,
          price: itemRemoved?.price ?? null,
          currency: 'BRL',
          attributes: {
            itemName: itemRemoved?.itemOffered.name,
            itemCode: `${affiliate}_${itemRemoved?.itemOffered.sku}`,
            itemCategory: itemRemoved?.itemOffered.categoryTree,
            itemAffiliate: affiliate,
          },
        },
      },
      ...identity,
    })
  }

  const selectItemEventSalesforce = (
    product: ProductsQueryResponse['node']
  ) => {
    SalesforceInteractions?.sendEvent({
      interaction: {
        name: 'View Catalog Object',
        catalogObject: {
          type: 'Product',
          id: product.isVariantOf.productGroupID,
          attributes: {
            itemName: product.isVariantOf.name,
            itemCode: `${affiliate}_${product.sku}`,
            itemCategory: product.categoryTree,
            itemAffiliate: affiliate,
          },
        },
      },
      ...identity,
    })
  }

  return {
    viewProductDetailsSalesforce,
    addItemEventSalesforce,
    removeItemEventSalesforce,
    selectItemEventSalesforce,
  }
}
