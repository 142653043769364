import React from 'react'

interface CleanButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

const CleanButton: React.FC<CleanButtonProps> = ({ children, ...props }) => {
  return (
    <button
      {...props}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        font: 'inherit',
        color: 'inherit',
        cursor: 'pointer',
        outline: 'none',
      }}
    >
      {children}
    </button>
  )
}

export default CleanButton
