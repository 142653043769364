import React, { useMemo } from 'react'

import { useFAQContext } from '../../../../contexts/FAQContext'
import { FAQAccordion } from '../FAQAccordion'
import { FAQLike } from '../FAQLike'
import { FAQLinks } from '../FAQLinks'

import './faq-questions-and-answers.scss'

export const FAQQuestionsAndAnswers = () => {
  const { activeTopic, faqData, activeQuestion, isMobile } = useFAQContext()

  const filteredQuestions = useMemo(() => {
    const validation =
      activeQuestion &&
      activeQuestion !== '' &&
      activeTopic &&
      activeTopic !== ''

    if (!validation) {
      return
    }

    return faqData?.topics[activeTopic].questions.find(
      (item) => item.question === activeQuestion
    )
  }, [activeQuestion, activeTopic, isMobile])

  if (isMobile && activeQuestion !== '' && filteredQuestions) {
    return (
      <section className="faq-question-and-answers">
        <h1>{filteredQuestions?.question}</h1>

        <div
          className="faq-accordion-body"
          dangerouslySetInnerHTML={{
            __html: filteredQuestions?.answer ?? '',
          }}
        />
        <FAQLike activeQuestion={activeQuestion} />

        <FAQLinks insideAccordion />
      </section>
    )
  }

  return (
    <section className="faq-question-and-answers">
      {activeTopic && (
        <span className="faq-question-and-answers-title">{activeTopic}</span>
      )}
      {faqData &&
        activeTopic &&
        activeTopic !== '' &&
        faqData.topics?.[activeTopic].questions?.map(({ question, answer }) => (
          <FAQAccordion key={question} question={question} answer={answer} />
        ))}

      <FAQLinks />
    </section>
  )
}
