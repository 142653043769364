import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import { ClubAdvantages } from '../../ClubAdvantages'
import { FlyerOffers } from '../../FlyerOffers'
import { CampaignBanner } from '../CampaignBanner'
import { CampaignClubProducts } from '../CampaignClubProducts'
import { CampaignCommonQuestions } from '../CampaignCommonQuestions'
import { CampaignCouponsShowCase } from '../CampaignCouponsShowCase'
import { CampaignListOfScorecards } from '../CampaignListOfScorecards'
import { CampaignMosaic } from '../CampaignMosaic'
import { CampaignNotFound } from '../CampaignNotFound'
import { CampaignProductGrid } from '../CampaignProductGrid'
import { CampaignProductShelfById } from '../CampaignProductShelfById'
import { CampaignShelfBanner } from '../CampaignShelfBanner'
import { CampaignShelfButtons } from '../CampaignShelfButtons'
import { CampaignShortcuts } from '../CampaignShortcuts'
import { CampaignText } from '../CampaignText'
import { CampaignVideo } from '../CampaignVideo'

import type { CampaignsResponse } from '../../../../types/Campaigns'
import type { ReactElement } from 'react'

type ComponentData = (typeof COMPONENTS)[keyof typeof COMPONENTS] extends (
  ...args: any[]
) => ReactElement<infer P>
  ? P & { loading?: boolean }
  : any

interface RenderCampaignsProps {
  segment: string
  cmsDataLandingPage?: CampaignsResponse
  loading?: boolean
}

const COMPONENTS = {
  'Banner header': CampaignBanner,
  'Banner footer': CampaignBanner,
  Texto: CampaignText,
  Vídeo: CampaignVideo,
  Mosaico: CampaignMosaic,
  Vitrine: CampaignProductShelfById,
  'Banners com vitrines': CampaignShelfBanner,
  ButtonsWithShowcaseOrLink: CampaignShelfButtons,
  'Grid de Produtos': CampaignProductGrid,
  'Perguntas Frequentes': CampaignCommonQuestions,
  Folheto: FlyerOffers,
  ClubAdvantages,
  Banner: CampaignBanner,
  ClubProducts: CampaignClubProducts,
  CouponShowcase: CampaignCouponsShowCase,
  ListOfScorecards: CampaignListOfScorecards,
  Shortcuts: CampaignShortcuts,
}

export const RenderCampaigns = ({
  segment,
  cmsDataLandingPage,
  loading,
}: RenderCampaignsProps) => {
  if (loading) {
    return (
      <section className="grid-content">
        <Skeleton
          margin={24}
          backgroundColor="var(--color-grayScale-faded)"
          table={{ columns: 1, rows: 3 }}
          height={200}
        />
      </section>
    )
  }

  if (!cmsDataLandingPage && !loading) {
    return <CampaignNotFound segment={segment} />
  }

  return (
    <>
      {cmsDataLandingPage?.sections?.map(({ name, data }, index) => {
        const Component = COMPONENTS[name as keyof typeof COMPONENTS]

        if (!Component) {
          console.error(
            `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
          )

          return null
        }

        return (
          <Component
            key={index}
            loading={loading}
            {...(data as ComponentData)}
          />
        )
      })}
    </>
  )
}
