import './points-wrapper.scss'

import React from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { scrollToElement } from '../../../../utils/scrollToElement'
import { Points } from '../Points'

import type { Points as PointsProps } from '../../../../types/ClubCard/ClubCardBenefits'

export interface PointsWrapperProps extends PointsProps {
  linkGlobal: string
}

export const PointsWrapper = ({ linkGlobal, ...data }: PointsWrapperProps) => {
  const { width } = useWindowDimensions()
  const isWidthMobile = width && width <= 768

  const handleClickLastListPoints = (e: React.MouseEvent) => {
    e?.preventDefault()

    if (isWidthMobile) {
      return
    }

    scrollToElement('.club-card-get-card-steps', 0, true)
  }

  return (
    <Points
      {...data}
      lastList={{
        title: data?.card?.title ?? '',
        isVisible: data?.card?.visibility,
        redirect: linkGlobal,
        onClick: handleClickLastListPoints,
      }}
      className="club-card-benefits-points-wrapper"
    />
  )
}
