import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import CloseIconV2 from '../../../../assets/CloseIconV2'
import { selectFacetFilter } from '../../../../utils/foodRestrictionFunctions'
import { FoodRestrictionFilterAccordion } from '../FoodRestrictionFilterAccordion'
import { FoodRestrictionFilterOption } from '../FoodRestrictionFilterOption'

import type { RestrictionFacet } from '../../../../types/FoodRestriction'

interface FacetsProps {
  value: string
  selected: boolean
  quantity: number
  label: string
}

interface MobileFilterModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  openMobileAccordion?: string[]
  handleFacets: (newFacet: RestrictionFacet[]) => void
  allFacets?: {
    facet: string
    title: string
    options: FacetsProps[]
  }[]
  filterFacets: RestrictionFacet[]
  setFilterFacets: (filterFacets: RestrictionFacet[]) => void
  requestLoading: boolean
  setters: (label: string, facet: string) => void
  selectedCategorie: string
  categorieFacetsKey: string
}

export const MobileFilterModal = ({
  isOpen,
  setIsOpen,
  openMobileAccordion,
  allFacets,
  handleFacets,
  filterFacets,
  setFilterFacets,
  requestLoading,
  selectedCategorie,
  setters,
  categorieFacetsKey,
}: MobileFilterModalProps) => {
  return (
    <div className="mobile-filter-modal-container">
      <button
        className="mobile-filter-modal-background"
        onClick={() => setIsOpen(false)}
        style={{ width: isOpen ? '10%' : '100%' }}
      />

      <div
        className="mobile-filter-modal-content"
        style={{ width: isOpen ? '90%' : '0%' }}
      >
        <div className="mobile-filter-modal-header">
          <button
            className="mobile-filter-close-button"
            onClick={() => setIsOpen(false)}
          >
            <CloseIconV2
              width={24}
              height={24}
              color="var(--color-grayScale)"
            />
          </button>
          Filtrar por
        </div>

        <div className="mobile-filter-modal-accordions">
          {allFacets?.map(({ title, options, facet }) => (
            <FoodRestrictionFilterAccordion
              title={title}
              openMobileAccordion={openMobileAccordion}
              key={title}
            >
              {requestLoading ? (
                <Skeleton
                  table={{ rows: 4, columns: 0 }}
                  width="100%"
                  height={80}
                  backgroundColor="var(--color-grayScale-faded)"
                />
              ) : (
                <>
                  {options.map((option) => (
                    <FoodRestrictionFilterOption
                      triger={() => {
                        setters(option.label, facet)
                        selectFacetFilter({
                          facets: filterFacets,
                          setFacets: setFilterFacets,
                          key: facet,
                          value: option.value,
                          categorieFacetsKey,
                        })
                      }}
                      isChecked={
                        option.label === selectedCategorie || option.selected
                      }
                      text={option.label}
                      quantity={option.quantity}
                      key={option.label}
                    />
                  ))}
                </>
              )}
            </FoodRestrictionFilterAccordion>
          ))}
        </div>

        <div className="mobile-filter-modal-footer">
          <button
            className="mobile-filter-modal-footer-clean-button"
            onClick={() => {
              setFilterFacets([])
              handleFacets([])
              setIsOpen(false)
            }}
          >
            Limpar
          </button>

          <button
            className="mobile-filter-modal-footer-apply-button"
            onClick={() => {
              handleFacets(filterFacets)
              setIsOpen(false)
            }}
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  )
}
