import { defaultSort } from '../constants'

import type { SearchItem } from '../types/SearchDropdown'

export const textHighlighted = (
  text: string,
  searchText: string,
  isAisles?: boolean,
  quantity?: number
) => {
  const searchedTerm = searchText.trim().split(/\s+/)

  const escapedSearchText = searchedTerm.map((term) =>
    term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  )

  const regex = new RegExp(`(${escapedSearchText.join('|')})`, 'gi')

  const highlightedText = text.replace(regex, '<strong>$&</strong>')
  const highlightedAislesItem = `"${searchText}" em <strong>${text} (${quantity})</strong>`

  return isAisles ? highlightedAislesItem : highlightedText
}

const matchedChars = (searchItem: string, searchedTerm?: string) => {
  if (!searchedTerm || searchItem.split('').length < 3) {
    return undefined
  }

  const charValidated = searchItem.split('').map((char, i) => {
    return (
      i < searchedTerm.length &&
      char.toLowerCase() === searchedTerm[i].toLowerCase()
    )
  })

  const isSimilar = charValidated[0] && charValidated[1] && charValidated[2]

  return isSimilar
}

const searchedItemsOrdering = (term: string, searchedItems: SearchItem[]) => {
  return searchedItems.sort((a, b) => {
    const termA = a.text.slice(0, 3)
    const termB = b.text.slice(0, 3)

    const matchedA = matchedChars(termA, term)
    const matchedB = matchedChars(termB, term)

    if (matchedA && !matchedB) {
      return -1
    }

    if (!matchedA && matchedB) {
      return 1
    }

    return a.text.localeCompare(b.text, undefined, { sensitivity: 'base' })
  })
}

export const formatSearchedItems = (
  terms: { value: string }[],
  searchedTerm?: string
) => {
  const defaultOrdering = terms?.map((item: { value: string }) => {
    const { value } = item

    return { text: value, link: `/s/?q=${value}&sort=${defaultSort}&page=0` }
  })

  if (!searchedTerm) {
    return defaultOrdering
  }

  return searchedItemsOrdering(searchedTerm, defaultOrdering)
}
