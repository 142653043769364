import { Skeleton } from '@acctglobal/skeleton'
import { Button } from '@faststore/ui'
import { navigate } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'

import { MobileIcon } from '../../../../assets/MobileIcon'
import { OrderStoreIcon } from '../../../../assets/OrderInStoreIcon'
import SpinnerIcon from '../../../../assets/SpinnerIcon'
import { WarningIcon } from '../../../../assets/WarningIcon'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { useRepeatOrder } from '../../../../hooks/useRepeatOrder'
import { addManyProductsToCart } from '../../../../utils/products/addManyProductsToCart'
import { Image } from '../../../ui/Image'

import type { CustomerOrder, ProductsData } from '../../../../types/RepeatOrder'

import './order-card.scss'

interface OrderCardProps extends CustomerOrder {
  itemsQuantity: number
  isRepeated: boolean
  repeatOrder: (orderId: string) => void
}

const OrderCard = ({
  creationDate,
  items,
  orderId,
  type,
  isRepeated,
  repeatOrder,
}: OrderCardProps) => {
  const [orderProductsData, setOrderProductsData] = useState<
    ProductsData['products']
  >([])

  const [loading, setLoading] = useState(false)

  const { genericTaggingEvent } = useAnalyticsEvents()
  const { getProductsData } = useRepeatOrder()

  const skus = items.map(({ id }) => id)
  const seeMoreQuantity =
    items.length > 3 ? `(${String(items.length - 3).padStart(2, '0')})` : ''

  const handleSeeMore = () => {
    genericTaggingEvent('repeat_order', { name: 'redirect plp repeat order' })
    navigate(`/repetir-pedido?orderId=${orderId}&type=${type}`)
  }

  const handleRepeatOrder = async () => {
    try {
      setLoading(true)

      const products = await getProductsData(skus)

      addManyProductsToCart(products, items)
      repeatOrder(orderId)

      genericTaggingEvent('repeat_order', {
        name: 'add all products button in home',
      })
    } catch {
      throw new Error('Failed adding products to cart')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const products = await getProductsData(skus.slice(0, 4))

        setOrderProductsData(products)
      } catch {
        setOrderProductsData([])
      }
    }

    fetchProductsData()
  }, [])

  return (
    <div className="order-card-container">
      <div className="order-card-header">
        <p className="order-card-date">{creationDate}</p>

        <div className="order-card-container-order">
          {type === 'digital' ? <MobileIcon /> : <OrderStoreIcon />}
          <p>{type === 'digital' ? 'Pedido Online' : 'Pedido na Loja'}</p>
        </div>
      </div>

      <div className="order-card-body">
        <div className="order-card-images-container">
          {orderProductsData.length > 0 ? (
            <>
              {orderProductsData.map(
                ({ id, image: [{ url, alternateName }] }) => (
                  <div key={id} className="order-card-product-image">
                    <Image
                      objectFit="contain"
                      baseUrl={url}
                      layout="fullWidth"
                      sourceWidth={55}
                      sourceHeight={55}
                      backgroundColor="var(--color-grayScale-faded)"
                      options={{
                        fitIn: true,
                      }}
                      alt={alternateName}
                      loading="lazy"
                    />
                  </div>
                )
              )}
            </>
          ) : (
            <>
              {items.slice(0, 4).map(({ id }) => (
                <Skeleton key={id} width={55} height={55} animation />
              ))}
            </>
          )}
        </div>

        <div className="order-card-product">
          {items.slice(0, 3).map(({ id, quantity, name }) => (
            <h3 className="order-card-products-body" key={id}>
              <span className="quantity">{quantity}</span>
              <span className="name">{name}</span>
            </h3>
          ))}
        </div>
      </div>

      <div className="order-card-footer">
        <Button className="order-card-button-see-all" onClick={handleSeeMore}>
          Ver mais {seeMoreQuantity}
        </Button>

        <>
          {isRepeated ? (
            <p className="order-card-button-added-products">Adicionado</p>
          ) : (
            <>
              {loading ? (
                <Button
                  className="order-card-button-add-to-cart"
                  disabled={loading}
                >
                  <div className="c-loader">
                    <SpinnerIcon />
                  </div>
                </Button>
              ) : (
                <Button
                  className="order-card-button-add-to-cart"
                  onClick={handleRepeatOrder}
                >
                  Adicionar
                </Button>
              )}
            </>
          )}
        </>
      </div>

      {isRepeated && (
        <div className="order-card-alert">
          <WarningIcon />
          <p>
            O preço de alguns produtos <br /> podem ter sido ajustados
          </p>
        </div>
      )}
    </div>
  )
}

export default memo(OrderCard)
