import React, { useState, useEffect } from 'react'

import { RenderProducts } from './RenderProducts'
import { SearchTermsHeader } from './SearchTermsHeader/index'
import { defaultSort } from '../../../../constants'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { useProductsQuery } from '../../../../sdk/product/useProductsQuery'
import ProductGrid from '../../../product/ProductGrid'

import type { ProductsQueryResponse } from '../../../../types/Product'

import './telesales-search-terms.scss'

interface TelevendasSearchProps {
  terms: string[]
  handleRedirect: () => void
}

export const TelesalesSearchTerms = ({
  terms,
  handleRedirect,
}: TelevendasSearchProps) => {
  const [selectedTerm, setSelectedTerm] = useState(terms[0])

  const [listProducts, setListProducts] = useState<
    ProductsQueryResponse['node'][]
  >([])

  const [viewMore, setViewMore] = useState(true)
  const [page, setpage] = useState(0)
  const { isMobile } = useWindowDimensions()

  const itemsPerPage = isMobile ? 6 : 20
  const sort = defaultSort

  const { data: productList, isLoading } = useProductsQuery({
    first: itemsPerPage,
    after: (itemsPerPage * page).toString(),
    sort,
    term: selectedTerm,
    selectedFacets: [],
  })

  const haveMoreItems =
    productList?.pageInfo?.totalCount > itemsPerPage && viewMore

  const handleChangeTerm = (term: string) => {
    setSelectedTerm(term)
    setViewMore(true)
    setListProducts([])
    setpage(0)
  }

  const handleProductsLoading = () => {
    const itensPage = page === 0 ? 2 * itemsPerPage : (page + 1) * itemsPerPage

    if (productList?.pageInfo?.totalCount <= itensPage) {
      setViewMore(false)
    }

    setpage(page + 1)
  }

  useEffect(() => {
    if (productList) {
      const newProducts = productList?.edges.map(
        (edge: { node: ProductsQueryResponse['node'][] }) => edge.node
      )

      setListProducts([...listProducts, ...newProducts])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList])

  return (
    <>
      <SearchTermsHeader
        terms={terms}
        changeTerm={handleChangeTerm}
        selectedTerm={selectedTerm}
      />

      <RenderProducts
        products={listProducts}
        selectedTerm={selectedTerm}
        handleRedirect={handleRedirect}
        isLoading={isLoading}
      >
        <div className="grid-content telesales-grid">
          <div className="telesales-title">
            <span>{productList?.pageInfo?.totalCount}</span> produtos
            encontrados
          </div>
          <ul className="product-grid" data-testid="product-grid">
            <ProductGrid
              location="telesales-search"
              products={listProducts}
              page={0}
            />
          </ul>
          {haveMoreItems && (
            <button
              className={`${isMobile && 'grid-content'} telesales-showmore`}
              onClick={() => handleProductsLoading()}
            >
              Mostrar Mais
            </button>
          )}
        </div>
      </RenderProducts>
    </>
  )
}
