import React, { useMemo } from 'react'

import { discount_desc } from '../../../constants'
import { ProductShelfByFilters } from '../ProductShelf/ProductShelfByFilters'

import type { DefaultFacets } from '../../../types/Campaigns'

import './ProductShelfById.scss'

export type ProductShelfByIdDataProps = {
  shelfValue?: string
  shelfType?: 'category' | 'cluster' | 'brand'
  visibility?: boolean
  shelfTitle?: string
  seeAllLink?: string
}

export type ProductShelfByIdProps = {
  loading?: boolean
  data?: ProductShelfByIdDataProps
}

export const ProductShelfById = ({ data }: ProductShelfByIdProps) => {
  const { shelfType, shelfValue, visibility, shelfTitle, seeAllLink } =
    data ?? {}

  const campignTypes = {
    category: 'category',
    cluster: 'productClusterIds',
    brand: 'brand',
  }

  const facets: DefaultFacets[] | undefined = useMemo(() => {
    if (!shelfType || !shelfValue) {
      return
    }

    if (shelfType !== 'category') {
      return [{ key: campignTypes[shelfType], value: shelfValue }]
    }

    const shelfValueReplaced = shelfValue
      .split('/')
      .filter((item) => item !== '')

    return shelfValueReplaced.map((item, index) => ({
      key: `category-${index + 1}`,
      value: item,
    }))
  }, [shelfType, shelfValue])

  if (!visibility) return <></>

  return (
    <ProductShelfByFilters
      title={shelfTitle ?? ''}
      link={seeAllLink}
      id={shelfValue ?? ''}
      buttonSeeAll={!!seeAllLink}
      queryInput={{
        selectedFacets: facets,
        sort: discount_desc,
        firstItems: 10,
      }}
    />
  )
}
