import '../styles/pages/club-card-user-page.scss'

import React, { useEffect } from 'react'

import Spinner from '../assets/SpinnerIcon'
import { Coupons } from '../components/ClubCard/Coupons'
import { Offers } from '../components/ClubCard/User/Offers'
import { PointsWrapper } from '../components/ClubCard/User/PointsWrapper'
import { Shortcuts } from '../components/ClubCard/User/Shortcuts'
import { Banner } from '../components/sections/Banner'
import { useCardContext } from '../contexts/CardContext'
import { useClubContext } from '../contexts/ClubContext'
import { useStoreContext } from '../contexts/StoreContext'
import { goToBenefitsClubCardPlus, goToLogin } from '../utils/navigation'

import type { ClubCardUserPageSectionData } from '../types/ClubCard/ClubCardUser'

interface ClubCardUserPageProps {
  data: ClubCardUserPageSectionData
}

export function ClubCardUserPage({ data }: ClubCardUserPageProps) {
  const { loginUrl } = useStoreContext()
  const { isLoading, userInfos } = useClubContext()
  const { balance, getBalance, pointsUrl, getPointsUrl } = useCardContext()

  const { shortcuts, desktopBanner, offers, marketingBanner, coupons, points } =
    data

  const bannerDesktop = {
    ...desktopBanner,
    mobile: desktopBanner?.desktop,
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!userInfos?.id) {
      goToLogin(loginUrl, window?.location?.pathname)

      return
    }

    if (!userInfos?.isCartonist) {
      goToBenefitsClubCardPlus()
    }
  }, [isLoading, userInfos])

  useEffect(() => {
    async function fetchCardInfos() {
      // Prevent requests if already has data
      const getBalanceRequest = balance?.data ? undefined : getBalance
      const getPointsUrlRequest = pointsUrl?.data ? undefined : getPointsUrl

      await Promise.all([getBalanceRequest?.(), getPointsUrlRequest?.()])
    }

    fetchCardInfos()
  }, [])

  if (isLoading || !userInfos?.isCartonist) {
    return (
      <div className="club-card-user-page-loader">
        <Spinner color="var(--loyalty-color-surface-strong)" />
      </div>
    )
  }

  return (
    <div className="club-card-user-page-container">
      <div className="club-card-user-shortcut-container grid-content">
        <Shortcuts {...shortcuts} location="cartonista" />
        <Banner
          data={bannerDesktop}
          className="club-card-user-shortcutBanner"
        />
      </div>

      <Offers {...offers} />
      <Banner data={marketingBanner} />
      <Coupons {...coupons} />
      <PointsWrapper {...points} />
    </div>
  )
}
