import { Carousel } from '@plurix/ecom-components'
import React, { useEffect, useState } from 'react'

import { HAS_WINDOW } from '../../../constants'
import { useMultipleSearchContext } from '../../../contexts/MultipleSearchContext'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Button from '../Button'
import './menu-carousel.scss'

interface MenuCarouselProps {
  termArray?: string[]
  children?: React.ReactNode
}

const MenuCarousel = ({ termArray, children }: MenuCarouselProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()
  const { currentSelectedTerm, setCurrentSelectedTerm, menuVisibleTerms } =
    useMultipleSearchContext()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  const carouselDisplacement = (elementId: string, mainHeaderId: string) => {
    if (!HAS_WINDOW) {
      return
    }

    const elementOffsetTop = document.getElementById(elementId)?.offsetTop ?? 0
    const sectionTitleHeight =
      document.getElementById(elementId)?.offsetHeight ?? 0

    const headerHeight =
      document.getElementById(mainHeaderId)?.offsetHeight ?? 0

    const displacement =
      elementOffsetTop - (sectionTitleHeight + 40) - headerHeight

    return displacement > 0 ? displacement : 0
  }

  const carouselNavigation = (term: string) => {
    if (!HAS_WINDOW) {
      return
    }

    scroll(0, carouselDisplacement(term, 'main-header') ?? 0)
  }

  const handleNavigation = (term?: string) => {
    if (term) {
      carouselNavigation(term)
      setCurrentSelectedTerm?.(term)
    }
  }

  if (termArray && termArray.length <= 0) {
    return null
  }

  return (
    <div className="product-carousel-grid" id="carousel-menu-content">
      <div className="container-term-grid grid-content">
        <Carousel
          arrow={{
            isVisible: !(width <= 1280),
            iconColor: 'var(--color-main)',
            style: {
              margin: '5px',
              cursor: 'pointer',
              backgroundColor: 'var(--color-main-faded)',
            },
          }}
        >
          <div className="carousel-menu">
            <ul>
              {menuVisibleTerms?.map((term) => (
                <li
                  key={term}
                  className={`${
                    currentSelectedTerm == term ? 'term-selected' : ''
                  }`}
                >
                  <Button onClick={() => handleNavigation(term)}>{term}</Button>
                </li>
              ))}
            </ul>
          </div>
        </Carousel>
        {children}
      </div>
    </div>
  )
}

export default MenuCarousel
