export const extractYouTubeVideoId = (url: string): string | undefined => {
  const match = url.match(
    /(?:\?v=|\/embed\/|\/\d+\/|\/vi\/|\/v\/|https?:\/\/(?:www\.)?youtube\.com\/v\/|https?:\/\/(?:www\.)?youtube\.com\/embed\/|https?:\/\/(?:www\.)?youtu\.be\/|https?:\/\/(?:www\.)?youtube\.com\/watch\?v=)([^#\&\?\/]+)/
  )

  if (match && match[1]) {
    return match[1]
  }

  return undefined
}

export const extractYouTubeVideoLink = (videoId: string): string => {
  return `https://www.youtube.com/embed/${videoId}`
}
