import { useAnalyticsEvent } from '@faststore/sdk'

import type { AnalyticsEvent } from '@faststore/sdk'

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer ?? []
}

export const AnalyticsHandler = () => {
  useAnalyticsEvent((event: AnalyticsEvent) => {
    // Cleans the ecommerce object before pushing a new one
    // This prevents the new data from getting merged with the previous one
    // which could lead do inaccurate and old data being sent with events
    //
    // source: https://developers.google.com/tag-manager/ecommerce-ga4?hl=pt-br#clearing_the_ecommerce_object
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({ event: event.name, ecommerce: event.params })

    /* These code are not being used and generating errors in console.
    const platform = window.localStorage.getItem('platform')

    import(`./platform/${platform}`).then(({ default: sendEvent }) => {
      sendEvent(event)
    })
    */
  })

  return null
}

export default AnalyticsHandler
