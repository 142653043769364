import React from 'react'

interface ProductDetailsMoreInfosProps {
  title: string
  description?: string
  headerStyleClass?: string
}
const ProductMoreInfos = ({
  title,
  description,
  headerStyleClass,
}: ProductDetailsMoreInfosProps) => {
  const normalizeDescription =
    description?.replaceAll(
      '<li>',
      '<li className="more-infos-description-list"> '
    ) ?? ''

  return (
    <div className={headerStyleClass ?? 'more-infos__description'}>
      <h2>{title}</h2>

      <p
        dangerouslySetInnerHTML={{ __html: normalizeDescription }}
        className="more-infos__description-text"
      />
    </div>
  )
}

export default ProductMoreInfos
