import React from 'react'

import CloseIcon from '../../../../../assets/AlertCloseIcon'
import { useStoreContext } from '../../../../../contexts/StoreContext'
import './orderStatus.scss'
import { HAS_WINDOW } from '../../../../../constants'

export type shippingEstimate = {
  dayOfTheWeek?: string
  dayAndMonth?: string
  timeRange?: string
}

interface Props {
  orderId?: string
  shippingMethod?: string
  closeButtonHandler?: () => void
  shippingEstimate?: shippingEstimate
  isPopUp?: boolean
}

export const OrderStatus = ({
  orderId,
  shippingMethod,
  shippingEstimate,
  closeButtonHandler,
  isPopUp,
}: Props) => {
  const { dayAndMonth, dayOfTheWeek, timeRange } = shippingEstimate ?? {}
  const { secureSubdomain } = useStoreContext()

  const redirect = () => {
    if (HAS_WINDOW) {
      window.location.href = `${secureSubdomain}/api/io/account#/orderhistory/${orderId}`
    }
  }

  const shippingMethodLabel =
    shippingMethod === 'delivery' ? 'Entrega' : 'Retirada'

  return (
    <div
      className={`orderStatusContainer ${
        isPopUp ? 'orderStatusContainerPopUp' : ''
      } `}
    >
      <section className="orderStatusContainer-containerTitle">
        {orderId && (
          <label
            onClick={() => redirect()}
            className="orderStatusContainer-title"
          >
            Pedido {orderId}
          </label>
        )}

        {closeButtonHandler && (
          <button
            className="orderStatusContainer-closeButton"
            onClick={closeButtonHandler}
          >
            <CloseIcon color="var(--color-main)" />
          </button>
        )}
      </section>

      {shippingMethod && (
        <label
          onClick={() => redirect()}
          className="orderStatusContainer-subtitle"
        >
          Previsão de {shippingMethodLabel}
        </label>
      )}
      <label
        onClick={() => redirect()}
        className="orderStatusContainer-description"
      >
        <p className="orderStatusContainer-description-leftContent">
          {dayOfTheWeek && (
            <span className="orderStatusContainer-description-highlight">
              {dayOfTheWeek}
            </span>
          )}
          , {dayAndMonth ?? ''}
        </p>{' '}
        {timeRange && (
          <span className="orderStatusContainer-description-highlight">
            {timeRange}
          </span>
        )}
      </label>
    </div>
  )
}
