import { navigate } from 'gatsby'
import React from 'react'

import { EmptyScreenSvg } from './EmptyListSvg'

import './empty-list.scss'

interface Props {
  handleEditTerms: () => void
}

export const EmptyList = ({ handleEditTerms }: Props) => {
  const handleHomePage = () => {
    navigate('/')
  }

  return (
    <div className="container">
      <div className="main_screen">
        <p className="title">Oops, parece que sua lista está vazia</p>
        <EmptyScreenSvg />
        <p className="text">
          Sem problemas, você pode criar uma nova lista de compras!
        </p>
        <div className="button_container">
          <button className="button_primary" onClick={handleEditTerms}>
            Criar minha lista de compras
          </button>
          <button className="button_secondary" onClick={handleHomePage}>
            Ir para o Site
          </button>
        </div>
      </div>
    </div>
  )
}
