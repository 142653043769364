import React from 'react'

import {
  CouponsIcon,
  CartIcon,
  WindowIcon,
  GiftIcon,
  MoneyIcon,
} from '../../../../assets/scratchIcons'

import './scratch-how-to-participate.scss'
import type { ScratchPageContent } from '../../../../types/CMS'

type IconKey =
  | 'CouponsIcon'
  | 'CartIcon'
  | 'WindowIcon'
  | 'GiftIcon'
  | 'MoneyIcon'

const iconMap: Record<IconKey, React.FC> = {
  CouponsIcon,
  CartIcon,
  WindowIcon,
  GiftIcon,
  MoneyIcon,
}

interface ScratchHowToParticipateProps {
  handleSeeMore: () => void
  scratchPageContent?: ScratchPageContent
}

export const ScratchHowToParticipate = ({
  handleSeeMore,
  scratchPageContent,
}: ScratchHowToParticipateProps) => {
  return (
    <article className="scratch-how-to-participate">
      <h2>{scratchPageContent?.participationTitle}</h2>

      <ul>
        {scratchPageContent?.participationCriteria?.map(
          ({ description, icon }) => {
            const IconComponent =
              icon in iconMap ? iconMap[icon as IconKey] : null

            return (
              <li key={description}>
                {IconComponent && <IconComponent />} {description}
              </li>
            )
          }
        )}
      </ul>

      <div className="know-more-container">
        <button onClick={handleSeeMore}>Saber Mais</button>
      </div>
    </article>
  )
}
