import React, { useEffect, useState } from 'react'

import { EmptyList } from '../components/sections/Telesales/EmptyList'
import { TelesalesBuyBar } from '../components/sections/Telesales/TelesalesBuyBar'
import { TelesalesEditList } from '../components/sections/Telesales/TelesalesEditList'
import { TelesalesHeader } from '../components/sections/Telesales/TelesalesHeader'
import { TelesalesLoading } from '../components/sections/Telesales/TelesalesLoading'
import { TelesalesModal } from '../components/sections/Telesales/TelesalesModal'
import { TelesalesSearchTerms } from '../components/sections/Telesales/TelesalesSearchTerms'
import { HAS_LOCAL_STORAGE } from '../constants'
import { useClubContext } from '../contexts/ClubContext'
import { useLocalStorageContext } from '../contexts/LocalStorageContext'
import { useRegionalizationContext } from '../contexts/RegionalizationContext'
import { addParamToURL, removeParamFromUrl } from '../utils/handleUrlParam'

import type { ModalContent } from '../components/sections/Telesales/TelesalesModal'

interface TelesalesPageProps {
  postalCode: string
  userId: string | null
  termsArr: string[]
  isClub: boolean | null
  href: string
}

export type HandleTerms = {
  terms: string[]
  setTerms: React.Dispatch<React.SetStateAction<string[]>>
}

export function TelesalesPage({
  postalCode,
  userId,
  isClub,
  href,
  termsArr,
}: TelesalesPageProps) {
  const [modalContent, setModalContent] = useState<ModalContent | null>(null)
  const [terms, setTerms] = useState([''])
  const [isEditView, setIsEditView] = useState(false)

  const { catalogRegionalize } = useRegionalizationContext()
  const { setLocalStorage } = useLocalStorageContext()
  const { userInfos } = useClubContext()

  const isLoggedIn = userInfos?.id !== undefined && userInfos?.id !== ''
  const isSameUser = isLoggedIn && userInfos?.id === userId
  const modalIsOpen = modalContent !== null

  const handleTerms: HandleTerms = {
    terms,
    setTerms,
  }

  const handleEdit = (editing: boolean) => {
    setIsEditView(editing)

    if (editing) {
      addParamToURL('edit', 'true')

      return
    }

    removeParamFromUrl('edit')
  }

  useEffect(() => {
    catalogRegionalize?.(postalCode)

    setLocalStorage?.('postalCode', postalCode)
    setLocalStorage?.('method', 'delivery')

    if (userInfos?.id === undefined || !userId) {
      return
    }

    if (!isSameUser && isLoggedIn && !isClub) {
      setModalContent({
        title: 'Identificamos que você já tem uma conta',
        subtitle: 'Para acessar sua lista de compras, por favor, faça o login.',
        action: isLoggedIn ? 'logout' : 'login',
      })

      return
    }

    if (!isSameUser && isClub) {
      setModalContent({
        title: 'Identificamos que você é um cliente clube',
        subtitle:
          'Para acessar a lista de compras com preços exclusivos, por favor, faça o login.',
        action: isLoggedIn ? 'logout' : 'login',
      })

      return
    }

    setModalContent(null)
  }, [isLoggedIn, isSameUser, isClub, userInfos])

  useState(() => {
    if (!HAS_LOCAL_STORAGE) {
      return
    }

    const localStorageTerms = localStorage.getItem('telesalesTerms')?.split(',')

    setTerms(localStorageTerms || termsArr || [''])
  }, [])

  if (modalIsOpen) {
    return (
      <>
        <TelesalesModal
          isOpen={modalIsOpen}
          handleClose={() => setModalContent(null)}
          modalContent={modalContent}
          href={href}
          isClub={isClub}
        />
        <TelesalesLoading />
      </>
    )
  }

  if (isEditView) {
    return (
      <TelesalesEditList
        handleReturn={() => handleEdit(false)}
        handleTerms={handleTerms}
      />
    )
  }

  if (!terms.length) {
    return <EmptyList handleEditTerms={() => handleEdit(true)} />
  }

  return (
    <>
      <TelesalesHeader handleRedirect={() => handleEdit(true)} />
      <TelesalesSearchTerms
        terms={terms}
        handleRedirect={() => handleEdit(true)}
      />
      <TelesalesBuyBar
        haveTerms={termsArr ? termsArr?.length > 0 : false}
        handleRedirect={() => handleEdit(true)}
        isClub={isClub}
      />
    </>
  )
}
