import './main-banner.scss'

import React from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { scrollToElement } from '../../../../utils/scrollToElement'
import { Banner } from '../../../sections/Banner'

import type { GenericBanner as ClubCardPageMainBannerProps } from '../../../../types/CMS'

interface MainBannerProps extends ClubCardPageMainBannerProps {
  className?: string
}

export const MainBanner = ({ className = '', ...data }: MainBannerProps) => {
  const { width } = useWindowDimensions()
  const isWidthMobile = width && width <= 768

  const handleClick = (e: React.MouseEvent) => {
    e?.preventDefault()

    if (isWidthMobile) {
      return
    }

    scrollToElement('.club-card-get-card-steps', 0, true)
  }

  return (
    <Banner
      data={data}
      openNewTab={!!isWidthMobile}
      onClick={handleClick}
      className={className}
    />
  )
}
