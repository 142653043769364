import { Coupons as NpmCoupons } from '@plurix/ecom-components'
import { Link } from 'gatsby'
import React from 'react'

import { useStoreContext } from '../../../contexts/StoreContext'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useCart } from '../../../sdk/cart'
import { DynamicTitle } from '../../common/DynamicTitle'

import type { ClubCardUserPageSectionData } from '../../../types/ClubCard/ClubCardUser'

import './coupons.scss'

export function Coupons(props: ClubCardUserPageSectionData['coupons']) {
  const { visibility, title, seeMoreText, text, list } = props

  const { account } = useStoreContext()
  const { id: orderFormId, items } = useCart()
  const { genericTaggingEvent } = useAnalyticsEvents()

  if (!visibility || !list?.length) {
    return null
  }

  return (
    <div className="club-card-user-coupons grid-content">
      <div className="coupons-header">
        <DynamicTitle title={title} titleLevel={2} className="coupons-title" />
        <Link to="/cupons-de-oferta">{seeMoreText}</Link>
      </div>

      <p className="coupons-subtitle">{text}</p>

      <NpmCoupons
        account={account}
        builder="faststore"
        hasItemsInCart={items.length > 0}
        orderFormId={orderFormId}
        genericTaggingEvent={genericTaggingEvent}
        disableFirstEmptyModal
        couponsList={list}
      />
    </div>
  )
}
