import React from 'react'

interface DynamicTitleProps {
  className?: string
  titleLevel: number
  title: string
  titleColor?: string
}

export function DynamicTitle({
  className,
  titleLevel,
  title,
  titleColor,
}: DynamicTitleProps) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: `<h${titleLevel} ${titleColor && `style=color:${titleColor};`} >${title}</h${titleLevel}>`,
      }}
    />
  )
}
