import React from 'react'

import { useClubContext } from '../../contexts/ClubContext'
import { useStoreContext } from '../../contexts/StoreContext'
import { Banner } from '../ui/Banner'

import type { CommonCmsBanner } from '../../types/CMS'

interface Props {
  data?: CommonCmsBanner
}

export const ClubRegisterBanner = ({ data }: Props) => {
  const { src, srcMobile, visibility, alt } = data ?? {}

  const { hasClubRegister } = useStoreContext()
  const { isClubClient } = useClubContext()

  if (!src || !srcMobile || !visibility || !hasClubRegister || isClubClient) {
    return null
  }

  return (
    <Banner
      href="/clube"
      src={src}
      srcMobile={srcMobile}
      alt={alt ?? ''}
      bannerWidth={720}
      bannerHeight={268}
      bannerDeskWidth={1240}
      bannerDeskHeight={190}
    />
  )
}
