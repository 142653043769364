import React from 'react'

interface ArrowIconProps {
  width?: number
  height?: number
  color?: string
}

export const ArrowIcon = ({
  width = 24,
  height = 24,
  color = 'var(--color-main)',
}: ArrowIconProps = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6L16 12L10 18"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
