import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'

import { HAS_WINDOW } from '../constants'

import type { DeliveryProps } from '../types/Regionalization'
import type { SearchItem } from '../types/SearchDropdown'
import type { PikcupPointType } from '@plurix/ecom-components'

interface LocalStorageContextProps {
  getLocalStorageItem?: () => GetLocalStorageItemProps
  setLocalStorage?: (name: string, value: string) => void
  children?: React.ReactNode
  cookiesModalRef?: React.MutableRefObject<HTMLDivElement | null>
  cookiesModalOpen?: boolean
  setCookieModalOpen?: (modalOpen: boolean) => void
  cookiesConfirm?: boolean
  setCookieConfirm?: (coockiesConfirm: boolean) => void
  setLocationHref?: (locationHref: string) => void
  addLastSearchedItem?: (searchText?: string, link?: string) => void
  lastSearched?: SearchItem[]
  getLocalStorage?: (name: string) => string | null | undefined
}

interface GetLocalStorageItemProps {
  acceptedCookie?: boolean
  regionalized?: string
  pickUpPointInfos?: {
    name?: string
    pickupTimes?: PikcupPointType['businessHours']['businessHoursWeek']
  }
  deliveryInfos?: DeliveryProps
  userAddressInfos?: UserAddressInfosProps
  clubModalViewed?: boolean
}

interface UserAddressInfosProps {
  postalCode?: string
  city?: string
  uf?: string
}

const LocalStorageContext = createContext<LocalStorageContextProps | null>(null)

export const LocalStorageProvider = ({
  children,
}: LocalStorageContextProps) => {
  const [cookiesModalOpen, setCookieModalOpen] = useState(true)
  const [cookiesConfirm, setCookieConfirm] = useState(false)
  const [lastSearched, setLastSearched] = useState<SearchItem[]>([])

  const cookiesModalRef = useRef<HTMLDivElement | null>(null)

  const setLocalStorage = (name: string, value: string) => {
    if (!HAS_WINDOW) {
      return
    }

    localStorage.setItem(name, value)
  }

  const getLocalStorage = (name: string) => {
    if (!HAS_WINDOW) {
      return
    }

    return localStorage.getItem(name)
  }

  const getLocalStorageItem = () => {
    const postalCode = getLocalStorage('postalCode')
    const method = getLocalStorage('method')
    const pickupInfos = JSON.parse(getLocalStorage('pickupInfos') ?? '{}')
    const acceptedCookie = getLocalStorage('acceptedCookie') === 'true'

    const deliveryInfos = JSON.parse(getLocalStorage('deliveryInfos') ?? '{}')
    const clubModalViewed = getLocalStorage('clubModalViewed') === 'true'

    const pickUpPointInfos: {
      name?: string
      pickupTimes?: PikcupPointType['businessHours']['businessHoursWeek']
    } = {
      name: pickupInfos?.pickupPointName,
      pickupTimes: pickupInfos?.pickupTimes,
    }

    const userAddressInfos: UserAddressInfosProps = {
      postalCode: postalCode ?? '',
      city: deliveryInfos?.city,
      uf: deliveryInfos?.uf,
    }

    const regionalized: string | undefined = method ?? ''

    return {
      acceptedCookie,
      regionalized,
      pickUpPointInfos: regionalized ? pickUpPointInfos : undefined,
      deliveryInfos: regionalized ? deliveryInfos : undefined,
      userAddressInfos,
      clubModalViewed,
    }
  }

  const addLastSearchedItem = (text?: string, link?: string) => {
    if (!HAS_WINDOW || (!text && !link)) {
      return
    }

    const currentLastSearched = JSON.parse(
      getLocalStorage('lastSearched') ?? '[]'
    )

    const newSearchItems = currentLastSearched
      .filter(
        (item: SearchItem) =>
          item.text.toLocaleLowerCase() !== text?.toLocaleLowerCase()
      )
      .slice(0, 9)

    newSearchItems.unshift({ text: text ?? '', link })

    localStorage.setItem('lastSearched', JSON.stringify(newSearchItems))
    setLastSearched(newSearchItems)
  }

  useEffect(() => {
    setLastSearched(JSON.parse(getLocalStorage('lastSearched') ?? '[]'))
    setCookieConfirm(getLocalStorageItem?.()?.acceptedCookie)
    setCookieModalOpen(!getLocalStorageItem?.()?.acceptedCookie)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HAS_WINDOW])

  return (
    <LocalStorageContext.Provider
      value={{
        addLastSearchedItem,
        lastSearched,
        cookiesModalRef,
        cookiesModalOpen,
        setCookieModalOpen,
        cookiesConfirm,
        setCookieConfirm,
        getLocalStorage,
        setLocalStorage,
        getLocalStorageItem,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}

export const useLocalStorageContext = () => {
  const context = useContext(LocalStorageContext)

  if (!context) {
    throw new Error(
      'useLocalStorageContext must be used within a LocalStorageProvider'
    )
  }

  return context
}
