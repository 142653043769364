import React, { useState } from 'react'

import { useStoreContext } from '../../../contexts/StoreContext'

import './engagement-banner.scss'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { validatePlatform } from '../../../utils/validatePlatform'
import EngagementModal from '../../common/EngagementModal'
import { Banner } from '../../ui/Banner'

import type { CommonCmsBanner } from '../../../types/CMS'

export function EngagementBanner({ data }: { data?: CommonCmsBanner }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { downloadModal } = useStoreContext()
  const { isMobile } = useWindowDimensions()

  const platforms = downloadModal?.platforms

  const showComponent = validatePlatform({ isMobile, platforms })

  const { src, srcMobile, visibility } = data ?? {}

  if (!src || !showComponent || !visibility) {
    return null
  }

  return (
    <>
      <section className="engagement-banner">
        <Banner
          href="#"
          src={src}
          srcMobile={srcMobile}
          alt="engagement-banner-image"
          bannerDeskWidth={1366}
          bannerDeskHeight={88}
          handleClick={() => setIsModalOpen(true)}
        />
      </section>

      <EngagementModal
        isModalOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
      />
    </>
  )
}
