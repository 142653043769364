import React, { createContext, useCallback, useContext, useMemo } from 'react'

import { ProductsQueryResponse } from '../types/Product'
import { replaceStringBreadcrumb } from '../utils/replaceStrings'

export interface SpecialPromotions {
  collectionID: string
  flagText: string
  colorCode: string
}
export interface ClusterHighlights {
  id: string
  name: string
}

interface SpecialPromotionsContextProps {
  children?: React.ReactNode
  allPromotions?: SpecialPromotions[]
  searchPromotion?: (
    product: ProductsQueryResponse['node']
  ) => SpecialPromotions | undefined
}

const transformCategoryList = (categoryTree: string[]) => {
  return categoryTree?.map((category) => {
    const categoryWithoutBar = category?.replace(/\/$/, '')

    return replaceStringBreadcrumb(categoryWithoutBar)
  })
}

const SpecialPromotionsContext =
  createContext<SpecialPromotionsContextProps | null>(null)

export const SpecialPromotionsContextProvider = ({
  children,
  allPromotions,
}: SpecialPromotionsContextProps) => {
  const searchPromotion = useCallback(
    (product: ProductsQueryResponse['node']) => {
      const brand = replaceStringBreadcrumb(product?.brand?.brandName, true)
      const categories = transformCategoryList(product?.categoryTree) ?? []
      const collections =
        product?.clusterHighlights?.map((item) => item.id) ?? []

      const productPromotionsListOption = [brand, ...categories, ...collections]

      return allPromotions?.find((promotion) => {
        const optionPromotionCmsWithoutBar = promotion?.collectionID?.replace(
          /\/$/,
          ''
        )

        return productPromotionsListOption?.includes(
          optionPromotionCmsWithoutBar
        )
      })
    },
    [allPromotions]
  )

  const values = useMemo(
    () => ({
      searchPromotion,
    }),
    []
  )

  return (
    <SpecialPromotionsContext.Provider value={values}>
      {children}
    </SpecialPromotionsContext.Provider>
  )
}

export const useSpecialPromotionsContext = () => {
  const context = useContext(SpecialPromotionsContext)

  if (!context) {
    throw new Error(
      'useSpecialPromotionsContext must be used within a SpecialPromotionsContextProvider'
    )
  }

  return context
}
