import { getMobileOS } from '../../../utils/getMobileOS'

interface ValidateLinkProps {
  deepLink?: {
    apple: string
    android: string
  }
  href: string
}

export const validateLink = ({ deepLink, href }: ValidateLinkProps) => {
  const SO_Detector = getMobileOS()

  if (SO_Detector === 'iOS' && deepLink?.apple) {
    return deepLink.apple
  }

  if (SO_Detector === 'Android' && deepLink?.android) {
    return deepLink.android
  }

  return href
}
