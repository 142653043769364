import React, { useState } from 'react'

import { Like } from '../../../../assets/faqIcons/Like'
import { Unlike } from '../../../../assets/faqIcons/UnlikeIcon'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { FAQVirtualServiceCards } from '../FAQVirtualServiceCards'

import './faq-like.scss'

export const FAQLike = (activeQuestion: any) => {
  const [isLiked, setIsLiked] = useState(false)
  const { evaluateEvent } = useAnalyticsEvents()
  const handleClick = (value: string) => {
    setIsLiked(true)
    evaluateEvent(value, activeQuestion)
  }

  return (
    <>
      <h2 className="evaluate-title">Essa resposta foi útil?</h2>
      <div className="evaluate-container">
        <button className="evaluate-button" onClick={() => handleClick('like')}>
          <Like />
          <p className="evaluate-text">Sim, me ajudou</p>
        </button>

        <button
          className="evaluate-button"
          onClick={() => handleClick('unlike')}
        >
          <Unlike />

          <p className="evaluate-text">Não ajudou</p>
        </button>
      </div>
      {isLiked && (
        <div>
          <p className="evaluated-mensage">Obrigado por avaliar</p>
        </div>
      )}

      <h2 className="faq-links-title">
        {true ? 'Continuou com alguma dúvida?' : 'Atendimento Virtual'}
      </h2>
      <FAQVirtualServiceCards />
    </>
  )
}
