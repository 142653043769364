import React, { Suspense, useRef, useState } from 'react'

import { MultipleSearchAddIcon } from '../../../assets/MultipleSearchAddIcon'
import { MultipleSearchInputIcon } from '../../../assets/MultipleSearchInputIcon'
import SearchDeleteIcon from '../../../assets/SearchDeleteIcon'
import { WarningIcon } from '../../../assets/WarningIcon'
import useSuggestions from '../../../sdk/search/useSuggestions'
import useOnClickOutside from '../../../sdk/ui/useOnClickOutside'
import { MultipleSearchInputDropdown } from '../../sections/MultipleSearch/MultipleSearchInputDropdown'

import type { HandleTerms } from '../../../pages/TelesalesPage'

import './telesales-terms-input.scss'

interface Props {
  handleTerms: HandleTerms
}

export const TelesalesTermsInput = ({
  handleTerms: { terms, setTerms },
}: Props) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')
  const dropdownContainer = useRef<HTMLLabelElement>(null)

  const { terms: suggestion } = useSuggestions('')

  const haveSearchedItems = suggestion?.length > 0
  const limitReached = terms.length === 30

  const handleChangeTerms = () => {
    setDropdownIsOpen(false)

    if (input !== '' && terms.length < 30) {
      const termsArray = input
        .split(',')
        .map((t) => t.trim())
        .filter((term) => term !== '')

      const filteredTerms = [...new Set([...terms, ...termsArray])].slice(0, 30)

      setTerms(filteredTerms)

      setInput('')
    }
  }

  useOnClickOutside(dropdownContainer, () => setDropdownIsOpen(false))

  return (
    <>
      {limitReached && (
        <div className="terms-limit-warning">
          <WarningIcon />
          <span>Você atingiu o valor máximo de produtos</span>
        </div>
      )}
      <label className="terms-input-container" ref={dropdownContainer}>
        <div
          className={`terms-input-subcontainer ${
            limitReached ? 'disabled' : ''
          }`}
        >
          <div className="terms-input-icon">
            <MultipleSearchInputIcon
              color={limitReached ? '#C6C6C6' : undefined}
            />
          </div>

          <input
            className="terms-input-input"
            type="text"
            value={input}
            onChange={({ target: { value: text } }) => {
              setInput(text)

              if (text.length >= 3) {
                setDropdownIsOpen(true)

                return
              }

              setDropdownIsOpen(false)
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
              event.key === 'Enter' && handleChangeTerms()
            }
            placeholder="Adicione à lista de compras"
            disabled={limitReached}
          />

          {input && (
            <button
              className="terms-input-delete-x"
              onClick={() => setInput('')}
            >
              <SearchDeleteIcon
                width={24}
                height={24}
                color="var(--color-main)"
              />
            </button>
          )}
        </div>

        <button
          className="terms-input-add-button"
          onClick={handleChangeTerms}
          disabled={limitReached}
        >
          <MultipleSearchAddIcon color={limitReached ? '#C6C6C6' : undefined} />
        </button>

        {dropdownIsOpen && haveSearchedItems && (
          <Suspense fallback={null}>
            <MultipleSearchInputDropdown
              suggestion={suggestion}
              title="SUGESTÕES"
              term={input}
              setTerm={setInput}
              multipleTerms={terms}
              setMultipleTerms={setTerms}
              setDropdownIsOpen={setDropdownIsOpen}
            />
          </Suspense>
        )}
      </label>
    </>
  )
}
