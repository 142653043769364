import { textClubAccount } from '../constants'

export const formatAccountNameSF = (account: string) => {
  switch (account) {
    case 'boa':
      return 'boa'

    case 'superpao':
      return 'spo'

    case 'compremais':
      return 'cpm'

    case 'avenidab2c':
      return 'ave'

    case 'domolivio':
      return 'dom'

    default:
      return 'smr'
  }
}

export function formatAccountName(account?: string): string {
  const Accounts = {
    avenidab2c: 'Avenida',
    boa: 'Boa',
    compremais: 'Compre Mais',
    domolivio: 'Dom Olivio',
    smrholding: 'SMR Holding',
    amigao: 'Amigão',
    superpao: 'Superpão',
  }

  return Accounts[account as keyof typeof Accounts] || Accounts.smrholding
}

export function getClubName(account: string): string {
  return `Ofertas ${textClubAccount(account)}`
}
