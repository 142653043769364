import axios from 'axios'

import type { Profile } from '../types/Club'

export const getProfileData = async () => {
  try {
    const { data: profile }: { data: Profile } = await axios.get(
      `/api/club/getProfile?random=${Math.random()}`
    )

    return profile
  } catch (err) {
    throw new Error('Get profile data: ', err)
  }
}
