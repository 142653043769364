import React from 'react'

interface SuccessIconProps {
  width?: number
  height?: number
}

const SuccessIcon = ({ width = 18, height = 18 }: SuccessIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16Z"
      fill="var(--color-positiveFeedback)"
      stroke="var(--color-positiveFeedback)"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m5 9 2.667 2.667L13 6.334"
      stroke="var(--color-grayScale-white)"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SuccessIcon
