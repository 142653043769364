import axios from 'axios'
import { Link } from 'gatsby'
import React, { useMemo, useState } from 'react'

import { AccordionArrows } from '../assets/AccordionArrows'
import { Popup } from '../components/common/Popup'
import { Loading } from '../components/sections/Loading'
import {
  ScratchHowToParticipate,
  ScratchIntro,
  ScratchParticipate,
  ScratchModal,
  ErrorScratchModal,
} from '../components/sections/Scratch'
import { useClubContext } from '../contexts/ClubContext'
import { useStoreContext } from '../contexts/StoreContext'
import { useAcceptedScratchTerms } from '../hooks/useAcceptedScratchTerms'
import { navigateUsingWindow } from '../utils/navigation/navigateUsingWindow'
import { validateCPF } from '../utils/validateCPF'

import type { MouseEvent } from 'react'

import '../styles/pages/scratch.scss'

export const ScratchPage = () => {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [isLoadingScratchRedirect, setIsLoadingScratchRedirect] =
    useState(false)

  const [isScratchModalOpen, setIsScratchModalOpen] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const { userInfos } = useClubContext()
  const { scratchPageContent } = useStoreContext()
  const { loading, redirected } = useAcceptedScratchTerms(
    scratchPageContent?.id
  )

  const handleBanner = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  const handleSeeMore = () => {
    setIsScratchModalOpen((prevState) => !prevState)
  }

  const handleToggleTerms = () => {
    setAcceptedTerms((prevState) => !prevState)
  }

  const userErrors = useMemo(() => {
    const errors: string[] = [] // Declarando o tipo explicitamente

    if (!userInfos) {
      return errors
    }

    const { document, givenName, familyName } = userInfos

    if (!validateCPF(document)) {
      errors.push('CPF')
    }

    if (!givenName || !familyName) {
      errors.push('Nome e sobrenome')
    }

    return errors
  }, [userInfos])

  const isUserInfosValid = userErrors.length === 0

  const handleParticipate = async () => {
    if (!isLoadingScratchRedirect) {
      if (!isUserInfosValid) {
        setIsErrorModalOpen(true)

        return
      }

      try {
        setIsLoadingScratchRedirect(true)

        const { data } = await axios.patch<{ url: string }>(
          '/api/scratch/acceptScratchTerms',
          {},
          {
            params: {
              promotionId: scratchPageContent?.id,
              random: Math.random(),
            },
          }
        )

        setIsLoadingScratchRedirect(false)
        navigateUsingWindow(data.url)
      } catch {
        setIsLoadingScratchRedirect(false)
        setAcceptedTerms(false)
      }
    }
  }

  if (loading) {
    return (
      <Loading
        title="Estamos quase lá!"
        message="Aguarde enquanto procuramos seu cadastro no nosso sistema"
      />
    )
  }

  if (redirected) {
    return null
  }

  return (
    <div className="scratch-container">
      <nav className="grid-content">
        <Link to="/">
          <AccordionArrows color="var(--color-support-links)" side="left" />
          <span>Voltar para a Loja</span>
        </Link>
      </nav>

      <ScratchIntro
        scratchPageContent={scratchPageContent}
        handleBanner={handleBanner}
      />
      <ScratchHowToParticipate
        handleSeeMore={handleSeeMore}
        scratchPageContent={scratchPageContent}
      />

      {!isScratchModalOpen && (
        <Popup>
          <ScratchParticipate
            acceptedTerms={acceptedTerms}
            isLoadingScratchRedirect={isLoadingScratchRedirect}
            handleToggleTerms={handleToggleTerms}
            handleParticipate={handleParticipate}
          />
        </Popup>
      )}

      <ScratchModal
        isOpen={isScratchModalOpen}
        setIsOpen={setIsScratchModalOpen}
        scratchPageContent={scratchPageContent}
        Footer={
          <ScratchParticipate
            acceptedTerms={acceptedTerms}
            isLoadingScratchRedirect={isLoadingScratchRedirect}
            handleToggleTerms={handleToggleTerms}
            handleParticipate={handleParticipate}
          />
        }
      />

      <ErrorScratchModal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        errors={userErrors}
      />
    </div>
  )
}
