import React from 'react'

interface UnlikeProps {
  width?: number
  height?: number
  color?: string
}

export const Unlike = ({
  width = 42,
  height = 41,
  color = 'var(--color-main)',
}: UnlikeProps = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 41"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.45 0.444336H33.8V26.0443L19.9 40.4443L18.25 39.1443C17.8833 38.8777 17.6417 38.5777 17.525 38.2443C17.4083 37.911 17.35 37.5277 17.35 37.0943V36.5943L19.6 26.0443H3.5C2.73333 26.0443 2.04167 25.736 1.425 25.1193C0.808333 24.5027 0.5 23.811 0.5 23.0443V18.9443C0.5 18.5777 0.541667 18.1527 0.625 17.6693C0.708333 17.186 0.833333 16.761 1 16.3943L6.8 2.99434C7.1 2.29434 7.59167 1.69434 8.275 1.19434C8.95833 0.694336 9.68333 0.444336 10.45 0.444336ZM36.8 26.0443V0.444336H42.5V26.0443H36.8Z"
        fill={color}
      />
    </svg>
  )
}
