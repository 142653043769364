import React from 'react'

export const Fourth = () => {
  return (
    <svg
      width="250"
      height="238"
      viewBox="0 0 250 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.3489 7.9751L65.5774 8.35688C65.8299 8.78287 66.1983 9.12856 66.64 9.35376L67.0384 9.54996L66.6559 9.77797C66.2305 10.0301 65.8859 10.3979 65.6624 10.8385L65.4605 11.2415L65.232 10.8597C64.9795 10.4337 64.6111 10.088 64.1695 9.86281L63.771 9.66661L64.1535 9.4333C64.5807 9.18176 64.9272 8.81388 65.1524 8.37279L65.3489 7.9751Z"
        fill="#E0E0E0"
      />
      <path
        d="M121.708 37.2717L121.936 37.6535C122.189 38.0795 122.557 38.4252 122.999 38.6504L123.397 38.8466L123.015 39.0746C122.589 39.3282 122.243 39.6955 122.016 40.1351L121.819 40.5328L121.591 40.1563C121.338 39.7303 120.97 39.3846 120.528 39.1594L120.13 38.9632L120.512 38.7299C120.939 38.4797 121.284 38.1113 121.506 37.6694L121.708 37.2717Z"
        fill="#E0E0E0"
      />
      <path
        d="M24.7742 118.671L25.0026 119.053C25.2567 119.478 25.6248 119.823 26.0652 120.05L26.469 120.246L26.0864 120.474C25.6609 120.728 25.3149 121.095 25.0876 121.535L24.891 121.938L24.6573 121.556C24.4052 121.13 24.0366 120.784 23.5947 120.559L23.1909 120.363L23.5734 120.129C24.0006 119.878 24.3471 119.51 24.5723 119.069L24.7742 118.671Z"
        fill="#E0E0E0"
      />
      <path
        d="M176.739 221.149L176.972 221.53C177.224 221.957 177.593 222.302 178.035 222.527L178.439 222.723L178.056 222.957C177.629 223.208 177.283 223.576 177.057 224.017L176.861 224.42L176.632 224.038C176.378 223.614 176.01 223.268 175.57 223.042L175.171 222.845L175.554 222.612C175.978 222.358 176.322 221.99 176.547 221.552L176.739 221.149Z"
        fill="#E0E0E0"
      />
      <path
        d="M220.002 107.478L220.533 108.31C221.097 109.246 221.917 110.002 222.897 110.489L223.774 110.919L222.94 111.449C222.001 112.012 221.244 112.831 220.756 113.809L220.326 114.684L219.821 113.851C219.258 112.914 218.438 112.157 217.457 111.672L216.58 111.242L217.42 110.739C218.356 110.175 219.112 109.357 219.598 108.379L220.002 107.478Z"
        fill="#E0E0E0"
      />
      <path
        d="M171.893 0.954468L172.398 1.79227C172.961 2.72832 173.782 3.48301 174.762 3.96632L175.639 4.39583L174.8 4.92608C173.863 5.48941 173.107 6.30809 172.621 7.28572L172.191 8.16064L171.66 7.32814C171.095 6.39033 170.273 5.63394 169.29 5.14879L168.445 4.66626L169.279 4.16251C170.219 3.6009 170.977 2.7819 171.463 1.80288L171.893 0.954468Z"
        fill="#E0E0E0"
      />
      <path
        d="M68.887 208.465L69.3864 209.303C69.9514 210.239 70.7738 210.993 71.756 211.477L72.6273 211.906L71.7932 212.41C70.854 212.972 70.0976 213.793 69.6149 214.775L69.1792 215.644L68.6798 214.812C68.1151 213.875 67.2926 213.121 66.3103 212.638L65.439 212.203L66.2731 211.705C67.21 211.139 67.9657 210.319 68.4514 209.34L68.887 208.465Z"
        fill="#E0E0E0"
      />
      <path
        d="M205.683 71.4466C205.683 71.4466 201.396 73.5677 195.79 76.8712C188.958 80.9223 174.895 88.8814 165.905 82.5926L157.676 66.4675C157.676 66.4675 162.957 68.8855 170.995 67.8409C178.168 66.9129 183.906 63.1428 186.488 61.4089C187.55 60.7037 188.91 59.7545 189.989 59.1076C191.795 58.0471 193.963 56.4563 197.119 54.908L205.683 71.4466Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.793 80.4123C187.379 79.785 189.803 76.5218 189.207 73.1238C188.61 69.7258 185.219 67.4797 181.632 68.107C178.045 68.7344 175.621 71.9976 176.218 75.3956C176.815 78.7936 180.206 81.0397 183.793 80.4123Z"
        fill="#F5F5F5"
      />
      <path
        d="M180.569 71.7171L180.038 70.9005L180.692 70.4763L181.223 71.277C181.827 70.9213 182.527 70.7602 183.226 70.8157L183.444 71.9398C182.895 71.885 182.342 71.988 181.85 72.2368L182.641 73.4564C183.491 73.1859 184.474 72.9261 185.149 73.9866C185.654 74.7608 185.579 75.7524 184.618 76.5477L185.149 77.359L184.496 77.7779L183.964 76.9826C183.477 77.2523 182.928 77.3912 182.37 77.3856L182.115 76.2773C182.544 76.2827 182.969 76.1921 183.359 76.0122L182.556 74.7714C181.712 75.0471 180.739 75.3016 180.075 74.2411C179.576 73.4935 179.634 72.539 180.569 71.7171ZM181.048 73.4882C181.212 73.748 181.489 73.7639 181.844 73.6897L181.191 72.6822C181.072 72.7756 180.992 72.9093 180.965 73.0579C180.939 73.2065 180.968 73.3597 181.048 73.4882ZM183.306 74.5487L183.975 75.6092C184.098 75.5092 184.18 75.3676 184.206 75.2112C184.231 75.0548 184.198 74.8946 184.113 74.7608C183.948 74.4585 183.661 74.4479 183.295 74.5275L183.306 74.5487Z"
        fill="#EBEBEB"
      />
      <path
        d="M166.825 82.6775L166.931 82.7411C167.197 82.9002 173.61 86.543 185.043 81.3518C189.314 79.4094 193.421 77.1235 197.321 74.5168C199.797 72.9261 201.571 71.7542 203.548 71.2346L204.079 71.0967L196.678 55.9208L196.258 56.2177C196.136 56.3026 184.071 64.6753 178.694 66.8706C174.444 68.6151 166.352 71.1126 160.264 68.3288L159.095 67.7985L166.825 82.6775ZM179.013 67.6872C182.896 66.0964 189.957 61.5203 193.889 58.8743C194.253 59.4358 194.808 59.8479 195.451 60.0356C196.164 60.233 196.926 60.1455 197.576 59.7916L201.63 68.1008C201.304 68.2354 201.014 68.4425 200.781 68.7063C200.548 68.97 200.379 69.2834 200.286 69.6226C200.119 70.2773 200.156 70.9672 200.392 71.6004C199.329 72.1837 198.176 72.9155 196.843 73.7798C192.982 76.3665 188.915 78.6313 184.682 80.5512C178.938 83.16 174.539 83.4092 171.862 83.16C171.162 83.0963 170.468 82.9846 169.784 82.826C169.582 82.0458 169.146 81.3457 168.534 80.819C167.923 80.2923 167.166 79.964 166.363 79.8777L162.043 71.5739C162.484 71.5368 163.063 71.2717 163.175 70.1581C168.881 71.2346 175.326 69.1878 178.986 67.6872H179.013Z"
        fill="#F5F5F5"
      />
      <path
        d="M59.9028 16.5334C59.9028 16.5334 55.6153 18.6544 50.0102 21.9579C43.1778 26.0037 29.1146 33.9682 20.1252 27.6794L11.8955 11.5596C11.8955 11.5596 17.1765 13.9722 25.2149 12.9329C32.3873 12.005 38.1253 8.23487 40.7073 6.49563C41.7699 5.7957 43.13 4.84124 44.2085 4.19432C46.0149 3.13381 48.1826 1.54304 51.3384 0L59.9028 16.5334Z"
        fill="#EBEBEB"
      />
      <path
        d="M38.0129 25.4879C41.5996 24.8605 44.0236 21.5973 43.4269 18.1993C42.8303 14.8013 39.439 12.5552 35.8522 13.1826C32.2655 13.8099 29.8416 17.0731 30.4382 20.4711C31.0349 23.8691 34.4262 26.1152 38.0129 25.4879Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.7887 16.8038L34.2574 15.9819L34.9109 15.563L35.4422 16.3637C36.0455 16.0057 36.7456 15.8444 37.4451 15.9024L37.6629 17.0265C37.1148 16.9724 36.5624 17.0735 36.0691 17.3181L36.8607 18.543C37.7108 18.2726 38.6936 18.0128 39.3684 19.0733C39.6348 19.4833 39.7285 19.9817 39.629 20.4602C39.5295 20.9387 39.245 21.3587 38.8371 21.6291L39.3684 22.4404L38.7149 22.8646L38.1836 22.0639C37.6966 22.3353 37.1475 22.476 36.5897 22.4722L36.3347 21.364C36.7635 21.3669 37.1878 21.2764 37.5779 21.0989L36.7757 19.8581C35.9309 20.1338 34.9587 20.3883 34.2946 19.3278C33.7792 18.5801 33.8536 17.5992 34.7887 16.8038ZM35.2668 18.5748C35.4315 18.8294 35.7078 18.8506 36.0638 18.7763L35.4103 17.7689C35.2914 17.8622 35.2112 17.996 35.1847 18.1446C35.1583 18.2932 35.1875 18.4463 35.2668 18.5748ZM37.5248 19.6354L38.1942 20.6959C38.3168 20.5943 38.3983 20.4518 38.4237 20.2949C38.4491 20.1379 38.4167 19.9771 38.3324 19.8422C38.1677 19.5452 37.8808 19.5134 37.5248 19.6141V19.6354Z"
        fill="#EBEBEB"
      />
      <path
        d="M21.0177 27.7643L21.1239 27.8279C21.3896 27.987 27.8022 31.6298 39.2356 26.4386C43.5134 24.4951 47.6266 22.2093 51.535 19.6036C54.0108 18.0129 55.7853 16.841 57.7617 16.3214L58.2929 16.1835L50.8549 1.00757L50.4352 1.30451C50.313 1.38935 38.2527 9.75679 32.8708 11.9574C28.6204 13.7019 20.5289 16.1994 14.4403 13.4156L13.2715 12.8853L21.0177 27.7643ZM33.2055 12.7739C37.0892 11.1832 44.15 6.60707 48.0816 3.96109C48.4606 4.5512 49.051 4.97471 49.7323 5.14519C50.4135 5.31566 51.1343 5.22025 51.7475 4.87844L55.8012 13.1875C55.4753 13.3215 55.1846 13.5284 54.9516 13.7923C54.7185 14.0561 54.5493 14.3699 54.457 14.7094C54.2901 15.3623 54.3271 16.0506 54.5633 16.6819C53.5007 17.2705 52.3478 18.0023 51.0143 18.8666C47.1589 21.4525 43.0967 23.7173 38.869 25.638C33.1258 28.2468 28.7267 28.496 26.0331 28.2415C25.3339 28.1793 24.6398 28.0694 23.9557 27.9127C23.754 27.1316 23.3184 26.4304 22.7071 25.9027C22.0958 25.375 21.3378 25.0459 20.5342 24.9592L16.2308 16.6554C16.6717 16.6236 17.2508 16.3585 17.3624 15.2396C23.1003 16.332 29.5289 14.2746 33.2055 12.7739Z"
        fill="#F5F5F5"
      />
      <path
        d="M212.447 199.35C212.447 199.35 208.159 201.471 202.554 204.774C195.727 208.815 181.659 216.769 172.669 210.496L164.439 194.371C164.439 194.371 169.72 196.789 177.759 195.749C184.931 194.816 190.669 191.046 193.251 189.312C194.314 188.607 195.674 187.658 196.752 187.011C198.559 185.95 200.727 184.359 203.882 182.811L212.447 199.35Z"
        fill="#EBEBEB"
      />
      <path
        d="M182.981 203.295C182.386 199.896 184.809 196.635 188.395 196.004C191.982 195.373 195.371 197.626 195.966 201.025C196.561 204.424 194.139 207.685 190.552 208.311C186.966 208.937 183.577 206.694 182.981 203.295Z"
        fill="#F5F5F5"
      />
      <path
        d="M187.332 199.62L186.801 198.804L187.455 198.385L187.986 199.18C188.59 198.824 189.29 198.663 189.989 198.719L190.207 199.843C189.658 199.791 189.106 199.894 188.613 200.14L189.404 201.359C190.255 201.089 191.243 200.829 191.917 201.89C192.417 202.669 192.343 203.655 191.386 204.451L191.917 205.262L191.264 205.681L190.733 204.886C190.245 205.155 189.696 205.293 189.139 205.289L188.884 204.18C189.312 204.186 189.737 204.097 190.127 203.921L189.325 202.674C188.48 202.95 187.508 203.205 186.844 202.144C186.323 201.391 186.397 200.421 187.332 199.62ZM187.811 201.397C187.975 201.651 188.257 201.667 188.608 201.598L187.954 200.585C187.838 200.681 187.76 200.814 187.734 200.962C187.707 201.11 187.735 201.262 187.811 201.391V201.397ZM190.069 202.457L190.743 203.491C190.865 203.39 190.945 203.248 190.969 203.092C190.994 202.936 190.961 202.776 190.876 202.643C190.733 202.367 190.425 202.351 190.069 202.452V202.457Z"
        fill="#EBEBEB"
      />
      <path
        d="M173.562 210.58L173.668 210.644C173.939 210.803 180.346 214.446 191.78 209.26C196.051 207.316 200.157 205.028 204.058 202.42C206.534 200.829 208.308 199.657 210.285 199.138L210.816 199L203.415 183.829L202.99 184.121C202.868 184.206 190.807 192.578 185.425 194.774C181.175 196.518 173.084 199.016 166.995 196.232L165.832 195.702L173.562 210.58ZM185.75 195.59C189.633 193.999 196.694 189.423 200.626 186.777C201.005 187.367 201.595 187.791 202.276 187.961C202.958 188.132 203.678 188.036 204.292 187.695L208.345 196.004C208.021 196.139 207.731 196.347 207.498 196.61C207.265 196.874 207.095 197.187 207.001 197.526C206.836 198.181 206.875 198.871 207.113 199.503C206.05 200.092 204.892 200.824 203.564 201.683C199.7 204.268 195.631 206.535 191.397 208.459C185.654 211.063 181.255 211.312 178.577 211.063C177.88 210.999 177.187 210.888 176.505 210.729C176.301 209.95 175.865 209.25 175.254 208.724C174.643 208.197 173.886 207.868 173.084 207.781L168.764 199.477C169.205 199.445 169.779 199.18 169.891 198.061C175.65 199.138 182.073 197.096 185.75 195.59Z"
        fill="#F5F5F5"
      />
      <path
        d="M52.316 149.532C52.316 149.532 48.0338 151.653 42.4287 154.957C35.5963 159.002 21.5331 166.967 12.5436 160.678L4.31396 144.553C4.31396 144.553 9.59498 146.971 17.6334 145.926C24.8005 144.998 30.5437 141.228 33.1258 139.489C34.1884 138.789 35.5432 137.835 36.627 137.193C38.4334 136.133 40.5957 134.542 43.7569 132.993L52.316 149.532Z"
        fill="#EBEBEB"
      />
      <path
        d="M22.8457 153.477C22.2507 150.078 24.6734 146.812 28.2596 146.186C31.8458 145.561 35.2354 147.804 35.8357 151.202C36.4361 154.601 34.0028 157.868 30.4272 158.493C26.8516 159.119 23.4514 156.876 22.8457 153.477Z"
        fill="#F5F5F5"
      />
      <path
        d="M27.2021 149.803L26.6709 148.981L27.3243 148.562L27.8556 149.362C28.4599 149.007 29.1593 148.846 29.8586 148.901L30.0711 150.025C29.5229 149.971 28.9704 150.072 28.4772 150.317L29.2476 151.547C30.103 151.277 31.0859 151.017 31.7606 152.077C32.26 152.852 32.1856 153.843 31.2293 154.638L31.7606 155.45L31.1071 155.869L30.5758 155.068C30.0895 155.341 29.5399 155.482 28.982 155.476L28.7323 154.368C29.1611 154.371 29.5853 154.28 29.9755 154.103L29.1732 152.862C28.3232 153.138 27.3562 153.392 26.6868 152.332C26.198 151.579 26.2671 150.598 27.2021 149.803ZM27.6803 151.574C27.8503 151.828 28.1266 151.849 28.4772 151.775L27.8291 150.768C27.7103 150.861 27.6297 150.994 27.6024 151.143C27.575 151.291 27.6027 151.444 27.6803 151.574ZM29.9436 152.634L30.613 153.695C30.7367 153.594 30.8186 153.451 30.8432 153.293C30.8677 153.136 30.833 152.975 30.7459 152.841C30.5812 152.544 30.2836 152.533 29.9436 152.608V152.634Z"
        fill="#EBEBEB"
      />
      <path
        d="M13.4313 160.763L13.5429 160.827C13.8085 160.986 20.2212 164.628 31.6545 159.437C35.9259 157.497 40.0321 155.212 43.9326 152.608C46.4085 151.017 48.183 149.845 50.1594 149.325L50.6907 149.187L43.2738 134.006L42.8541 134.303C42.7319 134.388 30.6663 142.755 25.2897 144.956C21.0394 146.701 12.9478 149.198 6.85927 146.414L5.69043 145.884L13.4313 160.763ZM25.6244 145.773C29.5081 144.182 36.569 139.606 40.5005 136.96C40.8806 137.55 41.4719 137.974 42.154 138.144C42.8362 138.315 43.5577 138.219 44.1717 137.877L48.2202 146.186C47.895 146.32 47.6052 146.528 47.3731 146.791C47.1409 147.055 46.9727 147.369 46.8813 147.708C46.7118 148.363 46.7489 149.053 46.9876 149.686C45.925 150.269 44.7721 151.001 43.4385 151.865C39.578 154.452 35.5105 156.717 31.2773 158.637C25.5341 161.245 21.135 161.495 18.452 161.245C17.7545 161.181 17.0622 161.07 16.38 160.911C16.1769 160.131 15.7409 159.431 15.1299 158.903C14.5188 158.376 13.7615 158.046 12.9585 157.958L8.63909 149.654C9.08006 149.622 9.65385 149.357 9.77073 148.238C15.5193 149.32 21.9426 147.273 25.6244 145.773Z"
        fill="#F5F5F5"
      />
      <path
        d="M184.533 152.178C184.533 152.178 192.741 142.575 208.876 148.758C209.488 148.997 210.121 149.175 210.768 149.288C212.606 149.651 214.509 149.516 216.277 148.896C219.417 147.634 223.891 145.794 223.891 145.794L237.704 153.96C237.704 153.96 231.908 158.202 226.297 158.531C220.687 158.859 214.078 154.289 207.617 155.879C204.346 156.587 201.375 158.292 199.117 160.758L184.533 152.178Z"
        fill="#EBEBEB"
      />
      <path
        d="M217.829 154.294C217.207 154.824 213.467 154.336 209.472 153.154C205.476 151.971 202.735 150.561 203.356 150.004C203.978 149.447 207.724 149.956 211.719 151.144C215.714 152.332 218.472 153.737 217.829 154.294Z"
        fill="#F5F5F5"
      />
      <path
        d="M212.277 153.212L212.532 153.482L211.692 153.297L211.442 153.037C210.673 152.862 209.914 152.645 209.169 152.385L209.354 152.194C209.941 152.404 210.539 152.581 211.145 152.724L210.768 152.327C209.774 152.056 208.643 151.738 208.324 151.393C208.079 151.144 208.52 151.064 209.806 151.314L209.556 151.049L210.396 151.234L210.646 151.494C211.288 151.648 211.947 151.828 212.489 152.024L212.346 152.226C211.852 152.061 211.379 151.929 210.943 151.823L211.331 152.226C212.319 152.496 213.456 152.809 213.754 153.143C213.987 153.382 213.578 153.456 212.277 153.212ZM210.433 151.971L210.109 151.632C209.694 151.563 209.578 151.595 209.657 151.674C209.896 151.819 210.159 151.919 210.433 151.971ZM212.425 152.867C212.192 152.724 211.934 152.624 211.666 152.57L211.979 152.899C212.372 152.963 212.516 152.942 212.425 152.867Z"
        fill="#EBEBEB"
      />
      <path
        d="M223.705 146.775L223.556 146.849C220.961 148.123 218.256 149.163 215.475 149.956C213.547 150.444 212.643 150.29 210.996 150.004C210.678 149.951 210.332 149.887 209.934 149.829C208.972 149.629 208.024 149.365 207.097 149.039C205.009 148.366 202.411 147.533 199.813 147.448H199.765C199.691 147.448 192.534 146.838 186.732 151.499L186.382 151.775L198.803 159.278L198.973 159.124C199.042 159.061 205.705 153.053 214.71 155.317C215.773 155.582 216.766 155.847 217.696 156.091C224.996 158.016 228.657 158.981 235.988 154.585L236.44 154.315L223.705 146.775ZM217.861 155.466C216.931 155.216 215.937 154.935 214.87 154.686C208.744 153.148 203.67 155.301 200.881 156.982C200.262 156.601 199.535 156.43 198.81 156.497C198.086 156.564 197.403 156.865 196.864 157.353L189.601 152.963C191.004 151.743 190.186 150.688 189.708 150.253C192.797 148.651 196.255 147.888 199.733 148.042C202.283 148.116 204.86 148.938 206.927 149.601C207.884 149.94 208.864 150.211 209.86 150.412L210.922 150.587C212.596 150.879 213.605 151.054 215.661 150.534C217.737 149.963 219.767 149.238 221.734 148.366C222.265 148.816 223.62 149.665 225.655 148.647L233.066 153.021C232.535 153.286 231.68 153.965 233.002 155.455C227.546 158.027 224.029 157.104 217.829 155.476L217.861 155.466Z"
        fill="#F5F5F5"
      />
      <path
        d="M59.5044 83.6159C59.5044 83.6159 59.3716 70.9905 75.6078 65.057C76.228 64.8385 76.8243 64.5578 77.3876 64.2192C79.0131 63.3006 80.3603 61.9615 81.2873 60.343C82.828 57.3311 85.0063 53.0149 85.0063 53.0149L100.802 50.1038C100.802 50.1038 99.2077 57.0872 95.2124 61.0323C91.2171 64.9774 83.1893 65.863 79.3746 71.2663C77.3781 73.9469 76.2643 77.18 76.1869 80.5192L59.5044 83.6159Z"
        fill="#EBEBEB"
      />
      <path
        d="M86.0264 63.3603C85.9254 64.1875 82.7642 66.2555 78.9708 67.9841C75.1774 69.7127 72.1916 70.4445 72.2925 69.6173C72.3935 68.7901 75.5546 66.7168 79.348 64.9934C83.1415 63.2701 86.1273 62.5331 86.0264 63.3603Z"
        fill="#F5F5F5"
      />
      <path
        d="M81.1121 66.2024L81.484 66.2396L80.7295 66.6479L80.3683 66.616C79.6774 66.986 78.9681 67.3205 78.2431 67.6182V67.3531C78.8213 67.123 79.387 66.8628 79.9379 66.5736L79.4066 66.5206C78.4769 66.9713 77.4196 67.4698 76.9521 67.422C76.6014 67.3902 76.883 67.0455 78.0146 66.388L77.6481 66.3562L78.4025 65.9426L78.7585 65.9744C79.3482 65.6669 79.9645 65.3752 80.4958 65.1578V65.4018C80.0123 65.6032 79.566 65.8154 79.1675 66.0168L79.7254 66.0699C80.6498 65.6245 81.6912 65.1313 82.1587 65.1737C82.5147 65.2109 82.2384 65.5502 81.1121 66.2024ZM78.9072 66.4729L78.4397 66.4304C78.0784 66.6532 78.0146 66.7486 78.1262 66.7592C78.4011 66.7092 78.6653 66.6124 78.9072 66.4729ZM80.9952 65.8419C80.7246 65.8894 80.464 65.9827 80.2248 66.1176L80.6817 66.16C81.0217 65.9532 81.1014 65.8525 80.9952 65.8419Z"
        fill="#EBEBEB"
      />
      <path
        d="M85.5006 53.8633L85.4368 54.0171C84.3107 56.6721 82.9506 59.2221 81.3725 61.6369C80.2408 63.2701 79.4545 63.7579 78.0253 64.6116L77.1168 65.1419C76.2608 65.6218 75.3733 66.0434 74.4604 66.4039C72.4415 67.2682 69.9391 68.3446 67.9255 69.9884L67.8777 70.0255C67.8193 70.068 62.0335 74.3047 60.7159 81.6222L60.6362 82.0623L74.912 79.602L74.9385 79.3686C74.9385 79.2785 76.0011 70.3808 84.2945 66.1812C85.2774 65.6827 86.1966 65.232 87.0572 64.8078C93.8471 61.4725 97.2261 59.8128 99.8826 51.6787L100.047 51.1855L85.5006 53.8633ZM86.8022 64.251C85.9415 64.6753 85.0171 65.1313 84.0395 65.6297C78.4079 68.4878 75.9958 73.4351 75.0076 76.5318C74.2896 76.6491 73.6295 76.9967 73.1271 77.522C72.6248 78.0473 72.3076 78.7216 72.2237 79.4429L63.8558 80.8958C64.1109 79.0611 62.7933 78.8013 62.1557 78.7748C63.4273 75.5405 65.5311 72.6981 68.2549 70.5346C70.226 68.9173 72.7071 67.8462 74.7048 66.9925C75.6497 66.62 76.5675 66.1824 77.4515 65.6827C77.7809 65.4759 78.0785 65.2957 78.3547 65.1525C79.8051 64.2723 80.6818 63.742 81.8878 62.0028C83.0911 60.2067 84.1567 58.3225 85.0755 56.3661C85.7609 56.3661 87.3548 56.1063 88.2155 54.0065L96.6948 52.4369C96.4504 52.9672 96.2698 54.0277 98.2462 54.3141C95.7916 59.8128 92.5348 61.4301 86.8022 64.251Z"
        fill="#F5F5F5"
      />
      <path
        d="M177.127 28.7132C177.127 28.7132 184.889 18.7285 201.285 24.1371C201.906 24.3466 202.547 24.4958 203.197 24.5826C205.05 24.86 206.944 24.6347 208.68 23.9303C211.756 22.5199 216.118 20.4678 216.118 20.4678L230.32 27.9656C230.32 27.9656 224.73 32.4516 219.163 33.0773C213.595 33.703 206.73 29.4238 200.381 31.2956C197.143 32.1655 194.256 34.0185 192.12 36.5982L177.127 28.7132Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.513 29.233C209.918 29.8162 206.151 29.4875 202.103 28.4959C198.054 27.5043 195.254 26.2264 195.849 25.6431C196.444 25.0598 200.206 25.3886 204.254 26.3802C208.303 27.3718 211.108 28.6497 210.513 29.233Z"
        fill="#F5F5F5"
      />
      <path
        d="M204.892 28.4218L205.158 28.6763L204.313 28.5279L204.053 28.2786C203.274 28.1468 202.504 27.9697 201.747 27.7484L201.922 27.5522C202.453 27.706 203.112 27.8703 203.739 27.9923L203.341 27.6105C202.337 27.3878 201.215 27.128 200.854 26.7992C200.599 26.5606 201.035 26.4599 202.337 26.6508L202.071 26.3962L202.916 26.5394L203.176 26.7886C203.829 26.9106 204.494 27.0591 205.046 27.2181L204.913 27.4302C204.414 27.2871 203.93 27.1757 203.495 27.0909L203.899 27.478C204.903 27.6954 206.024 27.9552 206.358 28.2786C206.608 28.5279 206.178 28.6074 204.892 28.4218ZM202.99 27.2712L202.65 26.9477C202.23 26.9 202.119 26.9477 202.198 27.0113C202.446 27.1417 202.713 27.2295 202.99 27.2712ZM205.025 28.0665C204.783 27.9372 204.52 27.8494 204.249 27.8067L204.579 28.1196C204.977 28.1673 205.078 28.1408 205.025 28.0665Z"
        fill="#EBEBEB"
      />
      <path
        d="M215.996 21.4595L215.853 21.539C213.319 22.9237 210.669 24.0823 207.931 25.0016C206.024 25.5796 205.115 25.4682 203.458 25.2614L202.395 25.1341C201.423 24.9821 200.463 24.7642 199.521 24.4819C197.396 23.9093 194.766 23.204 192.168 23.2464H192.104C192.03 23.2464 184.857 22.9813 179.279 27.9127L178.949 28.2043L191.7 35.0977L191.865 34.9333C191.929 34.8696 198.299 28.5702 207.4 30.3784C208.462 30.5958 209.482 30.8079 210.423 31.0094C217.802 32.6001 221.505 33.3743 228.619 28.6338L229.055 28.3422L215.996 21.4595ZM210.566 30.4155C209.626 30.214 208.622 29.9966 207.543 29.7792C201.359 28.5278 196.381 30.9298 193.677 32.7698C193.042 32.4113 192.307 32.2692 191.585 32.3649C190.862 32.4605 190.19 32.7889 189.671 33.3001L182.233 29.2595C183.571 27.9763 182.7 26.9635 182.206 26.5499C185.216 24.8021 188.633 23.875 192.115 23.8615C194.665 23.8138 197.273 24.5084 199.372 25.0758C200.345 25.3674 201.338 25.5924 202.342 25.7492L203.404 25.8712C204.979 26.1825 206.607 26.0857 208.133 25.5902C210.185 24.9261 212.183 24.1072 214.11 23.1404C214.641 23.5593 216.06 24.3441 218.041 23.2358L225.65 27.2499C225.118 27.5362 224.316 28.2574 225.703 29.6837C220.369 32.5047 216.82 31.7464 210.566 30.4155Z"
        fill="#F5F5F5"
      />
      <path
        d="M239.505 237.862C239.505 237.936 189.357 238 127.515 238C65.6727 238 15.5137 237.936 15.5137 237.862C15.5137 237.788 65.6515 237.724 127.515 237.724C189.378 237.724 239.505 237.788 239.505 237.862Z"
        fill="#263238"
      />
      <path
        d="M229.118 124.732L229.453 125.289C229.834 125.916 230.387 126.42 231.047 126.742L231.631 127.028L231.073 127.362C230.445 127.742 229.94 128.294 229.618 128.953L229.331 129.536L228.996 129.006C228.616 128.379 228.062 127.875 227.402 127.553L226.818 127.267L227.376 126.933C228.004 126.553 228.509 126.001 228.831 125.342L229.118 124.732Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M67.1866 228.376L67.5107 228.906C67.8757 229.512 68.4066 230.002 69.0408 230.317L69.6093 230.592L69.078 230.916C68.472 231.282 67.9835 231.814 67.6701 232.448L67.3885 233.01L67.0644 232.48C66.6986 231.876 66.1678 231.389 65.5343 231.075L64.9658 230.794L65.4971 230.47C66.1025 230.104 66.5922 229.575 66.9103 228.943L67.1866 228.376Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M34.6026 51.7954L35.187 52.7658C35.8435 53.8539 36.7985 54.7316 37.9391 55.2951L38.9539 55.8254L37.9869 56.4086C36.8944 57.061 36.0142 58.0149 35.4527 59.1554L34.9214 60.1681L34.337 59.1978C33.6946 58.0986 32.7483 57.2075 31.6114 56.6313L30.5967 56.1011L31.5689 55.5178C32.6577 54.8631 33.5368 53.9121 34.1032 52.7764L34.6026 51.7954Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M151.948 17.8538L152.533 18.8241C153.188 19.9134 154.143 20.7915 155.285 21.3534L156.3 21.8837L155.327 22.467C154.236 23.1208 153.356 24.0743 152.793 25.2137L152.294 26.2265L151.709 25.2561C151.056 24.1574 150.101 23.2685 148.957 22.695L147.937 22.1647L148.909 21.5815C150.001 20.9277 150.88 19.9741 151.444 18.8347L151.948 17.8538Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M240.674 102.992L240.902 103.368C241.155 103.794 241.523 104.14 241.965 104.365L242.363 104.561L241.981 104.795C241.553 105.046 241.207 105.414 240.982 105.855L240.785 106.258L240.557 105.876C240.304 105.45 239.936 105.105 239.494 104.879L239.096 104.683L239.478 104.455C239.904 104.202 240.25 103.834 240.477 103.395L240.674 102.992Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M44.9944 100.786L45.2335 101.178C45.4971 101.622 45.8849 101.98 46.3492 102.207L46.7636 102.408L46.3651 102.647C45.9238 102.913 45.568 103.299 45.3397 103.761L45.1325 104.174L44.8988 103.776C44.6324 103.336 44.2453 102.981 43.7831 102.753L43.3687 102.552L43.7618 102.313C44.2066 102.05 44.5649 101.663 44.7925 101.199L44.9944 100.786Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M85.4895 31.8472L85.7286 32.2396C85.9962 32.6803 86.3829 33.0368 86.8443 33.2683L87.2534 33.4697L86.8602 33.7084C86.4189 33.9742 86.0631 34.3606 85.8348 34.8219L85.6276 35.2302L85.3939 34.8378C85.1233 34.4028 84.7346 34.0535 84.2729 33.8303L83.8638 33.6235L84.2569 33.3902C84.7032 33.1124 85.0608 32.7132 85.2876 32.2396L85.4895 31.8472Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M177.658 154.373C177.658 154.941 181.084 170.037 181.084 170.037C181.084 170.037 188.22 165.196 190.929 162.916C193.639 160.636 206.767 148.535 206.767 148.535L214.641 155.789C214.641 155.789 188.607 191.131 179.39 193.257C174.656 194.344 169.407 190.775 168.387 188.251C166.086 182.62 162.574 158.621 162.574 158.621L167.68 156.367L177.658 154.373Z"
        fill="#BA7E6D"
      />
      <path
        d="M181.387 170.069C180.834 170.158 180.292 170.3 179.767 170.493C178.537 171.05 177.504 171.962 176.802 173.112C176.12 174.282 175.603 175.54 175.267 176.851C175.139 177.403 174.972 177.945 174.767 178.473C174.814 177.906 174.915 177.345 175.07 176.798C175.355 175.446 175.857 174.15 176.558 172.959C177.288 171.761 178.388 170.831 179.692 170.307C180.088 170.16 180.503 170.072 180.925 170.048C181.079 170.026 181.235 170.034 181.387 170.069Z"
        fill="#875149"
      />
      <path
        d="M204.684 150.455C204.684 150.455 211.856 142.437 214.752 142.665C217.648 142.893 221.808 143.519 222.966 143.323C224.429 143.221 225.897 143.221 227.36 143.323C227.36 143.323 234.877 139.261 236.264 139.579C236.264 139.579 237.555 139.51 238.001 140.242C238.001 140.242 240.86 138.434 242.921 139.558L235.43 145.788L237.714 145.502C237.714 145.502 244.446 141.949 246.576 144.394L241.492 147.119C241.492 147.119 245.716 147.056 246.353 148.768L236.891 150.285C236.891 150.285 218.912 151.786 217.961 152.199C217.01 152.613 213.206 157.502 213.206 157.502L204.684 150.455Z"
        fill="#BA7E6D"
      />
      <path
        d="M238.102 140.279C237.971 140.444 237.819 140.592 237.651 140.719C237.348 140.984 236.902 141.361 236.328 141.78C234.838 142.932 233.275 143.986 231.647 144.935C230.739 145.465 229.878 145.947 229.039 146.335C228.29 146.733 227.422 146.85 226.595 146.663L226.728 146.589C226.626 146.904 226.452 147.191 226.219 147.426C225.987 147.662 225.702 147.84 225.389 147.947C225.217 148.005 225.038 148.037 224.857 148.042C224.735 148.042 224.677 148.042 224.677 148.042C224.9 147.994 225.12 147.932 225.336 147.856C225.615 147.738 225.865 147.56 226.068 147.335C226.271 147.109 226.422 146.842 226.51 146.552L226.542 146.446L226.643 146.478C227.42 146.626 228.225 146.496 228.916 146.112C229.724 145.725 230.595 145.226 231.488 144.707C233.385 143.604 235.026 142.48 236.195 141.637L237.571 140.629C237.734 140.492 237.913 140.375 238.102 140.279Z"
        fill="#875149"
      />
      <path
        d="M235.43 145.932C235.145 146.25 234.805 146.514 234.426 146.711C233.546 147.246 232.579 147.619 231.568 147.814C230.972 147.931 230.399 147.952 229.905 148.042C229.468 148.127 229.047 148.277 228.656 148.487C228.293 148.723 227.867 148.845 227.434 148.837C227.821 148.686 228.197 148.508 228.56 148.307C228.96 148.059 229.398 147.88 229.857 147.777C230.388 147.666 230.919 147.639 231.509 147.522C232.893 147.207 234.217 146.669 235.43 145.932Z"
        fill="#875149"
      />
      <path
        d="M241.885 146.584C241.587 146.895 241.243 147.16 240.865 147.369C239.093 148.471 237.06 149.084 234.973 149.145C234.543 149.18 234.109 149.15 233.688 149.055C235.123 148.968 236.547 148.739 237.938 148.371C240.249 147.692 241.832 146.499 241.885 146.584Z"
        fill="#875149"
      />
      <path
        d="M97.1302 197.149C89.368 209.207 80.3574 224.293 74.8691 237.883H168.376L157.219 197.149H97.1302Z"
        fill="#263238"
      />
      <path
        d="M39.23 153.037C39.23 153.037 45.8339 162.704 48.0759 165.233C50.318 167.762 59.7643 181.284 64.6787 186.698C69.5932 192.112 74.9539 195.092 78.4232 195.092C81.9828 195.092 85.585 193.877 88.4114 187.255C92.4917 177.71 95.9292 149.606 95.9292 149.606L79.9905 143.774L74.1995 172.874C72.6428 171.326 48.0813 148.482 48.0813 148.482L42.4496 150.815L39.23 153.037Z"
        fill="#BA7E6D"
      />
      <path
        d="M18.7435 141.128C18.7435 141.128 19.1207 140.454 21.2193 140.756C23.3179 141.059 26.9997 143.227 27.8232 143.227C28.6467 143.227 37.8752 143.524 39.6763 143.975C41.4773 144.426 44.2772 144.696 45.9242 146.414L48.5807 148.779L39.671 153.668C39.3379 153.225 38.9088 152.863 38.4157 152.608C37.9225 152.354 37.3781 152.214 36.8232 152.199C34.799 152.077 25.5015 151.611 25.5015 151.611L18.2972 149.956L9.14307 145.539C9.35111 145.042 9.72839 144.633 10.2085 144.386C10.6886 144.139 11.2407 144.068 11.7676 144.187C13.6431 144.638 17.1708 145.91 17.1708 145.91L20.247 146.849L12.1449 142.989C12.6694 142.454 13.3353 142.079 14.0653 141.907C14.7952 141.735 15.5589 141.774 16.2677 142.018C18.8178 142.84 23.1744 144.786 23.1744 144.786L23.7748 144.564L18.7435 141.128Z"
        fill="#BA7E6D"
      />
      <path
        d="M28.8697 146.446C27.9333 146.518 26.9932 146.366 26.1282 146.001C25.231 145.719 24.4148 145.226 23.748 144.564C23.7959 144.495 24.8106 145.232 26.2185 145.746C27.6265 146.26 28.875 146.366 28.8697 146.446Z"
        fill="#875149"
      />
      <path
        d="M27.1748 148.763C26.784 148.859 26.3802 148.889 25.9794 148.853C24.0304 148.806 22.1326 148.22 20.4965 147.162C20.1463 146.963 19.8291 146.71 19.5562 146.414C20.7067 147.113 21.9285 147.687 23.2008 148.127C24.499 148.48 25.8312 148.693 27.1748 148.763Z"
        fill="#875149"
      />
      <path
        d="M11.1144 146.51C10.7971 146.661 10.5313 146.902 10.35 147.203C10.1686 147.503 10.0797 147.851 10.0943 148.201C13.137 149.194 16.2629 149.913 19.4344 150.349"
        fill="#875149"
      />
      <path
        d="M79.8203 185.149C79.7619 185.149 79.8735 184.343 79.7884 183.028C79.7388 182.274 79.6268 181.526 79.4537 180.791C79.233 179.894 78.9307 179.019 78.5506 178.176C78.1586 177.339 77.6891 176.54 77.1479 175.79C76.7019 175.18 76.2061 174.607 75.6656 174.078C74.7359 173.176 74.0718 172.736 74.0718 172.694C74.2485 172.767 74.4146 172.863 74.5659 172.98C74.753 173.097 74.9307 173.228 75.0972 173.372C75.2991 173.537 75.5435 173.712 75.7772 173.94C76.3454 174.459 76.8647 175.028 77.3286 175.642C78.4694 177.152 79.2704 178.89 79.6769 180.738C79.8404 181.489 79.9347 182.254 79.9585 183.023C79.9746 183.316 79.9746 183.61 79.9585 183.903C79.9558 184.125 79.9363 184.347 79.9 184.566C79.8977 184.763 79.8709 184.959 79.8203 185.149Z"
        fill="#875149"
      />
      <path
        d="M95.0579 115.59C92.8531 116.948 87.758 121.068 82.4876 132.203C75.4693 147.013 73.647 156.256 73.647 156.256L92.3005 162.905L97.9535 114.196C97.9535 114.196 95.7858 115.145 95.0579 115.59Z"
        fill="#455A64"
      />
      <path
        d="M91.0098 131.106C91.0098 131.106 91.4507 146.822 91.4507 151.091C91.4507 155.36 93.3634 187.461 93.3634 187.461C93.3634 187.461 93.3634 198.024 99.9354 197.86C106.507 197.695 117.744 198.968 122.191 198.968C125.469 198.968 144.102 199.185 153.909 198.31C158.558 197.897 160.348 195.632 161.783 191.947C161.974 191.449 161.857 191.74 161.974 191.449C164.785 184.555 162.612 155.057 162.612 155.057L159.132 128.391L151.205 112.658L106.306 112.515C91.913 113.501 91.913 119.398 91.0098 131.106Z"
        fill="#455A64"
      />
      <path
        d="M169.975 125.565C162.579 113.316 151.205 112.679 151.205 112.679L157.92 167.916L163.233 166.442C163.626 166.309 183.156 158.536 183.156 158.536C183.156 158.536 174.523 133.084 169.975 125.565Z"
        fill="#455A64"
      />
      <path
        d="M156.799 129.51C156.799 129.51 156.826 129.541 156.868 129.616C156.911 129.69 156.969 129.812 157.033 129.944C157.171 130.231 157.394 130.655 157.644 131.217C158.175 132.331 158.834 133.98 159.541 136.058C161.326 141.499 162.478 147.127 162.973 152.83C163.466 158.525 163.643 164.243 163.504 169.958C163.467 172.148 163.414 173.919 163.371 175.143C163.371 175.737 163.329 176.204 163.318 176.554C163.318 176.702 163.318 176.824 163.318 176.925C163.324 176.966 163.324 177.007 163.318 177.047C163.314 177.005 163.314 176.962 163.318 176.92V176.554C163.318 176.209 163.318 175.737 163.318 175.143C163.318 173.892 163.318 172.126 163.361 169.952C163.447 164.247 163.247 158.542 162.76 152.857C162.264 147.171 161.144 141.558 159.419 136.117C158.739 134.038 158.09 132.405 157.607 131.259C157.378 130.692 157.171 130.263 157.076 129.966L156.932 129.626C156.884 129.592 156.839 129.553 156.799 129.51Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M124.141 165.991C126.503 163.63 129.384 161.85 132.556 160.795C136.753 159.443 149.186 156.855 152.485 155.646C156.123 154.307 159.437 152.218 162.213 149.516L176.807 161.479C174.273 163.747 171.489 165.722 168.509 167.365C163.812 169.883 152.235 169.894 146.003 173.627C141.965 176.045 136.737 183.124 136.737 183.124L124.141 165.991Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M144.229 230.725C140.895 230.458 137.67 229.42 134.809 227.692C131.053 225.38 121.027 217.617 117.967 215.883C114.532 213.962 110.823 212.576 106.969 211.774L106.656 193.612C110.028 194.068 113.338 194.904 116.522 196.105C121.489 198.045 128.996 206.837 135.887 209.159C140.345 210.66 149.138 210.045 149.138 210.045L144.229 230.725Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M78.9761 139.648C82.6951 140.9 87.4023 138.773 89.7878 135.661C92.1733 132.548 94.5057 128.56 95.0741 124.684C92.3486 124.684 88.6455 124.886 85.92 124.881L78.9761 139.648Z"
          fill="black"
        />
      </g>
      <path
        d="M91.9127 143.922C91.937 144.066 91.9477 144.211 91.9446 144.357L91.9924 145.608C92.0243 146.722 92.0668 148.291 92.1199 150.216C92.2262 154.124 92.3696 159.485 92.5237 165.408C92.6777 171.331 92.8849 176.708 93.055 180.594C93.1506 182.519 93.225 184.084 93.2781 185.197C93.2781 185.727 93.3206 186.136 93.3365 186.449C93.3448 186.593 93.3448 186.739 93.3365 186.883C93.3026 186.741 93.283 186.595 93.2781 186.449C93.2462 186.141 93.209 185.722 93.1665 185.202C93.0656 184.142 92.97 182.551 92.8531 180.605C92.6299 176.718 92.4121 171.347 92.2368 165.413C92.0615 159.48 91.9658 154.108 91.9127 150.216C91.9127 148.291 91.8861 146.727 91.8755 145.608C91.8755 145.078 91.8755 144.67 91.8755 144.357C91.8751 144.211 91.8876 144.066 91.9127 143.922Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M92.4015 176.787C87.5455 165.864 88.2681 153.668 91.9127 142.268C92.444 153.376 92.6884 165.668 92.4174 176.787"
          fill="black"
        />
      </g>
      <path
        d="M150.354 60.1787C149.738 58.199 148.734 56.3612 147.401 54.7719C146.068 53.1826 144.431 51.8734 142.587 50.9204C138.533 48.8577 133.698 48.6827 129.262 49.7008C124.826 50.7189 120.73 52.8558 116.856 55.242C114.683 56.5782 112.521 58.0417 111.012 60.0832C108.218 63.795 105.524 69.8664 105.375 74.5168C105.258 78.2763 106.969 87.63 108.653 90.9918C109.254 92.1902 110.662 93.2454 112.372 94.1362C114.186 97.4669 116.581 100.448 119.444 102.938C120.241 100.393 121.039 97.848 121.84 95.3028L150.354 60.1787Z"
        fill="#263238"
      />
      <path
        d="M148.027 55.6132L140.589 54.4996C144.914 64.0124 146.646 86.0021 149.255 96.1194C151.874 92.0842 157.872 79.8565 157.394 75.0736C156.916 70.2907 156.81 54.5421 148.027 55.6132Z"
        fill="#263238"
      />
      <path
        d="M113.35 72.6398C113.589 67.0032 117.106 62.2839 122.542 60.7356L139.484 55.9049C146.837 54.8974 152.018 63.8163 152.714 71.1869C153.484 79.3793 154.063 88.0331 153.277 94.1894C151.683 106.566 142.29 108.427 142.29 108.427C142.29 108.427 142.454 114.26 142.55 117.881L132.498 138.821L116.883 113.729L113.329 72.7936C113.297 72.5815 113.34 72.9208 113.35 72.6398Z"
        fill="#BA7E6D"
      />
      <path
        d="M147.99 80.233C148.005 80.6445 147.86 81.0457 147.584 81.3522C147.309 81.6588 146.925 81.8468 146.513 81.8767C146.312 81.8987 146.109 81.8804 145.915 81.823C145.722 81.7655 145.541 81.67 145.385 81.5421C145.229 81.4142 145.099 81.2565 145.005 81.0781C144.91 80.8997 144.852 80.7044 144.835 80.5034C144.82 80.0919 144.965 79.6907 145.241 79.3841C145.516 79.0775 145.9 78.8895 146.312 78.8596C146.512 78.8376 146.716 78.8559 146.91 78.9134C147.103 78.9708 147.284 79.0663 147.44 79.1942C147.596 79.3221 147.726 79.4799 147.82 79.6582C147.915 79.8366 147.973 80.032 147.99 80.233Z"
        fill="#263238"
      />
      <path
        d="M150.997 76.0652C150.811 76.2773 149.584 75.4289 147.879 75.4872C146.173 75.5455 144.957 76.4311 144.765 76.2296C144.67 76.1394 144.861 75.7629 145.392 75.3493C146.113 74.8166 146.982 74.5202 147.879 74.5009C148.764 74.4693 149.635 74.7221 150.365 75.2221C150.886 75.6039 151.093 75.9697 150.997 76.0652Z"
        fill="#263238"
      />
      <path
        d="M133.284 80.2382C133.298 80.6488 133.152 81.0487 132.876 81.3541C132.601 81.6595 132.218 81.8468 131.807 81.8767C131.606 81.8987 131.403 81.8804 131.209 81.8229C131.015 81.7655 130.835 81.67 130.679 81.5421C130.522 81.4142 130.393 81.2564 130.299 81.0781C130.204 80.8997 130.146 80.7043 130.128 80.5033C130.114 80.0918 130.259 79.6906 130.535 79.384C130.81 79.0775 131.194 78.8895 131.605 78.8595C131.807 78.8376 132.011 78.8561 132.205 78.9139C132.399 78.9716 132.579 79.0676 132.736 79.196C132.892 79.3245 133.021 79.4829 133.116 79.6618C133.21 79.8408 133.267 80.0368 133.284 80.2382Z"
        fill="#263238"
      />
      <path
        d="M134.825 76.5265C134.634 76.7386 133.407 75.8902 131.706 75.9485C130.006 76.0068 128.779 76.8923 128.588 76.6908C128.497 76.6007 128.683 76.2242 129.215 75.8106C129.936 75.2788 130.805 74.9825 131.701 74.9622C132.587 74.9316 133.459 75.1823 134.193 75.6781C134.729 76.0651 134.915 76.431 134.825 76.5265Z"
        fill="#263238"
      />
      <path
        d="M140.531 89.7246C140.531 89.6292 141.594 89.4171 143.283 89.1413C143.713 89.083 144.122 88.9876 144.186 88.6906C144.212 88.2409 144.097 87.7942 143.857 87.4127L142.47 84.1463C140.542 79.496 139.128 75.6622 139.314 75.588C139.5 75.5138 141.222 79.2203 143.15 83.8706L144.489 87.1582C144.776 87.6746 144.874 88.2745 144.765 88.855C144.707 89.0101 144.614 89.1499 144.493 89.2638C144.373 89.3778 144.228 89.4628 144.069 89.5125C143.832 89.5867 143.585 89.6278 143.336 89.6345C141.609 89.783 140.542 89.8254 140.531 89.7246Z"
        fill="#263238"
      />
      <path
        d="M125.793 104.508C125.793 104.508 130.043 112.854 142.369 111.322L142.29 108.427C136.533 108.67 130.822 107.313 125.793 104.508Z"
        fill="#875149"
      />
      <path
        d="M134.113 70.4179C133.959 70.8845 132.254 70.7149 130.261 71.0171C128.269 71.3193 126.675 71.9132 126.394 71.5049C126.271 71.314 126.537 70.8686 127.175 70.402C128.028 69.8278 129.001 69.4546 130.019 69.3104C131.038 69.1661 132.077 69.2548 133.056 69.5695C133.8 69.8293 134.177 70.1793 134.113 70.4179Z"
        fill="#263238"
      />
      <path
        d="M150.296 70.8846C150.009 71.277 148.819 70.9164 147.411 70.9376C146.003 70.9588 144.813 71.277 144.526 70.8846C144.399 70.6884 144.574 70.296 145.084 69.9142C145.769 69.4473 146.582 69.2012 147.411 69.209C148.24 69.2 149.051 69.4483 149.733 69.9195C150.243 70.296 150.424 70.6884 150.296 70.8846Z"
        fill="#263238"
      />
      <path
        d="M149.037 60.0355C148.23 58.2009 146.163 57.1456 144.16 57.1032C142.157 57.0608 140.218 57.8721 138.512 58.922C131.282 63.3708 127.796 73.7214 120.661 78.3135C118.908 79.4429 116.554 78.5203 115.072 79.9944C113.59 81.4685 112.676 83.7804 113.568 85.6628C112.488 84.4015 111.593 82.9927 110.912 81.4791C110.581 80.7193 110.416 79.898 110.428 79.0696C110.44 78.2412 110.628 77.4249 110.981 76.675C111.576 75.572 112.665 74.7077 112.931 73.4934C113.17 72.4329 112.713 71.3088 112.729 70.2005C112.826 69.0711 113.19 67.9808 113.792 67.019C116.49 61.9126 120.279 57.2623 125.236 54.2929C128.478 52.3001 132.203 51.2276 136.01 51.1909C139.824 51.172 143.52 52.5148 146.429 54.9769C148.097 56.4457 149.85 57.9569 149.085 60.0355"
        fill="#263238"
      />
      <path
        d="M115.29 81.5161C115.151 81.1662 108.648 79.0293 108.755 85.8643C108.861 92.5031 115.534 91.0024 115.539 90.8115C115.545 90.6206 115.29 81.5161 115.29 81.5161Z"
        fill="#BA7E6D"
      />
      <path
        d="M113.446 88.3353C113.419 88.3353 113.334 88.4201 113.138 88.5155C112.864 88.6365 112.554 88.6479 112.272 88.5474C111.565 88.3034 110.954 87.2005 110.896 86.0074C110.87 85.4395 110.97 84.8729 111.188 84.3477C111.247 84.1388 111.355 83.9472 111.504 83.7893C111.653 83.6314 111.838 83.5119 112.043 83.441C112.176 83.404 112.317 83.4139 112.443 83.469C112.569 83.5241 112.672 83.621 112.734 83.7432C112.824 83.9288 112.787 84.0614 112.824 84.072C112.862 84.0826 112.963 83.9606 112.904 83.6849C112.863 83.5218 112.768 83.3776 112.633 83.2766C112.448 83.1407 112.217 83.0835 111.99 83.1175C111.718 83.1778 111.465 83.307 111.256 83.4927C111.048 83.6784 110.891 83.9144 110.8 84.178C110.527 84.7573 110.401 85.3947 110.434 86.0339C110.503 87.3702 111.215 88.611 112.182 88.8655C112.362 88.9175 112.552 88.9269 112.736 88.8928C112.921 88.8588 113.095 88.7822 113.244 88.6693C113.435 88.4996 113.473 88.3512 113.446 88.3353Z"
        fill="#875149"
      />
      <path
        d="M112.553 78.1809C112.693 78.118 112.824 78.0397 112.946 77.9476C113.196 77.7779 113.584 77.5499 114.041 77.2106C115.323 76.2156 116.495 75.0868 117.537 73.8434C119.064 71.9818 120.483 70.0344 121.787 68.0106C123.357 65.5388 125.134 63.2044 127.1 61.0325C128.144 59.9464 129.304 58.9774 130.559 58.1426C131.736 57.3776 133.003 56.7597 134.331 56.3026C136.582 55.5113 138.964 55.1515 141.349 55.2421C142.959 55.2937 144.554 55.5594 146.094 56.0322C146.625 56.1806 147.055 56.3556 147.337 56.4511C147.48 56.5105 147.627 56.5584 147.778 56.5942C147.647 56.5144 147.508 56.4486 147.363 56.398C146.963 56.2122 146.552 56.0528 146.131 55.9208C144.587 55.399 142.977 55.0991 141.349 55.03C138.933 54.9055 136.515 55.2495 134.23 56.0428C132.877 56.4992 131.587 57.1227 130.389 57.8987C129.11 58.7444 127.929 59.7277 126.866 60.831C124.896 63.0164 123.119 65.367 121.553 67.8569C120.269 69.8722 118.881 71.8191 117.393 73.6897C116.375 74.9346 115.236 76.0766 113.993 77.0992C113.547 77.4545 113.175 77.6984 112.93 77.8787C112.797 77.9691 112.671 78.0702 112.553 78.1809Z"
        fill="#455A64"
      />
      <path
        d="M112.723 89.5337C112.395 89.4823 112.059 89.5578 111.784 89.7448C111.51 89.9318 111.317 90.2163 111.245 90.5403C111.173 90.8643 111.227 91.2034 111.397 91.4887C111.567 91.774 111.839 91.9839 112.159 92.0758C112.478 92.1677 112.821 92.1346 113.117 91.9832C113.413 91.8319 113.64 91.5737 113.752 91.2612C113.863 90.9487 113.852 90.6054 113.719 90.3012C113.586 89.997 113.342 89.7548 113.037 89.6238"
        fill="white"
      />
      <path
        d="M113.037 89.6238C113.331 89.8424 113.55 90.1461 113.664 90.4934C113.718 90.6755 113.725 90.8683 113.684 91.0537C113.643 91.2392 113.556 91.4113 113.43 91.5539C113.288 91.7343 113.095 91.8683 112.876 91.9389C112.657 92.0095 112.423 92.0135 112.201 91.9504C111.98 91.8873 111.783 91.7599 111.635 91.5844C111.487 91.409 111.394 91.1935 111.369 90.9654C111.34 90.7799 111.359 90.5903 111.422 90.4136C111.485 90.2368 111.591 90.0784 111.73 89.9526C112.01 89.716 112.358 89.5732 112.724 89.5443C112.724 89.5443 112.623 89.4753 112.41 89.4806C112.222 89.4891 112.037 89.5358 111.867 89.6179C111.697 89.7 111.546 89.8159 111.423 89.9584C111.3 90.1008 111.207 90.2669 111.15 90.4464C111.093 90.6259 111.074 90.8152 111.092 91.0025C111.119 91.2875 111.231 91.558 111.413 91.7785C111.596 91.999 111.841 92.1593 112.117 92.2384C112.393 92.3175 112.686 92.3116 112.958 92.2217C113.23 92.1317 113.469 91.9618 113.643 91.7342C113.79 91.558 113.889 91.3461 113.928 91.1199C113.967 90.8936 113.946 90.6611 113.866 90.4457C113.772 90.1597 113.585 89.9133 113.335 89.7458C113.165 89.6185 113.042 89.6079 113.037 89.6238Z"
        fill="#263238"
      />
      <path
        d="M114.28 80.0103C114.227 81.8291 114.195 83.7221 115.082 85.3129C115.97 86.9037 118.116 87.9324 119.668 86.9885C120.932 86.2196 121.261 84.5599 121.447 83.0911C121.979 78.8491 122.127 74.5646 122.669 70.3279L114.28 80.0103Z"
        fill="#263238"
      />
      <path
        d="M147.31 56.9229C147.31 56.9653 146.72 56.8328 145.658 56.8275C144.151 56.843 142.663 57.1584 141.28 57.7554C140.324 58.1545 139.413 58.6559 138.565 59.2507C137.594 59.9451 136.664 60.6958 135.781 61.499C133.743 63.3241 131.932 65.3875 130.389 67.6447C129.576 68.8537 128.864 70.0892 128.083 71.2398C127.349 72.3617 126.484 73.3924 125.506 74.31C123.824 75.8171 121.976 77.1282 119.997 78.218C118.318 79.1618 116.915 79.8088 115.948 80.2966L114.827 80.8268C114.699 80.895 114.564 80.9483 114.423 80.9859C114.535 80.8946 114.658 80.818 114.79 80.7579L115.884 80.1799C116.83 79.6815 118.211 78.9762 119.864 78.0059C121.805 76.8954 123.619 75.576 125.272 74.0714C126.223 73.1666 127.065 72.154 127.78 71.0542C128.545 69.9142 129.257 68.6734 130.075 67.4538C131.636 65.1704 133.476 63.0899 135.553 61.2604C136.449 60.4509 137.397 59.6999 138.39 59.0121C139.262 58.4159 140.197 57.918 141.179 57.5274C142.597 56.9397 144.123 56.6578 145.658 56.7002C146.072 56.711 146.484 56.7554 146.89 56.8328C147.033 56.8473 147.174 56.8775 147.31 56.9229Z"
        fill="#455A64"
      />
      <path
        d="M113.977 75.9167C113.804 76.0777 113.611 76.2166 113.403 76.3303L111.746 77.3643L111.188 77.709C110.968 77.845 110.722 77.9335 110.465 77.9688C110.308 77.9905 110.148 77.9568 110.014 77.8734C109.94 77.8268 109.878 77.7638 109.833 77.6892C109.788 77.6147 109.76 77.5307 109.753 77.4439C109.76 77.1376 109.862 76.8409 110.045 76.5955C110.614 75.6198 111.241 74.554 111.91 73.4139L112.181 73.6525C111.543 74.146 110.824 74.5262 110.056 74.7767C109.292 75.0284 108.49 75.145 107.687 75.1213C107.482 75.1241 107.277 75.0955 107.081 75.0365C106.966 75.0058 106.86 74.9512 106.768 74.8762C106.677 74.8013 106.602 74.7077 106.55 74.6017C106.508 74.4904 106.495 74.3705 106.511 74.2528C106.528 74.1352 106.574 74.0236 106.645 73.9283C106.752 73.7746 106.875 73.6325 107.012 73.5041C107.862 72.6303 108.594 71.6499 109.19 70.5877C109.718 69.5902 110.17 68.5551 110.545 67.491C110.523 67.7983 110.46 68.1014 110.359 68.3924C110.128 69.1964 109.814 69.9747 109.424 70.7149C108.845 71.8196 108.116 72.8386 107.256 73.7427C107.017 73.9813 106.778 74.2729 106.879 74.4638C106.98 74.6547 107.347 74.7661 107.697 74.7555C109.232 74.7922 110.734 74.2997 111.948 73.3609L112.723 72.7617L112.192 73.5995L110.285 76.781C110.137 76.9713 110.047 77.1993 110.024 77.4386C110.024 77.6242 110.2 77.7302 110.412 77.709C110.835 77.6077 111.235 77.4278 111.592 77.1787L113.302 76.2349C113.515 76.1039 113.741 75.9972 113.977 75.9167Z"
        fill="#455A64"
      />
      <path
        d="M134.139 93.0439C134.252 92.5359 134.538 92.0831 134.95 91.7637C135.361 91.4442 135.871 91.2781 136.392 91.294C136.773 91.3334 137.138 91.4652 137.455 91.6778C137.773 91.8904 138.034 92.1773 138.214 92.5136C138.388 92.8536 138.492 93.2248 138.52 93.6053C138.548 93.9859 138.501 94.3682 138.379 94.7301L138.347 94.8892C138.004 95.2814 137.547 95.5577 137.04 95.6802C136.533 95.8027 136 95.7653 135.516 95.5732C135.039 95.3621 134.645 94.9996 134.396 94.5422C134.148 94.0847 134.057 93.5579 134.139 93.0439Z"
        fill="#875149"
      />
      <path
        d="M137.072 95.4567C137.072 95.4567 137.003 95.637 136.732 95.7961C136.337 96.0181 135.874 96.0879 135.43 95.9922C135.099 95.9148 134.79 95.764 134.525 95.5511C134.26 95.3381 134.046 95.0685 133.9 94.7621C133.648 94.1864 133.576 93.5477 133.694 92.9305C133.813 92.3133 134.116 91.7464 134.564 91.3048C134.727 91.1563 134.922 91.0474 135.134 90.9867C135.346 90.926 135.569 90.9151 135.786 90.9548C136.409 91.1414 136.982 91.4638 137.465 91.8987C138.299 92.5297 138.793 92.9592 138.751 93.0122C138.708 93.0652 138.135 92.7683 137.242 92.2221C136.782 91.8754 136.261 91.6165 135.707 91.4586C135.566 91.4361 135.422 91.4469 135.286 91.4902C135.151 91.5335 135.027 91.608 134.926 91.7078C134.692 91.957 134.511 92.2511 134.394 92.5721C134.26 92.8828 134.191 93.2175 134.191 93.5557C134.191 93.894 134.26 94.2287 134.394 94.5394C134.501 94.7888 134.66 95.0126 134.861 95.1955C135.062 95.3784 135.299 95.5163 135.558 95.5999C135.923 95.7139 136.314 95.7139 136.679 95.5999C136.902 95.5203 137.04 95.4249 137.072 95.4567Z"
        fill="#263238"
      />
      <path
        d="M177.886 82.969C181.076 84.3113 183.909 86.3774 186.159 89.0033C189.097 92.4977 196.423 103.394 198.872 106.083C201.577 109.045 204.875 111.406 208.552 113.013L202.74 131.646C199.573 130.104 196.591 128.208 193.852 125.994C189.601 122.478 185.08 111.396 179.061 106.878C175.166 103.957 166.31 101.708 166.31 101.708L177.886 82.969Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M207.421 113.57C207.367 113.533 201.576 110.261 199.122 107.541C197.384 105.627 194.707 101.989 192.114 98.4684C190.154 95.8171 188.299 93.2825 186.902 91.5751C182.439 86.1135 178.885 85.0105 178.736 84.9681L178.391 84.8621L168.296 100.589L168.929 100.759C168.992 100.759 175.511 102.535 178.587 104.778C182.684 107.764 185.048 111.735 187.338 115.574C188.974 118.321 190.526 120.914 192.651 123.184C194.541 125.203 196.655 127.002 198.952 128.545C199.867 129.167 200.824 129.727 201.815 130.22L202.315 130.448L207.421 113.57ZM179.129 104.026C177.296 102.689 174.417 101.549 172.223 100.802C172.591 100.339 172.78 99.7588 172.754 99.1684C172.499 98.2086 171.691 98.0124 170.857 98.3995L177.435 88.1868L177.488 88.2663C178.502 89.6874 180.489 88.4625 180.622 86.999C180.656 87.025 180.691 87.0481 180.729 87.0679C182.781 88.4868 184.611 90.2024 186.158 92.1584C187.54 93.8499 189.389 96.358 191.344 99.0199C191.572 99.3221 191.795 99.6297 192.018 99.9319C190.52 99.2426 188.544 99.5713 186.679 102.18C184.958 104.598 184.713 107.859 185.165 110.351C183.527 107.91 181.486 105.764 179.129 104.004V104.026ZM193.32 122.547C191.264 120.352 189.745 117.802 188.135 115.124L187.678 114.355C188.758 114.678 189.914 114.639 190.97 114.244C192.026 113.848 192.922 113.118 193.522 112.165C194.765 110.081 195.955 107.096 195.116 104.094C196.38 105.754 197.539 107.218 198.447 108.225C200.184 110.134 203.176 112.404 204.971 113.703C204.833 113.741 204.704 113.808 204.592 113.897C204.481 113.987 204.388 114.098 204.321 114.225C204.253 114.351 204.212 114.49 204.2 114.632C204.187 114.775 204.204 114.919 204.249 115.055C204.366 115.643 204.918 116.37 205.588 116.227L205.678 116.19L202.225 127.532C201.693 125.941 199.935 126.683 200.211 128.248C197.68 126.64 195.365 124.717 193.32 122.526V122.547Z"
          fill="white"
        />
      </g>
      <path
        d="M188.799 110.675L188.188 111.645L187.412 111.163L188.008 110.214C187.323 109.749 186.799 109.084 186.509 108.31L187.598 107.515C187.818 108.129 188.207 108.669 188.719 109.074L189.628 107.621C188.916 106.852 188.156 105.929 188.932 104.683C189.511 103.76 190.606 103.342 191.928 103.951L192.534 102.986L193.31 103.469L192.715 104.418C193.257 104.806 193.687 105.329 193.963 105.935L192.901 106.762C192.692 106.3 192.384 105.891 191.998 105.563L191.073 107.043C191.79 107.812 192.539 108.724 191.769 109.949C191.206 110.855 190.122 111.248 188.799 110.675ZM190.457 109.275C190.648 108.968 190.526 108.665 190.271 108.326L189.522 109.524C189.681 109.603 189.864 109.622 190.036 109.576C190.208 109.53 190.358 109.423 190.457 109.275ZM190.43 106.343L191.206 105.107C191.036 105.028 190.843 105.013 190.663 105.066C190.483 105.119 190.328 105.235 190.228 105.394C190.037 105.696 190.165 106.009 190.43 106.343Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M124.853 158.09C127.299 155.647 130.283 153.806 133.566 152.714C137.912 151.308 150.785 148.631 154.201 147.379C157.977 145.99 161.417 143.822 164.296 141.016L179.406 153.403C176.777 155.753 173.889 157.799 170.799 159.501C165.932 162.11 153.946 162.12 147.491 165.986C143.31 168.489 137.896 175.822 137.896 175.822L124.853 158.09Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M164.371 142.289C164.317 142.326 159.105 146.435 155.647 147.697C153.219 148.583 148.836 149.691 144.591 150.762C141.403 151.574 138.337 152.353 136.228 152.989C129.481 155.063 127.117 157.931 127.021 158.053L126.792 158.329L137.604 173.611L137.997 173.081C138.04 173.022 142.125 167.656 145.366 165.657C149.675 163.006 154.25 162.301 158.675 161.627C161.863 161.145 164.827 160.689 167.739 159.58C170.325 158.59 172.791 157.309 175.087 155.763C176.019 155.155 176.907 154.482 177.743 153.748L178.147 153.371L164.371 142.289ZM144.846 164.857C142.917 166.044 140.776 168.282 139.251 170.032C138.953 169.529 138.48 169.152 137.923 168.971C136.94 168.844 136.451 169.502 136.499 170.435L129.491 160.508H129.587C131.287 160.1 130.899 157.804 129.587 157.131L129.688 157.056C131.792 155.683 134.089 154.631 136.504 153.933C138.592 153.292 141.621 152.528 144.824 151.717L145.924 151.441C144.724 152.565 144.283 154.522 146.004 157.226C147.598 159.724 150.536 161.177 153.022 161.696C150.137 162.281 147.373 163.349 144.846 164.857ZM167.356 158.695C164.546 159.755 161.608 160.217 158.495 160.694L157.613 160.827C158.312 159.95 158.706 158.869 158.735 157.749C158.763 156.628 158.425 155.529 157.772 154.617C156.3 152.687 153.979 150.46 150.865 150.126C152.889 149.596 154.68 149.065 155.955 148.594C158.383 147.708 161.613 145.799 163.499 144.622C163.483 144.764 163.496 144.908 163.537 145.045C163.578 145.183 163.647 145.31 163.739 145.42C163.831 145.53 163.944 145.62 164.072 145.685C164.199 145.751 164.339 145.789 164.482 145.799C165.072 145.916 165.949 145.677 166.076 145.004C166.079 144.972 166.079 144.94 166.076 144.908L175.326 152.364C173.642 152.279 173.673 154.161 175.23 154.485C172.797 156.219 170.151 157.634 167.356 158.695Z"
          fill="white"
        />
      </g>
      <path
        d="M154.685 158.44L155.36 159.368L154.621 159.899L153.963 158.992C153.272 159.449 152.458 159.684 151.63 159.665L151.306 158.356C151.958 158.384 152.604 158.227 153.171 157.905L152.167 156.516C151.179 156.887 150.042 157.247 149.176 156.059C148.538 155.174 148.559 154.007 149.622 153.011L148.952 152.088L149.696 151.558L150.355 152.464C150.917 152.11 151.561 151.909 152.225 151.881L152.597 153.17C152.091 153.192 151.596 153.325 151.147 153.557L152.172 154.972C153.155 154.591 154.297 154.246 155.131 155.418C155.758 156.319 155.737 157.459 154.685 158.44ZM151.28 155.301L150.424 154.119C150.287 154.246 150.2 154.42 150.182 154.606C150.163 154.793 150.213 154.979 150.323 155.132C150.53 155.444 150.886 155.439 151.28 155.322V155.301ZM154.01 156.362C153.798 156.07 153.479 156.065 153.059 156.171L153.888 157.322C154.011 157.206 154.091 157.052 154.113 156.885C154.135 156.717 154.099 156.548 154.01 156.404V156.362Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M126.234 5.30779L108.17 2.88452L106.258 3.07541C102.985 3.52083 100.371 4.31091 96.4767 8.42569C92.6886 12.4291 91.0947 22.6578 91.0947 22.6578C91.0947 22.6578 95.5894 20.3511 102.512 20.5049C109.435 20.6587 111.953 21.8677 111.953 21.8677C111.953 21.8677 112.001 14.9744 114.96 11.5171C118.658 7.22732 126.234 5.30779 126.234 5.30779Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M126.234 5.30779L108.17 2.88452L106.258 3.07541C102.985 3.52083 100.371 4.31091 96.4767 8.42569C92.6886 12.4291 91.0947 22.6578 91.0947 22.6578C91.0947 22.6578 95.5894 20.3511 102.512 20.5049C109.435 20.6587 111.953 21.8677 111.953 21.8677C111.953 21.8677 112.001 14.9744 114.96 11.5171C118.658 7.22732 126.234 5.30779 126.234 5.30779Z"
          fill="black"
        />
      </g>
      <path
        d="M106.258 3.07544C106.258 3.07544 110.508 2.47625 117.234 8.24544C121.538 11.9254 128.923 23.2146 128.923 23.2146C130.458 21.1141 132.402 19.3448 134.639 18.0127C140.638 14.4653 144.075 13.5692 144.075 13.5692C144.075 13.5692 140.26 7.84774 134.528 5.59416C120.693 0.12722 106.258 3.07544 106.258 3.07544Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M111.868 2.38612C112.809 2.88456 118.361 4.8465 122.202 4.50714C126.043 4.16778 126.983 3.54738 125.432 3.14438C123.276 2.58407 121.061 2.28497 118.833 2.25355C116.511 2.15659 114.185 2.20086 111.868 2.38612Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M142.067 12.355C139.507 9.66319 136.572 7.35241 133.354 5.49349C132.674 5.12231 131.951 4.76704 131.229 4.43298C128.758 3.68423 126.232 3.13105 123.674 2.77858C126.096 3.30884 129.236 3.56336 133.274 6.33129C135.297 7.73649 137.225 9.27407 139.044 10.9339C138.412 11.2998 138.401 12.5618 138.853 13.0549C137.191 13.694 135.598 14.4987 134.098 15.457C132.718 16.3667 131.39 17.3509 130.118 18.4052C130.229 18.1689 130.274 17.9066 130.246 17.647C130.097 16.8091 128.912 16.263 128.121 16.4963C127.961 16.5238 127.813 16.5948 127.692 16.7016C127.571 16.8083 127.482 16.9465 127.435 17.1008C125.161 13.7973 121.384 8.54246 119.205 6.71838C117.008 4.93794 114.563 3.48414 111.948 2.4021H111.89C110.955 2.46043 110.216 2.56118 109.483 2.64072C112.765 3.72986 115.824 5.39837 118.515 7.56679C121.591 10.1438 128.354 20.4043 128.423 20.5103L128.774 21.0406L129.242 20.6164C130.97 19.0841 132.799 17.6671 134.714 16.3743C136.906 14.9714 139.305 13.9194 141.822 13.2564L142.742 13.0496L142.067 12.355Z"
          fill="white"
        />
      </g>
      <path
        d="M60.4233 133.338L76.7711 136.122L78.4872 136.005C81.473 135.708 83.8691 135.077 87.5191 131.466C91.0894 127.951 92.8639 118.74 92.8639 118.74C92.8639 118.74 88.7092 120.692 82.4293 120.331C76.1495 119.97 73.9022 118.788 73.9022 118.788C73.9022 118.788 73.6365 125.061 70.8366 128.089C67.3673 131.838 60.4233 133.338 60.4233 133.338Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M60.4233 133.338L76.7711 136.122L78.4872 136.005C81.473 135.708 83.8691 135.077 87.5191 131.466C91.0894 127.951 92.8639 118.74 92.8639 118.74C92.8639 118.74 88.7092 120.692 82.4293 120.331C76.1495 119.97 73.9022 118.788 73.9022 118.788C73.9022 118.788 73.6365 125.061 70.8366 128.089C67.3673 131.838 60.4233 133.338 60.4233 133.338Z"
          fill="black"
        />
      </g>
      <path
        d="M78.4873 136.005C78.4873 136.005 74.6195 136.413 68.685 130.962C64.8969 127.484 58.5905 116.99 58.5905 116.99C57.1304 118.85 55.3088 120.395 53.2351 121.535C47.6778 124.562 44.522 125.246 44.522 125.246C44.522 125.246 47.7788 130.549 52.911 132.797C65.2848 138.222 78.4873 136.005 78.4873 136.005Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M73.3712 136.456C72.5317 135.968 67.5589 134.011 64.0577 134.213C60.5565 134.414 59.7064 134.929 61.0825 135.348C63.0217 135.924 65.0228 136.268 67.0435 136.371C69.1491 136.526 71.2622 136.554 73.3712 136.456Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M46.2756 126.434C48.5134 128.956 51.1003 131.147 53.958 132.94C54.5637 133.301 55.2065 133.646 55.8707 133.969C58.0889 134.733 60.3647 135.318 62.6765 135.719C60.4929 135.141 57.6505 134.823 54.0696 132.182C52.2809 130.838 50.5808 129.38 48.9798 127.818C49.5643 127.505 49.6174 126.36 49.2242 125.899C50.7545 125.373 52.2268 124.693 53.618 123.868C54.9042 123.089 56.1458 122.239 57.337 121.322C57.2283 121.533 57.1805 121.77 57.1989 122.006C57.3051 122.77 58.3624 123.306 59.1009 123.12C59.247 123.099 59.3845 123.038 59.4987 122.945C59.6129 122.851 59.6995 122.729 59.7491 122.59C61.7042 125.66 64.961 130.544 66.8843 132.283C68.8166 133.97 70.9838 135.368 73.3182 136.435H73.366C74.2214 136.408 74.8961 136.339 75.5602 136.292C72.6172 135.195 69.896 133.579 67.5271 131.519C64.8176 129.085 59.0265 119.546 58.9521 119.451L58.6493 118.958L58.2083 119.329C56.5928 120.662 54.8888 121.884 53.1079 122.987C51.0728 124.193 48.8604 125.072 46.5518 125.591L45.7124 125.75L46.2756 126.434Z"
          fill="white"
        />
      </g>
      <path
        d="M177.546 37.1709C177.546 37.1709 181.366 34.0689 186.227 29.4397C192.156 23.7978 204.45 12.5564 214.917 16.809L226.919 31.1259C226.919 31.1259 221.074 29.9117 213.211 32.8122C206.246 35.3839 201.358 40.4956 199.153 42.8446C198.266 43.7938 197.103 45.0664 196.168 45.9625C194.574 47.4684 192.794 49.5576 189.973 51.8271L177.546 37.1709Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M206.071 26.7407C207.458 30.023 205.779 33.8567 202.32 35.3149C198.862 36.7731 194.935 35.3149 193.549 32.0167C192.162 28.7185 193.846 24.9007 197.305 23.4478C200.764 21.9949 204.69 23.4637 206.071 26.7407Z"
          fill="white"
        />
      </g>
      <path
        d="M202.767 31.126L203.484 31.8259L202.953 32.3986L202.23 31.7092C201.704 32.2054 201.038 32.5292 200.323 32.6372L199.844 31.5767C200.41 31.5047 200.944 31.275 201.385 30.9138L200.323 29.8533C199.526 30.32 198.596 30.7972 197.666 29.9011C196.981 29.2382 196.827 28.2308 197.592 27.2127L196.88 26.518L197.438 25.9454L198.139 26.6294C198.573 26.2406 199.1 25.9703 199.669 25.8446L200.2 26.9051C199.771 26.9973 199.366 27.1779 199.01 27.4354L200.105 28.4959C200.896 28.024 201.816 27.5573 202.724 28.4429C203.372 29.111 203.526 30.1185 202.767 31.126ZM199.366 28.9307L198.452 28.0399C198.352 28.169 198.302 28.3305 198.313 28.4937C198.324 28.6569 198.394 28.8105 198.511 28.9254C198.739 29.1481 199.026 29.0951 199.366 28.9307ZM201.879 29.4609C201.651 29.2382 201.348 29.286 201.029 29.4609L201.916 30.3253C202.024 30.204 202.08 30.0467 202.075 29.8852C202.07 29.7237 202.004 29.5701 201.89 29.4556L201.879 29.4609Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M214.025 16.9257L213.902 16.8886C213.594 16.7878 206.31 14.6085 196.025 22.451C192.181 25.3875 188.583 28.631 185.266 32.1493C183.141 34.334 181.616 35.9089 179.767 36.8845L179.278 37.139L190.202 50.6818L190.558 50.2894C190.664 50.178 200.849 38.9896 205.747 35.5377C209.631 32.8016 217.165 28.4216 223.928 29.8321L225.267 30.1185L214.025 16.9257ZM205.242 34.8006C201.704 37.2875 195.679 43.518 192.316 47.0813C191.798 46.5749 191.106 46.2851 190.381 46.2713C189.656 46.2575 188.953 46.5207 188.417 47.0071L182.429 39.5835C182.723 39.3727 182.965 39.0981 183.137 38.7802C183.309 38.4624 183.407 38.1098 183.422 37.7488C183.439 37.0512 183.243 36.3651 182.859 35.7816C183.81 34.9438 184.782 33.9469 185.919 32.775C189.202 29.288 192.767 26.0763 196.577 23.1721C201.747 19.2323 206.108 17.9703 208.855 17.6044C209.572 17.506 210.294 17.4564 211.018 17.4559C211.401 18.1948 212.001 18.7992 212.738 19.1887C213.474 19.5781 214.313 19.7339 215.14 19.6353L221.399 26.9846C220.963 27.1225 220.448 27.5149 220.591 28.6656C214.572 28.9042 208.59 32.441 205.242 34.8006Z"
          fill="white"
        />
      </g>
      <path
        d="M25.252 174.152C25.252 174.152 29.0667 177.259 34.5921 181.072C41.3341 185.727 54.8501 195.468 52.8047 206.577L41.1907 221.201C41.1907 221.201 41.1907 215.22 36.765 208.147C32.8122 201.842 26.8087 198.072 24.0672 196.417C22.9568 195.744 21.4745 194.864 20.4013 194.132C18.6109 192.907 16.1935 191.56 13.3989 189.264L25.252 174.152Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M41.2526 199.935C44.189 197.917 44.844 193.78 42.7156 190.695C40.5872 187.61 36.4815 186.745 33.5452 188.763C30.6089 190.781 29.9539 194.918 32.0822 198.003C34.2106 201.088 38.3163 201.953 41.2526 199.935Z"
          fill="white"
        />
      </g>
      <path
        d="M36.229 197.541L35.6977 198.39L35.0229 197.96L35.5542 197.133C34.9626 196.719 34.512 196.135 34.2632 195.458L35.2248 194.774C35.4131 195.312 35.7479 195.788 36.1918 196.147L36.9993 194.88C36.3777 194.201 35.7242 193.384 36.4149 192.308C36.9462 191.502 37.8866 191.147 39.0448 191.693L39.5761 190.855L40.2508 191.284L39.7195 192.106C40.1835 192.453 40.5495 192.913 40.7821 193.443L39.847 194.158C39.6669 193.753 39.4004 193.391 39.066 193.098L38.2532 194.392C38.8748 195.065 39.5229 195.871 38.8376 196.937C38.3275 197.722 37.3819 198.077 36.229 197.541ZM37.69 196.333C37.86 196.067 37.7538 195.802 37.5359 195.5L36.8665 196.561C37.0079 196.63 37.1699 196.645 37.3217 196.603C37.4735 196.561 37.6046 196.465 37.69 196.333ZM37.69 193.771L38.3754 192.711C38.2272 192.642 38.0592 192.629 37.9022 192.674C37.7451 192.719 37.6094 192.818 37.52 192.955C37.3553 193.204 37.4669 193.474 37.7219 193.771H37.69Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M52.5023 205.707V205.575C52.5395 205.257 53.2036 197.695 43.4226 189.232C39.7647 186.07 35.8558 183.21 31.7342 180.679C29.1628 179.051 27.3086 177.874 25.9803 176.262L25.6244 175.838L14.5576 189.28L15.0145 189.545C15.1473 189.625 28.1905 197.313 32.5471 201.402C36.0164 204.647 41.8393 211.121 41.8287 218.02V219.34L52.5023 205.707ZM33.1846 200.739C30.0288 197.791 22.697 193.151 18.5157 190.595C18.9073 189.987 19.0516 189.253 18.9193 188.543C18.787 187.832 18.388 187.199 17.8038 186.772L23.8658 179.412C24.1322 179.657 24.451 179.839 24.798 179.942C25.1451 180.046 25.5112 180.07 25.8688 180.011C26.5568 179.89 27.19 179.559 27.6805 179.062C28.6952 179.826 29.8747 180.573 31.256 181.448C35.3421 183.956 39.2157 186.794 42.8381 189.932C47.7526 194.174 49.8777 198.194 50.8075 200.808C51.0484 201.488 51.2419 202.185 51.3866 202.892C50.7408 203.416 50.2704 204.124 50.0378 204.921C49.8053 205.719 49.8217 206.568 50.0849 207.357L44.1239 214.95C43.906 214.552 43.4066 214.128 42.3122 214.499C40.8564 208.666 36.1811 203.539 33.1846 200.739Z"
          fill="white"
        />
      </g>
      <path
        d="M0 86.3521C0 86.3521 8.50063 76.4522 25.1087 82.8259C25.74 83.065 26.393 83.2426 27.0586 83.3561C28.9538 83.7309 30.915 83.5918 32.7381 82.9531C35.9789 81.654 40.5852 79.7716 40.5852 79.7716L54.8397 88.1921C54.8397 88.1921 48.8627 92.5402 43.0769 92.906C37.2912 93.2719 30.4535 88.5314 23.8177 90.1487C20.4434 90.8781 17.3799 92.6367 15.0514 95.1808L0 86.3521Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M34.3638 88.5315C33.721 89.1042 29.8638 88.5792 25.741 87.3596C21.6182 86.14 18.7971 84.7084 19.4399 84.1092C20.0828 83.51 23.9399 84.0614 28.0627 85.2863C32.1856 86.5112 35.0014 87.9588 34.3638 88.5315Z"
          fill="white"
        />
      </g>
      <path
        d="M28.6154 87.4232L28.8757 87.6989L28.0097 87.508L27.7547 87.2376C26.9623 87.057 26.1801 86.834 25.4117 86.5695L25.603 86.3733C26.1343 86.5588 26.809 86.7603 27.4466 86.9035L27.0587 86.4952C26.0333 86.2142 24.8698 85.8907 24.5351 85.5354C24.2907 85.2756 24.7423 85.1961 26.0705 85.4506L25.8102 85.1802L26.6762 85.3658L26.9312 85.6362C27.5953 85.7953 28.2754 85.9809 28.8332 86.1665L28.6845 86.3786C28.1797 86.2089 27.6856 86.0657 27.2394 85.9597L27.6378 86.3786C28.6579 86.6543 29.8055 86.9777 30.1349 87.3224C30.3793 87.5981 29.933 87.6724 28.6154 87.4232ZM26.7134 86.1399L26.3787 85.79C25.9483 85.721 25.8474 85.7529 25.9111 85.8324C26.1595 85.9788 26.4307 86.0828 26.7134 86.1399ZM28.7695 87.0626C28.5268 86.9163 28.2609 86.8123 27.9832 86.755L28.3073 87.0944C28.6898 87.1633 28.8439 87.1421 28.7695 87.0626Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M40.378 80.7949L40.2186 80.8692C37.5445 82.1761 34.7582 83.2409 31.8933 84.0507C29.901 84.5809 28.9712 84.3954 27.2764 84.0984L26.2138 83.9181C25.2202 83.7141 24.2421 83.4412 23.2864 83.1015C21.1613 82.4122 18.457 81.5532 15.7793 81.4683H15.7102C15.6359 81.4683 8.27219 80.832 2.27393 85.6415L1.91797 85.9278L14.722 93.6642L14.8974 93.4998C14.9664 93.4362 21.836 87.2428 31.1229 89.576C32.2227 89.8517 33.2481 90.1062 34.2044 90.3766C41.7275 92.3598 45.4996 93.3567 53.0652 88.823L53.5221 88.5473L40.378 80.7949ZM34.3638 89.7509C33.4022 89.4964 32.3768 89.2207 31.277 88.9503C24.96 87.3595 19.7268 89.5866 16.8525 91.3205C14.7274 90.207 13.2557 91.2091 12.7138 91.697L5.22259 87.1739C6.6677 85.9172 5.82295 84.8302 5.33416 84.3794C8.51747 82.7233 12.0811 81.9315 15.6677 82.0834C18.2976 82.1577 20.9541 83.0061 23.1058 83.6742C24.0931 84.0222 25.1031 84.3021 26.1288 84.512L27.1914 84.6923C28.9128 84.9945 29.9541 85.1695 32.074 84.634C34.2244 84.0499 36.3272 83.3036 38.3644 82.4016C38.8957 82.8629 40.3143 83.7378 42.4128 82.6932L50.0528 87.2004C49.479 87.4708 48.6236 88.1761 49.989 89.7138C44.3521 92.3863 40.734 91.4319 34.3638 89.7509Z"
          fill="white"
        />
      </g>
      <path
        d="M194.452 219.95C194.452 219.95 193.448 206.975 209.748 199.8C210.37 199.529 210.964 199.199 211.522 198.814C213.123 197.745 214.407 196.267 215.241 194.535C216.617 191.353 218.546 186.74 218.546 186.74L234.596 182.668C234.596 182.668 233.438 189.959 229.597 194.291C225.756 198.623 217.558 200.081 213.998 205.904C212.123 208.796 211.195 212.198 211.342 215.639L194.452 219.95Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M220.347 197.324C220.299 198.183 217.191 200.506 213.408 202.563C209.626 204.62 206.597 205.559 206.645 204.684C206.693 203.809 209.801 201.502 213.584 199.445C217.367 197.388 220.395 196.465 220.347 197.324Z"
          fill="white"
        />
      </g>
      <path
        d="M215.486 200.585H215.868L215.119 201.062H214.747C214.061 201.492 213.352 201.883 212.622 202.234V201.964C213.203 201.688 213.769 201.382 214.317 201.046H213.743C212.824 201.577 211.772 202.155 211.283 202.144C210.922 202.144 211.188 201.757 212.309 201.004H211.932L212.681 200.527H213.053C213.632 200.172 214.248 199.827 214.779 199.572L214.822 199.822C214.344 200.06 213.897 200.304 213.499 200.543H214.078C214.997 200.012 216.038 199.44 216.517 199.482C216.862 199.466 216.596 199.837 215.486 200.585ZM213.233 201.015H212.75C212.394 201.264 212.335 201.37 212.452 201.37C212.731 201.297 212.995 201.177 213.233 201.015ZM215.358 200.225C215.083 200.292 214.821 200.405 214.583 200.559H215.056C215.374 200.331 215.454 200.225 215.342 200.225H215.358Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M219.152 187.604L219.098 187.769C218.121 190.578 216.896 193.294 215.438 195.887C214.375 197.642 213.61 198.188 212.202 199.175C211.926 199.365 211.628 199.572 211.304 199.806C210.454 200.36 209.566 200.856 208.648 201.29C206.634 202.319 204.127 203.592 202.172 205.421L202.124 205.469C202.065 205.516 196.396 210.241 195.552 217.887L195.499 218.343L210.045 214.806V214.568C210.045 214.472 210.539 205.246 218.764 200.357C219.741 199.779 220.655 199.254 221.51 198.766C228.247 194.879 231.605 192.933 233.778 184.386L233.911 183.855L219.152 187.604ZM221.208 198.209C220.347 198.708 219.428 199.233 218.456 199.8C212.861 203.125 210.72 208.38 209.907 211.636C209.18 211.809 208.528 212.212 208.049 212.785C207.57 213.359 207.29 214.072 207.251 214.817L198.75 216.88C198.883 214.971 197.517 214.796 196.853 214.822C197.94 211.408 199.91 208.341 202.565 205.93C204.477 204.132 206.958 202.86 208.94 201.847C209.886 201.397 210.8 200.883 211.676 200.309C211.995 200.076 212.293 199.869 212.564 199.678C213.998 198.676 214.859 198.088 215.985 196.2C217.105 194.272 218.076 192.262 218.891 190.187C219.598 190.134 221.218 189.763 221.962 187.535L230.564 185.356C230.351 185.945 230.24 187.048 232.29 187.18C230.149 193.018 226.908 194.895 221.208 198.209Z"
          fill="white"
        />
      </g>
      <path
        d="M218.238 91.5274C218.238 91.5274 214.625 79.0187 229.124 68.6787C229.68 68.291 230.196 67.8486 230.664 67.3584C232.022 65.9892 232.986 64.2802 233.454 62.4111C234.155 58.9962 235.111 54.1126 235.111 54.1126L249.987 46.8799C249.987 46.8799 250.327 54.2504 247.448 59.272C244.568 64.2935 236.822 67.3743 234.527 73.7957C233.279 77.0085 233.062 80.5291 233.905 83.8705L218.238 91.5274Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M238.995 64.1185C239.123 64.9669 236.556 67.894 233.262 70.6513C229.968 73.4086 227.2 74.957 227.073 74.1086C226.945 73.2602 229.517 70.3331 232.806 67.5758C236.094 64.8185 238.868 63.2648 238.995 64.1185Z"
          fill="white"
        />
      </g>
      <path
        d="M234.899 68.2968L235.276 68.2279L234.639 68.8483L234.272 68.9119C233.686 69.4778 233.066 70.0089 232.418 70.5027L232.359 70.2376C232.871 69.8511 233.362 69.4387 233.831 69.0021L233.273 69.0975C232.476 69.7975 231.563 70.5876 231.084 70.6883C230.728 70.7466 230.914 70.3277 231.86 69.3627L231.483 69.4316L232.12 68.8112L232.487 68.7476C232.986 68.2809 233.518 67.8196 233.985 67.459L234.075 67.6977C233.656 68.0264 233.268 68.3605 232.928 68.668L233.502 68.5673C234.293 67.8673 235.197 67.0932 235.669 67.0136C236.015 66.9182 235.834 67.3318 234.899 68.2968ZM232.774 69.1771L232.296 69.2566C232.003 69.5801 231.966 69.6914 232.078 69.6702C232.338 69.5447 232.575 69.3761 232.779 69.1718L232.774 69.1771ZM234.676 67.9734C234.419 68.0931 234.186 68.2564 233.985 68.4559L234.453 68.3764C234.74 68.0688 234.787 67.9469 234.681 67.9681L234.676 67.9734Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M235.85 54.8284V54.998C235.463 57.9445 234.816 60.851 233.916 63.6836C233.241 65.6191 232.593 66.3084 231.414 67.5545C231.18 67.8037 230.93 68.0848 230.659 68.3605C229.943 69.075 229.178 69.7394 228.369 70.3489C226.6 71.7647 224.406 73.5305 222.865 75.7045L222.823 75.7576C222.78 75.8159 218.19 81.6169 218.907 89.2473L218.95 89.7033L232.476 83.3031L232.439 83.0645C232.439 82.9743 231.031 73.838 238.097 67.3901C238.931 66.6266 239.723 65.9266 240.462 65.2691C246.269 60.0991 249.18 57.5062 249.557 48.7092V48.179L235.85 54.8284ZM240.015 64.7866C239.271 65.4494 238.48 66.1546 237.646 66.9076C232.838 71.2928 231.802 76.8764 231.674 80.2223C230.993 80.537 230.434 81.0642 230.08 81.7243C229.727 82.3844 229.598 83.1415 229.714 83.8811L221.803 87.6247C221.548 85.7317 220.172 85.8324 219.529 85.9915C219.898 82.4282 221.205 79.0257 223.317 76.1287C224.826 74.0077 226.999 72.2367 228.747 70.8262C229.584 70.1971 230.376 69.5096 231.116 68.7688C231.382 68.4771 231.647 68.2385 231.855 67.9734C233.055 66.7061 233.778 65.9372 234.511 63.8745C235.203 61.7609 235.736 59.5985 236.105 57.4054C236.79 57.2092 238.299 56.5199 238.575 54.1921L246.545 50.3159C246.46 50.9416 246.577 52.0392 248.612 51.7582C247.724 57.9198 244.924 60.412 240.015 64.7866Z"
          fill="white"
        />
      </g>
      <path
        d="M64.2222 49.0964C60.7705 48.8207 57.4307 47.7459 54.4678 45.9573C50.584 43.5605 40.1973 35.5113 37.0308 33.7243C33.4724 31.7397 29.6312 30.3092 25.64 29.4822L25.3159 10.6741C28.8078 11.1494 32.2349 12.0157 35.5326 13.2564C40.6755 15.2661 48.4536 24.3918 55.5835 26.7726C60.2057 28.3316 69.3067 27.69 69.3067 27.69L64.2222 49.0964Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M26.5646 28.7186L26.9046 28.8193C26.9684 28.8193 33.1579 30.6063 36.3563 32.4197C38.609 33.6923 42.2961 36.3065 45.8611 38.8358C48.5653 40.7554 51.1208 42.5476 52.9803 43.7513C58.9361 47.5373 62.6551 47.4631 62.8145 47.4631H63.1705L67.8405 29.3549L67.187 29.392C67.1179 29.392 60.3706 29.7685 56.7472 28.6019C51.9231 27.0535 48.4272 24.0258 45.0588 21.0935C42.6414 19.0043 40.3622 17.0264 37.6208 15.5364C35.1786 14.2072 32.5921 13.1611 29.9117 12.4185C28.8476 12.1116 27.7627 11.8812 26.6656 11.7292L26.1343 11.6655L26.5646 28.7186ZM56.455 29.5139C58.6067 30.2033 61.7041 30.3836 64.0258 30.4101C63.8326 30.9652 63.8458 31.5712 64.063 32.1175C64.5943 32.9447 65.4338 32.8811 66.1032 32.2501L63.0748 44.0111L63.0005 43.9475C61.5872 42.9188 60.0837 44.7058 60.4184 46.1321L60.2962 46.1003C57.883 45.3845 55.5907 44.3121 53.4957 42.9188C51.6521 41.7469 49.1072 39.9441 46.4136 38.0298L45.4892 37.3723C47.1255 37.5579 48.9053 36.6247 49.851 33.5651C50.7277 30.7335 49.9307 27.5626 48.7194 25.3355C51.0346 27.1522 53.641 28.565 56.4284 29.5139H56.455ZM37.1267 16.3742C39.7831 17.8165 42.0145 19.7572 44.3894 21.8199C44.6178 22.0108 44.841 22.207 45.0641 22.4032C43.9417 22.4393 42.8617 22.8399 41.9882 23.5443C41.1147 24.2486 40.4953 25.2182 40.2241 26.3059C39.6928 28.6708 39.5121 31.8842 41.2867 34.4665C39.544 33.3 37.9873 32.2394 36.8026 31.5872C34.5552 30.3146 31.0009 29.095 28.8864 28.4057C29.1148 28.2444 29.2702 27.9997 29.319 27.7247C29.3677 27.4497 29.3058 27.1666 29.1467 26.9369C28.8492 26.4066 28.0841 25.8764 27.5103 26.2423L27.4359 26.3006L27.1437 14.4547C28.1691 15.7856 29.5877 14.5448 28.8332 13.1449C31.7242 13.8645 34.5112 14.9497 37.1267 16.3742Z"
          fill="white"
        />
      </g>
      <path
        d="M45.1594 26.2158L45.4304 25.0969L46.3229 25.3143L46.0573 26.3748C46.8514 26.6035 47.5561 27.0699 48.0762 27.7111L47.2846 28.8352C46.8832 28.3216 46.3458 27.9304 45.7332 27.7058L45.3241 29.3708C46.2486 29.901 47.258 30.5161 46.918 31.9425C46.6576 33.003 45.7491 33.7401 44.2987 33.5757L44.0331 34.6839L43.1405 34.4665L43.4062 33.406C42.7705 33.2027 42.1991 32.8371 41.7485 32.3455L42.503 31.2161C42.8497 31.5844 43.2719 31.8738 43.7409 32.0645L44.1553 30.3677C43.2308 29.8692 42.232 29.2382 42.5614 27.833C42.8111 26.7937 43.7037 26.062 45.1594 26.2158ZM44.0278 28.0611C43.9428 28.411 44.1553 28.6602 44.5006 28.9042L44.8353 27.5308C44.6574 27.5024 44.4752 27.5409 44.3241 27.6389C44.1729 27.7368 44.0636 27.8873 44.0171 28.0611H44.0278ZM44.9735 30.8343L44.6281 32.2395C44.8146 32.2618 45.0028 32.2154 45.1574 32.109C45.312 32.0025 45.4223 31.8434 45.4676 31.6615C45.5366 31.3274 45.3188 31.0729 44.9628 30.8343H44.9735Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M144.303 223.789C140.853 223.513 137.514 222.436 134.554 220.645C130.665 218.253 120.278 210.204 117.112 208.417C113.555 206.433 109.716 205.002 105.726 204.175L105.397 185.367C108.89 185.838 112.317 186.705 115.614 187.949C120.757 189.959 128.535 199.058 135.67 201.465C140.287 203.019 149.388 202.383 149.388 202.383L144.303 223.789Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M106.619 203.411L106.959 203.507C107.023 203.507 113.218 205.299 116.416 207.113C118.663 208.385 122.35 210.999 125.915 213.529C128.62 215.448 131.175 217.24 133.04 218.444C138.99 222.23 142.715 222.156 142.869 222.156H143.23L147.895 204.042L147.241 204.08C147.172 204.08 140.43 204.456 136.801 203.289C131.977 201.741 128.481 198.713 125.113 195.781C122.696 193.692 120.411 191.714 117.675 190.224C115.236 188.896 112.653 187.852 109.977 187.111C108.913 186.804 107.828 186.574 106.731 186.422L106.199 186.358L106.619 203.411ZM136.509 204.207C138.666 204.896 141.758 205.076 144.085 205.103C143.892 205.658 143.905 206.264 144.123 206.81C144.654 207.638 145.493 207.574 146.163 206.943L143.129 218.704L143.055 218.64C141.647 217.612 140.143 219.399 140.478 220.825L140.356 220.793C137.943 220.088 135.651 219.024 133.555 217.638C131.712 216.466 129.167 214.663 126.473 212.749L125.543 212.092C127.185 212.277 128.96 211.344 129.905 208.284C130.782 205.453 129.99 202.282 128.774 200.055C131.099 201.865 133.714 203.269 136.509 204.207ZM117.208 191.067C119.864 192.504 122.095 194.45 124.476 196.508C124.699 196.704 124.922 196.9 125.15 197.091C124.027 197.129 122.947 197.53 122.072 198.236C121.198 198.941 120.578 199.911 120.305 200.999C119.774 203.364 119.593 206.577 121.368 209.154C119.646 207.972 118.084 206.948 116.905 206.28C114.652 205.007 111.103 203.788 108.983 203.099C109.103 203.019 109.205 202.916 109.283 202.796C109.362 202.676 109.415 202.541 109.44 202.4C109.465 202.258 109.461 202.114 109.428 201.974C109.395 201.834 109.334 201.703 109.249 201.587C108.951 201.057 108.186 200.527 107.607 200.898L107.533 200.956L107.246 189.105C108.271 190.441 109.684 189.201 108.93 187.801C111.818 188.532 114.6 189.629 117.208 191.067Z"
          fill="white"
        />
      </g>
      <path
        d="M125.23 200.903L125.506 199.79L126.394 200.007L126.128 201.094C126.925 201.32 127.632 201.787 128.152 202.43L127.366 203.512C126.964 202.997 126.424 202.605 125.809 202.383L125.384 204.042C126.309 204.573 127.323 205.188 126.978 206.614C126.723 207.675 125.815 208.412 124.364 208.247L124.093 209.356L123.206 209.138L123.472 208.078C122.833 207.876 122.26 207.51 121.809 207.017L122.558 205.904C122.903 206.272 123.323 206.561 123.79 206.752L124.205 205.055C123.28 204.557 122.287 203.926 122.611 202.521C122.892 201.487 123.79 200.755 125.23 200.903ZM124.098 202.754C124.013 203.104 124.226 203.353 124.571 203.597L124.911 202.224C124.734 202.198 124.554 202.238 124.404 202.335C124.255 202.433 124.146 202.582 124.098 202.754ZM125.044 205.527L124.699 206.943C124.885 206.964 125.073 206.917 125.227 206.811C125.381 206.705 125.492 206.546 125.538 206.365C125.623 206.015 125.384 205.76 125.044 205.527Z"
        fill="var(--color-positiveFeedback)"
      />
      <path
        d="M106.062 70.1316C102.667 70.2164 96.4293 70.0838 90.5319 67.3848C83.2267 64.0389 80.0655 59.2401 80.0655 59.2401L78.4717 77.1044C82.722 87.1051 99.6117 87.7944 107.485 87.98C105.403 79.3262 104.861 75.2591 106.062 70.1316Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M103.386 81.2699C104.675 78.1007 103.01 74.433 99.6659 73.0778C96.322 71.7226 92.5659 73.1931 91.2765 76.3624C89.9872 79.5316 91.6527 83.1993 94.9966 84.5545C98.3406 85.9097 102.097 84.4391 103.386 81.2699Z"
          fill="white"
        />
      </g>
      <path
        d="M96.5087 75.6409V74.6758H97.2791V75.6196C97.9748 75.6381 98.6481 75.8694 99.2077 76.2825L98.7986 77.343C98.3648 77.0052 97.8429 76.7991 97.295 76.7491V78.1861C98.1504 78.4088 99.112 78.7163 99.128 79.9518C99.128 80.8639 98.5542 81.654 97.3428 81.8289V82.7834H96.5725V81.8448C96.0039 81.81 95.4544 81.6272 94.9786 81.3146L95.3505 80.2541C95.7099 80.4807 96.114 80.6272 96.5353 80.6836V79.2201C95.6799 79.008 94.7342 78.6898 94.7183 77.4808C94.7289 76.6059 95.3027 75.8211 96.5087 75.6409ZM95.9774 77.3854C95.9774 77.6876 96.2059 77.8467 96.5406 77.974V76.7809C96.1634 76.8816 95.9774 77.1097 95.9774 77.3854ZM97.3322 79.4322V80.6518C97.4876 80.6311 97.63 80.5545 97.7328 80.4363C97.8355 80.3181 97.8914 80.1666 97.8901 80.0102C97.906 79.7238 97.6722 79.5594 97.3322 79.4322Z"
        fill="var(--color-positiveFeedback)"
      />
      <g opacity="0.2">
        <path
          d="M107.018 85.9597C103.086 86.0914 99.1497 85.8588 95.2605 85.2651C89.0603 84.4379 85.235 82.338 83.1152 80.7208C82.5628 80.302 82.0392 79.8467 81.5479 79.358C81.7897 78.5964 81.7931 77.7794 81.5577 77.0158C81.3222 76.2522 80.8592 75.5784 80.2303 75.0841L80.9741 65.8418C81.3619 66.0433 81.9835 66.1228 82.6689 65.2426C86.9192 69.1612 93.3956 70.8156 97.2794 71.4784C100.068 71.8547 102.881 72.0319 105.695 72.0087L105.86 71.0701C103.056 71.1057 100.252 70.9462 97.4706 70.5929C92.9653 69.8187 84.8631 67.6924 81.2238 62.1512L80.5225 61.0907L79.189 77.6559L79.2474 77.7672C79.3855 78.043 82.8549 84.4644 95.1861 86.1082C99.1492 86.7126 103.159 86.954 107.167 86.8293L107.018 85.9597Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
