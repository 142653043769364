import type { shippingEstimate } from '../components/ui/Orders/components/Status/OrderStatus'

interface TimeInterval {
  startDateUtc: string
  endDateUtc: string
}

export const getShippingEstimateDetails = (
  interval: TimeInterval
): shippingEstimate => {
  const startDate = new Date(interval.startDateUtc)
  const endDate = new Date(interval.endDateUtc)

  const getDayPosition = startDate.getUTCDay()
  const daysWeekName: string[] = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ]

  const dayOfTheWeek = daysWeekName[getDayPosition]

  const day = startDate.getUTCDate()
  const month = startDate.getUTCMonth() + 1

  const dayAndMonth = `${day} de ${getMonthhName(month)}`
  const timeRange = formatedTimeRange(startDate, endDate)

  return {
    dayOfTheWeek,
    dayAndMonth,
    timeRange,
  }
}

const getMonthhName = (monthIndex: number): string => {
  const monthName: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  return monthName[monthIndex - 1]
}

const formatedTimeRange = (
  currentStartDate: Date,
  currentEndDate: Date
): string => {
  const startTime = `${padZero(currentStartDate.getUTCHours())}:${padZero(
    currentStartDate.getUTCMinutes()
  )}`

  const endTime = `${padZero(currentEndDate.getUTCHours())}:${padZero(
    currentEndDate.getUTCMinutes()
  )}`

  return `${startTime} - ${endTime}`
}

const padZero = (number: number): string => {
  return number < 10 ? `0${number}` : number.toString()
}
