import axios from 'axios'
import React from 'react'

import { useClubContext } from '../../../contexts/ClubContext'
import { useLocalStorageContext } from '../../../contexts/LocalStorageContext'
import { useRegionalizationContext } from '../../../contexts/RegionalizationContext'
import { useStoreContext } from '../../../contexts/StoreContext'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useSalesforceEvents } from '../../../hooks/useSalesforceEvents'
import { cartStore, useCart } from '../../../sdk/cart'
import Button from '../../ui/Button'

import type { CartItem } from '../../../sdk/cart'

interface AddToCartButtonProps {
  buyProps: CartItem
  setOpenClubModal: (openClubModal: boolean) => void
  hasClub: boolean | undefined
  clubPrice?: number
  location: string
  lowStock?: boolean
  setModalChangeOrder: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddToCartButton = ({
  buyProps,
  setOpenClubModal,
  hasClub,
  clubPrice,
  location,
  lowStock,
  setModalChangeOrder,
}: AddToCartButtonProps) => {
  const { hasChangeOrder } = useStoreContext()
  const { regionalized, setProduct, setOpenRegionModal, setLocationItem } =
    useRegionalizationContext()

  const { getLocalStorageItem } = useLocalStorageContext()
  const { addItemEvent } = useAnalyticsEvents()
  const { addItemEventSalesforce } = useSalesforceEvents()
  const { isClubClient } = useClubContext()
  const { id: orderFormId } = useCart()

  const saveAddEventsOnOrderForm = () => {
    axios.post(`/api/saveAddEventsOnOrderForm`, {
      orderFormId,
      id: buyProps.id,
      location,
    })
  }

  const handleAddToCart = () => {
    if (lowStock && Boolean(hasChangeOrder)) {
      setModalChangeOrder?.(true)
    }

    if (regionalized) {
      const modalView = getLocalStorageItem?.().clubModalViewed

      cartStore.addItem(buyProps)
      addItemEvent(buyProps, location)
      addItemEventSalesforce(buyProps)
      saveAddEventsOnOrderForm()

      // open club modal just 1 time
      if (clubPrice && hasClub && !modalView && !isClubClient) {
        setOpenClubModal(true)
      }

      return
    }

    setProduct?.(buyProps)
    setLocationItem?.(location)
    setOpenRegionModal?.(true)
  }

  return (
    <Button
      onClick={handleAddToCart}
      variant="primary"
      aria-label="adicionado ao carrinho"
      title="Add to cart Sales"
    >
      Adicionar
    </Button>
  )
}
