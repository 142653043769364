import { Skeleton } from '@acctglobal/skeleton'
import axios from 'axios'
import React, { useState, useEffect } from 'react'

import Spinner from '../../../../assets/SpinnerIcon'
import { useRegionalizationContext } from '../../../../contexts/RegionalizationContext'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { useSession } from '../../../../sdk/session'
import { createCookie, deleteCookie } from '../../../../utils/cookies'
import { createArray } from '../../../../utils/createArrayForNumber'
import { NavigationBar } from '../../../common/NavigationBar'
import ProductGrid from '../../../product/ProductGrid'
import { FeatureDisabled } from '../../RenderErrors/FeatureDisabled'
import { NoDataError } from '../../RenderErrors/NoDataError'
import { NoOrderError } from '../../RenderErrors/NoOrderError'

import type { ProductsQueryResponse } from '../../../../types/Product'
import type { FacetValue } from '../../../common/NavigationBar'

import './buy-again-product-listing.scss'

interface Props {
  mostPurchased?: string[]
}

const defaultLabel = 'Tudo'

export const BuyAgainProductListing = ({
  mostPurchased: loadedMostPurchased,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [calledMostPurchased, setCalledMostPurchased] = useState(false)
  const [mostPurchased, setMostPurchased] = useState<string[] | null>(null)
  const [errorMostPurchased, setErrorMostPurchased] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [products, setProducts] = useState<ProductsQueryResponse['node'][]>([])

  const [initialProducts, setInitialProducts] = useState<
    ProductsQueryResponse['node'][]
  >([])

  const [productCategories, setProductCategories] = useState<FacetValue[]>([])

  const [selectedTab, setSelectedTab] = useState(defaultLabel)

  const { postalCode } = useRegionalizationContext()
  const { channel } = useSession()
  const { sections } = useStoreContext()

  const isActive: boolean | undefined = sections?.find(
    ({ name }) => name === 'BuyAgain'
  )?.data?.isActive

  const getMostPurchased = async () => {
    setLoading(true)
    setCalledMostPurchased(true)

    try {
      const { data } = await axios.get('/api/v2/products/mostPurchased', {
        params: {
          builder: 'faststore',
          postalCode,
          origin: 'plp',
          random: Math.random(),
        },
      })

      setMostPurchased(data)
      createCookie('mostpurchasedplp', JSON.stringify(data))
    } catch {
      // Delete cached data when authentication expires
      deleteCookie('mostpurchasedplp')
      setMostPurchased([])
      setErrorMostPurchased(true)
    }
  }

  const getProductsData = async (skusIds: string[]) => {
    try {
      setLoading(true)

      const {
        data: { products },
      } = await axios.post<ProductsQueryResponse>(
        '/api/products/getProductsByIds',
        {
          ids: skusIds,
          channel,
        }
      )

      setInitialProducts(products)
      setProducts(products)
    } catch {
      setHasError(true)
      setProducts([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (postalCode && !calledMostPurchased) {
      if (!loadedMostPurchased) {
        getMostPurchased()
      } else {
        setMostPurchased(loadedMostPurchased)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode])

  useEffect(() => {
    if (mostPurchased) {
      getProductsData(mostPurchased)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostPurchased])

  const getAvailableCategories = () => {
    const availableCategories: FacetValue[] = []

    products.forEach((product) => {
      const productCategoryName =
        product.breadcrumbList?.itemListElement[2].name

      const categoryIsAdd = availableCategories.some(
        (category) => category.label === productCategoryName
      )

      if (productCategoryName && !categoryIsAdd) {
        availableCategories.push({
          label: productCategoryName,
          value: productCategoryName,
        })
      }
    })

    setProductCategories([
      { label: defaultLabel, value: '' },
      ...availableCategories,
    ])
  }

  useEffect(() => {
    if (initialProducts?.length) {
      getAvailableCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProducts])

  const filterProductListByCategory = (label: string) => {
    let filteredProducts = []

    if (label !== defaultLabel) {
      filteredProducts = initialProducts.filter(
        (product) => product.breadcrumbList?.itemListElement[2].name === label
      )
    } else {
      filteredProducts = initialProducts
    }

    setProducts(filteredProducts)
    setSelectedTab(label)
  }

  if (!isActive) {
    return <FeatureDisabled />
  }

  if (errorMostPurchased || (mostPurchased && !mostPurchased.length)) {
    return <NoOrderError />
  }

  if (hasError) {
    return <NoDataError />
  }

  if (!postalCode) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <NavigationBar
        tabOptions={productCategories}
        selectedTab={selectedTab}
        handleTabItem={filterProductListByCategory}
      />

      <div className="grid-content">
        <ul className="product-grid">
          {!!products?.length && (
            <ProductGrid
              location="plp-compre-novamente"
              page={1}
              products={products}
            />
          )}
          {loading &&
            createArray(15).map((item) => (
              <Skeleton
                key={item}
                width={219}
                height={446}
                backgroundColor="var(--color-grayScale-faded)"
              />
            ))}
        </ul>
      </div>
    </>
  )
}
