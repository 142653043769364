import React from 'react'

import { useClubContext } from '../../contexts/ClubContext'
import { BuyAgainShelf } from '../sections/BuyAgainShelf'
import { TourUserLogged } from '../sections/TourUserLogged'

interface BuyAgainProps {
  data?: { isActive: boolean }
}

export const BuyAgain = ({ data }: BuyAgainProps) => {
  const { isClubClient } = useClubContext()

  if (!data?.isActive || !isClubClient) {
    return null
  }

  return (
    <>
      <BuyAgainShelf />
      <TourUserLogged />
    </>
  )
}
