import React from 'react'

import { ProductCardClubPrices } from './ProductCardClubPrices'
import { useClubContext } from '../../../../contexts/ClubContext'
import { truncPrice } from '../../../../utils/prices/truncPrice'
import { DiscountBadge } from '../../../ui/Badge'
import { PriceCustom } from '../../../ui/PriceCustom'

interface ProductCardPricesProps {
  listPriceCustom: number
  spotPriceKg: number | null
  spotPriceCustom: number
  hasDiscount: boolean
  unitMultiplier: number
  measurementUnit?: string
  clubPrice?: number
  defaultScListPrice: number | null | undefined
  hasPesavelUnd?: boolean
  hasUndQtd?: number | string
  hasUnd?: string
}

export const ProductCardPrices = ({
  listPriceCustom,
  spotPriceKg,
  spotPriceCustom,
  hasDiscount,
  unitMultiplier,
  measurementUnit,
  clubPrice,
  defaultScListPrice,
  hasPesavelUnd,
  hasUndQtd,
  hasUnd,
}: ProductCardPricesProps) => {
  const { isClubClient } = useClubContext()

  const clubPriceIsDiffFromSpotPrice = Boolean(
    clubPrice && clubPrice !== spotPriceCustom
  )

  const defaultScListPriceIsDiffFromSpotPrice = Boolean(
    defaultScListPrice && defaultScListPrice !== spotPriceCustom
  )

  const isMeasured = Boolean(measurementUnit !== 'un')

  const listPriceToShow =
    defaultScListPrice ??
    (hasPesavelUnd
      ? listPriceCustom
      : truncPrice(listPriceCustom * unitMultiplier))

  return (
    <div className="product-card__prices">
      {clubPriceIsDiffFromSpotPrice && !isClubClient && (
        <ProductCardClubPrices
          clubPrice={clubPrice}
          measurementUnit={measurementUnit}
          hasUndQtd={hasUndQtd}
          hasUnd={hasUnd}
        />
      )}

      {(defaultScListPriceIsDiffFromSpotPrice || hasDiscount) && (
        <div className="container-old-price">
          <PriceCustom price={listPriceToShow} className="old-price" />
          {isMeasured && (
            <span className="old-price">
              /{hasUndQtd}
              {unitMultiplier * 1000 >= 1000 ? measurementUnit : 'g'}{' '}
            </span>
          )}

          <DiscountBadge
            listPrice={listPriceToShow}
            spotPrice={spotPriceCustom}
            type="small"
          />
        </div>
      )}

      {isMeasured && !!spotPriceKg && hasPesavelUnd && (
        <div className="measure-full-price-container">
          <PriceCustom price={spotPriceKg} />
          <span>/{measurementUnit}</span>
        </div>
      )}

      <div className="new-price-container">
        <PriceCustom price={spotPriceCustom} className="new-price" />
        {isMeasured && (
          <span className="product-multiplier">
            /{hasUndQtd}
            {hasUnd}
          </span>
        )}
      </div>
    </div>
  )
}
