import React from 'react'

export const GlassIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="white" />
    <path
      d="M15.784 18.696V17.198H18.416V14.776H19.942V17.198H22.56V18.696H19.942V21.118H18.416V18.696H15.784Z"
      fill=" var(--color-main)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7999 18.2C27.7999 22.8392 24.0391 26.6 19.4 26.6C14.7608 26.6 11 22.8392 11 18.2C11 13.5608 14.7608 9.8 19.4 9.8C24.0391 9.8 27.7999 13.5608 27.7999 18.2ZM25.3261 26.5028C23.6558 27.6972 21.6099 28.4 19.4 28.4C13.7666 28.4 9.19995 23.8333 9.19995 18.2C9.19995 12.5667 13.7666 8 19.4 8C25.0333 8 29.5999 12.5667 29.5999 18.2C29.5999 20.9799 28.4879 23.5 26.6844 25.3398C26.6928 25.3475 26.701 25.3555 26.7091 25.3636L30.2363 28.8908C30.5878 29.2423 30.5878 29.8121 30.2363 30.1636C29.8849 30.5151 29.315 30.5151 28.9636 30.1636L25.4363 26.6364C25.3947 26.5947 25.3579 26.5499 25.3261 26.5028Z"
      fill=" var(--color-main)"
    />
  </svg>
)
