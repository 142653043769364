import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import { CommonBanner } from '../../common/CommonBanner'
import {
  ScratchBanner,
  EngagementBannerHandler,
  CouponsBanner,
  OffersBanner,
  ClubRegisterBanner,
  TabloidBanner,
  ClubProducts,
  BuyAgain,
  RepeatOrders,
  DynamicShowcases,
  YouMayAlsoLikeShelf,
  AlreadyVisitedShelf,
  ClubCardBanner,
} from '../../HomePage'
import { Banner } from '../Banner'
import { CarouselBanner } from '../CarouselBanner'
import { HighlightBrandsCarousel } from '../HighlightBrands/HighlightBrandsCarousel'
import { Mosaic } from '../Mosaic'
import { ProductShelfById } from '../ProductShelfById'
import { SuggestedKitsCarousel } from '../SuggestedKits/SuggestedKitsCarousel'

import type { CMSSection } from '../../../types/CMS'
import type { DynamicShowcase } from '../../HomePage/DynamicShowcases'

const COMPONENTS = {
  Suggestedkits: SuggestedKitsCarousel,
  'Carousel de marcas': HighlightBrandsCarousel,
  'Carrossel de marcas - v2': HighlightBrandsCarousel,
  Mosaico: Mosaic,
  Vitrine: ProductShelfById,
  SCRATCH_BANNER: ScratchBanner,
  EngagementBannerV2: EngagementBannerHandler,
  CouponsBannerV2: CouponsBanner,
  'Banner Rotativo da home': CarouselBanner,
  BenefitsBannerV2: OffersBanner,
  ClubRegisterBannerV2: ClubRegisterBanner,
  'Banner de ofertas': TabloidBanner,
  CommonBanner,
  ClubProducts,
  BuyAgain,
  RepeatOrders,
  DynamicShowcases,
  YouMayAlsoLikeShelf,
  AlreadyVisitedShelf,
  Banner,
  ClubCardBanner,
}

interface Props {
  preview?: CMSSection[] | null
  sections?: CMSSection[]
  loadingDynamicShowcases: boolean
  dynamicShowcases: DynamicShowcase[]
}

export const RenderCMS = ({
  preview,
  sections,
  loadingDynamicShowcases,
  dynamicShowcases,
}: Props) => {
  const loading = !preview && !sections

  if (loading) {
    return (
      <section className="grid-content">
        <Skeleton
          margin={24}
          backgroundColor="var(--color-grayScale-faded)"
          table={{ columns: 1, rows: 3 }}
          height={500}
          animation
        />
      </section>
    )
  }

  const dataSection = preview ?? sections

  return (
    <>
      {dataSection?.map(({ id, name, data }) => {
        const Component =
          COMPONENTS[(data?.refKey ?? name) as keyof typeof COMPONENTS]

        if (!Component) {
          return null
        }

        if (name === 'DynamicShowcases') {
          return (
            <Component
              key={id}
              data={data}
              loading={loadingDynamicShowcases}
              dynamicShowcases={dynamicShowcases}
            />
          )
        }

        return <Component key={id} data={data} />
      })}
    </>
  )
}
