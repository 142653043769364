import React from 'react'

import { AppStore, PlayStore } from '../../../../assets/downloadIcons'
import { BoldTextParts } from '../../../common/BoldTextParts'
import { DynamicTitle } from '../../../common/DynamicTitle'

import type { ClubCardGetCardPageSectionData } from '../../../../types/ClubCard/ClubCardGetCard'

import './steps.scss'

interface StepsProps {
  steps: ClubCardGetCardPageSectionData['steps']
  title?: string
}

export function Steps({ steps }: StepsProps) {
  const { visibility, list, downloadStep, links, title } = steps

  if (!visibility) {
    return null
  }

  return (
    <div className="club-card-get-card-steps grid-content">
      {title && (
        <DynamicTitle className="step-title" titleLevel={2} title={title} />
      )}
      <div className="step-list">
        {list?.map((step, index) => (
          <div
            key={step}
            className="step-container"
            data-testid="step-container"
          >
            <div className="step-content">
              <span>{index + 1}</span>
              <BoldTextParts text={step} />
            </div>

            {index + 1 === downloadStep && (
              <div
                className="download-container"
                data-testid="download-container"
              >
                <a
                  id="apple-download"
                  data-testid="apple-download"
                  href={links.web.apple}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppStore />
                </a>

                <a
                  id="android-download"
                  data-testid="android-download"
                  href={links.web.android}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PlayStore />
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
