import { Link } from 'gatsby'
import React from 'react'

import { useStoreContext } from '../../../contexts/StoreContext'

import './render-errors.scss'

import Image404 from '../../../assets/generic_error_repeat_order.png'
import Button from '../../ui/Button'

export const GenericError = () => {
  const { secureSubdomain } = useStoreContext()
  const url = `${secureSubdomain}/`

  function toHome() {
    window.location.assign(url)
  }

  return (
    <div className="no-data-error-container">
      <h2 className="error-title">
        Desculpe, mas aconteceu um erro ao carregar a página
      </h2>

      <div>
        <img src={Image404} width={328} height={312} />
      </div>

      <p className="no-data-error-message">
        Tente recarregar a página, ou entre novamente em alguns minutos
      </p>

      <Button type="button" variant="primary" onClick={() => location.reload()}>
        Recarregar Página
      </Button>
      <div className="generic-error-back-to-home">
        <Link to="/" onClick={toHome}>
          <span>Voltar para o Início</span>
        </Link>
      </div>
    </div>
  )
}
