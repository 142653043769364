import * as React from 'react'

interface SearchIconProps {
  width?: number
  height?: number
  color?: string
}

const SearchIcon = ({
  height = 16,
  width = 16,
  color = 'var(--color-assistant-main)',
}: SearchIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1999 7.66671C12.1999 10.1704 10.1703 12.2 7.66659 12.2C5.16289 12.2 3.13325 10.1704 3.13325 7.66671C3.13325 5.16302 5.16289 3.13337 7.66659 3.13337C10.1703 3.13337 12.1999 5.16302 12.1999 7.66671ZM11.6425 12.5968C10.5556 13.4745 9.17245 14 7.66659 14C4.16878 14 1.33325 11.1645 1.33325 7.66671C1.33325 4.1689 4.16878 1.33337 7.66659 1.33337C11.1644 1.33337 13.9999 4.1689 13.9999 7.66671C13.9999 9.0204 13.5752 10.2749 12.8518 11.3042C12.9092 11.3387 12.9635 11.3798 13.0135 11.4275L14.7046 13.042C15.0641 13.3853 15.0641 13.9418 14.7046 14.285C14.3451 14.6283 13.7622 14.6283 13.4027 14.285L11.7115 12.6705C11.6868 12.6469 11.6638 12.6223 11.6425 12.5968Z"
      fill={color}
    />
  </svg>
)

export default SearchIcon
