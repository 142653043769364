import React, { useMemo, useCallback } from 'react'

import { useRegionalizationContext } from '../../../../contexts/RegionalizationContext'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../../hooks/useAnotherScPrices'
import { useProductWithLowStock } from '../../../../hooks/useProductWithLowStock'
import { useCart, cartStore } from '../../../../sdk/cart'
import { formatBuyProduct } from '../../../../utils/formatBuyProduct'
import { SearchProductsCard } from '../../../common/DropdownSearchProducts/SearchProductsCard'
import { SuggestedKitClearButton } from '../SuggestedKitClearButton'
import { SuggestedKitModalFooter } from '../SuggestedKitModalFooter'
import { SuggestedKitModalHeader } from '../SuggestedKitModalHeader'

import type { CartItem } from '../../../../sdk/cart'
import type { ProductsQueryResponse } from '../../../../types/Product'
import type { ModalScreen } from '../SuggestedKitsCarousel'

import './suggested-kit-view.scss'

interface SuggestedKitViewProps {
  setIsOpenModal: (isOpenModal: boolean) => void
  setModalScreen: (modalScreen: ModalScreen) => void
  selectedKit?: {
    image: string
    title: string
    description: string
    productSkus: ProductsQueryResponse['node'][]
  }
}

export const SuggestedKitView = ({
  setIsOpenModal,
  setModalScreen,
  selectedKit,
}: SuggestedKitViewProps) => {
  const { image, title, description, productSkus } = selectedKit ?? {}
  const { productsWithLowStock, loading } = useProductWithLowStock(productSkus)

  const { items: cartItems }: { items: CartItem[] } = useCart()

  const { regionalized, setOpenRegionModal } = useRegionalizationContext()
  const { genericTaggingEvent } = useAnalyticsEvents()
  const { getAnotherScPrices } = useAnotherScPrices(productSkus)

  const cartSkus = useMemo(
    () => cartItems.map((cartItem) => cartItem.itemOffered.sku),
    [cartItems]
  )

  const kitSkus = productsWithLowStock?.map((product) => product.sku)

  const { productsKitAdded, productsKitNotAdded } = useMemo(
    () => ({
      productsKitAdded: productsWithLowStock?.filter((productSku) =>
        cartSkus.includes(productSku.sku)
      ),
      productsKitNotAdded: productsWithLowStock?.filter(
        (productSku) => !cartSkus.includes(productSku.sku)
      ),
    }),
    [cartSkus, productsWithLowStock]
  )

  const kitIsCompleted = useMemo(
    () => !productsKitNotAdded?.length,
    [productsKitNotAdded]
  )

  const totalKit = useMemo(() => {
    const kitCartItems = cartItems.filter((cartItem) =>
      kitSkus?.includes(cartItem.itemOffered.sku)
    )

    return kitCartItems.reduce(
      (acc, currentProduct) =>
        acc + currentProduct.price * currentProduct.quantity,
      0
    )
  }, [cartItems, kitSkus])

  const addAllProductsKit = useCallback(() => {
    productsKitNotAdded?.forEach((product) => {
      const formatedBuyProduct = formatBuyProduct(product)

      formatedBuyProduct && cartStore.addItem(formatedBuyProduct)
    })
    genericTaggingEvent('Adicionar Todos Produtos', {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsKitNotAdded])

  const removeAllProductsKit = useCallback(() => {
    productsKitAdded?.forEach((product) => {
      const productCartId = cartItems.find(
        (cartItem) => cartItem.itemOffered.sku === product.sku
      )?.id

      productCartId && cartStore.removeItem(productCartId)
    })
    genericTaggingEvent('Remover Todos Produtos', {})
  }, [cartItems, genericTaggingEvent, productsKitAdded])

  const goBack = () => {
    genericTaggingEvent('lista_de_kits', {
      ecommerce: { click_text: 'lista de kits' },
    })
    setModalScreen('kitList')
  }

  if (loading) return null

  return (
    <div className="suggested-kit-view-container">
      <SuggestedKitModalHeader
        onClose={() => setIsOpenModal(false)}
        viewTitle="Lista de Kits"
        goBack={goBack}
      />

      <div className="suggested-kit-view-content">
        <img src={image} alt={title} className="suggested-kit-view-image" />
        <h2 className="suggested-kit-view-title">{title}</h2>
        <p className="suggested-kit-view-description">{description}</p>
        <h3 className="suggested-kit-view-subtitle">Produtos</h3>

        <div className="suggested-kit-view-products">
          {productsWithLowStock?.map((product) => {
            const anotherScPrices = getAnotherScPrices(product.sku)

            return (
              <SearchProductsCard
                product={product}
                key={`suggested-kit-view-search-products-card=${product.id}`}
                location="kits_pra_voce"
                {...anotherScPrices}
              />
            )
          })}
        </div>

        {!!productsKitAdded?.length && (
          <SuggestedKitClearButton
            text="Remover todos produtos"
            onClick={() => removeAllProductsKit()}
          />
        )}
      </div>

      <SuggestedKitModalFooter
        text={
          kitIsCompleted
            ? 'Todos Produtos Adicionados'
            : 'Adicionar Todos Produtos'
        }
        totalValue={totalKit}
        addAllProducts={() =>
          regionalized ? addAllProductsKit() : setOpenRegionModal?.(true)
        }
        isDisabled={kitIsCompleted}
      />
    </div>
  )
}
