import axios, { CancelTokenSource } from 'axios'
import { useState, useEffect } from 'react'

export function useLandingPageCms<T>(lpName?: string | null) {
  const [cmsData, setCmsData] = useState<T>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const source: CancelTokenSource = axios.CancelToken.source()

    const request = async () => {
      setLoading(true)

      if (!lpName) {
        setLoading(false)
        return
      }

      try {
        const { data }: { data: T } = await axios.get(
          `/api/v1/dynamicPage/landingPage?builder=faststore&lpName=${lpName}`,
          { cancelToken: source.token }
        )
        setCmsData(data)
      } catch (error) {
        throw new Error('Error when trying to fetch campaigns in useCampaigns')
      } finally {
        setLoading(false)
      }
    }

    request()

    return () =>
      source.cancel('Requisição cancelada ao desmontar ou atualizar lpName')
  }, [lpName])

  return { cmsData, loading }
}
