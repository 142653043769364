import React, { memo } from 'react'

import { useSearchOverlayContext } from '../../../contexts/SearchOverlayContext'
import './searchOverlay.scss'

export const SearchOverlay = () => {
  const { visibleSearchOverlay } = useSearchOverlayContext()

  if (!visibleSearchOverlay) {
    return null
  }

  return <div className="searchOverlay" role="presentation" />
}

export default memo(SearchOverlay)
