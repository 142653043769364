import React, { useMemo } from 'react'

import { useProductWithLowStock } from '../../../hooks/useProductWithLowStock'
import { useProductsQuery } from '../../../sdk/product/useProductsQuery'
import { Variant } from '../../product/ProductCard'
import { ProductShelf, ProductShelfProps } from '../../ui/ProductShelf'

import type { Facet } from '@faststore/sdk/dist/types'

interface ProductShelfByFiltersProps extends ProductShelfProps {
  queryInput?: {
    firstItems?: number
    sort?: string
    selectedFacets?: Facet | Facet[]
    term?: string
  }
  type?: 'club' | 'default'

  //items club
  customQtyItems?: number
  description?: string

  // ProductShelfByID
  buttonSeeAll?: boolean
  variant?: Variant
}

export const ProductShelfByFilters = ({
  title,
  link,
  id,
  queryInput,
  customQtyItems,
  description,
  titleColor,
  buttonSeeAll,
  variant,
  propsCarousel,
  backgroundColor,
  limit,
  type,
  SeeMoreText,
}: ProductShelfByFiltersProps) => {
  const { data: productList } = useProductsQuery({
    first: queryInput?.firstItems,
    sort: queryInput?.sort,
    selectedFacets: queryInput?.selectedFacets,
    term: queryInput?.term,
  })

  const productsFilter = useMemo(
    () => productList?.edges.map(({ node }) => node),
    [productList]
  )

  const { productsWithLowStock, loading: productsWithLowStockLoading } =
    useProductWithLowStock(productsFilter)

  return (
    <>
      <ProductShelf
        titleColor={titleColor ?? ''}
        products={productsWithLowStock}
        title={title}
        link={link}
        id={id}
        loading={productsWithLowStockLoading}
        variant={variant}
        propsCarousel={propsCarousel}
        backgroundColor={backgroundColor}
        limit={limit}
        description={description}
        typeShelf={type}
        customQtyItems={customQtyItems}
        buttonSeeAll={buttonSeeAll}
        SeeMoreText={SeeMoreText}
      />
    </>
  )
}
