import axios from 'axios'
import { useState, useEffect } from 'react'

import { formatPhysicalOrder } from '../utils/formatPhysicalOrder'

import type {
  CustomerOrder,
  GetOrdersResponse,
  PhysicalOrdersResponse,
} from '../types/RepeatOrder'

export const useRepeatOrders = (limit: number) => {
  const [lastOrders, setLastOrders] = useState<CustomerOrder[]>()

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const [{ data: digitalOrders }, { data: physicalOrders }] =
          await Promise.all([
            axios.get<GetOrdersResponse[]>(
              `/api/v2/orders?status=invoice,invoiced&limit=${limit}&fields=id,quantity,name&random=${Math.random()}`
            ),
            axios.get<PhysicalOrdersResponse[]>(
              `/api/v1/orders/physicalOrders?page=1&pageSize=${limit}&fields=items{itemId,name}&builder=faststore&random=${Math.random()}`
            ),
          ])

        const formattedPhysicalOrders: GetOrdersResponse[] =
          physicalOrders.map(formatPhysicalOrder)

        const digitalOrdersWithType: CustomerOrder[] = digitalOrders.map(
          ({ data: { customerOrder } }) => ({
            ...customerOrder,
            type: 'digital',
          })
        )

        const physicalOrderWithType: CustomerOrder[] =
          formattedPhysicalOrders.map(({ data: { customerOrder } }) => ({
            ...customerOrder,
            type: 'physical',
          }))

        const lastOrders = [...digitalOrdersWithType, ...physicalOrderWithType]
          .sort(
            (a, b) => Date.parse(b.creationDate) - Date.parse(a.creationDate)
          )
          .slice(0, limit)

        setLastOrders(lastOrders)
      } catch {
        setLastOrders([])
      }
    }

    fetchOrders()
  }, [])

  return { lastOrders }
}
