import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react'

import { useLocalStorageContext } from './LocalStorageContext'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { accountCardNames } from '../utils/faqFunctions'
import {
  deleteQueryParam,
  extractSearchParameters,
  setQueryParams,
} from '../utils/FAQQueryParamFunctions'

import type { FaqData, TopicOptions } from '../types/FAQ'

interface FAQContextProps {
  children?: React.ReactNode
  faqData?: FaqData
  activeQuestion?: string
  setActiveQuestion?: (activeQuestion: string) => void
  activeTopic?: string
  setActiveTopic?: (activeTopic: string) => void
  handleChangeTopic?: (title: TopicOptions) => void
  showAsideMenu?: boolean
  setShowAsideMenu?: (showAsideMenu: boolean) => void
  isMobile?: boolean | null
  handleQuestion?: (question: string) => void
  searchParam?: string | ''
  dropdownIsOpen?: boolean
  questionSearch?: string
  setQuestionSearch?: (questionSearch: string) => void
  setDropdownIsOpen?: (dropdownIsOpen: boolean) => void
  FAQsuggestionNavigation?: (searchedItem?: string, topic?: string) => void
  setSearchParam?: (searchParam?: string) => void
  setPathName?: (pathName?: string) => void
  pathName?: string
}

const FAQContext = createContext<FAQContextProps | null>(null)

export const FAQPageContextProvider = ({
  children,
  faqData,
}: FAQContextProps) => {
  const [isMobile, setIsMobile] = useState<boolean | null>(null)
  const { isMobile: isMobileHook } = useWindowDimensions()
  const [activeQuestion, setActiveQuestion] = useState('')
  const [activeTopic, setActiveTopic] = useState<string>('')
  const [showAsideMenu, setShowAsideMenu] = useState(true)
  const [questionSearch, setQuestionSearch] = useState('')
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false)
  const [searchParam, setSearchParam] = useState<string | undefined>('')
  const [pathName, setPathName] = useState<string | undefined>()

  const defaultTopic = 'Perguntas Frequentes'
  const { getLocalStorage } = useLocalStorageContext()
  const storeId = getLocalStorage('storeId')

  const newFaqData = useMemo(() => {
    const replacedTopics = faqData?.topics

    if (!replacedTopics || !storeId) {
      return faqData
    }

    replacedTopics[accountCardNames[storeId]] = faqData.topics['Cartão Boa']

    return {
      topics: replacedTopics,
      attendanceMethodsLinks: faqData?.attendanceMethodsLinks,
    }
  }, [faqData, storeId, accountCardNames])

  const handleChangeTopic = (topic: string, noUpdateParam?: boolean) => {
    setActiveTopic(topic)

    if (!noUpdateParam) {
      setQueryParams('topic', topic)
    }

    deleteQueryParam('question')
    setShowAsideMenu(!(isMobile && topic))
  }

  const handleQuestion = (question: string) => {
    setActiveQuestion(activeQuestion !== question ? question : '')
    setQueryParams('topic', activeTopic)
    setQueryParams('question', question)
  }

  const FAQsuggestionNavigation = useCallback(
    (question?: string, topic?: string) => {
      if (!question || !topic) {
        return
      }

      setQuestionSearch?.('')
      setDropdownIsOpen?.(false)
      setShowAsideMenu?.(!isMobile)
      setActiveTopic?.(topic)
      setActiveQuestion?.(question)
      setQueryParams('topic', topic)
      setQueryParams('question', question)
    },
    [isMobile]
  )

  useEffect(() => {
    setIsMobile(isMobileHook)
  }, [isMobileHook])

  useEffect(() => {
    if (isMobile === null) {
      return
    }

    if (isMobile && activeTopic !== '') {
      setShowAsideMenu(false)
    } else {
      setShowAsideMenu(true)
    }

    if (!isMobile && activeTopic === '') {
      handleChangeTopic(defaultTopic, true)
    }
  }, [isMobile, activeTopic, defaultTopic])

  useEffect(() => {
    // Extract search parameters from the URL query string
    const { topic: topicParam, question: questionParam } =
      extractSearchParameters(searchParam ?? '')

    // Decode the topic parameter and convert it to a valid topic option
    const currentTopic = decodeURIComponent(topicParam || '').replaceAll(
      '+',
      ' '
    ) as TopicOptions

    // Decode the question parameter
    const currentQuestion = decodeURIComponent(questionParam || '').replaceAll(
      '+',
      ' '
    )

    // Check if the topic exists in the FAQ data
    const topicExists = newFaqData?.topics?.[currentTopic]

    if (searchParam && !topicExists) {
      // If the topic does not exist in the FAQ data and there is a search param,
      // show the aside menu and set the active topic to the default topic
      if (!isMobile) {
        setShowAsideMenu?.(true)
        setActiveTopic(defaultTopic)
      }

      return
    }

    // If the topic exists, set it as the active topic
    if (activeTopic !== currentTopic) {
      setActiveTopic(currentTopic)

      // Hide the aside menu on mobile devices when a topic is selected
      setShowAsideMenu?.(!isMobile && Boolean(currentTopic))
    }

    // If the question has changed, set it as the active question
    if (activeQuestion !== currentQuestion) {
      setActiveQuestion(currentQuestion)
    }

    // If there is no search param, reset the active topic and question and show the aside menu
    if (!searchParam) {
      setActiveTopic('')
      setActiveQuestion('')
      setShowAsideMenu?.(true)
    }
  }, [searchParam, faqData])

  const value = useMemo(
    () => ({
      setSearchParam,
      dropdownIsOpen,
      setDropdownIsOpen,
      activeQuestion,
      setPathName,
      setActiveQuestion,
      FAQsuggestionNavigation,
      activeTopic,
      setActiveTopic,
      handleChangeTopic,
      showAsideMenu,
      setShowAsideMenu,
      setQuestionSearch,
      questionSearch,
      isMobile,
      faqData: newFaqData,
      handleQuestion,
    }),
    [
      pathName,
      searchParam,
      questionSearch,
      dropdownIsOpen,
      activeQuestion,
      activeTopic,
      handleChangeTopic,
      showAsideMenu,
      isMobile,
      faqData,
      handleQuestion,
      FAQsuggestionNavigation,
      faqData,
      handleQuestion,
    ]
  )

  return <FAQContext.Provider value={value}>{children}</FAQContext.Provider>
}

export const useFAQContext = () => {
  const context = useContext(FAQContext)

  if (!context) {
    throw new Error('useFAQContext must be used within a FAQContextProvider')
  }

  return context
}
