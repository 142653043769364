import React, { useMemo } from 'react'

import { ProductContainer } from './ProductContainer/ProductsContainer'
import ProductNotFound from '../../../../../sections/FoodRestriction/NotFoundProduct'
import Button from '../../../../../ui/Button/Button'
import { FoodRestrictiontHeader } from '../FoodRestrictiontHeader/FoodRestrictiontHeader'

import type { RestrictionFacet } from '../../../../../../types/FoodRestriction'

import './food-restriction-products-content.scss'

interface FacetsProps {
  value: string
  selected: boolean
  quantity: number
  label: string
}
interface FoodRestrictionSearchPageContentProps {
  totalCount: number
  setSort: (sort: string) => void
  sort: string
  products: any[]
  hasProductRestriction: boolean
  requestLoading: boolean
  handlePagination: () => void
  maxPagination: boolean
  layoutLoading: boolean
  handleFacets: (newFacet: RestrictionFacet[]) => void
  allFacets?: {
    facet: string
    title: string
    options: FacetsProps[]
  }[]
  selectedCategorie: string
  setSelectedCategorie: (selectedCategorie: string) => void
  setSelectedSubcategorie?: (subcategorie: string) => void
  filterFacets: RestrictionFacet[]
  setFilterFacets: (filterFacets: RestrictionFacet[]) => void
  categorieFacetsKey: string
  subcategorieFacetsKey: string
}

export const FoodRestrictionProductsContent = ({
  totalCount,
  setSort,
  sort,
  products,
  handlePagination,
  hasProductRestriction,
  requestLoading,
  maxPagination,
  handleFacets,
  allFacets,
  selectedCategorie,
  setSelectedCategorie,
  setSelectedSubcategorie,
  filterFacets,
  setFilterFacets,
  categorieFacetsKey,
  subcategorieFacetsKey,
}: FoodRestrictionSearchPageContentProps) => {
  const title = useMemo(() => {
    if (selectedCategorie === '') {
      return 'Todos'
    }

    return selectedCategorie
  }, [selectedCategorie])

  return (
    <div className="restrictionProductsPage-productContent">
      <FoodRestrictiontHeader
        title={title}
        setSort={setSort}
        totalCount={totalCount}
        sort={sort}
        handleFacets={handleFacets}
        allFacets={allFacets}
        requestLoading={requestLoading}
        selectedCategorie={selectedCategorie}
        setSelectedCategorie={setSelectedCategorie}
        setSelectedSubcategorie={setSelectedSubcategorie}
        hasProductRestriction={hasProductRestriction}
        filterFacets={filterFacets}
        setFilterFacets={setFilterFacets}
        categorieFacetsKey={categorieFacetsKey}
        subcategorieFacetsKey={subcategorieFacetsKey}
      />

      {requestLoading || hasProductRestriction ? (
        <ProductContainer products={products} requestLoading={requestLoading} />
      ) : (
        <div className="restrictionProductsPage-productContent-products product-not-found">
          <ProductNotFound />
        </div>
      )}

      <div className="restrictionProductsPage-productContent-moreProducts">
        {hasProductRestriction && maxPagination && (
          <Button disabled={requestLoading} onClick={() => handlePagination()}>
            {requestLoading ? 'Buscando Produtos' : 'Mostrar Mais'}
          </Button>
        )}
      </div>
    </div>
  )
}
