import React, { useEffect, useRef, Suspense, useMemo, useCallback } from 'react'

import { FAQInputDropdown } from './FAQInputDropdown'
import SearchDeleteIcon from '../../../../assets/SearchDeleteIcon'
import SearchIcon from '../../../../assets/SearchIcon'
import { useFAQContext } from '../../../../contexts/FAQContext'
import useOnClickOutside from '../../../../sdk/ui/useOnClickOutside'

import type { Topics } from '../../../../types/FAQ'
import './faq-input.scss'

interface FAQInputProps {
  placeholderText?: string
}

export const FAQInput = ({ placeholderText }: FAQInputProps) => {
  const {
    FAQsuggestionNavigation,
    questionSearch,
    faqData,
    setDropdownIsOpen,
    dropdownIsOpen,
    setQuestionSearch,
  } = useFAQContext()

  const dropdownContainer = useRef<HTMLLabelElement>(null)

  useOnClickOutside(dropdownContainer, () => setDropdownIsOpen?.(false))

  useEffect(() => {
    if (!questionSearch) {
      return
    }

    questionSearch.length >= 3
      ? setDropdownIsOpen?.(true)
      : setDropdownIsOpen?.(false)
  }, [questionSearch])

  const filterQuestions = (term: string, topics?: Topics) => {
    if (!topics) {
      return
    }

    const filteredQuestions: {
      topic: string
      questions: { question: string; answer: string }[]
    }[] = []

    Object.entries(topics).forEach(
      ([topic, { questions }]: [
        string,
        { questions: { question: string; answer: string }[] },
      ]) => {
        const filteredTopicQuestions = questions.filter(
          ({ question, answer }: { question: string; answer: string }) =>
            question.toLowerCase().includes(term.toLowerCase()) ||
            answer.toLowerCase().includes(term.toLowerCase())
        )

        if (filteredTopicQuestions.length > 0) {
          filteredQuestions.push({
            topic,
            questions: filteredTopicQuestions,
          })
        }
      }
    )

    return filteredQuestions
  }

  const filteredQuestions = useMemo(() => {
    if (!questionSearch) {
      return
    }

    return filterQuestions(questionSearch, faqData?.topics)
  }, [questionSearch, faqData])

  const handleSearchedQuestion = () => {
    if (!filteredQuestions || filteredQuestions?.length < 1) {
      return
    }

    FAQsuggestionNavigation?.(
      filteredQuestions?.[0].questions[0].question,
      filteredQuestions?.[0].topic ?? ''
    )
  }

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearchedQuestion()
      }
    },
    [filteredQuestions]
  )

  return (
    <label className="faq-input-container" ref={dropdownContainer}>
      <div className="faq-input-subcontainer">
        <input
          className="faq-input"
          type="text"
          value={questionSearch}
          onChange={(event) => setQuestionSearch?.(event.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholderText}
        />

        {questionSearch && (
          <button
            className="faq-input-button-delete-x"
            onClick={() => setQuestionSearch?.('')}
          >
            <SearchDeleteIcon />
          </button>
        )}

        <button
          disabled={filteredQuestions && filteredQuestions?.length < 0}
          className="faq-input-button"
          onClick={() => handleSearchedQuestion()}
        >
          <SearchIcon />
        </button>
      </div>

      {dropdownIsOpen && (
        <Suspense fallback={null}>
          <FAQInputDropdown
            filteredQuestions={filteredQuestions}
            questionSearch={questionSearch ?? ''}
            setQuestionSearch={setQuestionSearch}
          />
        </Suspense>
      )}
    </label>
  )
}
