import React from 'react'

export const CouponsIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="coupons-icon"
    >
      <mask
        id="mask0_3286_6876"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3286_6876)">
        <path
          d="M12.2666 21.3333L16 18.5333L19.6666 21.3333L18.2666 16.8L22 13.8667H17.4666L16 9.33333L14.5333 13.8667H9.99996L13.6666 16.8L12.2666 21.3333ZM5.33329 26.6667C4.59996 26.6667 3.97218 26.4056 3.44996 25.8833C2.92774 25.3611 2.66663 24.7333 2.66663 24V19.5C2.66663 19.2556 2.7444 19.0444 2.89996 18.8667C3.05551 18.6889 3.25551 18.5778 3.49996 18.5333C4.03329 18.3556 4.47218 18.0333 4.81663 17.5667C5.16107 17.1 5.33329 16.5778 5.33329 16C5.33329 15.4222 5.16107 14.9 4.81663 14.4333C4.47218 13.9667 4.03329 13.6444 3.49996 13.4667C3.25551 13.4222 3.05551 13.3111 2.89996 13.1333C2.7444 12.9556 2.66663 12.7444 2.66663 12.5V7.99999C2.66663 7.26666 2.92774 6.63888 3.44996 6.11666C3.97218 5.59444 4.59996 5.33333 5.33329 5.33333H26.6666C27.4 5.33333 28.0277 5.59444 28.55 6.11666C29.0722 6.63888 29.3333 7.26666 29.3333 7.99999V12.5C29.3333 12.7444 29.2555 12.9556 29.1 13.1333C28.9444 13.3111 28.7444 13.4222 28.5 13.4667C27.9666 13.6444 27.5277 13.9667 27.1833 14.4333C26.8388 14.9 26.6666 15.4222 26.6666 16C26.6666 16.5778 26.8388 17.1 27.1833 17.5667C27.5277 18.0333 27.9666 18.3556 28.5 18.5333C28.7444 18.5778 28.9444 18.6889 29.1 18.8667C29.2555 19.0444 29.3333 19.2556 29.3333 19.5V24C29.3333 24.7333 29.0722 25.3611 28.55 25.8833C28.0277 26.4056 27.4 26.6667 26.6666 26.6667H5.33329ZM5.33329 24H26.6666V20.6C25.8444 20.1111 25.1944 19.4611 24.7166 18.65C24.2388 17.8389 24 16.9556 24 16C24 15.0444 24.2388 14.1611 24.7166 13.35C25.1944 12.5389 25.8444 11.8889 26.6666 11.4V7.99999H5.33329V11.4C6.15551 11.8889 6.80551 12.5389 7.28329 13.35C7.76107 14.1611 7.99996 15.0444 7.99996 16C7.99996 16.9556 7.76107 17.8389 7.28329 18.65C6.80551 19.4611 6.15551 20.1111 5.33329 20.6V24Z"
          fill="var(--color-main)"
        />
      </g>
    </svg>
  )
}
