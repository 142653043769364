import { CardActions, Modal } from '@faststore/ui'
import React, { memo, useEffect, useMemo, useState } from 'react'

import { InfoIcon } from '../../../assets/InfoIcon'
import { HAS_WINDOW, pesavelUND } from '../../../constants'
import { useClubContext } from '../../../contexts/ClubContext'
import { useStoreContext } from '../../../contexts/StoreContext'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import { useBuyProduct } from '../../../hooks/useBuyProduct'
import { useSalesforceEvents } from '../../../hooks/useSalesforceEvents'
import { formatEspecifications } from '../../../utils/formatEspecifications'
import Breadcrumb from '../../ui/Breadcrumb'
import ProductMoreInfos from '../../ui/ProductMoreInfos/ProductMoreInfos'
import { BackButton } from '../BackButton'
import { ClubModal } from '../ClubModal'
import { FoodRestrictionFlagLink } from '../FoodRestriction/FoodRestrictionFlagLink'
import { ProductDetailsActions } from './components/ProductDetailsActions'
import { ProductDetailsGallery } from './components/ProductDetailsGallery'
import { ProductDetailsHeader } from './components/ProductDetailsHeader'
import { ProductDetailsPrices } from './components/ProductDetailsPrices'
import { StorageDetailsFlag } from './components/StorageDetailsFlag'

import type {
  ProductsQueryResponse,
  SkuSpecificationsProps,
} from '../../../types/Product'

import './product-details.scss'

interface ProductDetailsProps {
  product: ProductsQueryResponse['node']
}

function ProductDetails({ product: staleProduct }: ProductDetailsProps) {
  const [openClubModal, setOpenClubModal] = useState(false)
  const { buyProps, cartProductId, cartProductQty, productData } =
    useBuyProduct(staleProduct)

  const {
    product: {
      measurementUnit,
      unitMultiplier,
      sku,
      description,
      brand,
      image: productImages,
      name,
      offers: {
        offers: [{ availability }],
      },
      customOffers: {
        listPriceCustom,
        spotPriceKg,
        spotPriceCustom,
        hasDiscount,
      },
      breadcrumbList: breadcrumbs,
      additionalProperty,
    },
  } = productData

  const hasPesavelUnd = productData?.product?.skuSpecifications?.some(
    (specification: SkuSpecificationsProps) => {
      return (
        specification.field.originalName === pesavelUND &&
        specification.values.some((value) => value.originalName === 'true')
      )
    }
  )

  const hasUndQtd = useMemo(() => {
    if (hasPesavelUnd) {
      return ''
    }

    if (unitMultiplier > 1) {
      return unitMultiplier
    }

    if (unitMultiplier === 1) {
      return ''
    }

    return unitMultiplier * 1000
  }, [hasPesavelUnd, unitMultiplier])

  const hasUnd = useMemo(() => {
    if (hasPesavelUnd) {
      return 'Un'
    }

    if (unitMultiplier * 1000 >= 1000) {
      return measurementUnit
    }

    return 'g'
  }, [hasPesavelUnd, measurementUnit, unitMultiplier])

  const buyDisabled = availability !== 'https://schema.org/InStock'

  const { hasClub } = useStoreContext()
  const { isClubClient } = useClubContext()
  const { viewProductDetails } = useAnalyticsEvents()
  const anotherScPrices = useAnotherScPrices([staleProduct]).getAnotherScPrices(
    staleProduct.sku
  )

  const { viewProductDetailsSalesforce } = useSalesforceEvents()
  const { foodRestrictionSection, storageRestrictionSection } =
    useStoreContext()

  const restrictionTags = foodRestrictionSection?.map(
    ({ specificationName }) => specificationName
  )

  const storageTags = storageRestrictionSection?.map(
    ({ specificationName }) => specificationName
  )

  const { productRestriction, productStorage } = useMemo(
    () =>
      formatEspecifications(additionalProperty, restrictionTags, storageTags),
    [additionalProperty, restrictionTags, storageTags]
  )

  useEffect(() => {
    viewProductDetails(buyProps)
    viewProductDetailsSalesforce(buyProps)
  }, [buyProps, viewProductDetails, viewProductDetailsSalesforce])

  const terms =
    productRestriction?.map((restriction) => ({
      term: restriction,
    })) ?? []

  const clubValidation = !!(
    hasClub &&
    isClubClient &&
    anotherScPrices?.defaultScListPrice
  )

  const clubModalCanBeOpened = HAS_WINDOW && hasClub && !isClubClient

  return (
    <>
      {clubModalCanBeOpened && (
        <Modal isOpen={openClubModal} className="modal-regionalization">
          <ClubModal
            setOpenClubModal={setOpenClubModal}
            buyProps={buyProps}
            clubPrice={anotherScPrices?.clubPrice}
          />
        </Modal>
      )}

      <div className="product-details-container">
        <Breadcrumb breadcrumbList={breadcrumbs.itemListElement} />

        <BackButton />

        <section className="product-details__body">
          <ProductDetailsGallery
            clubValidation={clubValidation}
            productImages={productImages}
            listPriceCustom={listPriceCustom}
            spotPriceCustom={spotPriceKg ?? spotPriceCustom}
            hasDiscount={hasDiscount}
          />

          <div className="product-details__content">
            <ProductDetailsHeader name={name} brand={brand.name} />
            <section className="product-details__main-infos">
              <ProductDetailsPrices
                listPriceCustom={listPriceCustom}
                spotPriceKg={spotPriceKg}
                spotPriceCustom={spotPriceCustom}
                hasDiscount={hasDiscount}
                unitMultiplier={unitMultiplier}
                measurementUnit={measurementUnit}
                clubValidation={clubValidation}
                {...anotherScPrices}
                isClubClient={isClubClient}
                hasUndQtd={hasUndQtd}
                hasUnd={hasUnd}
                hasPesavelUnd={hasPesavelUnd}
              />

              <CardActions>
                <ProductDetailsActions
                  hasClub={hasClub}
                  cartProductId={cartProductId}
                  sku={sku}
                  unitMultiplier={unitMultiplier}
                  measurementUnit={measurementUnit}
                  cartProductQty={cartProductQty}
                  buyProps={buyProps}
                  buyDisabled={buyDisabled}
                  setOpenClubModal={setOpenClubModal}
                  clubPrice={anotherScPrices?.clubPrice}
                  isClubClient={isClubClient}
                  location="pdp"
                  hasPesavelUnd={hasPesavelUnd}
                  spotPriceCustom={spotPriceCustom}
                />
              </CardActions>

              {measurementUnit !== 'un' && (
                <div className="weight-warning">
                  <InfoIcon />
                  Pode existir variação de peso após separação do pedido.
                </div>
              )}

              {terms.length > 0 && (
                <section className="restriction-flag-link-content">
                  <h3 className="restriction-flag-link-title">
                    Restrição Alimentar
                  </h3>
                  <FoodRestrictionFlagLink restrictionTerms={terms} />
                </section>
              )}
            </section>

            <section className="product-details__more-infos">
              <ProductMoreInfos
                title="Descrição do Produto"
                description={description}
              />

              {productStorage.length > 0 && (
                <StorageDetailsFlag
                  title="Armazenamento"
                  storage={productStorage[0]}
                />
              )}
            </section>
          </div>
        </section>
      </div>
    </>
  )
}

export default memo(ProductDetails)
