import React from 'react'

import { EngagementBanner } from '../sections/EngagementBanner'

import type { CommonCmsBanner } from '../../types/CMS'

interface Props {
  data?: CommonCmsBanner
}

export const EngagementBannerHandler = ({ data }: Props) => {
  return <EngagementBanner data={data} />
}
