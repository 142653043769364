// Store ID
const account = 'amigao'
const production = 'amigao'

// SalesChannels
const salesChannels = {
  default: 1,
  club: 2,
}

// Chat URL
const chatUrl =
  'https://api.whatsapp.com/send/?phone=558006433565&text&type=phone_number&app_absent=0'

const salesForceActivated = true

// GTM-IDs
const gtmIdTest = 'GTM-MVWKZ3W'
const gtmIdProduction = 'GTM-P2C56CMC'

const secureLink = 'https://secure.amigao.com'

module.exports = {
  account,
  production,

  // Ecommerce Platform
  platform: 'vtex',

  experimental: {
    nodeVersion: 14,
  },

  // Platform specific configs for API
  api: {
    storeId: account,
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  // Default channel
  session: {
    currency: {
      code: 'BRL',
      symbol: 'R$',
    },
    locale: 'pt-br',
    channel: `{"salesChannel":"${salesChannels.default}","regionId":""}`,
    country: 'BRA',
    postalCode: null,
    person: null,
  },

  /*
    https://www.novo.amigao.com
    https://secure.amigao.com
  */
  // Production URLs
  storeUrl: `https://www.amigao.com`,
  secureSubdomain: secureLink,
  checkoutUrl: `${secureLink}/api/io/cart`,
  loginUrl: `${secureLink}/api/io/login`,
  accountUrl: `${secureLink}/api/io/account`,
  chatUrl,
  salesForceActivated,

  // Jitterbit flows urls
  jitterbitUrls: {
    createUser:
      'https://smrholding.wevo.io/iomanager/api/flows/execute/route/prd/user/v1/create',
  },

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/oleo-de-soja-tipo-1-leve-embalagem-900ml-5/p',
      collection: '/alimentos/despensa/alimentos-basicos/oleos-e-azeites/',
    },
  },

  // SalesChannels
  salesChannels,

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/oleo-de-soja-tipo-1-leve-embalagem-900ml-5/p',
      collection: '/alimentos/despensa/alimentos-basicos/oleos-e-azeites',
      collection_filtered:
        '/alimentos/despensa/alimentos-basicos/oleos-e-azeites/?category-4=acucar-e-adocantes&category-1=alimentos&category-2=despensa&category-3=alimentos-basicos&category-4=oleos-e-azeites&facets=category-4%2Ccategory-1%2Ccategory-2%2Ccategory-3&sort=score_desc&page=0',
      search: '/s/?q=oleo+de+soja+tipo+1',
    },
  },

  hotjarId: '3312211',
  crazyEgg: true,

  // Meta Tags
  metaTags: {
    'google-site-verification': '',
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId:
      process.env.FASTSTORE_ENV === 'production' ? gtmIdProduction : gtmIdTest,
  },
}
