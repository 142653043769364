import { Badge as UIBadge } from '@faststore/ui'
import React from 'react'

import type { ReactNode } from 'react'
import './badge.scss'

export type BadgeVariants = 'outOfStock' | 'new' | 'recommended' | 'neutral'

type Props = {
  small?: boolean
  variant?: BadgeVariants
  children: ReactNode
  type?: 'small' | 'big'
}

const Badge = ({
  small = false,
  variant,
  children,
  type = 'big',
  ...otherProps
}: Props) => {
  return (
    <UIBadge
      className={`badge ${type}`}
      data-store-badge={small ? 'small' : ''}
      data-store-badge-variant={variant}
      {...otherProps}
    >
      {children}
    </UIBadge>
  )
}

export default Badge
