import React from 'react'

import type { Icon } from '../../types/FAQ'

export const StarIcon = ({
  width = 28,
  height = 36,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M5.76675 25.3333L7.93341 15.9667L0.666748 9.66667L10.2667 8.83333L14.0001 0L17.7334 8.83333L27.3334 9.66667L20.0667 15.9667L22.2334 25.3333L14.0001 20.3667L5.76675 25.3333Z"
          fill={color}
        />
      ) : (
        <path
          d="M8.76663 21.1667L14 18.0333L19.2333 21.2L17.8333 15.2667L22.4333 11.2667L16.3666 10.7333L14 5.13333L11.6333 10.7L5.56663 11.2333L10.1666 15.2333L8.76663 21.1667ZM5.76663 25.3333L7.93329 15.9667L0.666626 9.66667L10.2666 8.83333L14 0L17.7333 8.83333L27.3333 9.66667L20.0666 15.9667L22.2333 25.3333L14 20.3667L5.76663 25.3333Z"
          fill={color}
        />
      )}
    </svg>
  )
}
