import React, { useCallback, useState } from 'react'

import Spinner from '../../../../assets/SpinnerIcon'
import { TabloidBanners } from '../TabloidBanners'
import { TabloidCard } from '../TabloidCard'
import { TabloidCategories } from '../TabloidCategories'

import type { ProductsQueryResponse } from '../../../../types/Product'

import './tabloid-content.scss'

interface TabloidContentProps {
  topBanner?: {
    src: string
    srcMobile: string
  }
  clusterDefaultProducts: ProductsQueryResponse[] | undefined
  activeTabloid: string
  categoryBanners?: {
    src?: string
    srcMobile?: string
  }[]
  isUserRegion: boolean
}

export const TabloidContent = ({
  clusterDefaultProducts,
  activeTabloid,
  categoryBanners,
  isUserRegion,
  topBanner,
}: TabloidContentProps) => {
  const [categorySelected, setCategorySelected] = useState<string | undefined>()

  const categories = [
    ...new Set(clusterDefaultProducts?.map((product) => product.sectionName)),
  ].filter((category) => !!category)

  const menuAutoNavigation = useCallback(
    (index: number, sectionName: string) => {
      const carouselElement = document.getElementById(
        'carousel-container-tracking-tabloid'
      )

      if (!carouselElement) return

      const navegationDisplacement = Array.from(
        carouselElement.querySelectorAll('li')
      )
        .map((item) => item.offsetWidth)
        .slice(1, index)
        .reduce((widthTotal, item) => widthTotal + item, 0)

      // Correcting the displacement for the 2 element index
      const finalValue =
        index === 2 ? navegationDisplacement - 10 : navegationDisplacement

      carouselElement.scroll(finalValue, 0)
      setCategorySelected(sectionName)
    },
    []
  )

  // prevent content
  if (!clusterDefaultProducts) {
    return (
      <div className="loader-container">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <div className="tabloid-content-container">
        <div className="grid-content">
          {topBanner && (
            <TabloidBanners
              mobileBanner={{
                url: topBanner.srcMobile,
                width: 328,
                height: 88,
              }}
              desktopBanner={{ url: topBanner.src, width: 1408, height: 144 }}
              alt="Banner promocional"
            />
          )}
          <div className="tabloid-container">
            <div className="tabloid-menu" id="tabloid-menu">
              <TabloidCategories
                categorySelected={categorySelected}
                setCategorySelected={setCategorySelected}
                categories={categories}
                activeTabloid={activeTabloid}
              />
            </div>

            {clusterDefaultProducts?.map(({ sectionName, products }, index) => {
              const mappedProducts = products.map(({ node }) => ({
                ...node,
              }))

              return (
                <TabloidCard
                  sectionName={sectionName}
                  products={mappedProducts}
                  menuAutoNavigation={menuAutoNavigation}
                  key={sectionName}
                  isUserRegion={isUserRegion}
                  index={index}
                  categoryBanners={categoryBanners}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
