import {
  CardIcon,
  CartIcon,
  LikeIcon,
  MoneyIcon,
  PhoneIcon,
  QuestionIcon,
  ShippingIcon,
  StarIcon,
  StoreIcon,
  UserIcon,
  BagIcon,
} from '../../../assets/faqIcons'
import { useLocalStorageContext } from '../../../contexts/LocalStorageContext'
import { useStoreContext } from '../../../contexts/StoreContext'
import { accountCardNames } from '../../../utils/faqFunctions'

import type { DefaultTopic } from '../../../types/FAQ'

// default topics that can't stay in CMS to prevent changes and deletions
export const topics = (): DefaultTopic[] => {
  const { getLocalStorage } = useLocalStorageContext()
  const storeId = getLocalStorage('storeId') ?? 'boa'

  return [
    {
      icon: QuestionIcon(),
      activeIcon: QuestionIcon({ filled: true }),
      title: 'Perguntas Frequentes',
      description: 'Dúvidas comuns que você pode ter',
    },
    {
      // change to the correct icon
      icon: CartIcon(),
      activeIcon: CartIcon({ filled: true }),
      title: 'Pedido, Cancelamentos, Trocas e Devoluções',
      description: 'Problemas com pedido e muito mais',
    },
    {
      // change to the correct icon
      icon: ShippingIcon(),
      activeIcon: ShippingIcon({ filled: true }),
      title: 'Entrega',
      description: 'Informações sobre entrega e agendamentos',
    },
    {
      // change to the correct icon
      icon: MoneyIcon(),
      activeIcon: MoneyIcon({ filled: true }),
      title: 'Pagamento',
      description:
        'Informações sobre tipo de pagamentos, bandeiras e muito mais',
    },
    {
      // change to the correct icon
      icon: UserIcon(),
      activeIcon: UserIcon({ filled: true }),
      title: 'Cadastro e Dados Pessoais',
      description:
        'Resolva problemas como alteração de senha ou de informações pessoais',
    },
    {
      // change to the correct icon
      icon: PhoneIcon(),
      activeIcon: PhoneIcon({ filled: true }),
      title: 'Compra Online',
      description:
        'Informações sobre como comprar e dúvidas em geral sobre compra online',
    },
    {
      // change to the correct icon
      icon: StarIcon(),
      activeIcon: StarIcon({ filled: true }),
      title: 'Clube de Vantagens',
      description:
        'Informações sobre nosso programa de vantagens para clientes exclusivos',
    },
    {
      // change to the correct icon
      icon: StoreIcon(),
      activeIcon: StoreIcon({ filled: true }),
      title: 'Informações sobre a Loja',
      description: 'Horários, localizações e muito mais',
    },
    {
      // change to the correct icon
      icon: CardIcon(),
      activeIcon: CardIcon({ filled: true }),
      title: accountCardNames[storeId],
      description: `Dúvidas relacionadas ao seu ${accountCardNames[storeId]}`,
    },
    {
      // change to the correct icon
      icon: LikeIcon(),
      activeIcon: LikeIcon({ filled: true }),
      title: 'Elogios e Reclamações',
      description:
        'Entre aqui e nos conte sobre sua experiencia positiva ou negativa',
    },
  ]
}

export const usefulLinks = () => {
  const { secureSubdomain } = useStoreContext()

  return [
    {
      icon: BagIcon(),
      title: 'Pedidos',
      links: [
        {
          text: 'Acompanhar meus pedidos',
          href: `${secureSubdomain}/api/io/account#/orders`,
        },
        {
          text: 'Cancelar meu pedido',
          href: `${secureSubdomain}/api/io/account#/orders`,
        },
      ],
    },
    {
      icon: UserIcon({ filled: false }),
      title: 'Dados Pessoais',
      links: [
        {
          text: 'Meus dados pessoais',
          href: `${secureSubdomain}/api/io/account#/profile`,
        },
        {
          text: 'Meus dados de contato',
          href: `${secureSubdomain}/api/io/account#/profile`,
        },
      ],
    },
  ]
}
