import React from 'react'

import { SettingsIcon } from '../../../../assets/scratchIcons/SettingsIcon'
import { ModalQuickView } from '../../../common/ModalQuickView'

import type { Dispatch, SetStateAction } from 'react'

import './error-scratch-modal.scss'
import { useStoreContext } from '../../../../contexts/StoreContext'

interface ErrorScratchModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  errors: string[]
}

export const ErrorScratchModal = ({
  isOpen,
  setIsOpen,
  errors,
}: ErrorScratchModalProps) => {
  const { secureSubdomain } = useStoreContext()

  const handleAdjustClick = () => {
    window.location.href = `${secureSubdomain}/api/io/account#/`
  }

  return (
    <ModalQuickView
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Cadastro inválido"
      Footer={
        <div className="modal-footer">
          <button onClick={handleAdjustClick} className="adjust-button">
            Ajustar cadastro
          </button>
        </div>
      }
    >
      <div className="scratch-modal-content">
        <h3>Revise os seguintes itens do seu cadastro</h3>
        <ul>
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
        <SettingsIcon />
        <span>
          Após ajustar seu cadastro, retorne para a página da raspadinha pelo
          banner de cadastro da promoção.
        </span>
      </div>
    </ModalQuickView>
  )
}
