import React from 'react'

interface TabloidCheckIconProps {
  width?: number
  height?: number
}

export const TabloidCheckIcon = ({
  width = 16,
  height = 16,
}: TabloidCheckIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 17C13.9183 17 17.5 13.4183 17.5 9C17.5 4.58172 13.9183 1 9.5 1C5.08172 1 1.5 4.58172 1.5 9C1.5 13.4183 5.08172 17 9.5 17Z"
      fill="var(--color-positiveFeedback)"
      stroke="var(--color-positiveFeedback)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 9L8.16667 11.6667L13.5 6.33333"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
