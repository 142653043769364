import React from 'react'

import './multiple-search-counter.scss'

interface MultipleSearchCounterProps {
  currentQuantity: number
  maxQuantity: number
}

export const MultipleSearchCounter = ({
  currentQuantity,
  maxQuantity,
}: MultipleSearchCounterProps) => {
  return (
    <div className="multiple-search-counter-container">
      <span className="multiple-search-counter">
        Produtos adicionados a lista:{' '}
        <span>
          {currentQuantity} de {maxQuantity}
        </span>
      </span>
    </div>
  )
}
