export const carouselProps = (
  width: number,
  customQtyItems?: number,
  bulletExtraStyle?: object,
  arrowExtraStyle?: object
) => {
  const qtyItems = () => {
    if (customQtyItems) {
      return customQtyItems
    }

    if (width <= 374) {
      return 2.1
    }

    if (width <= 425) {
      return 2.5
    }

    if (width <= 540) {
      return 3.3
    }

    if (width <= 900) {
      return 4.3
    }

    if (width <= 1100) {
      return 4.6
    }

    return 5.2
  }

  const bulletStyle = {
    mobile: {
      disableColor: 'var(--color-grayScale-dark)',
      style: {
        width: 8,
        height: 8,
        margin: '0px 2px',
        borderRadius: '50%',
        padding: 0,
      },
    },
    desk: {
      disableColor: 'transparent',
      style: {
        width: 8,
        height: 8,
        margin: '0px 4px',
        borderStyle: 'solid',
        borderColor: 'var(--color-main-dark)',
        borderWidth: 0.5,
        borderRadius: '50%',
        padding: 0,
        cursor: 'pointer',
      },
    },
  }

  return {
    qtyItems: qtyItems(),
    bullet: {
      isVisible: true,
      bulletEnableColor: 'var(--color-main)',
      bulletDisableColor:
        width < 768
          ? bulletStyle.mobile.disableColor
          : bulletStyle.desk.disableColor,
      style:
        width < 768
          ? { ...bulletStyle.mobile.style, ...bulletExtraStyle }
          : { ...bulletStyle.desk.style, ...bulletExtraStyle },
    },
    arrow: {
      isVisible: !(width < 1280),
      iconColor: 'var(--color-main)',
      style: {
        backgroundColor: 'var(--color-main-faded)',
        cursor: 'pointer',
        ...arrowExtraStyle,
      },
    },
  }
}
