import { gql } from '@faststore/graphql-utils'
import { useCallback, useMemo } from 'react'
import { useSWRConfig } from 'swr'

import { ITEMS_PER_PAGE, defaultSort } from '../../constants'
import { prefetchQuery } from '../graphql/prefetchQuery'
import { useQuery } from '../graphql/useQuery'
import { useSession } from '../session'

import type { ProductsQueryWithEdges } from '../../types/Product'
import type { QueryOptions } from '../graphql/useQuery'

type QueryResponse = { search?: { products?: ProductsQueryWithEdges } }

export const query = gql`
  query ProductsQuery(
    $first: Int!
    $after: String
    $sort: StoreSort!
    $term: String!
    $selectedFacets: [IStoreSelectedFacet!]!
  ) {
    search(
      first: $first
      after: $after
      sort: $sort
      term: $term
      selectedFacets: $selectedFacets
    ) {
      products {
        pageInfo {
          totalCount
        }
        edges {
          node {
            ...ProductSummary_product
          }
        }
      }
    }
  }
`

const toArray = <T>(x: T[] | T | undefined) => {
  if (Array.isArray(x)) {
    return x
  }

  if (x) {
    return [x]
  }

  return []
}

export const useLocalizedVariables = ({
  first,
  after,
  sort,
  term,
  selectedFacets,
  customChannel,
}: Partial<any>) => {
  const { channel, locale } = useSession()

  return useMemo(() => {
    const facets = toArray(selectedFacets)

    return {
      first: first ?? ITEMS_PER_PAGE,
      after: after ?? '0',
      sort: sort ?? defaultSort,
      term: term ?? '',
      selectedFacets: [
        ...facets,
        { key: 'channel', value: customChannel ?? channel ?? '' },
        { key: 'locale', value: locale },
      ],
    }
  }, [selectedFacets, first, after, sort, term, channel, locale])
}

/**
 * Use this hook for fetching a list of products, like in search results and shelves
 */
export const useProductsQuery = (
  variables: Partial<any>,
  options?: QueryOptions
) => {
  const localizedVariables = useLocalizedVariables(variables)

  const { data, isValidating } = useQuery<QueryResponse, any>(
    query,
    localizedVariables,
    {
      fallbackData: null,
      suspense: true,
      ...options,
    }
  )

  return {
    data: data?.search?.products,
    isLoading: isValidating,
  }
}

export const useProductsQueryPrefetch = (
  variables: any,
  options?: QueryOptions
) => {
  const localizedVariables = useLocalizedVariables(variables)
  const { cache } = useSWRConfig()

  return useCallback(
    () => prefetchQuery(query, localizedVariables, { cache, ...options }),
    [localizedVariables, cache, options]
  )
}
