import React from 'react'

import RepeatOrderSection from './RepeatOrderSection'
import { useClubContext } from '../../../contexts/ClubContext'

interface RepeatOrdersProps {
  data?: { isActive: boolean }
}

export const RepeatOrders = ({ data }: RepeatOrdersProps) => {
  const { isClubClient } = useClubContext()

  if (!data?.isActive || !isClubClient) {
    return null
  }

  return <RepeatOrderSection />
}
