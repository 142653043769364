import React from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { AdaptiveCarousel } from '../../../common/AdaptiveCarousel'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { Image } from '../../../ui/Image'

import type { Benefits as BenefitsProps } from '../../../../types/ClubCard/ClubCardBenefits'

import './benefits-desktop.scss'

type BenefitsDesktopProps = Omit<BenefitsProps, 'linkText' | 'text'>

export const BenefitsDesktop = ({
  visibility,
  title,
  list,
  moreBenefits,
}: BenefitsDesktopProps) => {
  const { width } = useWindowDimensions()
  const listBenefits = [...list, ...moreBenefits?.list]

  if (!visibility) {
    return null
  }

  return (
    <div className="club-card-benefits-benefitsDesktop grid-content">
      {title && (
        <DynamicTitle
          className="benefitsDesktop-title"
          titleLevel={2}
          title={title}
        />
      )}
      <AdaptiveCarousel
        quantity={listBenefits?.length}
        propsCarousel={{
          gapItems: 20,
          qtyItems: width && width >= 1024 ? 4 : 3,
        }}
      >
        {listBenefits?.map((itemBenefit) => (
          <div key={itemBenefit?.benefit} className="benefitsDesktop-card">
            <div className="benefitsDesktop-card__icon">
              <Image
                alt={itemBenefit?.icon?.alt}
                baseUrl={itemBenefit?.icon?.src}
                width={56}
                height={56}
              />
            </div>
            <DynamicTitle
              className="benefitsDesktop-card__text"
              titleLevel={3}
              title={itemBenefit?.benefit}
            />
          </div>
        ))}
      </AdaptiveCarousel>
    </div>
  )
}
