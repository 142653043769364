import React from 'react'

import { CheckCircleIcon } from '../../../assets/CheckCircleIcon'

import './list.scss'

interface ListProps {
  list: string[]
  className?: string
  Icon?: React.ReactElement
  disableIcon?: boolean
}

export function List({
  list,
  className = 'list-ui-container',
  Icon = <CheckCircleIcon />,
  disableIcon,
}: ListProps) {
  return (
    <ul className={className}>
      {list?.map((text) => (
        <li key={text}>
          {disableIcon ? null : Icon} {text}
        </li>
      ))}
    </ul>
  )
}
