import React, { memo } from 'react'

import Badge from './Badge'
import { useDiscountPercent } from '../../../sdk/product/useDiscountPercent'
import './badge.scss'

type Props = {
  listPrice: number
  spotPrice: number
  small?: boolean
  // Set limit percentage value to consider a low discount.
  thresholdLow?: number
  // Set limit percentage value to consider a high discount
  thresholdHigh?: number
  type?: 'small' | 'big'
}

const DiscountBadge = ({
  listPrice,
  spotPrice,
  small = false,
  thresholdLow = 15,
  thresholdHigh = 40,
  type = 'big',
}: Props) => {
  const discountPercent = Math.round(
    Number(useDiscountPercent(listPrice, spotPrice))
  )

  if (discountPercent <= 0) {
    return null
  }

  const discountVariant = () => {
    if (discountPercent <= thresholdLow) {
      return 'low'
    }

    if (discountPercent <= thresholdHigh) {
      return 'medium'
    }

    return 'high'
  }

  return (
    <Badge
      small={small}
      data-store-discount-badge-variant={discountVariant()}
      type={type}
    >
      -{discountPercent}%
    </Badge>
  )
}

export default memo(DiscountBadge)
