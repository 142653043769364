import { Carousel } from '@plurix/ecom-components'
import React, { useEffect, useState } from 'react'

import { HAS_WINDOW } from '../../../../constants'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import Button from '../../../ui/Button'

import './tabloid-categories.scss'

interface TabloidCategoriesProps {
  activeTabloid: string
  categories: string[] | undefined
  setCategorySelected: (categories: string | undefined) => void
  categorySelected: string | undefined
}

export const TabloidCategories = ({
  setCategorySelected,
  categorySelected,
  categories,
  activeTabloid,
}: TabloidCategoriesProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  useEffect(() => {
    if (categories) {
      setCategorySelected(categories[0])
    }
  }, [activeTabloid])

  const tabloidDisplacement = (
    elementId: string,
    mainHeaderId: string,
    tabloidMenuId: string
  ) => {
    if (!HAS_WINDOW) {
      return
    }

    const elementOffsetTop = document.getElementById(elementId)?.offsetTop ?? 0
    const sectionTitleHeight =
      document.getElementById(elementId)?.offsetHeight ?? 0

    const headerHeight =
      document.getElementById(mainHeaderId)?.offsetHeight ?? 0

    const tabloidMenuHeight =
      document.getElementById(tabloidMenuId)?.offsetHeight ?? 0

    const displacement =
      elementOffsetTop - sectionTitleHeight - headerHeight - tabloidMenuHeight

    return displacement > 0 ? displacement : 0
  }

  const tabloidNavigation = (category: string) => {
    if (!HAS_WINDOW) {
      return
    }

    scroll(0, tabloidDisplacement(category, 'main-header', 'tabloid-menu') ?? 0)
  }

  const handleNavigation = (category?: string) => {
    if (category) {
      tabloidNavigation(category)
      setCategorySelected(category)
    }
  }

  if (categories && categories.length <= 0) {
    return null
  }

  return (
    <div className="container-category-grid grid-content ">
      <Carousel
        gapItems={width >= 1280 ? 32 : 24}
        id="tabloid"
        bullet={{
          isVisible: false,
        }}
        arrow={{
          iconColor: 'var(--color-main)',
          isVisible: width >= 1280,
        }}
      >
        <ul>
          {categories?.map((category) => (
            <li
              key={category}
              className={
                categorySelected === category ? 'category-selected' : undefined
              }
            >
              <Button onClick={() => handleNavigation(category)}>
                {category}
              </Button>
            </li>
          ))}
        </ul>
      </Carousel>
    </div>
  )
}
