import React from 'react'

import { DownloadIcon } from '../../../../assets/scratchIcons'
import {
  First,
  Second,
  Third,
  Fourth,
  Fifth,
} from '../../../../assets/scratchSvgs'
import { ModalQuickView } from '../../../common/ModalQuickView'

import type {
  FindOutMoreSection,
  ScratchPageContent,
} from '../../../../types/CMS'
import type { Dispatch, SetStateAction } from 'react'
import './scratch-modal.scss'

interface ScratchModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  Footer: React.ReactNode
  scratchPageContent?: ScratchPageContent
}

export const ScratchModal = ({
  isOpen,
  setIsOpen,
  Footer,
  scratchPageContent,
}: ScratchModalProps) => {
  if (!scratchPageContent) {
    return null
  }

  const { findOutMoreSections, mainTitle } = scratchPageContent
  const iconArray = [First, Second, Third, Fourth, Fifth]
  const sections = Object.values(findOutMoreSections || {})

  return (
    <ModalQuickView
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={mainTitle}
      Footer={Footer}
    >
      <div className="scratch-modal-content">
        {sections.map((section, index) => {
          const { sectionTitle, sectionDescription }: FindOutMoreSection =
            section

          const IconComponent = iconArray[index]

          return (
            <div
              key={sectionTitle}
              className={`section ${index % 2 === 0 ? 'faded' : 'main'}`}
              data-testid={`section-${index}-svg`}
            >
              <h3>{sectionTitle}</h3>

              <div className="svg-container">
                {IconComponent && <IconComponent />}
              </div>

              <p dangerouslySetInnerHTML={{ __html: sectionDescription }} />
            </div>
          )
        })}

        <div className="download-container">
          <DownloadIcon />

          <a
            href="/scratch_documents/regulation.pdf"
            download="regulamento.pdf"
            type="application/pdf"
          >
            Baixar Regulamento
          </a>
        </div>
      </div>
    </ModalQuickView>
  )
}
