import React from 'react'

export const PasskeyEyes = ({
  open,
  color,
}: {
  open: boolean
  color?: string
}) => {
  if (open) {
    return (
      <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.257 7.53341C20.731 8.15341 20.731 8.99041 20.257 9.60941C18.764 11.5584 15.182 15.5714 11 15.5714C6.81801 15.5714 3.23601 11.5584 1.74301 9.60941C1.51239 9.31254 1.38721 8.94733 1.38721 8.57141C1.38721 8.19549 1.51239 7.83028 1.74301 7.53341C3.23601 5.58441 6.81801 1.57141 11 1.57141C15.182 1.57141 18.764 5.58441 20.257 7.53341V7.53341Z"
          stroke={color ?? 'var(--color-grayScale-light)'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 11.5714C12.6569 11.5714 14 10.2283 14 8.57141C14 6.91456 12.6569 5.57141 11 5.57141C9.34315 5.57141 8 6.91456 8 8.57141C8 10.2283 9.34315 11.5714 11 11.5714Z"
          stroke={color ?? 'var(--color-grayScale-light)'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1 13.8714L14.65 12.4214C14.8 11.6381 14.575 10.9047 13.975 10.2214C13.375 9.53807 12.6 9.2714 11.65 9.4214L10.2 7.9714C10.4833 7.83807 10.7708 7.73807 11.0625 7.6714C11.3542 7.60473 11.6667 7.5714 12 7.5714C13.25 7.5714 14.3125 8.0089 15.1875 8.8839C16.0625 9.7589 16.5 10.8214 16.5 12.0714C16.5 12.4047 16.4667 12.7172 16.4 13.0089C16.3333 13.3006 16.2333 13.5881 16.1 13.8714ZM19.3 17.0214L17.85 15.6214C18.4833 15.1381 19.0458 14.6089 19.5375 14.0339C20.0292 13.4589 20.45 12.8047 20.8 12.0714C19.9667 10.3881 18.7708 9.05057 17.2125 8.0589C15.6542 7.06723 13.9167 6.5714 12 6.5714C11.5167 6.5714 11.0417 6.60473 10.575 6.6714C10.1083 6.73807 9.65 6.83807 9.2 6.9714L7.65 5.4214C8.33333 5.13807 9.03333 4.92557 9.75 4.7839C10.4667 4.64223 11.2167 4.5714 12 4.5714C14.5167 4.5714 16.7583 5.26723 18.725 6.6589C20.6917 8.05057 22.1167 9.85473 23 12.0714C22.6167 13.0547 22.1125 13.9672 21.4875 14.8089C20.8625 15.6506 20.1333 16.3881 19.3 17.0214ZM19.8 23.1714L15.6 19.0214C15.0167 19.2047 14.4292 19.3422 13.8375 19.4339C13.2458 19.5256 12.6333 19.5714 12 19.5714C9.48333 19.5714 7.24167 18.8756 5.275 17.4839C3.30833 16.0922 1.88333 14.2881 1 12.0714C1.35 11.1881 1.79167 10.3672 2.325 9.6089C2.85833 8.85057 3.46667 8.1714 4.15 7.5714L1.4 4.7714L2.8 3.3714L21.2 21.7714L19.8 23.1714ZM5.55 8.9714C5.06667 9.40473 4.625 9.87973 4.225 10.3964C3.825 10.9131 3.48333 11.4714 3.2 12.0714C4.03333 13.7547 5.22917 15.0922 6.7875 16.0839C8.34583 17.0756 10.0833 17.5714 12 17.5714C12.3333 17.5714 12.6583 17.5506 12.975 17.5089C13.2917 17.4672 13.6167 17.4214 13.95 17.3714L13.05 16.4214C12.8667 16.4714 12.6917 16.5089 12.525 16.5339C12.3583 16.5589 12.1833 16.5714 12 16.5714C10.75 16.5714 9.6875 16.1339 8.8125 15.2589C7.9375 14.3839 7.5 13.3214 7.5 12.0714C7.5 11.8881 7.5125 11.7131 7.5375 11.5464C7.5625 11.3797 7.6 11.2047 7.65 11.0214L5.55 8.9714Z"
        fill={color ?? 'var(--color-grayScale-light)'}
      />
    </svg>
  )
}
