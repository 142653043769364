import React from 'react'

import { createArray } from '../../../utils/createArrayForNumber'

export function ProductGridSkeleton() {
  return (
    <>
      {createArray(20).map((item) => (
        <li key={item}>
          <div
            className="product-card"
            data-store-card="true"
            data-card-variant="vertical"
            data-card-bordered="false"
            data-card-out-of-stock="false"
            style={{ backgroundColor: 'var(--color-grayScale-faded)' }}
          />
        </li>
      ))}
    </>
  )
}
