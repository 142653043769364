import { Skeleton } from '@acctglobal/skeleton'
import React, { useState, useEffect } from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { Image } from '../../../ui/Image'
import './tabloid-banners.scss'

interface TabloidBannersProps {
  mobileBanner: {
    url: string
    width: number
    height: number
  }
  desktopBanner: {
    url: string
    width: number
    height: number
  }
  alt: string
}

export const TabloidBanners = ({
  mobileBanner,
  desktopBanner,
  alt,
}: TabloidBannersProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  const baseUrl = width < 768 ? mobileBanner.url : desktopBanner.url
  const sourceWidth = width < 768 ? mobileBanner.width : desktopBanner.width
  const sourceHeight = width < 768 ? mobileBanner.height : desktopBanner.height

  if (!baseUrl) {
    return null
  }

  if (!width) {
    return (
      <div className="tabloid-banners-loading">
        <Skeleton
          height={mobileBanner.height}
          backgroundColor="var(--color-grayScale-faded)"
        />
        <Skeleton
          height={desktopBanner.height}
          backgroundColor="var(--color-grayScale-faded)"
        />
      </div>
    )
  }

  return (
    <div className="tabloid-banners-container">
      <Image
        baseUrl={baseUrl}
        alt={alt}
        sourceWidth={sourceWidth}
        sourceHeight={sourceHeight}
        layout="fullWidth"
        backgroundColor="var(--color-grayScale-faded)"
        loading="lazy"
      />
    </div>
  )
}
