import React, { useRef } from 'react'

import { useCascadePopup } from '../../../hooks/useCascadePopup'

import type { PropsWithChildren } from 'react'

import './popup.scss'

export const Popup = ({ children }: PropsWithChildren<{}>) => {
  const toKnowPopupRef = useRef<HTMLDivElement | null>(null)

  const { customPopupHeight } = useCascadePopup(toKnowPopupRef)

  return (
    <div
      className="popup-container"
      ref={toKnowPopupRef}
      style={{ height: customPopupHeight }}
    >
      {children}
    </div>
  )
}
