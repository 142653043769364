import React from 'react'

import { showSection } from '../../../../utils/showSection'
import { PointsWrapper } from '../../../ClubCard/User/PointsWrapper'

import type { Point } from '../../../../types/ClubCard/ClubCardUser'
import type { showSectionVariables } from '../../../../utils/showSection'

interface Props {
  visibilityConfig: showSectionVariables
  card: {
    isVisible: boolean
    title: string
    redirect: string
  }
  points: Point[]
  title: string
  icon: {
    alt: string
    src: string
  }
  description: string
  isActive: boolean
  colorConfig?: {
    titleColor?: string
    containerColor?: string
    textColor?: string
  }
}
export const CampaignListOfScorecards = (props: Props) => {
  const {
    visibilityConfig,
    icon,
    card,
    points,
    title,
    description,
    isActive,
    colorConfig,
  } = props

  if (!showSection(visibilityConfig) || !isActive) return

  return (
    <PointsWrapper
      visibility={isActive}
      title={title}
      text={description}
      icon={icon}
      list={points}
      lastList={card}
      colorConfig={colorConfig}
    />
  )
}
