import { graphql, useStaticQuery } from 'gatsby'

interface SpecialPromotions {
  collectionID: string
  flagText: string
  colorCode: string
}

const useCmsSpecialPromotions = () => {
  const data = useStaticQuery(graphql`
    query CmsSpecialPromotionsQuery {
      cmsSpecialPromotions {
        sections {
          data
        }
      }
    }
  `)

  const promotionsSections = data?.cmsSpecialPromotions?.sections ?? []

  const allPromotions: SpecialPromotions[] = promotionsSections?.map(
    (promotions: { data: SpecialPromotions }) => promotions?.data
  )

  return { allPromotions }
}

export default useCmsSpecialPromotions
