import React from 'react'

import './scratch-club-client.scss'

export interface ScratchClubClientProps {
  acceptedTerms: boolean
  isLoadingScratchRedirect: boolean
  handleToggleTerms: () => void
  handleParticipate: () => void
}

export const ScratchClubClient = ({
  acceptedTerms,
  isLoadingScratchRedirect,
  handleToggleTerms,
  handleParticipate,
}: ScratchClubClientProps) => {
  return (
    <div className="scratch-club-client">
      <label
        htmlFor="scratch"
        className={acceptedTerms ? 'acceptedTerms' : undefined}
      >
        <input
          type="checkbox"
          name="scratch"
          id="scratch"
          checked={acceptedTerms}
          onChange={handleToggleTerms}
        />

        <span>
          Li e aceito o{' '}
          <a
            href="/scratch_documents/regulation.pdf"
            download="regulamento.pdf"
            type="application/pdf"
          >
            Regulamento
          </a>{' '}
          e{' '}
          <a
            href="/scratch_documents/terms_and_policies.pdf"
            download="termos_e_políticas.pdf"
            type="application/pdf"
          >
            Termos e Políticas
          </a>{' '}
          da
          <br />
          promoção.
        </span>
      </label>

      <button
        disabled={!acceptedTerms || isLoadingScratchRedirect}
        onClick={handleParticipate}
        className={isLoadingScratchRedirect ? 'loading' : undefined}
      >
        Participar da Promoção
      </button>

      <p>Você será redirecionado para o site exclusivo da promoção</p>
    </div>
  )
}
