import { useState, useEffect, useMemo } from 'react'

import { useLocalStorageContext } from '../contexts/LocalStorageContext'

import type { RefObject } from 'react'

// Calculates the new height of the popup to be above the cookies popup
export const useCascadePopup = (popupRef: RefObject<HTMLElement>) => {
  const [originalRefHeight, setOriginalRefHeight] = useState<number | null>(
    null
  )

  const { cookiesModalOpen, cookiesModalRef } = useLocalStorageContext()

  useEffect(() => {
    if (popupRef.current && !originalRefHeight) {
      setOriginalRefHeight(popupRef.current.offsetHeight)
    }
  }, [popupRef.current])

  const customPopupHeight = useMemo(() => {
    if (
      !cookiesModalOpen ||
      !cookiesModalRef?.current?.offsetHeight ||
      !originalRefHeight
    ) {
      return undefined
    }

    return cookiesModalRef.current.offsetHeight + originalRefHeight
  }, [
    cookiesModalOpen,
    cookiesModalRef?.current?.offsetHeight,
    originalRefHeight,
  ])

  return { customPopupHeight }
}
