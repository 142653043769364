import { HAS_WINDOW } from '../constants'

export const removeParamFromUrl = (parameterName: string) => {
  if (!HAS_WINDOW) return

  const queryParams = new URLSearchParams(window.location.search)

  queryParams.delete(parameterName)

  const newUrl = `${window.location.pathname}?${queryParams.toString()}`

  window.history.pushState({}, '', newUrl)
}

export const addParamToURL = (
  parameterName: string,
  parameterValue: string
) => {
  if (!HAS_WINDOW) return

  const queryParams = new URLSearchParams(window.location.search)

  queryParams.set(parameterName, parameterValue)

  const newUrl = `${window.location.pathname}?${queryParams.toString()}`

  window.history.pushState({}, '', newUrl)
}
