import React from 'react'

import type { ModalScreen } from '../SuggestedKitsCarousel'

import './suggested-kits-card.scss'

interface SuggestedKitsCardProps {
  image: string
  title: string
  isList?: boolean
  setSelectedKitIndex: (selectedKitIndex: number) => void
  setIsOpenModal: (isOpenModal: boolean) => void
  setModalScreen?: (modalScreen: ModalScreen) => void
  index: number
  handleGenericTaggingEvent: () => void
}

export const SuggestedKitsCard = ({
  image,
  title,
  setSelectedKitIndex,
  setIsOpenModal,
  setModalScreen,
  isList,
  index,
  handleGenericTaggingEvent,
}: SuggestedKitsCardProps) => {
  return (
    <button
      className={`suggested-kits-card ${
        isList ? 'list-settings' : 'carousel-settings'
      }`}
      onClick={() => {
        setSelectedKitIndex(index)
        setIsOpenModal(true)
        setModalScreen?.('kitView')
        handleGenericTaggingEvent?.()
      }}
    >
      <img src={image} alt={title} className="suggested-kits-card-image" />
      <h3 className="suggested-kits-card-title">{title}</h3>
    </button>
  )
}
