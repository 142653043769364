import { formatSeals } from './formatSeals'

export type Variantions = Variantion[]

interface Variantion {
  name: string
  value: 'true' | 'false'
}

export const formatEspecifications = (
  variantions: Variantions,
  restrictionTags?: string[],
  storageTag?: string[]
) => {
  const formatVariations = (
    acceptedType: string[] | undefined,
    allVariantions: Variantions
  ) => {
    const variantionsAccepted = allVariantions?.filter(
      (item) =>
        acceptedType?.includes(item.name) &&
        item?.value?.toLowerCase() === 'true'
    )

    const variantionsList = variantionsAccepted?.map((item) => item.name) ?? []

    return variantionsList
  }

  const productRestriction = formatVariations(restrictionTags, variantions)

  const productStorage = formatVariations(storageTag, variantions)
  const { productEspecificationsAll } = formatSeals(
    productRestriction,
    restrictionTags
  )

  return { productRestriction, productStorage, productEspecificationsAll }
}
