import { graphql, useStaticQuery } from 'gatsby'
interface storeInfosProps {
  infos: string
  sectionLink: string[]
}

const useCmsFooter = () => {
  const data = useStaticQuery(graphql`
    query CmsFooterQuery {
      cmsFooter {
        sections {
          data
          name
        }
      }
    }
  `)

  let { infos, sectionLink } = data?.cmsFooter?.sections.find(
    ({ name }: { name: string }) => name === 'Footer Infos'
  ).data

  const storeInfos: storeInfosProps = {
    infos: infos ?? '',
    sectionLink: sectionLink ?? [],
  }
  return { storeInfos }
}

export default useCmsFooter
