import { graphql, useStaticQuery } from 'gatsby'

import type { CmsFoodRestrictionQueryQuery } from '../../@generated/graphql/index'

type FoodRestrictionProps = {
  specificationName: string
  specificationLabel: string
  specificationColor: string
}[]

const useCmsFoodRestriction = () => {
  const data = useStaticQuery<CmsFoodRestrictionQueryQuery>(graphql`
    query CmsFoodRestrictionQuery {
      cmsFoodRestriction {
        sections {
          data
        }
      }
    }
  `)

  const foodRestriction: FoodRestrictionProps =
    data?.cmsFoodRestriction?.sections?.[0]?.data?.foodRestriction

  const storageRestriction: FoodRestrictionProps =
    data?.cmsFoodRestriction?.sections?.[0]?.data?.storage

  return { foodRestriction, storageRestriction }
}

export default useCmsFoodRestriction
