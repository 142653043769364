import { Carousel } from '@plurix/ecom-components'
import React from 'react'

import { Image } from '../../ui/Image'

import type { InstitucionalCarouselImages } from '.'

type Banner = string[] | InstitucionalCarouselImages
interface InstitucionalCarouselProps {
  banners: Banner
  bannersMobile: Banner
  imgProps: {
    width: number
    alt: string
    sourceWidth: number
    sourceHeight: number
  }
}

// Type guard to determine that the array type is `InstitucionalCarousel`
function bannersIsCarouselArray(
  banners: Banner
): banners is InstitucionalCarouselImages {
  if (banners as InstitucionalCarouselImages) {
    return true
  }

  return false
}

export function InstitucionalCarousel({
  banners,
  bannersMobile,
  imgProps,
}: InstitucionalCarouselProps) {
  if (
    !bannersIsCarouselArray(banners) ||
    !bannersIsCarouselArray(bannersMobile)
  )
    return null

  const { width, alt, sourceHeight, sourceWidth } = imgProps

  const arrow = {
    isVisible: !(width <= 1280),
    iconColor: 'var(--color-main)',
    style: {
      margin: '5px',
      cursor: 'pointer',
      backgroundColor: 'var(--color-main-faded)',
    },
  }

  const bullet = {
    isVisible: true,
    bulletEnableColor: 'var( --color-main)',
    bulletDisableColor: 'transparent',
    style: {
      width: 8,
      height: 8,
      margin: 4,
      borderStyle: 'solid',
      borderColor: 'var( --color-main-dark)',
      borderWidth: '0.5px',
      borderRadius: '50%',
      padding: '0',
    },
  }

  return (
    <section className="institucional-carousel">
      <Carousel qtyItems={1} arrow={arrow} bullet={bullet}>
        {banners.map((banner, i) => (
          <a href={banner.link}>
            <Image
              baseUrl={width <= 768 ? bannersMobile[i].image : banner.image}
              alt={alt}
              width={sourceWidth}
              height={sourceHeight}
              sourceWidth={sourceWidth}
              sourceHeight={sourceHeight}
              layout="fullWidth"
              backgroundColor="var(--color-grayScale-faded)"
              loading="lazy"
            />
          </a>
        ))}
      </Carousel>
    </section>
  )
}
