export function formatDate(dateString: string) {
  const date = new Date(dateString)

  const monthIndex = date.getMonth()
  const monthName = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(
    new Date(2000, monthIndex, 1)
  )

  const formattedString = `${date.getDate()} de ${monthName} de ${date.getFullYear()}`

  return formattedString
}
