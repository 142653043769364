import React from 'react'

import { sanitizeString } from '../../../utils/sanitizeString'
import { updateSearchParam } from '../../../utils/updateSearchParam'
import Select from '../../ui/Select'

interface Props {
  title?: string
  regions: string[]
  region: string
  url: URL
  setRegion: (region: string) => void
}

export const SelectBoxFlyerOffers = ({
  title = 'Folheto de Ofertas',
  regions,
  region,
  url,
  setRegion,
}: Props) => {
  const updateUrl = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = e.target.value

    setRegion(selected)
    updateSearchParam(url, 'region', selected)
  }

  if (!regions && !region) return null

  const OPTIONS: Record<string, string> = regions.reduce(
    (acc, item) => {
      const key = sanitizeString(item) ?? ''

      acc[key] = item

      return acc
    },
    {} as Record<string, string>
  )

  return (
    <div>
      <h1>{title}</h1>
      <form>
        <Select
          id="region-select-flyer-offers"
          testId="region-select-flyer-offers"
          options={OPTIONS}
          value={region}
          labelText="Escolha a cidade:"
          onChange={updateUrl}
          textFixed=""
        />
      </form>
    </div>
  )
}
