export const bannersDimensions = {
  1: {
    mobileWidth: 720,
    mobileHeight: 240,
    desktopWidth: 1216,
    desktopHeight: 336,
  },
  2: {
    mobileWidth: 356,
    mobileHeight: 240,
    desktopWidth: 596,
    desktopHeight: 336,
  },
  3: {
    mobileWidth: 234,
    mobileHeight: 370,
    desktopWidth: 389,
    desktopHeight: 480,
  },
  4: {
    mobileWidth: 174,
    mobileHeight: 174,
    desktopWidth: 286,
    desktopHeight: 286,
  },
}
