interface ValidatePlatformsProps {
  isMobile: boolean | null
  platforms:
    | {
        mobile: boolean
        responsiveSite: boolean
        site: boolean
      }
    | undefined
}

/**
 * This function will return if the component will be rendered
 * based on platforms boolean object.
 *
 * Example: if isMobile = true && renponsiveSite = true, will show
 * the component
 *
 * @param isMobile
 * @param platforms
 * @returns boolean
 */
export function validatePlatform({
  isMobile,
  platforms,
}: ValidatePlatformsProps): boolean {
  const isDesktop = !isMobile

  if (isMobile && !platforms?.responsiveSite) return false

  if (isDesktop && !platforms?.site) return false

  return true
}
