import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../../../hooks/useAnalyticsEvents'

interface SeeMoreCardProps {
  seeMoreLink: string
  location: string
  text?: string
}

export const SeeMoreCard = ({
  seeMoreLink,
  location,
  text = 'Produtos da mesma Categoria',
}: SeeMoreCardProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()

  const handleSeeAll = () => {
    genericTaggingEvent('see_all_card', { name: location })
  }

  return (
    <div className="multipler-search-see-more-items">
      <div className="multipler-search-see-more-item-texts">
        <p className="see-more-text">Veja mais</p>
        <p className="products-category-text">{text}</p>
      </div>

      <div className="multipler-search-see-more-items-action">
        <Link to={seeMoreLink} onClick={handleSeeAll}>
          <span>Veja mais</span>
        </Link>
      </div>
    </div>
  )
}
