import './adaptive-carousel.scss'

import { Carousel } from '@plurix/ecom-components'
import React, { useEffect, useState } from 'react'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../utils/carouselProps'

import type { CarouselPropsWithoutChildren } from '../../../hooks/useVerticalShelfCarouselProps'

interface AdaptiveCarouselProps {
  quantity: number
  children: React.ReactNode
  quantityToCarousel?: number
  propsCarousel?: CarouselPropsWithoutChildren
  activeBlur?: boolean
  forceCarousel?: boolean
}

export const AdaptiveCarousel = ({
  quantity,
  children,
  quantityToCarousel = 6,
  propsCarousel,
  activeBlur = true,
  forceCarousel,
}: AdaptiveCarouselProps) => {
  const [isClient, setIsClient] = useState(false)
  const { width } = useWindowDimensions()
  const isCarousel = forceCarousel || quantity >= quantityToCarousel
  const carouselFinalProps: CarouselPropsWithoutChildren = {
    ...carouselProps(width ?? 0),
    ...propsCarousel,
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return (
    <div
      className={`adaptiveCarousel-container ${
        isCarousel ? 'isCarousel' : 'notCarousel'
      }`}
    >
      {isCarousel ? (
        <>
          <Carousel {...carouselFinalProps}>{children}</Carousel>
          {activeBlur && <div className="background-blur" />}
        </>
      ) : (
        children
      )}
    </div>
  )
}
