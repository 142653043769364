import React from 'react'

interface CartHeaderIconPropsV2 {
  width?: number
  height?: number
  color?: string
  className?: string
}

export const CartHeaderIconV2 = ({
  width = 24,
  height = 24,
  color = 'var(--color-assistant-main)',
  className,
}: CartHeaderIconPropsV2) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4999 22.5C9.78856 22.5 10.8332 21.4554 10.8332 20.1667C10.8332 18.878 9.78856 17.8334 8.4999 17.8334C7.21123 17.8334 6.16656 18.878 6.16656 20.1667C6.16656 21.4554 7.21123 22.5 8.4999 22.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8333 22.5C19.122 22.5 20.1667 21.4554 20.1667 20.1667C20.1667 18.878 19.122 17.8334 17.8333 17.8334C16.5447 17.8334 15.5 18.878 15.5 20.1667C15.5 21.4554 16.5447 22.5 17.8333 22.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 1.5H3.83333L6.16666 15.5C6.41834 16.2157 6.89576 16.8303 7.52702 17.2511C8.15828 17.672 8.9092 17.8763 9.66666 17.8333H17.8333C18.5908 17.8763 19.3417 17.672 19.973 17.2511C20.6042 16.8303 21.0816 16.2157 21.3333 15.5L22.5 7.33333H4.76666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
