import axios from 'axios'
import { useEffect, useState } from 'react'

import { useStoreContext } from '../contexts/StoreContext'

import type { CMSSection } from '../types/CMS'

export const useHomeContent = () => {
  const [preview, setPreview] = useState<CMSSection[] | null>(null)
  const { logo, sections } = useStoreContext()

  const getCmsData = async (
    contentType: string,
    documentId: string,
    versionId: string
  ) => {
    await axios
      .post('/api/getCmsContent', {
        contentType,
        documentId,
        versionId,
      })
      .then((response) => {
        if (contentType === 'homeContent') {
          setPreview(response?.data?.sections)
        }
      })
      .catch(() => setPreview(null))
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search)

    const contentType = searchParams.get('contentType')
    const documentId = searchParams.get('documentId')
    const versionId = searchParams.get('versionId')

    if (contentType && documentId && versionId) {
      getCmsData(contentType, documentId, versionId)
    }
  }, [])

  return { preview, logo, sections }
}
