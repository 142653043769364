import React from 'react'

import type { Icon } from '../../types/FAQ'

export const QuestionIcon = ({
  width = 28,
  height = 28,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M16.1 18.6667C16.4777 18.6667 16.8 18.5333 17.0666 18.2667C17.3333 18 17.4666 17.6778 17.4666 17.3C17.4666 16.9222 17.3333 16.6 17.0666 16.3333C16.8 16.0667 16.4777 15.9333 16.1 15.9333C15.7222 15.9333 15.4 16.0667 15.1333 16.3333C14.8666 16.6 14.7333 16.9222 14.7333 17.3C14.7333 17.6778 14.8666 18 15.1333 18.2667C15.4 18.5333 15.7222 18.6667 16.1 18.6667ZM15.2666 14.4667H16.8333C16.8777 13.8222 16.9722 13.3444 17.1166 13.0333C17.2611 12.7222 17.6222 12.2889 18.2 11.7333C18.8 11.1556 19.2166 10.65 19.45 10.2167C19.6833 9.78334 19.8 9.27778 19.8 8.7C19.8 7.67778 19.45 6.84445 18.75 6.20001C18.05 5.55556 17.1333 5.23334 16 5.23334C15.1555 5.23334 14.4 5.46112 13.7333 5.91667C13.0666 6.37223 12.5777 7.01112 12.2666 7.83334L13.7666 8.46667C14.0111 7.91112 14.3166 7.48889 14.6833 7.20001C15.05 6.91112 15.4888 6.76667 16 6.76667C16.6666 6.76667 17.2055 6.95556 17.6166 7.33334C18.0277 7.71112 18.2333 8.18889 18.2333 8.76667C18.2333 9.21112 18.1333 9.6 17.9333 9.93334C17.7333 10.2667 17.3777 10.6222 16.8666 11C16.1555 11.6444 15.7111 12.1611 15.5333 12.55C15.3555 12.9389 15.2666 13.5778 15.2666 14.4667ZM6.66663 23.3333C6.13329 23.3333 5.66663 23.1333 5.26663 22.7333C4.86663 22.3333 4.66663 21.8667 4.66663 21.3333V2.66667C4.66663 2.13334 4.86663 1.66667 5.26663 1.26667C5.66663 0.866672 6.13329 0.666672 6.66663 0.666672H25.3333C25.8666 0.666672 26.3333 0.866672 26.7333 1.26667C27.1333 1.66667 27.3333 2.13334 27.3333 2.66667V21.3333C27.3333 21.8667 27.1333 22.3333 26.7333 22.7333C26.3333 23.1333 25.8666 23.3333 25.3333 23.3333H6.66663ZM2.66663 27.3333C2.13329 27.3333 1.66663 27.1333 1.26663 26.7333C0.866626 26.3333 0.666626 25.8667 0.666626 25.3333V4.66667H2.66663V25.3333H23.3333V27.3333H2.66663Z"
          fill={color}
        />
      ) : (
        <path
          d="M16.1 18.6667C16.4778 18.6667 16.8 18.5333 17.0667 18.2667C17.3333 18 17.4667 17.6778 17.4667 17.3C17.4667 16.9222 17.3333 16.6 17.0667 16.3333C16.8 16.0667 16.4778 15.9333 16.1 15.9333C15.7222 15.9333 15.4 16.0667 15.1333 16.3333C14.8667 16.6 14.7333 16.9222 14.7333 17.3C14.7333 17.6778 14.8667 18 15.1333 18.2667C15.4 18.5333 15.7222 18.6667 16.1 18.6667ZM15.2667 14.4667H16.8333C16.8778 13.8222 16.9722 13.3444 17.1167 13.0333C17.2611 12.7222 17.6222 12.2889 18.2 11.7333C18.8 11.1556 19.2167 10.65 19.45 10.2167C19.6833 9.78333 19.8 9.27778 19.8 8.7C19.8 7.67778 19.45 6.84445 18.75 6.2C18.05 5.55556 17.1333 5.23333 16 5.23333C15.1556 5.23333 14.4 5.46111 13.7333 5.91667C13.0667 6.37222 12.5778 7.01111 12.2667 7.83333L13.7667 8.46667C14.0111 7.91111 14.3167 7.48889 14.6833 7.2C15.05 6.91111 15.4889 6.76667 16 6.76667C16.6667 6.76667 17.2056 6.95556 17.6167 7.33333C18.0278 7.71111 18.2333 8.18889 18.2333 8.76667C18.2333 9.21111 18.1333 9.6 17.9333 9.93333C17.7333 10.2667 17.3778 10.6222 16.8667 11C16.1556 11.6444 15.7111 12.1611 15.5333 12.55C15.3556 12.9389 15.2667 13.5778 15.2667 14.4667ZM6.66667 23.3333C6.13334 23.3333 5.66667 23.1333 5.26667 22.7333C4.86667 22.3333 4.66667 21.8667 4.66667 21.3333V2.66667C4.66667 2.13333 4.86667 1.66667 5.26667 1.26667C5.66667 0.866668 6.13334 0.666668 6.66667 0.666668H25.3333C25.8667 0.666668 26.3333 0.866668 26.7333 1.26667C27.1333 1.66667 27.3333 2.13333 27.3333 2.66667V21.3333C27.3333 21.8667 27.1333 22.3333 26.7333 22.7333C26.3333 23.1333 25.8667 23.3333 25.3333 23.3333H6.66667ZM6.66667 21.3333H25.3333V2.66667H6.66667V21.3333ZM2.66667 27.3333C2.13334 27.3333 1.66667 27.1333 1.26667 26.7333C0.866672 26.3333 0.666672 25.8667 0.666672 25.3333V4.66667H2.66667V25.3333H23.3333V27.3333H2.66667Z"
          fill={color}
        />
      )}
    </svg>
  )
}
