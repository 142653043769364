import React from 'react'

const HomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="home-icon"
  >
    <path
      d="M3 12.1399V11.8587C3.11532 11.6927 3.20919 11.5071 3.34947 11.3644C6.01728 8.68818 8.68932 6.01641 11.3656 3.34909C11.5062 3.20847 11.6939 3.11531 11.8595 3H12.1408C12.3068 3.11531 12.4945 3.20847 12.6351 3.34874C15.3119 6.01629 17.9839 8.68806 20.6512 11.364C20.7919 11.5047 20.8847 11.6927 21 11.8587V12.1399C20.9884 12.1601 20.9779 12.1809 20.9684 12.2021C20.7673 12.7351 20.1924 12.8701 19.7811 12.4788C19.7371 12.4373 19.6918 12.3973 19.62 12.3322V12.5783C19.62 15.0901 19.6155 17.6023 19.6246 20.1141C19.6246 20.5462 19.484 20.8495 19.0663 21H4.93371C4.51778 20.8495 4.37364 20.5465 4.3754 20.1145C4.38489 17.597 4.37996 15.0792 4.37996 12.5618V12.3301C4.2766 12.4247 4.21226 12.4918 4.13948 12.5491C4.06654 12.6083 3.98231 12.6521 3.89192 12.6777C3.80153 12.7034 3.70686 12.7104 3.61368 12.6983C3.52051 12.6861 3.43078 12.6552 3.34994 12.6073C3.26909 12.5595 3.19884 12.4956 3.14345 12.4198C3.08298 12.335 3.04711 12.2334 3 12.1399ZM8.52865 19.5864V19.3628C8.52865 17.8514 8.52865 16.3398 8.52865 14.8279C8.52865 14.3536 8.80991 14.0639 9.27681 14.0632C11.0926 14.0611 12.9091 14.0623 14.7264 14.0668C14.8659 14.0675 15.0032 14.1016 15.1268 14.1662C15.3891 14.3093 15.4738 14.5589 15.4735 14.8482C15.4716 16.3536 15.4716 17.8589 15.4735 19.3642V19.5783H18.2158V19.3766C18.2158 16.6176 18.2169 13.8588 18.219 11.1C18.221 11.0371 18.2092 10.9744 18.1844 10.9165C18.1596 10.8585 18.1225 10.8067 18.0755 10.7647C16.0971 8.79177 14.1206 6.81688 12.1461 4.84001C12.1014 4.79536 12.0543 4.75352 12.0026 4.70501C11.9605 4.74333 11.9253 4.77286 11.8929 4.8052C9.8922 6.80552 7.89263 8.807 5.89423 10.8097C5.83048 10.8807 5.79419 10.9722 5.79192 11.0677C5.78653 13.8442 5.78501 16.6206 5.78735 19.3969V19.5864H8.52865ZM14.058 15.4814H9.94447V19.5794H14.058V15.4814Z"
      fill=" var(--color-grayScale-darkest)"
    />
  </svg>
)

export default HomeIcon
