import React from 'react'

interface PencilIconProps {
  color?: string
}

const PencilIcon = ({ color }: PencilIconProps) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33333 12.6663H4.26667L10.0167 6.91634L9.08333 5.98301L3.33333 11.733V12.6663ZM12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2811 1.83301 12.5949 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.43634 14.0722 4.74456 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301ZM9.55 6.44967L9.08333 5.98301L10.0167 6.91634L9.55 6.44967Z"
      fill={color ?? 'var(--color-grayScale)'}
    />
  </svg>
)

export default PencilIcon
