import React from 'react'

export const NotFoundProduct = () => {
  return (
    <svg
      width="461"
      height="460"
      viewBox="0 0 461 460"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M237.657 0.105382C119.898 -3.47129 17.8953 84.4163 2.48521 199.737C-14.365 325.824 78.374 439.272 192.485 457.442C334.418 480.042 472.086 349.293 459.726 207.45C450.411 100.584 357.359 3.74075 237.657 0.105382Z"
        fill="#F4F7FA"
      />
      <path
        d="M415.944 390.764L35.7234 425.094C30.0486 425.557 21.9043 418.049 21.9043 412.353V124.417C21.9043 119.086 26.186 114.745 31.5138 114.671L415.016 109.366C420.45 109.29 430.722 113.298 430.722 118.734L424.896 381.049C424.896 386.124 421.001 390.35 415.944 390.764Z"
        fill="url(#paint0_linear_26420_319024)"
      />
      <path
        d="M423.666 393.427L40.1633 424.732C34.4884 425.195 29.625 420.713 29.625 415.018V127.08C29.625 121.749 33.9067 117.408 39.2345 117.334L422.737 112.03C428.171 111.955 432.617 116.34 432.617 121.776V383.713C432.618 388.787 428.724 393.013 423.666 393.427Z"
        fill="url(#paint1_linear_26420_319024)"
      />
      <path
        d="M74.0495 413.046L398.887 387.174C404.073 386.762 408.071 382.43 408.071 377.227V128.711C408.071 123.141 403.511 118.649 397.942 118.734L73.1056 123.723C67.6571 123.807 63.2832 128.248 63.2832 133.7V403.099C63.2815 408.923 68.2472 413.509 74.0495 413.046Z"
        fill="url(#paint2_linear_26420_319024)"
      />
      <path
        d="M421.639 261.275C419.763 261.275 418.242 259.754 418.242 257.877V232.688C418.242 230.812 419.763 229.291 421.639 229.291C423.515 229.291 425.035 230.812 425.035 232.688V257.877C425.037 259.754 423.515 261.275 421.639 261.275Z"
        fill="url(#paint3_linear_26420_319024)"
      />
      <path
        opacity="0.9"
        d="M350.723 331.804C321.139 305.994 297.423 316.09 266.127 289.864C227.282 257.31 246.623 227.294 212.426 198.628C169.663 162.783 117.991 191.859 85.8999 155.953C77.5008 146.556 72.8251 135.368 70.3708 124.152C66.2634 125.393 63.2793 129.197 63.2793 133.698V403.097C63.2793 408.921 68.2433 413.505 74.0473 413.044L385.105 388.269C379.42 368.965 369.29 348.002 350.723 331.804Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M362.803 250.121L110.095 259.637C105.81 259.798 102.328 256.695 102.328 252.711V235.799C102.328 231.836 105.795 228.526 110.063 228.404L361.809 221.138C365.485 221.033 368.563 223.913 368.692 227.576L369.242 243.204C369.371 246.883 366.491 249.983 362.803 250.121Z"
          fill="#97A6B7"
        />
        <path
          d="M120.285 265.623L143.197 349.422C144.658 354.763 149.808 358.237 155.675 357.848L325.874 346.592C331.168 346.241 335.66 342.336 336.783 337.128L354.176 256.397L120.285 265.623ZM185.205 342.2C185.242 345.572 182.42 348.492 178.9 348.716L172.809 349.105C169.268 349.332 166.37 346.765 166.341 343.374L165.795 277.884C165.766 274.554 168.593 271.737 172.098 271.591L178.128 271.339C181.615 271.193 184.465 273.759 184.502 277.072L185.205 342.2ZM218.769 340.113C218.82 343.451 216.065 346.339 212.612 346.56L206.638 346.942C203.167 347.164 200.31 344.622 200.268 341.263L199.441 276.422C199.399 273.123 202.159 270.335 205.597 270.192L211.514 269.946C214.934 269.803 217.742 272.345 217.792 275.625L218.769 340.113ZM251.694 338.065C251.757 341.372 249.068 344.229 245.68 344.446L239.821 344.82C236.414 345.037 233.601 342.517 233.546 339.194L232.448 274.986C232.392 271.72 235.088 268.96 238.461 268.819L244.265 268.576C247.62 268.435 250.388 270.954 250.451 274.202L251.694 338.065ZM283.994 336.055C284.071 339.328 281.444 342.157 278.12 342.37L272.371 342.737C269.03 342.95 266.259 340.453 266.188 337.161L264.83 273.578C264.762 270.343 267.395 267.61 270.705 267.472L276.4 267.234C279.692 267.097 282.42 269.592 282.495 272.81L283.994 336.055ZM315.689 334.084C315.78 337.327 313.213 340.126 309.951 340.336L304.31 340.696C301.03 340.906 298.299 338.431 298.217 335.171L296.61 272.198C296.527 268.993 299.101 266.289 302.35 266.153L307.939 265.92C311.171 265.786 313.859 268.257 313.948 271.445L315.689 334.084Z"
          fill="#97A6B7"
        />
        <path
          d="M223.282 145.616C222.011 144.263 219.433 144.464 217.522 146.07L137.933 212.878C135.893 214.592 135.314 217.107 136.647 218.496C137.978 219.882 140.711 219.612 142.746 217.897L222.132 150.975C224.038 149.367 224.553 146.969 223.282 145.616Z"
          fill="#97A6B7"
        />
        <path
          d="M254.666 145.306C255.876 143.951 258.436 144.137 260.381 145.72L337.643 208.584C339.534 210.122 340.136 212.48 338.987 213.851C337.839 215.225 335.37 215.091 333.475 213.551L256.004 150.633C254.052 149.049 253.454 146.663 254.666 145.306Z"
          fill="#97A6B7"
        />
      </g>
      <path
        d="M308.432 256.006C305.012 292.975 272.272 322.181 235.19 321.235C197.983 320.286 170.515 289.358 173.957 252.16C177.397 214.961 210.427 185.764 247.613 186.943C284.673 188.118 311.852 219.037 308.432 256.006Z"
        fill="url(#paint4_linear_26420_319024)"
      />
      <path
        d="M242.068 296.572C218.644 296.572 199.588 277.511 199.588 254.083C199.588 230.654 218.644 211.594 242.068 211.594C265.492 211.594 284.548 230.654 284.548 254.083C284.55 277.511 265.492 296.572 242.068 296.572ZM242.068 227.222C227.26 227.222 215.213 239.271 215.213 254.083C215.213 268.894 227.26 280.944 242.068 280.944C256.876 280.944 268.923 268.894 268.923 254.083C268.923 239.271 256.876 227.222 242.068 227.222Z"
        fill="white"
      />
      <path
        d="M222.421 283.439C220.429 283.439 218.438 282.683 216.914 281.167C213.854 278.124 213.839 273.177 216.88 270.115L259.288 227.447C262.331 224.385 267.277 224.371 270.338 227.413C273.398 230.456 273.413 235.403 270.372 238.465L227.964 281.134C226.436 282.67 224.428 283.439 222.421 283.439Z"
        fill="white"
      />
      <g opacity="0.2">
        <path
          d="M235.646 303.427C230.774 294.804 240.632 287.71 241.034 270.516C241.644 244.406 219.518 234.938 222.482 212.913C223.307 206.789 226.35 197.629 237.767 187.342C204.764 191.047 177.092 218.282 173.957 252.158C170.517 289.356 197.982 320.284 235.19 321.233C247.876 321.557 260.05 318.35 270.719 312.513C256.835 313.451 240.801 312.552 235.646 303.427Z"
          fill="url(#paint5_linear_26420_319024)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_26420_319024"
          x1="80.5598"
          y1="17.2978"
          x2="174.381"
          y2="177.311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26420_319024"
          x1="187.311"
          y1="132.282"
          x2="384.9"
          y2="745.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26420_319024"
          x1="63.2823"
          y1="265.906"
          x2="408.071"
          y2="265.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.494" stopColor="#EBEFF2" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_26420_319024"
          x1="449.385"
          y1="193.564"
          x2="410.687"
          y2="265.668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_26420_319024"
          x1="174.352"
          y1="247.899"
          x2="308.039"
          y2="260.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_26420_319024"
          x1="174.286"
          y1="248.611"
          x2="275.763"
          y2="257.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
