import React from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import Link from '../../../ui/Link'
import { HighlightBrandsProduct } from '../HighlightBrandsProduct'

import type { HighlightProductIndex } from '../../../../types/Product'
import type { ProductsData } from '../../../../types/RepeatOrder'
import './highlight-brands-card.scss'

interface HighlightBrandsCardProps {
  logo: string
  href: string
  brandProducts: ProductsData['products']
  setSelectedHighlightIndex: (
    selectedHighlightIndex: HighlightProductIndex
  ) => void
  brandIndex: number
  setOpenQuickView: (openQuickView: boolean) => void
}

export const HighlightBrandsCard = ({
  logo,
  href,
  brandProducts,
  setSelectedHighlightIndex,
  brandIndex,
  setOpenQuickView,
}: HighlightBrandsCardProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()

  const triggerSeeMoreEvent = () => {
    genericTaggingEvent('click_destaque', { name: brandProducts[0].brand.name })
  }

  if (brandProducts.length < 4) {
    console.warn(
      'Existem menos de 4 produtos no card de destaque de marca, por isso o mesmo não será renderizado'
    )

    return null
  }

  if (brandProducts.length > 4) {
    brandProducts = brandProducts.slice(0, 4)
    console.warn(
      'Existem mais de 4 produtos no card de destaque de marca, por isso limitamos a renderização nos 4 primeiros'
    )
  }

  return (
    <div className="highlight-brands-card-container">
      <div className="highlight-brands-card-header">
        <img
          className="highlight-brands-card-header-image"
          src={logo}
          alt="Logo da marca"
        />
        <Link
          className="highlight-brands-card-header-link"
          to={href}
          onClick={triggerSeeMoreEvent}
        >
          Ver mais
        </Link>
      </div>

      <div className="highlight-brands-card-body">
        {brandProducts?.map((product, index) => (
          <HighlightBrandsProduct
            product={product}
            setSelectedHighlightIndex={setSelectedHighlightIndex}
            brandIndex={brandIndex}
            productIndex={index + 1}
            setOpenQuickView={setOpenQuickView}
            key={`highlight-brands-${product.sku}-${index}`}
          />
        ))}
      </div>
    </div>
  )
}
