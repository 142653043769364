import React from 'react'

import './render-errors.scss'

import Image404 from '../../../assets/404.png'
import Button from '../../ui/Button'

export const NoDataError = () => {
  return (
    <div className="no-data-error-container">
      <h2 className="error-title">
        Desculpe, mas aconteceu um erro ao carregar a página
      </h2>

      <div>
        <img src={Image404} width={328} height={200} />
      </div>

      <p className="no-data-error-message">
        Tente recarregar a página, ou entre novamente em alguns minutos
      </p>

      <Button type="button" variant="primary" onClick={() => location.reload()}>
        Recarregar Página
      </Button>
    </div>
  )
}
