import React from 'react'

interface ProductDetailsHeaderProps {
  name: string
  brand: string
}

export const ProductDetailsHeader = ({
  name,
  brand,
}: ProductDetailsHeaderProps) => {
  const boldBrand = name.replace(brand, `<strong>${brand}</strong>`)
  const title = `<h1>${name.includes(brand) ? boldBrand : name}</h1>`

  return (
    <header className="product-details__header">
      <div dangerouslySetInnerHTML={{ __html: title }} />
    </header>
  )
}
