import React from 'react'

import type { Icon } from '../../types/FAQ'

export const PhoneIcon = ({
  width = 20,
  height = 30,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M7.26675 26.8333H12.7334V25.5H7.26675V26.8333ZM2.66675 29.6667C2.13341 29.6667 1.66675 29.4667 1.26675 29.0667C0.866748 28.6667 0.666748 28.2 0.666748 27.6667V2.33333C0.666748 1.79999 0.866748 1.33333 1.26675 0.933328C1.66675 0.533328 2.13341 0.333328 2.66675 0.333328H17.3334C17.8667 0.333328 18.3334 0.533328 18.7334 0.933328C19.1334 1.33333 19.3334 1.79999 19.3334 2.33333V27.6667C19.3334 28.2 19.1334 28.6667 18.7334 29.0667C18.3334 29.4667 17.8667 29.6667 17.3334 29.6667H2.66675ZM2.66675 22.6667H17.3334V5.33333H2.66675V22.6667Z"
          fill={color}
        />
      ) : (
        <path
          d="M7.26663 26.8333H12.7333V25.5H7.26663V26.8333ZM2.66663 29.6666C2.13329 29.6666 1.66663 29.4666 1.26663 29.0666C0.866626 28.6666 0.666626 28.2 0.666626 27.6666V2.33331C0.666626 1.79998 0.866626 1.33331 1.26663 0.933313C1.66663 0.533313 2.13329 0.333313 2.66663 0.333313H17.3333C17.8666 0.333313 18.3333 0.533313 18.7333 0.933313C19.1333 1.33331 19.3333 1.79998 19.3333 2.33331V27.6666C19.3333 28.2 19.1333 28.6666 18.7333 29.0666C18.3333 29.4666 17.8666 29.6666 17.3333 29.6666H2.66663ZM2.66663 24.6666V27.6666H17.3333V24.6666H2.66663ZM2.66663 22.6666H17.3333V5.33331H2.66663V22.6666ZM2.66663 3.33331H17.3333V2.33331H2.66663V3.33331Z"
          fill={color}
        />
      )}
    </svg>
  )
}
