import React, { useMemo } from 'react'

import MenuCarousel from '../../../ui/MenuCarousel'
import { MultipleSearchMessage } from '../MultipleSearchMessage'
import { MultipleSearchNavigation } from '../MultipleSearchNavigation'

import './multiple-search-header.scss'

interface MultipleSearchHeaderProsp {
  isProductsView?: boolean
  terms?: string[]
  backButtonProps: {
    link?: string
    backText: string
    trigger: () => void
  }
  hasTerms?: boolean
  limitReached?: boolean
}

export const MultipleSearchHeader = ({
  backButtonProps,
  hasTerms,
  limitReached,
  terms,
  isProductsView,
}: MultipleSearchHeaderProsp) => {
  const breadcrumb = useMemo(() => {
    const defaultItemList = ['Buscar Lista de Compras']

    if (isProductsView) {
      defaultItemList.push('Resultados da Lista de Compras')
    }

    return defaultItemList
  }, [isProductsView])

  const navigateTriggers = [
    () => {
      backButtonProps?.trigger()
    },
    () => {},
  ]

  return (
    <>
      <div className="grid-content">
        <MultipleSearchNavigation
          backButton={backButtonProps}
          breadcrumb={breadcrumb}
          navigateTriggers={navigateTriggers}
        />

        <h1 className="multiple-search-title">
          {!isProductsView
            ? 'Buscar Lista de Compras'
            : 'Resultados da Lista de Compras'}
        </h1>

        {!isProductsView && (
          <ul className="multiple-search-subtitle-list">
            <li>
              <strong>Adicione</strong> os produtos na sua lista de compras;
            </li>
            <li>
              <strong>Pesquise todos</strong> os produtos de uma só vez
            </li>
          </ul>
        )}

        {hasTerms && limitReached && !isProductsView && (
          <MultipleSearchMessage text="Você atingiu o número máximo de produtos" />
        )}
      </div>
      {isProductsView && <MenuCarousel termArray={terms} />}
    </>
  )
}
