import '../styles/pages/club-card-benefits-page.scss'

import React, { useCallback, useEffect, useState } from 'react'

import { Benefits } from '../components/ClubCard/Benefits/Benefits'
import { BenefitsDesktop } from '../components/ClubCard/Benefits/BenefitsDesktop'
import { Discounts } from '../components/ClubCard/Benefits/Discounts'
import { FAQ } from '../components/ClubCard/Benefits/Faq'
import { MainBanner } from '../components/ClubCard/Benefits/MainBanner'
import { PointsWrapper } from '../components/ClubCard/Benefits/PointsWrapper'
import { Steps } from '../components/ClubCard/GetCard/Steps'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { getMobileOS } from '../utils/getMobileOS'

import type { ClubCardBenefitsPageSectionData } from '../types/ClubCard/ClubCardBenefits'

interface ClubCardBenefitsPageProps {
  data: ClubCardBenefitsPageSectionData
}

export function ClubCardBenefitsPage({ data }: ClubCardBenefitsPageProps) {
  const {
    mainBanner,
    legalText,
    faq,
    benefits,
    discounts,
    points,
    steps,
    orientations,
    links,
  } = data

  const [linkGlobal, setLinkGlobal] = useState('')
  const { width } = useWindowDimensions()
  const isWidthMobile = width && width <= 768

  const transformHrefToMobile = useCallback(() => {
    if (!isWidthMobile) {
      return ''
    }

    const typeOS = getMobileOS()

    return typeOS === 'iOS' ? links?.web?.apple : links?.web?.android
  }, [links])

  useEffect(() => {
    setLinkGlobal(transformHrefToMobile())
  }, [transformHrefToMobile])

  return (
    <div className="club-card-benefits-page-container">
      <MainBanner
        {...mainBanner}
        className="club-card-benefits-mainBanner"
        href={linkGlobal}
      />

      <Benefits benefits={benefits} link={linkGlobal} />
      <BenefitsDesktop {...benefits} />
      <Discounts {...discounts} />
      <PointsWrapper {...points} linkGlobal={linkGlobal} />
      <Steps steps={{ ...steps, links }} title={steps?.title} />

      {orientations.visibility && (
        <p className="club-card-benefits-orientations grid-content">
          {orientations.text}
        </p>
      )}

      <FAQ
        title={faq?.title}
        visibility={faq?.visibility}
        questions={faq?.questions}
      />

      {legalText.visibility && (
        <p className="club-card-benefits-legalText grid-content">
          {legalText.text}
        </p>
      )}
    </div>
  )
}
