import React from 'react'

interface FilterCheckProps {
  width?: number
  height?: number
  color?: string
  isChecked: boolean
}

export const FilterCheck = ({
  width = 16,
  height = 16,
  color = 'var(--color-main)',
  isChecked,
}: FilterCheckProps) => {
  if (isChecked) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="2" fill={color} />
        <path
          d="M5 8L7 10L11 6"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx="1.5"
        fill="white"
        stroke="var(--color-grayScale-light)"
      />
    </svg>
  )
}
