import './discount-card.scss'

import React from 'react'

import { DynamicTitle } from '../../common/DynamicTitle'
import { Image } from '../Image'

import type { ImageWithAlt } from '../../../types/ClubCard/ClubCardBenefits'

interface DiscountsProps {
  text: string
  tag: ImageWithAlt
  image: ImageWithAlt
  imageBottom?: ImageWithAlt
  isMobile?: boolean
  className?: string
}

export const DiscountCard = ({
  text,
  tag,
  image,
  imageBottom,
  isMobile,
  className = '',
}: DiscountsProps) => {
  return (
    <div className={`discountCard ${className}`}>
      <div className="discountCard-top">
        <div className="discountCard-tag">
          <Image alt={tag?.alt} baseUrl={tag?.src} width={56} height={20} />
        </div>
        <div className="discountCard-image">
          <Image
            alt={image?.alt}
            baseUrl={image?.src}
            width={134}
            height={isMobile ? 112 : 130}
          />
        </div>
      </div>
      <div className="discountCard-bottom">
        <DynamicTitle
          className="discountCard-text"
          titleLevel={3}
          title={text}
        />
        {imageBottom && (
          <div className="discountCard-image">
            <Image
              alt={imageBottom?.alt}
              baseUrl={imageBottom?.src}
              width={110}
              height={39}
            />
          </div>
        )}
      </div>
    </div>
  )
}
