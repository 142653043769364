import React from 'react'

import { Steps } from '../components/ClubCard/GetCard/Steps'
import { Banner } from '../components/sections/Banner'

import type { ClubCardGetCardPageSectionData } from '../types/ClubCard/ClubCardGetCard'

import '../styles/pages/club-card-get-card-page.scss'

interface ClubCardGetCardPageProps {
  data: ClubCardGetCardPageSectionData
}

export function ClubCardGetCardPage({ data }: ClubCardGetCardPageProps) {
  const { mainBanner, steps, orientations } = data

  return (
    <div className="club-card-get-card-page-container">
      <Banner className="club-card-get-card-mainBanner" data={mainBanner} />

      <Steps steps={steps} />

      {orientations.visibility && (
        <p className="club-card-get-card-orientations grid-content">
          {orientations.text}
        </p>
      )}
    </div>
  )
}
