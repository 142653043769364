import React from 'react'

import CloseIconV2 from '../../../../../assets/CloseIconV2'
import Button from '../../../../ui/Button'

interface TelesalesModalHeaderProps {
  closeModal: () => void
}

export const TelesalesModalHeader = ({
  closeModal,
}: TelesalesModalHeaderProps) => {
  return (
    <div className="telesales-modal__header">
      <Button
        onClick={() => {
          closeModal()
        }}
      >
        <CloseIconV2 color="var(--color-main-dark)" />
      </Button>
    </div>
  )
}
