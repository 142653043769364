import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React, { useEffect, useState } from 'react'

import { HAS_WINDOW } from '../../../../../../constants'
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions'
import Button from '../../../../../ui/Button/Button'

import type { FacetsProps } from '../../../../../../types/FoodRestriction'
import './food-restriction-navigationb-bar.scss'

interface FoodRestrictionNavigationBarProps {
  layoutLoading: boolean
  mainMenuCategories: FacetsProps[]
  selectedCategorie: string
  handleMenuItem: (value: string, label: string, key: string) => void
  categorieFacetsKey: string
}

export const FoodRestrictionNavigationBar = ({
  layoutLoading,
  mainMenuCategories,
  selectedCategorie,
  handleMenuItem,
  categorieFacetsKey,
}: FoodRestrictionNavigationBarProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  if (layoutLoading) {
    return (
      <div className="restrictionProductsPage-container-navigation-bar">
        <div className="restrictionProductsPage-container-navigation-bar-content / grid-content">
          <Skeleton
            table={{ rows: 1, columns: 5 }}
            height={20}
            backgroundColor="var(--color-grayScale-faded)"
          />
        </div>
      </div>
    )
  }

  const handleTopPage = () => {
    if (!HAS_WINDOW) {
      return
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className="restrictionProductsPage-container-navigation-bar">
      <div className="restrictionProductsPage-container-navigation-bar-content / grid-content">
        <div style={{ width: '100%' }}>
          <Carousel
            gapItems={35}
            arrow={{
              isVisible: width >= 1280,
              iconColor: 'var(--color-main)',
              style: {
                width: '32px',
                height: '32px',
                margin: '5px',
                cursor: 'pointer',
                backgroundColor: 'var(--color-main-faded)',
              },
            }}
            bullet={{ isVisible: false }}
          >
            {mainMenuCategories?.map(({ label, value }) => (
              <div
                key={label}
                className={`restrictionProductsPage-container-navigation-bar-content-item ${
                  selectedCategorie === label ? 'selected' : ''
                }`}
              >
                <Button
                  className={`button${
                    selectedCategorie === label ? '-selected' : '-default'
                  }`}
                  onClick={() => {
                    handleTopPage()
                    handleMenuItem(value, label, categorieFacetsKey)
                  }}
                >
                  {label}
                </Button>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
