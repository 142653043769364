import { Carousel } from '@plurix/ecom-components'
import React from 'react'

import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import Button from '../../../../ui/Button'

import './telesales-header.scss'

interface SearchTermsHeaderProps {
  terms: string[]
  selectedTerm: string
  changeTerm: (term: string) => void
}

export const SearchTermsHeader = ({
  terms,
  selectedTerm,
  changeTerm,
}: SearchTermsHeaderProps) => {
  const { isMobile } = useWindowDimensions()

  return (
    <>
      {!isMobile && (
        <div className="telesales-header-information">
          <span>
            <b>Dica: </b>Navegue pela aba dos termos buscados abaixo e encontre
            seus produtos
          </span>
        </div>
      )}

      <div className="telesales-carousel-grid" id="carousel-menu-content">
        <div className="container-term-grid grid-content">
          <Carousel
            arrow={{
              isVisible: !isMobile,
              iconColor: 'var(--color-main)',
              style: {
                margin: '5px',
                cursor: 'pointer',
                backgroundColor: 'var(--color-main-faded)',
              },
            }}
          >
            <div className="carousel-menu">
              <ul>
                {terms?.map((item) => (
                  <li
                    key={item}
                    className={`${selectedTerm == item ? 'term-selected' : ''}`}
                  >
                    <Button
                      onClick={() => {
                        {
                          changeTerm(item)
                        }
                      }}
                    >
                      {item}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  )
}
