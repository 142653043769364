import { Skeleton } from '@acctglobal/skeleton'
import React, { useEffect, useState } from 'react'

import { PhoneIcon } from '../assets/faqIcons'
import { OrderStoreIcon } from '../assets/OrderInStoreIcon'
import { WarningIcon } from '../assets/WarningIcon'
import { GenericError } from '../components/sections/RenderErrors/GenericError'
import { NotLoggedIn } from '../components/sections/RenderErrors/NotLoggedIn'
import { AddProductsToCart } from '../components/sections/RepeatOrder/AddProductsToCart'
import { ProductCard } from '../components/sections/RepeatOrder/ProductCard'
import { NavigationInstitutional } from '../components/ui/NavigationInstitutional'
import { useClubContext } from '../contexts/ClubContext'
import { useAnotherScPrices } from '../hooks/useAnotherScPrices'
import { useProductWithLowStock } from '../hooks/useProductWithLowStock'
import { useRepeatOrder } from '../hooks/useRepeatOrder'
import { formatDate } from '../utils/formatDate'
import { updateOrderTotalValue } from '../utils/updateOrderTotalValue'

import type { ProductsQueryResponse } from '../types/Product'
import type { OrderType, CustomerOrder } from '../types/RepeatOrder'

import '../styles/pages/repeat-order.scss'

interface RepeatOrderPageProps {
  orderId: string
  type: OrderType
}

export const RepeatOrderPage = ({ orderId, type }: RepeatOrderPageProps) => {
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<CustomerOrder>()
  const [orderProductsData, setOrderProductsData] = useState<
    ProductsQueryResponse['node'][]
  >([])

  const { getAnotherScPrices } = useAnotherScPrices(orderProductsData)
  const { productsWithLowStock, loading: productsWithLowStockLoading } =
    useProductWithLowStock(orderProductsData)

  const { userInfos } = useClubContext()
  const { getOrderData, getProductsData } = useRepeatOrder()

  const formattedDate = order?.creationDate
    ? formatDate(order.creationDate)
    : ''

  useEffect(() => {
    const fetchOrderAndProductsData = async () => {
      try {
        setLoading(true)

        const orderData = await getOrderData(orderId, type)

        if (orderData.items.length) {
          const skus = orderData.items.map(({ id }) => id)
          const products = await getProductsData(skus)

          setOrder(updateOrderTotalValue(orderData, products))
          setOrderProductsData(products)
        } else {
          setOrder(undefined)
          setOrderProductsData([])
        }
      } catch {
        setOrder(undefined)
        setOrderProductsData([])
      } finally {
        setLoading(false)
      }
    }

    fetchOrderAndProductsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  if (!userInfos && !loading) {
    return (
      <div className="repeat-order-container grid-content">
        <NavigationInstitutional
          titleText="Repetir Pedido"
          backText="Tela Inicial"
        />

        <NotLoggedIn />
      </div>
    )
  }

  if (!order?.items?.length && !orderProductsData?.length && !loading) {
    return (
      <div className="repeat-order-container grid-content">
        <NavigationInstitutional
          titleText="Repetir Pedido"
          backText="Tela Inicial"
        />

        <GenericError />
      </div>
    )
  }

  return (
    <div className="repeat-order-container grid-content">
      <NavigationInstitutional
        titleText="Repetir Pedido"
        backText="Tela Inicial"
      />

      <div className="repeat-order-header">
        <div className="repeat-order-header-info">
          <h2 className="repeat-order-title">Pedido #{orderId}</h2>

          {formattedDate && (
            <span className="repeat-order-date">{formattedDate}</span>
          )}
        </div>

        <div className="repeat-order-header-order-type">
          {type === 'digital' ? <PhoneIcon /> : <OrderStoreIcon />}
          <p>{type === 'digital' ? 'Pedido Online' : 'Pedido Físico'}</p>
        </div>
      </div>

      <div className="warning-container">
        <span className="message-warning">
          <WarningIcon /> O preço de alguns produtos podem ter sido ajustados
        </span>
      </div>

      <div className="repeat-order-resume">
        <p>Resumo de pedido</p>
      </div>

      <div className="product-list-content">
        {loading || productsWithLowStockLoading ? (
          <div className="container-skeleton grid-content">
            <Skeleton
              table={{ rows: 4, columns: 0 }}
              width="100%"
              height={160}
              backgroundColor="var(--color-grayScale-faded)"
            />
          </div>
        ) : (
          <>
            {productsWithLowStock?.map((product) => {
              const anotherScPrices = getAnotherScPrices(product.sku)

              return (
                <ProductCard
                  key={product.id}
                  product={product}
                  order={order as CustomerOrder}
                  titleLevel={3}
                  {...anotherScPrices}
                />
              )
            })}

            <AddProductsToCart
              order={order as CustomerOrder}
              products={orderProductsData}
            />
          </>
        )}
      </div>
    </div>
  )
}
