import React from 'react'

import badgeProductPlusFriend from '../../../assets/badgeProduct.png'
import './product-card-top-flag-image.scss'

export const ProductCardTopFlagImage = () => {
  return (
    <div className="product-card-top-flag-image">
      <img
        className={`product-card-top-flag-content`}
        src={badgeProductPlusFriend}
      />
    </div>
  )
}
