export const formatListPrice = (
  listPrice: number,
  multiplier: number,
  unit = ''
) => {
  if (unit === 'un') {
    return listPrice
  }

  // trunc price in 2 decimals
  const listPriceCustom = (listPrice * multiplier)
    .toString()
    .match(/^\d+(?:\.\d{0,2})?/)

  return Number(listPriceCustom)
}

export const formatBrazilianPrice = (price: number) => {
  return price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const truncPrice = (price: number) =>
  Number(price.toString().match(/^\d+(?:\.\d{0,2})?/))

export const formatUndQtd = (hasPesavelUnd: boolean, unitMultiplier: any) => {
  if (hasPesavelUnd) {
    return ''
  }

  if (unitMultiplier > 1) {
    return unitMultiplier
  }

  if (unitMultiplier === 1) {
    return ''
  }

  return unitMultiplier * 1000
}

export const formatUnd = (
  hasPesavelUnd: boolean,
  unitMultiplier: any,
  measurementUnit: any
) => {
  if (hasPesavelUnd) {
    return 'Un'
  }

  if (unitMultiplier * 1000 >= 1000) {
    return measurementUnit
  }

  return 'g'
}
