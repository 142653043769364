import React from 'react'

import { Image } from '../../ui/Image'

interface MosaicBannerProps {
  screenWidth: number
  src: string
  srcMobile: string
  mobileWidth: number
  desktopWidth: number
  mobileHeight: number
  desktopHeight: number
  alt: string
  hasPriority?: boolean
}

export const MosaicBanner = ({
  screenWidth,
  srcMobile,
  src,
  mobileWidth,
  desktopWidth,
  mobileHeight,
  desktopHeight,
  alt,
  hasPriority,
}: MosaicBannerProps) => {
  return (
    <Image
      objectFit="contain"
      baseUrl={screenWidth <= 768 ? srcMobile : src}
      layout="fullWidth"
      sourceWidth={screenWidth <= 768 ? mobileWidth : desktopWidth}
      sourceHeight={screenWidth <= 768 ? mobileHeight : desktopHeight}
      backgroundColor="var(--color-grayScale-faded)"
      options={{
        fitIn: true,
      }}
      alt={alt}
      loading={hasPriority ? 'eager' : 'lazy'}
    />
  )
}
