import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React from 'react'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../utils/carouselProps'
import { createArray } from '../../../utils/createArrayForNumber'

import type { CarouselPropsWithoutChildren } from '../../../hooks/useVerticalShelfCarouselProps'
import type { Variant } from '../../product/ProductCard'

import './product-shelf-skeleton.scss'

interface ProductShelfSkeletonProps {
  backgroundColor?: string
  variant?: Variant
  propsCarousel?: CarouselPropsWithoutChildren
}

export const ProductShelfSkeleton = ({
  backgroundColor = 'var(--color-grayScale-faded)',
  variant = 'vertical',
  propsCarousel,
}: ProductShelfSkeletonProps) => {
  const { width } = useWindowDimensions()

  // SSR
  if (!width) {
    return <Skeleton height={500} backgroundColor={backgroundColor} />
  }

  const carouselFinalProps = propsCarousel ?? carouselProps(width)

  return (
    <div className="product-shelf-skeleton">
      <div className="carousel-product-header">
        <h2
          className="carousel-product-header-title"
          style={{ backgroundColor }}
        >
          Título do carrossel
        </h2>
        <a href="#" data-store-link style={{ backgroundColor }}>
          Ver Tudo
        </a>
      </div>

      <div className="div-content-shelf-product">
        <Carousel {...carouselFinalProps}>
          {createArray(variant === 'vertical' ? 10 : 4).map((item) => (
            <div
              key={item}
              className={
                variant === 'vertical'
                  ? undefined
                  : 'product-card-horizontal-content'
              }
            >
              <div
                className={
                  variant === 'vertical'
                    ? 'product-card'
                    : 'product-card-horizontal'
                }
                data-store-card="true"
                data-card-variant={variant}
                data-card-bordered="false"
                data-card-out-of-stock="false"
                style={{ backgroundColor }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
