import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'

interface Page500Props {
  errorId: string | null
  fromUrl: string | null
}

export const Page500 = ({ errorId, fromUrl }: Page500Props) => {
  return (
    <div className="grid-content">
      <GatsbySeo noindex nofollow />

      <h1>500</h1>
      <h2>Internal Server Error</h2>

      <div>
        The server errored with id {errorId} when visiting page {fromUrl}
      </div>
    </div>
  )
}
