import './status-message.scss'

import React from 'react'

import { ErrorOctagonIcon } from '../../../assets/ErrorOctagonIcon'
import { BoldTextParts } from '../../common/BoldTextParts'

interface StatusMessageProps {
  Icon?: React.ReactElement
  message?: string
  className?: string
}

export function StatusMessage({
  Icon = <ErrorOctagonIcon />,
  message = 'Encontramos um erro ao tentar acessar as suas informações. Por favor, recarregue a página',
  className = '',
}: StatusMessageProps) {
  return (
    <div className={`status-message-container ${className}`}>
      {Icon}
      <span>
        <BoldTextParts text={message} />
      </span>
    </div>
  )
}
