import React from 'react'

interface ChatIconProps {
  width?: number
  height?: number
  color?: string
}

export const ChatIcon = ({
  width = 32,
  height = 32,
  color = 'var(--color-main)',
}: ChatIconProps = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99996 18.7255H18.4333V16.7255H7.99996V18.7255ZM7.99996 14.3921H24V12.3921H7.99996V14.3921ZM7.99996 10.0588H24V8.05881H7.99996V10.0588ZM2.66663 29.3588V4.69214C2.66663 4.18103 2.86663 3.71992 3.26663 3.30881C3.66663 2.89769 4.13329 2.69214 4.66663 2.69214H27.3333C27.8444 2.69214 28.3055 2.89769 28.7166 3.30881C29.1277 3.71992 29.3333 4.18103 29.3333 4.69214V22.0255C29.3333 22.5366 29.1277 22.9977 28.7166 23.4088C28.3055 23.8199 27.8444 24.0255 27.3333 24.0255H7.99996L2.66663 29.3588ZM4.66663 24.5255L7.16663 22.0255H27.3333V4.69214H4.66663V24.5255Z"
        fill={color}
      />
    </svg>
  )
}
