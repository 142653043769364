import type { CartItem } from '../sdk/cart'
import type { ProductsQueryResponse } from '../types/Product'

export const formatBuyProduct = (product: ProductsQueryResponse['node']) => {
  const {
    unitMultiplier,
    measurementUnit,
    id,
    sku,
    gtin,
    brand,
    sellers,
    categoryTree,
    priceWithoutPromotions,
    isVariantOf,
    isVariantOf: { name },
    image: [img],
    offers: {
      offers,
      offers: [{ seller }],
    },
    hasClubPrice,
    customOffers,
    customOffers: { listPriceCustom, spotPriceCustom },
    skuSpecifications,
  } = product

  const isOutOfStock = offers.some(
    (offer: { availability: string }) =>
      offer.availability === 'https://schema.org/OutOfStock'
  )

  const buyProps: CartItem = {
    id,
    price: spotPriceCustom,
    listPrice: listPriceCustom,
    quantity: 1,
    seller,
    itemOffered: {
      unitMultiplier,
      measurementUnit,
      sellers,
      categoryTree,
      hasClubPrice,
      priceWithoutPromotions,
      skuSpecifications,
      customOffers,
      sku,
      name,
      image: [img],
      brand,
      isVariantOf,
      gtin,
      additionalProperty: [
        {
          propertyID: '',
          name: '',
          value: '',
          valueReference: '',
        },
      ],
    },
  }

  if (isOutOfStock) {
    return
  }

  return buyProps
}
