import { gql } from '@faststore/graphql-utils'
import { useMemo } from 'react'

import { useQuery } from '../graphql/useQuery'
import { useSession } from '../session'

const query = gql`
  query BrowserProductQuery($locator: [IStoreSelectedFacet!]!) {
    product(locator: $locator) {
      ...ProductDetailsFragment_product
    }
  }
`

export const useProduct = <T>(productID: string, fallbackData?: T) => {
  const { channel, locale } = useSession()
  const variables = useMemo(() => {
    if (!channel) {
      throw new Error(`useProduct: 'channel' from session is an empty string.`)
    }

    return {
      locator: [
        { key: 'id', value: productID },
        { key: 'channel', value: channel },
        { key: 'locale', value: locale },
      ],
    }
  }, [channel, locale, productID])

  return useQuery<any, any>(query, variables, {
    fallbackData,
    revalidateOnMount: true,
  })
}
