import { sendAnalyticsEvent } from '@faststore/sdk'

import { getItemId, useCart } from '../sdk/cart'
import {
  formatItemTagging,
  formatViewListItemTagging,
  formattedSelectItem,
} from '../utils/formatItemTagging'

import type { AnalyticsItem } from '../sdk/analytics/types'
import type { CartItem } from '../sdk/cart'
import type { Product, ProductsQueryResponse } from '../types/Product'
import type {
  ViewItemEvent,
  AddToCartEvent,
  RemoveFromCartEvent,
  SelectItemEvent,
} from '@faststore/sdk'

interface SelectPromotion {
  promotionName?: string
  href?: string
  locale?: string
}

interface SendPostalCode {
  postalCode: string
  isValid: boolean
  region: string
}

interface Search {
  siteSearchTerm: string
  siteSearchForm: string
  siteSearchResults: number
}

export const useAnalyticsEvents = () => {
  const { items } = useCart()

  const viewProductDetails = (item: CartItem) => {
    const cartItem = {
      ...item,
      id: getItemId(item as any),
    }

    sendAnalyticsEvent<ViewItemEvent<AnalyticsItem> | any>({
      name: 'view_item',
      params: {
        currency: 'BRL',
        value: item?.price ?? null,
        items: [formatItemTagging(cartItem)],
      },
    })
  }

  const addItemEvent = (item: CartItem, location?: string) => {
    const cartItem = {
      ...item,
      id: getItemId(item as any),
    }

    sendAnalyticsEvent<AddToCartEvent<AnalyticsItem> | any>({
      name: 'add_to_cart',
      params: {
        currency: 'BRL',
        value: item?.price ?? null,
        items: [formatItemTagging(cartItem)],
        location,
      },
    })
  }

  const quickViewEvent = (
    product: ProductsQueryResponse['node'],
    location: string
  ) => {
    const formattedItem = formattedSelectItem(product)

    if (formattedItem.price) {
      delete formattedItem.price
    }

    const items = [formattedItem]

    sendAnalyticsEvent<any>({
      name: 'quick_view',
      params: {
        currency: 'BRL',
        value: product?.customOffers?.listPriceCustom ?? null,
        items,
        location,
      },
    })
  }

  const removeItemEvent = (sku: string, location?: string) => {
    const itemRemoved = items.find(
      (item: CartItem) => item.id.replace(/:.*$/, '') === sku
    )

    sendAnalyticsEvent<RemoveFromCartEvent<AnalyticsItem> | any>({
      name: 'remove_from_cart',
      params: {
        currency: 'BRL',
        value: itemRemoved?.price ?? undefined,
        items: [formatItemTagging(itemRemoved)],
        location,
      },
    })
  }

  const emptyCartEvent = () => {
    const itemsRemoved = items.map((item: CartItem) => formatItemTagging(item))
    const totalPrice = itemsRemoved.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + currentValue.price,
      0
    )

    sendAnalyticsEvent<RemoveFromCartEvent<any>>({
      name: 'remove_from_cart',
      params: {
        currency: 'BRL',
        value: totalPrice,
        items: itemsRemoved,
      },
    })
  }

  const sendPostalCodeEvent = ({
    postalCode,
    isValid,
    region,
  }: SendPostalCode) => {
    sendAnalyticsEvent<any>({
      name: 'cep',
      params: {
        cep_user: postalCode,
        entrega: isValid,
        endereço: region,
        page: window.location.href,
      },
    })
  }

  const evaluateEvent = (event: string, activeQuestion: string) => {
    sendAnalyticsEvent<any>({
      name: 'evaluate',
      params: {
        value: event,
        question: activeQuestion,
      },
    })
  }

  const activeQuestionEvent = (activeQuestion: string) => {
    sendAnalyticsEvent<any>({
      name: 'activeQuestion',
      params: {
        question: activeQuestion,
      },
    })
  }

  const selectPromotion = ({
    href,
    promotionName,
    locale,
  }: SelectPromotion) => {
    if (!promotionName || !href || !locale) {
      return
    }

    sendAnalyticsEvent<any>({
      name: 'select_promotion',
      params: {
        location_id: href,
        promotion_name: promotionName,
        url_promotion: locale,
      },
    })
  }

  const searchEvent = ({
    siteSearchTerm,
    siteSearchForm,
    siteSearchResults,
  }: Search) => {
    sendAnalyticsEvent<any>({
      name: 'internalSiteSearchView',
      params: {
        siteSearchTerm,
        siteSearchForm,
        siteSearchResults,
      },
    })
  }

  const viewItemListEvent = (
    productList:
      | ProductsQueryResponse[]
      | ProductsQueryResponse['node'][]
      | Product[],
    listName?: string
  ) => {
    sendAnalyticsEvent<any>({
      name: 'view_item_list',
      params: {
        items: formatViewListItemTagging(productList, listName),
      },
    })
  }

  const selectItemEvent = (product: ProductsQueryResponse['node']) => {
    sendAnalyticsEvent<SelectItemEvent<AnalyticsItem> | any>({
      name: 'select_item',
      params: {
        items: [formattedSelectItem(product)],
      },
    })
  }

  const genericTaggingEvent = (name: string, params: object) => {
    sendAnalyticsEvent<any>({ name, params })
  }

  return {
    viewItemListEvent,
    viewProductDetails,
    addItemEvent,
    quickViewEvent,
    removeItemEvent,
    emptyCartEvent,
    selectPromotion,
    sendPostalCodeEvent,
    searchEvent,
    selectItemEvent,
    evaluateEvent,
    activeQuestionEvent,
    genericTaggingEvent,
  }
}
