import React from 'react'

import { RenderCampaigns } from '../components/sections/Campaigns/RenderCampaigns'
import { useCmsLandingPageContext } from '../contexts/CmsLandingPage'

import '../styles/pages/download-app-page.scss'

interface DownloadAppPageProps {
  segment: string
}

export const DownloadAppPage = ({ segment }: DownloadAppPageProps) => {
  const { cmsDataLandingPage, loading } = useCmsLandingPageContext()

  return (
    <div className="download-app__container">
      <RenderCampaigns
        segment={segment}
        cmsDataLandingPage={cmsDataLandingPage}
        loading={loading}
      />
    </div>
  )
}
