import React from 'react'

export const WarningIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6678 11.9985L10.0158 1.60865C9.10768 0.0798702 6.89402 0.077839 5.98471 1.60865L0.332959 11.9985C-0.595353 13.5607 0.528553 15.5389 2.34802 15.5389H13.6523C15.4702 15.5389 16.5961 13.5623 15.6678 11.9985ZM8.00024 13.6639C7.48343 13.6639 7.06274 13.2432 7.06274 12.7264C7.06274 12.2096 7.48343 11.7889 8.00024 11.7889C8.51705 11.7889 8.93774 12.2096 8.93774 12.7264C8.93774 13.2432 8.51705 13.6639 8.00024 13.6639ZM8.93774 9.91387C8.93774 10.4307 8.51705 10.8514 8.00024 10.8514C7.48343 10.8514 7.06274 10.4307 7.06274 9.91387V5.22637C7.06274 4.70956 7.48343 4.28887 8.00024 4.28887C8.51705 4.28887 8.93774 4.70956 8.93774 5.22637V9.91387Z"
      fill="var(--color-warning)"
    />
  </svg>
)
