import React from 'react'

import { useStoreContext } from '../../../contexts/StoreContext'

import './render-errors.scss'

import Image401 from '../../../assets/without_session_repeat_order.png'
import Button from '../../ui/Button'

export const NotLoggedIn = () => {
  const { secureSubdomain } = useStoreContext()
  const url = `${secureSubdomain}/api/io/login`

  function toLogin() {
    window.location.assign(url)
  }

  return (
    <div className="no-data-error-container">
      <h2 className="error-title">
        Para acessar a página você precisa entrar na sua conta
      </h2>

      <div>
        <img src={Image401} width={328} height={312} />
      </div>

      <Button type="button" variant="primary" onClick={() => toLogin()}>
        Entrar na Minha Conta
      </Button>
    </div>
  )
}
