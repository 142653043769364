import React from 'react'

export const PlurixLogo = () => {
  return (
    <svg
      width="140"
      height="32"
      viewBox="0 0 440 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.9576 0H0V98.9018H17.0478V56.7234C17.0478 56.7234 43.8699 66.3117 64.2939 58.1171C84.7179 49.9224 85.2181 30.9448 85.2181 30.9448C85.2181 30.9448 86.8437 1.52995 55.9576 0ZM48.9655 45.8985C40.9105 45.9299 34.7208 46.2862 16.9644 42.1469V14.9432C16.9644 14.9432 42.7966 14.7546 51.4039 14.9432C56.9371 15.0585 67.3263 19.6169 67.3263 30.4208C67.3367 42.608 54.9572 45.8775 48.9655 45.8985Z"
        fill="var(--color-main)"
      />
      <path
        d="M117.574 0H100.609V98.9018H117.574V0Z"
        fill="var(--color-main)"
      />
      <path
        d="M328.394 24.8566H311.43V98.902H328.394V24.8566Z"
        fill="var(--color-main)"
      />
      <path
        d="M320.874 20.9163C326.618 20.9163 331.274 16.234 331.274 10.4581C331.274 4.68227 326.618 0 320.874 0C315.131 0 310.475 4.68227 310.475 10.4581C310.475 16.234 315.131 20.9163 320.874 20.9163Z"
        fill="var(--color-main)"
      />
      <path
        d="M266.516 28.199C258.711 32.7889 253.553 42.4296 250.959 48.3922V24.8562H233.994V98.9015H250.959V74.3804C250.959 66.0181 253.22 57.6453 258.243 50.9701C262.15 45.7725 267.756 41.1617 275.655 39.6946C281.136 38.6781 288.514 39.1811 297.757 42.7545V24.8562C297.757 24.8562 278.25 21.3038 266.516 28.199Z"
        fill="var(--color-main)"
      />
      <path
        d="M417.908 84.1888C407.363 82.2293 400.923 74.6738 397.12 67.6109L439.625 24.8562H416.304L390.93 50.4357C388.731 44.9132 383.292 33.3547 374.518 28.199C362.784 21.3038 343.277 24.8562 343.277 24.8562V42.765C352.52 39.1811 359.888 38.6781 365.379 39.7051C375.602 41.6018 381.937 48.759 385.782 55.6333L342.85 98.912H366.015L392.066 72.7038C394.035 77.8385 399.558 90.2772 408.759 95.6844C420.493 102.58 440 99.0273 440 99.0273V81.1185C430.767 84.7023 423.39 85.2053 417.908 84.1888Z"
        fill="var(--color-main)"
      />
      <path
        d="M133.102 45.2175C133.102 72.3689 135.613 83.6129 143.897 91.7552C158.746 106.877 186.892 101.836 198.083 82.062L202.523 74.3075V86.327V98.5457H211.203H219.883V61.6907V24.8566H211.401H202.919L202.147 42.6921C201.178 64.2161 196.166 76.047 185.183 81.6638C175.544 86.7043 162.623 85.1534 156.839 78.1743C152.786 73.5216 152.4 71.3838 152.4 48.8957V24.8566H142.762H133.123V45.2175H133.102Z"
        fill="var(--color-main)"
      />
    </svg>
  )
}
