import { navigatorGlobal } from '../constants'

export const getMobileOS = () => {
  if (!navigatorGlobal) return undefined

  const { userAgent } = navigatorGlobal

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (
    /iPad|iPhone|iPod/.test(userAgent) ||
    (navigatorGlobal.platform === 'MacIntel' &&
      navigatorGlobal.maxTouchPoints > 1)
  ) {
    return 'iOS'
  }

  return 'Other'
}
