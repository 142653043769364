import { graphql, useStaticQuery } from 'gatsby'

import type { CartSimulationQueryQuery } from '@generated/graphql'

interface CartSimulationSection {
  text: string
  mainSeller: string
}

const useCmsCartSimulation = () => {
  const data = useStaticQuery<CartSimulationQueryQuery>(graphql`
    query CartSimulationQuery {
      cmsCartSimulation {
        sections {
          name
          data
        }
      }
    }
  `)

  const cartSimulation: CartSimulationSection | undefined =
    data?.cmsCartSimulation?.sections?.find(
      ({ name }) => name === 'Postal Code Simulation'
    )?.data

  return {
    simulationPostalCode: cartSimulation?.text,
    mainSeller: cartSimulation?.mainSeller,
  }
}

export default useCmsCartSimulation
