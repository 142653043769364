import axios from 'axios'

export const orderFormProductsValidate = async (
  orderFormId: string,
  currentCartItems: { id: string; quantity: number }[]
) => {
  try {
    await axios.post('/api/orderForm/orderFormValidate', {
      orderFormId,
      currentCartItems,
    })
  } catch (err) {
    console.error('Error in Order form products validate,')
  }
}
