import React from 'react'

import Image404 from '../../../../assets/TabloidsNotFound.png'

import './styles.scss'

export const TabloidNotFound = () => {
  return (
    <div className="tabloid-container grid-content">
      <div className="message">
        <p className="notfound-title">Página não encontrada</p>
        <p className="notfound-text">
          Lamentamos, mas a página que você está tentando acessar não pode ser
          encontrada. Verifique se digitou corretamente o endereço.
        </p>
      </div>
      <div className="container-image">
        <img src={Image404} />
      </div>
    </div>
  )
}
