import React from 'react'

import { NotFoundProduct } from '../../../../assets/NotFoundProduct'
import './not-found-product.scss'

const ProductNotFound = () => {
  return (
    <div className="grid-content product-not-found-container">
      <h2>Em breve adicionaremos produtos</h2>
      <NotFoundProduct />
    </div>
  )
}

export default ProductNotFound
