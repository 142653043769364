import React from 'react'

import { useStoreContext } from '../../../contexts/StoreContext'

import './product-especifications.scss'

type ProductEspecificationsSealsProps = {
  text: string
}

export const ProductEspecificationsSeals = ({
  text,
}: ProductEspecificationsSealsProps) => {
  const { foodRestrictionSection } = useStoreContext()

  return (
    <>
      {foodRestrictionSection?.map(
        ({ specificationName, specificationColor, specificationLabel }) =>
          specificationName === text && (
            <p
              className="product-especifications-seals"
              key={specificationName}
              style={{ backgroundColor: specificationColor }}
              data-testid="product-especifications-seals-test"
            >
              {specificationLabel}
            </p>
          )
      )}
    </>
  )
}
