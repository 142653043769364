import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { Image } from '../Image'
import { validateLink } from './validateLink'

import type { ImageWithAlt } from '../../../types/ClubCard/ClubCardBenefits'

import './shortcut.scss'

export interface ShortcutProps {
  text: string
  icon: ImageWithAlt
  href: string
  location: string
  className?: string
  deepLink?: {
    android: string
    apple: string
  }
  isLoading?: boolean
  openNewTab?: boolean
}

export const Shortcut = ({
  text,
  icon,
  href,
  className = '',
  location,
  deepLink,
  isLoading = false,
  openNewTab = false,
}: ShortcutProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()
  if (!text || !icon?.src || !href) {
    return null
  }

  const link = validateLink({ deepLink, href })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    genericTaggingEvent('header_shortcut', {
      name: text,
      location: location,
    })

    window.location.href = link

    // Default redirect if deepLink doesn't work
    setTimeout(() => {
      if (document.hasFocus()) {
        window.location.href = href
      }
    }, 1000)
  }

  return (
    <Link
      to={link}
      target={openNewTab ? '_blank' : undefined}
      className={`shortcut ${isLoading ? 'loading' : ''} ${className}`}
      onClick={handleClick}
    >
      <div className="shortcut-image">
        <Image alt={icon?.alt} baseUrl={icon?.src} width={24} height={24} />
      </div>
      <p className="shortcut-text">{text}</p>
    </Link>
  )
}
