import { useMemo } from 'react'

import { useStoreContext } from '../contexts/StoreContext'

import type {
  PlpBannerBaseProps,
  PlpBannerCategory,
  PlpBannerSubcategory,
} from '../types/CMS'

type Banner = PlpBannerCategory | PlpBannerSubcategory | PlpBannerBaseProps

export const usePlpBanner = (slug?: string) => {
  const { plpBanners } = useStoreContext()

  const compareSlug = (sectionSlug: string) => {
    return sectionSlug === `/${slug}` || sectionSlug === `/colecao/${slug}`
  }

  const findBannerRecursively = (
    banners: Banner[]
  ): PlpBannerBaseProps | null => {
    for (const banner of banners) {
      if (compareSlug(banner.slug)) {
        return banner
      }

      if ('categorie' in banner && banner.categorie) {
        const categoryBanner = findBannerRecursively(banner.categorie)

        if (categoryBanner) {
          return categoryBanner
        }
      }

      if ('subcategorie' in banner && banner.subcategorie) {
        const subcategoryBanner = findBannerRecursively(banner.subcategorie)

        if (subcategoryBanner) {
          return subcategoryBanner
        }
      }
    }

    return null
  }

  const plpBanner = useMemo(() => {
    if (!slug || !plpBanners?.departmentOrCluster) {
      return null
    }

    return findBannerRecursively(plpBanners.departmentOrCluster)
  }, [slug, plpBanners])

  return { plpBanner }
}
