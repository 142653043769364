import React, { memo, useCallback, useEffect, useState } from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useSalesforceEvents } from '../../../hooks/useSalesforceEvents'
import { cartStore } from '../../../sdk/cart'
import CustomQuantitySelector from '../CustomQuantitySelector'
import './quantity-selector.scss'

interface QuantitySelectorProps {
  cartProductId: string
  sku: string
  max?: number
  min?: number
  initial?: number
  disabled?: boolean
  unitMultiplier?: number
  measurementUnit?: string
  hasPesavelUnd?: boolean
  location?: string
}

export function QuantitySelector({
  cartProductId,
  sku,
  max,
  min = 1,
  initial,
  disabled = false,
  unitMultiplier = 1,
  measurementUnit,
  hasPesavelUnd,
  location,
}: QuantitySelectorProps) {
  const [quantity, setQuantity] = useState<number>(initial ?? min)
  const { updateItemQuantity, removeItem } = cartStore
  const { removeItemEvent } = useAnalyticsEvents()
  const { removeItemEventSalesforce } = useSalesforceEvents()

  const handleUpdateQuantity = useCallback((quantityValue: number) => {
    if (quantityValue === 0) {
      removeItem(cartProductId)
      removeItemEvent(sku, location)
      removeItemEventSalesforce(sku)
    } else {
      updateItemQuantity(cartProductId, quantityValue)
    }
  }, [])

  const isLeftDisabled = quantity === min
  const isRightDisabled = quantity === max

  const changeQuantity = (increaseValue: number) => {
    const quantityValue = validateQuantityBounds(quantity + increaseValue)

    handleUpdateQuantity?.(quantityValue)
    setQuantity(quantityValue)
  }

  const increase = () => changeQuantity(1)

  const decrease = () => changeQuantity(-1)

  function validateQuantityBounds(n: number): number {
    const maxValue = min ? Math.max(n, min) : n

    return max ? Math.min(maxValue, max) : maxValue
  }

  function validateInput(e: React.FormEvent<HTMLInputElement>) {
    const val = e.currentTarget.value

    if (!Number.isNaN(Number(val))) {
      setQuantity(() => {
        const quantityValue = validateQuantityBounds(Number(val))

        handleUpdateQuantity?.(quantityValue)

        return quantityValue
      })
    }
  }

  const quantityProp = () => {
    if (unitMultiplier * 1000 * quantity >= 1000) {
      return (unitMultiplier * 1000 * quantity) / 1000
    }

    return unitMultiplier * 1000 * quantity
  }

  useEffect(() => {
    initial && setQuantity(initial)
  }, [initial])

  const hasUndQtd = hasPesavelUnd ? quantity : quantityProp()

  return (
    <CustomQuantitySelector
      measureUnit={measurementUnit}
      data-store-quantity-selector={disabled ? 'disabled' : 'true'}
      quantity={hasUndQtd}
      type="text"
      leftButtonProps={{
        onClick: decrease,
        disabled: isLeftDisabled || disabled,
        icon: (
          <svg
            width="13"
            height="28"
            viewBox="0 0 11 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.568324 3.336V0.767999H10.6243V3.336H0.568324Z"
              fill="var( --color-main)"
            />
          </svg>
        ),
      }}
      rightButtonProps={{
        onClick: increase,
        disabled: isRightDisabled || disabled,
        icon: (
          <svg
            width="15"
            height="28"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.952276 7.336V4.768H5.46428V0.615999H8.08028V4.768H12.5683V7.336H8.08028V11.488H5.46428V7.336H0.952276Z"
              fill="var( --color-main)"
            />
          </svg>
        ),
      }}
      inputProps={{
        onChange: validateInput,
        readOnly: false,
      }}
    />
  )
}

export default memo(QuantitySelector)
