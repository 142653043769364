interface MainContentParams {
  couponsLoading: boolean
  hasActiveCoupons: boolean
  hasClubRegister?: boolean
  hasClub?: boolean
}

type MainContent = 'loading' | 'coupons' | 'club-shelf' | 'default'

export const getMainContent = ({
  couponsLoading,
  hasActiveCoupons,
  hasClub,
  hasClubRegister,
}: MainContentParams): MainContent => {
  if (couponsLoading) {
    return 'loading'
  }

  if (hasActiveCoupons) {
    return 'coupons'
  }

  if (hasClubRegister && hasClub) {
    return 'club-shelf'
  }

  return 'default'
}
