import React from 'react'

export const NotFoundIcon = () => {
  return (
    <svg
      width="202"
      height="201"
      viewBox="0 0 202 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M104.127 0.0460473C52.6719 -1.5168 8.101 36.8863 1.36745 87.2763C-5.99536 142.371 34.5276 191.943 84.3889 199.882C146.408 209.758 206.563 152.626 201.162 90.6467C197.092 43.951 156.432 1.63454 104.127 0.0460473Z"
        fill="#F4F7FA"
      />
      <path
        d="M182.031 170.747L15.8914 185.748C13.4117 185.95 9.85303 182.67 9.85303 180.181V54.3649C9.85303 52.0356 11.7239 50.1387 14.052 50.1064L181.626 47.7882C184 47.7552 188.488 49.5064 188.488 51.8818L185.943 166.502C185.943 168.72 184.241 170.566 182.031 170.747Z"
        fill="url(#paint0_linear_28696_23693)"
      />
      <path
        d="M185.406 171.911L17.8318 185.589C15.3522 185.792 13.2271 183.833 13.2271 181.345V55.5284C13.2271 53.1992 15.098 51.3022 17.426 51.27L185 48.9524C187.374 48.9195 189.317 50.8355 189.317 53.2109V167.666C189.318 169.883 187.616 171.73 185.406 171.911Z"
        fill="url(#paint1_linear_28696_23693)"
      />
      <path
        d="M32.638 180.483L174.578 169.178C176.844 168.998 178.591 167.106 178.591 164.832V56.2413C178.591 53.8073 176.598 51.8444 174.165 51.8818L32.2256 54.0615C29.8448 54.0982 27.9336 56.0391 27.9336 58.4211V176.137C27.9329 178.682 30.1027 180.685 32.638 180.483Z"
        fill="url(#paint2_linear_28696_23693)"
      />
      <path
        d="M184.519 114.166C183.7 114.166 183.035 113.501 183.035 112.681V101.675C183.035 100.855 183.7 100.19 184.519 100.19C185.339 100.19 186.003 100.855 186.003 101.675V112.681C186.004 113.501 185.339 114.166 184.519 114.166Z"
        fill="url(#paint3_linear_28696_23693)"
      />
      <path
        opacity="0.9"
        d="M153.533 144.984C140.606 133.706 130.243 138.118 116.568 126.658C99.594 112.434 108.045 99.3176 93.103 86.7921C74.4172 71.1291 51.8387 83.8342 37.8164 68.1448C34.1463 64.0388 32.1032 59.1502 31.0308 54.2491C29.2361 54.7913 27.9321 56.4538 27.9321 58.4204V176.136C27.9321 178.681 30.1012 180.684 32.6373 180.483L168.556 169.657C166.072 161.222 161.646 152.062 153.533 144.984Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M158.81 109.292L48.388 113.45C46.5156 113.52 44.9941 112.165 44.9941 110.424V103.034C44.9941 101.302 46.509 99.856 48.3741 99.8025L158.376 96.6277C159.982 96.5815 161.327 97.8403 161.384 99.4405L161.624 106.269C161.68 107.877 160.422 109.232 158.81 109.292Z"
          fill="#97A6B7"
        />
        <path
          d="M52.8398 116.066L62.8515 152.682C63.4896 155.016 65.74 156.534 68.3039 156.364L142.673 151.446C144.987 151.292 146.949 149.586 147.44 147.31L155.04 112.034L52.8398 116.066ZM81.2069 149.527C81.223 151 79.9901 152.276 78.4518 152.374L75.7905 152.544C74.2433 152.643 72.9768 151.521 72.9643 150.04L72.7255 121.423C72.7131 119.968 73.9481 118.737 75.4799 118.673L78.1148 118.563C79.6385 118.5 80.8839 119.621 80.9 121.069L81.2069 149.527ZM95.8732 148.614C95.8952 150.073 94.6916 151.335 93.1825 151.432L90.5725 151.599C89.0554 151.695 87.8071 150.585 87.7888 149.117L87.4277 120.784C87.4094 119.343 88.6151 118.125 90.1176 118.062L92.7027 117.955C94.1971 117.892 95.4241 119.003 95.4461 120.436L95.8732 148.614ZM110.26 147.72C110.287 149.165 109.112 150.413 107.632 150.508L105.072 150.671C103.583 150.766 102.354 149.665 102.33 148.213L101.85 120.157C101.826 118.73 103.004 117.524 104.478 117.462L107.014 117.356C108.48 117.294 109.689 118.395 109.717 119.814L110.26 147.72ZM124.374 146.841C124.407 148.272 123.259 149.508 121.807 149.601L119.295 149.761C117.835 149.854 116.624 148.763 116.593 147.325L116 119.542C115.97 118.128 117.121 116.934 118.567 116.874L121.055 116.77C122.494 116.71 123.686 117.8 123.719 119.206L124.374 146.841ZM138.223 145.98C138.263 147.397 137.141 148.62 135.716 148.712L133.251 148.869C131.818 148.961 130.624 147.88 130.588 146.455L129.886 118.939C129.85 117.538 130.975 116.357 132.394 116.297L134.836 116.195C136.249 116.137 137.423 117.217 137.462 118.61L138.223 145.98Z"
          fill="#97A6B7"
        />
        <path
          d="M97.8474 63.6277C97.2921 63.0364 96.1655 63.1243 95.3304 63.8263L60.5536 93.0185C59.6621 93.7674 59.4093 94.8664 59.9917 95.4731C60.5733 96.079 61.7674 95.9611 62.6567 95.2115L97.3449 65.9694C98.1778 65.2667 98.4027 64.219 97.8474 63.6277Z"
          fill="#97A6B7"
        />
        <path
          d="M111.56 63.4921C112.089 62.9001 113.208 62.9814 114.057 63.6731L147.817 91.142C148.644 91.8139 148.907 92.8441 148.405 93.4435C147.903 94.0435 146.824 93.9849 145.996 93.3123L112.145 65.8199C111.292 65.1275 111.03 64.0849 111.56 63.4921Z"
          fill="#97A6B7"
        />
      </g>
      <path
        d="M135.052 111.864C133.558 128.017 119.252 140.779 103.049 140.366C86.7909 139.951 74.7889 126.437 76.2928 110.183C77.796 93.9285 92.2285 81.1708 108.477 81.6858C124.671 82.1995 136.547 95.7097 135.052 111.864Z"
        fill="url(#paint4_linear_28696_23693)"
      />
      <path
        d="M106.055 129.589C95.8195 129.589 87.4927 121.26 87.4927 111.023C87.4927 100.786 95.8195 92.4573 106.055 92.4573C116.29 92.4573 124.617 100.786 124.617 111.023C124.617 121.26 116.29 129.589 106.055 129.589ZM106.055 99.2861C99.5841 99.2861 94.32 104.551 94.32 111.023C94.32 117.495 99.5841 122.76 106.055 122.76C112.525 122.76 117.789 117.495 117.789 111.023C117.789 104.551 112.525 99.2861 106.055 99.2861Z"
        fill="white"
      />
      <path
        d="M97.4686 123.851C96.5983 123.851 95.728 123.52 95.0622 122.858C93.7253 121.528 93.7187 119.367 95.0475 118.029L113.578 99.3843C114.908 98.0464 117.069 98.0405 118.406 99.3697C119.743 100.7 119.75 102.861 118.421 104.199L99.8904 122.843C99.223 123.514 98.3454 123.851 97.4686 123.851Z"
        fill="white"
      />
      <g opacity="0.2">
        <path
          d="M103.248 132.584C101.119 128.817 105.427 125.717 105.603 118.204C105.869 106.795 96.201 102.658 97.4962 93.0339C97.8566 90.3581 99.1861 86.3554 104.175 81.8602C89.7539 83.4795 77.6625 95.38 76.2927 110.182C74.7895 126.436 86.7908 139.95 103.049 140.365C108.592 140.506 113.912 139.105 118.574 136.555C112.507 136.964 105.501 136.572 103.248 132.584Z"
          fill="url(#paint5_linear_28696_23693)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_28696_23693"
          x1="35.4829"
          y1="7.55858"
          x2="76.4785"
          y2="77.4774"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_28696_23693"
          x1="82.1289"
          y1="57.8018"
          x2="168.467"
          y2="325.923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_28696_23693"
          x1="27.9332"
          y1="116.189"
          x2="178.591"
          y2="116.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.494" stopColor="#EBEFF2" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_28696_23693"
          x1="196.643"
          y1="84.5791"
          x2="179.734"
          y2="116.085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_28696_23693"
          x1="76.4652"
          y1="108.321"
          x2="134.881"
          y2="113.723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_28696_23693"
          x1="76.4363"
          y1="108.632"
          x2="120.777"
          y2="112.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
