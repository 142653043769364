import React from 'react'

interface OrderStoreIconProps {
  width?: number
  height?: number
}
export const OrderStoreIcon = ({
  width = 21,
  height = 18,
}: OrderStoreIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5255 8.05V16C19.5255 16.55 19.3296 17.0208 18.938 17.4125C18.5463 17.8042 18.0755 18 17.5255 18H3.52545C2.97545 18 2.50462 17.8042 2.11295 17.4125C1.72129 17.0208 1.52545 16.55 1.52545 16V8.05C1.14212 7.7 0.846288 7.25 0.637954 6.7C0.429621 6.15 0.425454 5.55 0.625454 4.9L1.67545 1.5C1.80879 1.06667 2.04629 0.708333 2.38795 0.425C2.72962 0.141667 3.12545 0 3.57545 0H17.4755C17.9255 0 18.3171 0.1375 18.6505 0.4125C18.9838 0.6875 19.2255 1.05 19.3755 1.5L20.4255 4.9C20.6255 5.55 20.6213 6.14167 20.413 6.675C20.2046 7.20833 19.9088 7.66667 19.5255 8.05ZM12.7255 7C13.1755 7 13.5171 6.84583 13.7505 6.5375C13.9838 6.22917 14.0755 5.88333 14.0255 5.5L13.4755 2H11.5255V5.7C11.5255 6.05 11.6421 6.35417 11.8755 6.6125C12.1088 6.87083 12.3921 7 12.7255 7ZM8.22545 7C8.60879 7 8.92129 6.87083 9.16296 6.6125C9.40462 6.35417 9.52545 6.05 9.52545 5.7V2H7.57545L7.02545 5.5C6.95879 5.9 7.04629 6.25 7.28795 6.55C7.52962 6.85 7.84212 7 8.22545 7ZM3.77545 7C4.07545 7 4.33795 6.89167 4.56295 6.675C4.78795 6.45833 4.92545 6.18333 4.97545 5.85L5.52545 2H3.57545L2.57545 5.35C2.47545 5.68333 2.52962 6.04167 2.73795 6.425C2.94629 6.80833 3.29212 7 3.77545 7ZM17.2755 7C17.7588 7 18.1088 6.80833 18.3255 6.425C18.5421 6.04167 18.5921 5.68333 18.4755 5.35L17.4255 2H15.5255L16.0755 5.85C16.1255 6.18333 16.263 6.45833 16.488 6.675C16.713 6.89167 16.9755 7 17.2755 7ZM3.52545 16H17.5255V8.95C17.4421 8.98333 17.388 9 17.363 9H17.2755C16.8255 9 16.4296 8.925 16.088 8.775C15.7463 8.625 15.4088 8.38333 15.0755 8.05C14.7755 8.35 14.4338 8.58333 14.0505 8.75C13.6671 8.91667 13.2588 9 12.8255 9C12.3755 9 11.9546 8.91667 11.563 8.75C11.1713 8.58333 10.8255 8.35 10.5255 8.05C10.2421 8.35 9.91296 8.58333 9.53796 8.75C9.16296 8.91667 8.75879 9 8.32545 9C7.84212 9 7.40462 8.91667 7.01295 8.75C6.62129 8.58333 6.27545 8.35 5.97545 8.05C5.62545 8.4 5.27962 8.64583 4.93795 8.7875C4.59629 8.92917 4.20879 9 3.77545 9H3.66295C3.62129 9 3.57545 8.98333 3.52545 8.95V16Z"
        fill="var(--color-main)"
      />
    </svg>
  )
}
