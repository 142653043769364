import type { StoresProps, StoreProps } from '../types/StoreInfoProps'

export const formatStoreInfo = (storesInfo: StoresProps) => {
  const allCities = storesInfo?.map((store: StoreProps) => store.city)
  const citiesList = allCities?.filter(
    (city, index) => allCities?.indexOf(city) === index
  )

  const storeInfoSeparated = citiesList?.map((city) => {
    const stores = storesInfo?.filter((store) => store.city === city)

    return {
      city: stores?.[0]?.city,
      stores,
    }
  })

  return storeInfoSeparated
}
