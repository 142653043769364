import { cartStore } from '../../sdk/cart'

import type { CartItem } from '../../sdk/cart'
import type { ProductsQueryResponse } from '../../types/Product'

type ExternalListWithQuantity = { id: string; quantity: number }[]

export const addManyProductsToCart = (
  products: ProductsQueryResponse['node'][],
  externalListWithQuantity?: ExternalListWithQuantity // Used to add products with quantity from external sources
) => {
  products.forEach((product) => {
    let quantity = 1

    if (externalListWithQuantity) {
      const externalItem = externalListWithQuantity.find(
        ({ id }) => id === product.sku
      )

      if (!externalItem) {
        return
      }

      quantity = externalItem.quantity
    }

    const isOutOfStock = product.offers.offers.some(
      ({ availability }) => availability === 'https://schema.org/OutOfStock'
    )

    if (!isOutOfStock) {
      const {
        unitMultiplier,
        measurementUnit,
        id,
        sku,
        gtin,
        brand,
        sellers,
        categoryTree,
        priceWithoutPromotions,
        isVariantOf,
        isVariantOf: { name },
        image: [img],
        offers: {
          offers: [{ seller }],
        },
        hasClubPrice,
        customOffers,
        skuSpecifications,
        customOffers: { listPriceCustom, spotPriceCustom },
      } = product

      const buyProps: CartItem = {
        id,
        price: spotPriceCustom,
        listPrice: listPriceCustom,
        quantity,
        seller,
        itemOffered: {
          unitMultiplier,
          measurementUnit,
          sellers,
          categoryTree,
          hasClubPrice,
          priceWithoutPromotions,
          skuSpecifications,
          customOffers,
          sku,
          name,
          image: [img],
          brand,
          isVariantOf,
          gtin,
          additionalProperty: [],
        },
      }

      cartStore.addItem(buyProps)
    }
  })
}
