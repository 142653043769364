import React from 'react'

import ProductGrid from '../../product/ProductGrid'
import { ProductGridSkeleton } from '../../product/ProductGrid/ProductGridSkeleton'
import { NoDataError } from '../../sections/RenderErrors/NoDataError'
import { NavigationInstitutional } from '../../ui/NavigationInstitutional'

import type { ProductsQueryResponse } from '../../../types/Product'

import './products-by-ids-plp.scss'

interface ProductsByIdsPLPProps {
  title: string
  location: string
  loading: boolean
  products?: ProductsQueryResponse['node'][]
}

export function ProductsByIdsPLP({
  title,
  location,
  loading,
  products = [],
}: ProductsByIdsPLPProps) {
  return (
    <article className="grid-content products-by-ids-plp">
      <NavigationInstitutional titleText={title} />

      <h1>{title}</h1>

      {loading ? (
        <ul className="product-grid">
          <ProductGridSkeleton />
        </ul>
      ) : (
        <>
          {!products.length ? (
            <NoDataError />
          ) : (
            <ul className="product-grid">
              <ProductGrid location={location} page={1} products={products} />
            </ul>
          )}
        </>
      )}
    </article>
  )
}
