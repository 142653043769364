import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import { QuantitySelector } from '../../ui/QuantitySelector'

interface QuantitySelectorContainerProps {
  cartProductId: string
  cartProductQty: number
  unitMultiplier: number
  measurementUnit?: string
  hasPesavelUnd?: boolean
  location?: string
  isHorizontal?: boolean
}

export const QuantitySelectorContainer = ({
  cartProductId,
  cartProductQty,
  unitMultiplier,
  measurementUnit,
  hasPesavelUnd,
  location,
  isHorizontal,
}: QuantitySelectorContainerProps) => {
  const sku = cartProductId.replace(/:.*$/, '')

  const quantitySelectorHeavyProps = {
    measurementUnit:
      unitMultiplier * 1000 * cartProductQty >= 1000 ? 'kg' : 'g',
    unitMultiplier,
  }

  return (
    <div className="input-add" data-testid="quantity-selector-container">
      {measurementUnit ? (
        <>
          {!isHorizontal && (
            <div className="product-add-text">
              <p>Produto adicionado</p>
            </div>
          )}
          <QuantitySelector
            cartProductId={cartProductId}
            sku={sku}
            {...(measurementUnit !== 'un' &&
              !hasPesavelUnd &&
              quantitySelectorHeavyProps)}
            min={0}
            initial={cartProductQty}
            hasPesavelUnd={hasPesavelUnd}
            location={location}
          />
        </>
      ) : (
        <Skeleton
          width={100}
          height={40}
          backgroundColor="var(--color-grayScale-faded)"
        />
      )}
    </div>
  )
}
