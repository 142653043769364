import React, { useMemo } from 'react'

import { hasPlusFriendBadge } from '../../../../../../devFeatureToggle.json'
import { pesavelUND } from '../../../../constants'
import { useClubContext } from '../../../../contexts/ClubContext'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { useBuyProduct } from '../../../../hooks/useBuyProduct'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { formatUndQtd, formatUnd } from '../../../../utils/formatPrices'
import { ProductCardActions } from '../../../product/ProductCard/components/ProductCardActions'
import { ProductCardPrices } from '../../../product/ProductCard/components/ProductCardPrices'
import { Image } from '../../../ui/Image'
import { ProductCardTopFlag } from '../../../ui/ProductCardTopFlag'
import { ProductCardTopFlagImage } from '../../../ui/ProductCardTopFlagImage'

import type { ProductsQueryResponse } from '../../../../types/Product'

import './search-products-card.scss'

interface SearchProductsCardProps {
  product: ProductsQueryResponse['node']
  location: string
  defaultScListPrice?: number
  clubPrice?: number
}

export const SearchProductsCard = ({
  product: staleProduct,
  location,
  defaultScListPrice,
  clubPrice,
}: SearchProductsCardProps) => {
  const { skuSpecifications } = staleProduct

  const { isMobile } = useWindowDimensions()
  const { productData, buyProps, cartProductId, cartProductQty } =
    useBuyProduct(staleProduct)

  const { hasClub } = useStoreContext()
  const { isClubClient } = useClubContext()

  const {
    product: {
      measurementUnit,
      unitMultiplier,
      isVariantOf: { name },
      image: productImages,
      offers: {
        offers: [{ availability }],
      },
      customOffers: {
        listPriceCustom,
        spotPriceKg,
        spotPriceCustom,
        hasDiscount,
      },
    },
  } = productData

  const buyDisabled = availability !== 'https://schema.org/InStock'
  const isClubValid = !!(hasClub && isClubClient && defaultScListPrice)

  const hasPesavelUnd = skuSpecifications?.some((specification) => {
    return (
      specification.field.originalName === pesavelUND &&
      specification.values.some((value) => value.originalName === 'true')
    )
  })

  const hasUndQtd = useMemo(
    () => formatUndQtd(hasPesavelUnd, unitMultiplier),
    [hasPesavelUnd, unitMultiplier]
  )

  const hasUnd = useMemo(
    () => formatUnd(hasPesavelUnd, unitMultiplier, measurementUnit),
    [hasPesavelUnd, measurementUnit, unitMultiplier]
  )

  if (buyDisabled) {
    return null
  }

  return (
    <div className="search-products-card-container">
      <div className="search-products-card-image-container">
        {hasPlusFriendBadge && isClubValid ? (
          <ProductCardTopFlagImage />
        ) : !hasPlusFriendBadge && isClubValid ? (
          <ProductCardTopFlag
            colorCode="var(--color-badge-main)"
            flagText="Clube"
            colorText="var(--color-badge-secondary)"
          />
        ) : null}

        <Image
          className="search-products-card-image"
          baseUrl={productImages[0].url}
          layout="fullWidth"
          objectFit="contain"
          alt={name}
          sourceWidth={isMobile ? 58 : 80}
          sourceHeight={isMobile ? 58 : 80}
          options={{
            fitIn: true,
          }}
          loading="lazy"
        />
      </div>

      <div className="search-products-card-content">
        <h2 className="search-products-card-name">{name}</h2>
        <div className="search-products-card-footer">
          <ProductCardPrices
            listPriceCustom={listPriceCustom}
            spotPriceKg={spotPriceKg}
            spotPriceCustom={spotPriceCustom}
            hasDiscount={hasDiscount}
            unitMultiplier={unitMultiplier}
            measurementUnit={measurementUnit}
            clubPrice={clubPrice}
            defaultScListPrice={defaultScListPrice}
            hasPesavelUnd={hasPesavelUnd}
            hasUndQtd={hasUndQtd}
            hasUnd={hasUnd}
          />

          <ProductCardActions
            product={staleProduct}
            setOpenClubModal={() => null}
            location={location}
            hasPesavelUnd={hasPesavelUnd}
            clubPrice={clubPrice}
            buyProps={buyProps}
            cartProductId={cartProductId}
            cartProductQty={cartProductQty}
          />
        </div>
      </div>
    </div>
  )
}
