import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import ProductDetails from '../components/sections/ProductDetails'
import {
  ProductShelfSkeleton,
  ProductShelf,
} from '../components/sections/ProductShelf'
import { useGetProduct } from '../hooks/useGetProduct'
import { replaceStringBreadcrumb } from '../utils/replaceStrings'

import type { ClusterItem } from '../types/Cluster'

import '../styles/pages/product-page.scss'

interface ProductPageProps {
  loading: boolean
  cluster: ClusterItem
  slug: string
}

export const ProductPage = ({ loading, cluster, slug }: ProductPageProps) => {
  const { product, loading: productLoading } = useGetProduct(slug)

  if (productLoading) {
    return (
      <div className="product-details-skeleton">
        <Skeleton width={600} height={500} borderRadius={8} animation />
        <div className="product-details-skeleton-price">
          <Skeleton width={500} height={30} borderRadius={8} animation />
          <Skeleton width={500} height={30} borderRadius={8} animation />
          <Skeleton width={500} height={30} borderRadius={8} animation />
        </div>
      </div>
    )
  }

  if (!product) {
    return <></>
  }

  return (
    <div className="grid-content product_page-content">
      <ProductDetails product={product} />

      <div className="shelf-in-pdp" style={{ width: '100%', margin: '40px 0' }}>
        {loading ? (
          <ProductShelfSkeleton />
        ) : (
          !!(cluster?.id && cluster?.name) && (
            <ProductShelf
              selectedFacets={{
                key: 'productClusterIds',
                value: cluster.id,
              }}
              title={cluster.name}
              link={`/colecao/${replaceStringBreadcrumb(cluster.name)}`}
              id="vitrine-pdp"
            />
          )
        )}
      </div>
    </div>
  )
}
