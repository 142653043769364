import type { FaqData } from '../types/FAQ'

interface UniqueQuestion {
  question: string
  answer: string
}

export const extractQuestions = (faqData: FaqData): string[] =>
  Object.values(faqData.topics).flatMap((topic) =>
    topic.questions.map((question: UniqueQuestion) => question.question)
  )

export const formatSearchedquestions = (
  extractedQuestions?: string[],
  questionSearch?: string
) => {
  if (!extractedQuestions || !questionSearch) return

  return extractedQuestions.filter((question) =>
    question.toLowerCase().includes(questionSearch?.toLowerCase())
  )
}

export const accountCardNames: { [key: string]: string } = {
  boa: 'Cartão Boa',
  smrholding: 'Cartão Smrholding',
  compremais: 'Cartão Compre Mais',
  superpao: 'Cartão Superpão',
  avenidab2c: 'Cartão Avenida',
  domolivio: 'Cartão Dom Olívio',
}
