import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { usePlpBanner } from '../../../hooks/usePlpBanner'
import { PlpBanner } from '../PlpBanner'

import type { ItemElement } from '../../ui/Breadcrumb'

interface ProductGalleryHeaderProps {
  itemListElement?: ItemElement[]
  slug?: string
}

export const ProductGalleryHeader = ({
  itemListElement,
  slug,
}: ProductGalleryHeaderProps) => {
  const { plpBanner } = usePlpBanner(slug)
  const { selectPromotion } = useAnalyticsEvents()

  return (
    <div className="section-header">
      {itemListElement && itemListElement.length >= 1 && (
        <h1>{itemListElement?.[itemListElement.length - 1]?.name}</h1>
      )}

      {plpBanner &&
        (plpBanner.href ? (
          <Link
            to={plpBanner.href}
            className="banner-container"
            onClick={() =>
              selectPromotion({
                href: plpBanner.href,
                promotionName: plpBanner.promotionName,
                locale: window.location.href,
              })
            }
          >
            <PlpBanner plpBanner={plpBanner} />
          </Link>
        ) : (
          <PlpBanner plpBanner={plpBanner} />
        ))}
    </div>
  )
}
