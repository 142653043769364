import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react'

import { HAS_WINDOW } from '../constants'

export interface SearchOverlayContextProps {
  children?: React.ReactNode
  visibleSearchOverlay?: boolean
  setVisibleSearchOverlay?: (visibleSearchOverlay: boolean) => void
}

const SearchOverlayContext = createContext<SearchOverlayContextProps | null>(
  null
)

export const SearchOverlayContextProvider = ({
  children,
}: SearchOverlayContextProps) => {
  const [visibleSearchOverlay, setVisibleSearchOverlay] = useState(false)

  useEffect(() => {
    if (!HAS_WINDOW) {
      return
    }

    if (visibleSearchOverlay) {
      document.getElementById('layout')?.classList.add('no-scroll')
    } else {
      document.getElementById('layout')?.classList.remove('no-scroll')
    }
  }, [visibleSearchOverlay])

  const values = useMemo(
    () => ({ visibleSearchOverlay, setVisibleSearchOverlay }),
    [visibleSearchOverlay, setVisibleSearchOverlay]
  )

  return (
    <SearchOverlayContext.Provider value={values}>
      {children}
    </SearchOverlayContext.Provider>
  )
}

export const useSearchOverlayContext = () => {
  const context = useContext(SearchOverlayContext)

  if (!context) {
    throw new Error(
      'useSearchOverlayContext must be used within a SearchOverlayContextProvider'
    )
  }

  return context
}
