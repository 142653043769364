import { graphql, useStaticQuery } from 'gatsby'

interface LoyaltyClubTexts {
  beClient: string
  clubName: string
  modalTitle: string
  warning: string
}

interface LoyaltyClubBanner {
  src: string
  alt: string
}

interface LoyaltyClubDownloadImages {
  'image-mobile': string
  alt: string
  androidAppButton: string
  iOSAppButton: string
  androidAppLink: string
  iOSAppLink: string
}

const useCMSLoyaltyClub = () => {
  const data = useStaticQuery(graphql`
    query LoyaltyClubQuery {
      cmsLoyaltyClub {
        sections {
          data
        }
      }
    }
  `)

  const loyaltyClubTexts: LoyaltyClubTexts | undefined =
    data?.cmsLoyaltyClub?.sections?.[0]?.data

  const loyaltyClubBanner: LoyaltyClubBanner | undefined =
    data?.cmsLoyaltyClub?.sections?.[1]?.data

  const loyaltyClubDownloadImages: LoyaltyClubDownloadImages | undefined =
    data?.cmsLoyaltyClub?.sections?.[2]?.data

  return {
    loyaltyClubTexts,
    loyaltyClubBanner,
    loyaltyClubDownloadImages,
  }
}

export default useCMSLoyaltyClub
