import Loadable from '@loadable/component'
import React from 'react'

import type { ItemElement } from '../../ui/Breadcrumb'

type ProductGalleryProps = {
  title: string
  slug?: string
  itemListElement?: ItemElement[]
  dynamicShowcaseSkus?: string
}

const ProductGallery = Loadable(() => import('../ProductGallery'))

function ProductListing({
  title,
  slug,
  itemListElement,
  dynamicShowcaseSkus,
}: ProductGalleryProps) {
  return (
    <div className="product-listing / grid-content-full">
      <ProductGallery
        title={title}
        slug={slug}
        itemListElement={itemListElement}
        dynamicShowcaseSkus={dynamicShowcaseSkus}
      />
    </div>
  )
}

export default ProductListing
