import React from 'react'

interface SearchDeleteIconProps {
  width?: number
  height?: number
  color?: string
}

const SearchDeleteIcon = ({
  height = 16,
  width = 16,
  color = 'var(--color-main-dark)',
}: SearchDeleteIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4L4 12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4L12 12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchDeleteIcon
