import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

import useCmsFeatureToggle from './useCmsFeatureToggle'

const useCmsPaymentMethods = () => {
  const data = useStaticQuery(graphql`
    query PaymentMethods {
      cmsPaymentMethods {
        sections {
          data
        }
      }
    }
  `)

  const hasPaymentMethods = useCmsFeatureToggle()?.hasPaymentMethods

  const paymentMethods: { icon: string; name: string }[] | undefined =
    useMemo(() => {
      if (!hasPaymentMethods) {
        return null
      }

      return data?.cmsPaymentMethods?.sections?.[0]?.data?.methods
    }, [hasPaymentMethods])

  return { paymentMethods }
}

export default useCmsPaymentMethods
