import React from 'react'

import type { Icon } from '../../types/FAQ'

export const CardIcon = ({
  width = 28,
  height = 22,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M2.66675 21.6666C2.13341 21.6666 1.66675 21.4666 1.26675 21.0666C0.866748 20.6666 0.666748 20.2 0.666748 19.6666V2.33331C0.666748 1.79998 0.866748 1.33331 1.26675 0.933313C1.66675 0.533313 2.13341 0.333313 2.66675 0.333313H25.3334C25.8667 0.333313 26.3334 0.533313 26.7334 0.933313C27.1334 1.33331 27.3334 1.79998 27.3334 2.33331V19.6666C27.3334 20.2 27.1334 20.6666 26.7334 21.0666C26.3334 21.4666 25.8667 21.6666 25.3334 21.6666H2.66675ZM2.66675 10.2666H25.3334V5.96665H2.66675V10.2666Z"
          fill={color}
        />
      ) : (
        <path
          d="M27.3333 2.33331V19.6666C27.3333 20.2 27.1333 20.6666 26.7333 21.0666C26.3333 21.4666 25.8666 21.6666 25.3333 21.6666H2.66663C2.13329 21.6666 1.66663 21.4666 1.26663 21.0666C0.866626 20.6666 0.666626 20.2 0.666626 19.6666V2.33331C0.666626 1.79998 0.866626 1.33331 1.26663 0.933313C1.66663 0.533313 2.13329 0.333313 2.66663 0.333313H25.3333C25.8666 0.333313 26.3333 0.533313 26.7333 0.933313C27.1333 1.33331 27.3333 1.79998 27.3333 2.33331ZM2.66663 5.96665H25.3333V2.33331H2.66663V5.96665ZM2.66663 10.2666V19.6666H25.3333V10.2666H2.66663Z"
          fill={color}
        />
      )}
    </svg>
  )
}
