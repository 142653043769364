import React from 'react'

import SearchIcon from '../../../../assets/SearchIcon'

import './not-found-products.scss'

interface Props {
  actualTerm: string
  handleRedirect: () => void
}

export const NotFoundProducts = ({ actualTerm, handleRedirect }: Props) => {
  return (
    <section className="telesales-not-found-container grid-content">
      <SearchIcon width={110} height={110} color="#8D8D8D" />

      <h1>Nenhum resultado encontrado com o termo "{actualTerm}"</h1>

      <span>
        Sem problemas, você pode alterar sua lista de compras e fazer uma nova
        busca.
      </span>

      <button onClick={handleRedirect}>Alterar lista de compras</button>
    </section>
  )
}
