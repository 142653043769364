import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React, { useCallback, useEffect, useState } from 'react'

import { HAS_WINDOW } from '../../../constants'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Button from '../../ui/Button/Button'
import './styles.scss'

export interface FacetValue {
  label: string
  value: string
}

interface NavigationBarProps {
  layoutLoading?: boolean
  tabOptions: FacetValue[]
  selectedTab: string
  handleTabItem: (label: string, value: string) => void
}

export const NavigationBar = ({
  layoutLoading,
  tabOptions,
  selectedTab,
  handleTabItem,
}: NavigationBarProps) => {
  const [topOffset, setTopOffset] = useState(0)
  const [width, setWidth] = useState(0)
  const { width: widthHook, isMobile } = useWindowDimensions()

  const handleHeaderSize = useCallback(() => {
    const header = document.getElementById('main-header')?.offsetHeight ?? 0
    const subHeader =
      document.getElementById('common-desktop-navigation-render')
        ?.offsetHeight ?? 0

    const headerSize = header + subHeader

    if (topOffset !== headerSize) {
      setTopOffset(headerSize - 4)
    }
  }, [width, topOffset])

  useEffect(() => {
    window.addEventListener('scroll', handleHeaderSize)

    return () => window.removeEventListener('scroll', handleHeaderSize)
  }, [])

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  if (layoutLoading) {
    return (
      <div className="navigation-bar" data-testid="loading-skeleton">
        <div className="navigation-bar-content / grid-content">
          <Skeleton
            table={{ rows: 1, columns: 5 }}
            height={20}
            backgroundColor="var(--color-grayScale-faded)"
          />
        </div>
      </div>
    )
  }

  const handleTopPage = () => {
    if (!HAS_WINDOW) {
      return
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div
      className="navigation-bar"
      style={{ top: topOffset }}
      data-testid="navigation-bar"
    >
      <div className="navigation-bar-content / grid-content">
        <div style={{ width: '100%' }}>
          <Carousel
            gapItems={isMobile ? 16 : 24}
            arrow={{
              isVisible: width >= 1280,
              iconColor: 'var(--color-main)',
              style: {
                width: '40px',
                height: '40px',
                cursor: 'pointer',
                margin: '7px',
                backgroundColor: 'var(--color-main-faded)',
              },
            }}
            bullet={{ isVisible: false }}
          >
            {tabOptions?.map(({ label, value }) => (
              <div
                key={label}
                className={`navigation-bar-content-item ${
                  selectedTab === label ? 'selected' : ''
                }`}
              >
                <Button
                  className={`button${
                    selectedTab === label ? '-selected' : '-default'
                  }`}
                  onClick={() => {
                    handleTopPage()
                    handleTabItem(label, value)
                  }}
                >
                  {label}
                </Button>

                {selectedTab === label && (
                  <div className="select-bottom-highlight" />
                )}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
