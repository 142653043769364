import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React, { useState, useMemo } from 'react'

import { useRegionalizationContext } from '../../../../contexts/RegionalizationContext'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { useGetProductsByIds } from '../../../../hooks/useGetProductsByIds'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import {
  separateKitsSkus,
  separateProductsByKit,
} from '../../../../utils/kitsFunctions'
import { clearTaggingTitle } from '../../../../utils/tagging/clearTaggingTitle'
import { GenericModal } from '../../../ui/GenericModal'
import { SuggestedKitList } from '../SuggestedKitList'
import { SuggestedKitsCard } from '../SuggestedKitsCard'
import { SuggestedKitView } from '../SuggestedKitView'

import type { SuggestedKits } from '../../../../types/CMS'
import './suggested-kits-carousel.scss'

interface SuggestedKitsCarouselProps {
  data?: SuggestedKits
}

export type ModalScreen = 'kitList' | 'kitView'

export const SuggestedKitsCarousel = ({
  data: suggestedKits,
}: SuggestedKitsCarouselProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalScreen, setModalScreen] = useState<ModalScreen>('kitView')
  const [selectedKitIndex, setSelectedKitIndex] = useState(0)
  const { width } = useWindowDimensions()
  const { genericTaggingEvent } = useAnalyticsEvents()
  const { openRegionModal } = useRegionalizationContext()

  const productSkus = useMemo(
    () => (suggestedKits ? separateKitsSkus(suggestedKits) : []),
    [suggestedKits]
  )

  const { loading, products } = useGetProductsByIds(productSkus)

  const productsSeparatedByKit = useMemo(
    () => suggestedKits && separateProductsByKit(suggestedKits, products),
    [suggestedKits, products]
  )

  const selectedKit = useMemo(
    () => productsSeparatedByKit?.[selectedKitIndex],
    [selectedKitIndex, productsSeparatedByKit]
  )

  const arrow = {
    isVisible: !!(width && width > 1280),
    iconColor: 'var(--color-main)',
    style: {
      margin: '5px',
      cursor: 'pointer',
      backgroundColor: 'var(--color-main-faded)',
    },
  }

  const bullet = {
    bulletEnableColor: 'var(--color-main)',
    bulletDisableColor: 'var(--color-grayScale-lightest)',
    isVisible: true,
    style: {
      width: 8,
      height: 8,
      margin: '0px 2px',
      borderRadius: '50%',
      padding: 0,
    },
  }

  if (loading || !productsSeparatedByKit) {
    return (
      <section className="suggested-kits-skeleton grid-content">
        <Skeleton
          width="100%"
          height={280}
          backgroundColor="var(--color-grayScale-faded)"
          borderRadius={8}
        />
      </section>
    )
  }

  if (
    !suggestedKits?.kits?.length ||
    !suggestedKits?.isActive ||
    suggestedKits?.kits.some((item) =>
      item.skus.some((skuItem) => typeof skuItem !== 'string')
    )
  ) {
    return null
  }

  return (
    <section className="suggested-kits-carousel-container grid-content shelf-section-grid shelf-section-content-grid">
      <div className="suggested-kits-carousel-header">
        <h2 className="suggested-kits-carousel-title">Kits Pra Você</h2>
        <button
          className="suggested-kits-carousel-view-more"
          onClick={() => {
            setIsOpenModal(true)
            setModalScreen('kitList')
            genericTaggingEvent('ver_mais', {
              ecommerce: { name: 'Ver Mais Kits Pra Você' },
            })
          }}
        >
          Ver mais
        </button>
      </div>

      <Carousel arrow={arrow} bullet={bullet} gapItems={8}>
        {suggestedKits.kits.map(({ image, title }, index) => {
          const handleGenericTaggingEvent = () =>
            genericTaggingEvent('kits_pra_voce', {
              ecommerce: { name: clearTaggingTitle(title) },
            })

          return (
            <SuggestedKitsCard
              image={image}
              title={title}
              setSelectedKitIndex={setSelectedKitIndex}
              setIsOpenModal={setIsOpenModal}
              setModalScreen={setModalScreen}
              index={index}
              key={title}
              handleGenericTaggingEvent={handleGenericTaggingEvent}
            />
          )
        })}
      </Carousel>

      <GenericModal
        isOpenModal={isOpenModal && !openRegionModal}
        onClickOverlay={() => setIsOpenModal(false)}
        className="suggested-kits-modal"
        overlayAriaLabel="suggested-kits-modal"
      >
        {modalScreen === 'kitView' ? (
          <SuggestedKitView
            setIsOpenModal={setIsOpenModal}
            setModalScreen={setModalScreen}
            selectedKit={selectedKit}
          />
        ) : (
          <SuggestedKitList
            setSelectedKitIndex={setSelectedKitIndex}
            setModalScreen={setModalScreen}
            suggestedKits={suggestedKits}
            setIsOpenModal={setIsOpenModal}
            genericTaggingEvent={genericTaggingEvent}
          />
        )}
      </GenericModal>
    </section>
  )
}
