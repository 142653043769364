import React from 'react'

import { TabloidCheckIcon } from '../../../../assets/TabloidCheckIcon'
import './share-tabloid-link.scss'

export const FeedbackPopup = () => {
  return (
    <div className="feedback-popup-container">
      <TabloidCheckIcon />
      <span className="feedback-popup-text">Link do folheto copiado</span>
    </div>
  )
}
