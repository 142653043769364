import React from 'react'

import Button from '../../../ui/Button'

import './multiple-search-more-button.scss'

interface MultipleSearchMoreButtonProps {
  hasPagination?: boolean
  loading?: boolean
  nextPage?: () => void
}

export const MultipleSearchMoreButton = ({
  hasPagination,
  loading,
  nextPage,
}: MultipleSearchMoreButtonProps) => {
  if (!hasPagination) {
    return null
  }

  return (
    <div className="grid-content ">
      <div className="multiple-search-more-button-container">
        <Button
          disabled={loading}
          className="multiple-search-more-button"
          onClick={() => nextPage?.()}
        >
          <span>{loading ? 'Reunindo Produtos...' : 'Ver mais'}</span>
        </Button>
      </div>
    </div>
  )
}
