import { Skeleton } from '@acctglobal/skeleton'
import React, { useState, useEffect } from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import {
  showSection,
  defaultVisibilityConfig,
} from '../../../../utils/showSection'
import { Banner } from '../../Banner'

import type { CampaignBannerData } from '../../../../types/Campaigns'
import type { GenericBannerInfos } from '../../../../types/CMS'
import type { showSectionVariables } from '../../../../utils/showSection'

import './campaign-banner.scss'

export interface CampaignBannerProps extends CampaignBannerData {
  loading: boolean
  mobile?: GenericBannerInfos
  desktop?: GenericBannerInfos
  isActive?: boolean
  redirect?: string
  promotionName?: string
  visibilityConfig?: showSectionVariables
}

export const CampaignBanner = (props: CampaignBannerProps) => {
  const {
    alt,
    src,
    srcMobile,
    loading,
    redirect,
    desktop,
    mobile,
    isActive,
    promotionName = 'banner campanha',
    visibilityConfig = defaultVisibilityConfig,
  } = props
  const [screenWidth, setScreenWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    if (widthHook) {
      setScreenWidth(widthHook)
    }
  }, [widthHook])

  if (!isActive) return

  if (loading) {
    return (
      <div
        className="campaign-banner-loading"
        data-testid="campaign-banner-loading"
      >
        <Skeleton
          backgroundColor="var(--color-grayScale-faded)"
          width="100%"
          height={screenWidth <= 768 ? 168 : 336}
        />
      </div>
    )
  }

  if (
    !alt ||
    !(desktop?.src ?? src) ||
    !(mobile?.src ?? srcMobile) ||
    !showSection(visibilityConfig)
  )
    return

  const dataBanner = {
    visibility: true,
    href: redirect,
    mobile: {
      width: mobile?.width ?? 768,
      height: mobile?.height ?? 168,
      src: mobile?.src ?? srcMobile,
    },
    desktop: {
      width: desktop?.width ?? 1920,
      height: desktop?.height ?? 336,
      src: desktop?.src ?? src,
    },
    alt,
    promotionName,
  }

  return <Banner data={dataBanner} className="campaign-banner-container" />
}
