import './offers.scss'

import React from 'react'

import { AdaptiveCarousel } from '../../../../components/common/AdaptiveCarousel'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { OfferCard } from '../../../ui/OfferCard'

import type { Offers as OffersProps } from '../../../../types/ClubCard/ClubCardUser'

export const Offers = ({
  visibility,
  buttonText,
  title,
  subtitle,
  tag,
  list,
}: OffersProps) => {
  const { width } = useWindowDimensions()
  const isMobileWidth = width && width < 768

  if (!visibility || !list?.length) {
    return null
  }

  return (
    <div className="club-card-user-offers grid-content">
      {title && (
        <DynamicTitle className="offers-title" titleLevel={2} title={title} />
      )}
      {subtitle && (
        <DynamicTitle
          className="offers-subTitle"
          titleLevel={3}
          title={subtitle}
        />
      )}
      <AdaptiveCarousel
        quantity={list?.length}
        propsCarousel={{
          gapItems: 0,
          qtyItems: width && width < 640 ? 2 : 4,
          itemWidthProp: isMobileWidth ? 162 : 265,
        }}
        activeBlur={false}
        quantityToCarousel={5}
        forceCarousel={!!(width && width <= 1024)}
      >
        {list?.map((itemOffer) => (
          <OfferCard
            key={itemOffer?.text}
            buttonText={buttonText}
            tag={tag}
            {...itemOffer}
          />
        ))}
      </AdaptiveCarousel>
    </div>
  )
}
