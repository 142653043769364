import React from 'react'

import './loading.scss'
import { Spinner } from '../../../assets/Spinner'

interface LoadingProps {
  title?: string
  message?: string
}

export const Loading = ({ title = '', message = '' }: LoadingProps) => {
  return (
    <div className="loading-container">
      <div className="texts-container">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>

      <div className="spinner-container">
        <Spinner />
      </div>
    </div>
  )
}
