import React from 'react'

import { WarningIcon } from '../../../../assets/WarningIcon'
import './tabloid-warning.scss'

export const TabloidWarning = () => (
  <p className="tabloid-warning">
    <WarningIcon />O folheto que você está vendo não está de acordo com a sua
    localização. Preços e produtos podem variar.
  </p>
)
