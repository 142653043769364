import React from 'react'

import { CleanMultipleSearchIcon } from '../../../../assets/CleanMultipleSearchIcon'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'

import './clean-multiple-search.scss'

interface CleanMultipleSearchProps {
  text: string
  setTMultipleTerms: (multipleTerms: string[]) => void
}

export const CleanMultipleSearch = ({
  text,
  setTMultipleTerms,
}: CleanMultipleSearchProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()

  const sendEventClearList = () => {
    return genericTaggingEvent('remover_todos_produtos_lista', {
      click_text: 'Remover todos os produtos',
    })
  }

  return (
    <div className="clean-multiple-search-container">
      <div className="clean-multiple-search-icon">
        <CleanMultipleSearchIcon />
      </div>
      <button
        className="clean-multiple-search-button"
        onClick={() => {
          setTMultipleTerms([])
          sessionStorage.removeItem('multipleSearchTerms')
          sendEventClearList()
        }}
      >
        {text}
      </button>
    </div>
  )
}
