/* eslint-disable vtex/prefer-early-return */
import React, { useEffect, useState } from 'react'

import { HAS_WINDOW } from '../../../constants'
import { inView } from '../../../utils/InView'

interface Props {
  children: React.ReactNode
  trigger: () => void
  id?: string
  singleActivation?: boolean
  className?: string
  referenceId?: string
  isProductShelf?: boolean
}

const WhenVisible = ({
  children,
  trigger,
  id,
  singleActivation,
  className,
  referenceId,
  isProductShelf = false,
}: Props) => {
  const [hasTriggered, setHasTriggered] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleVisibility = () => {
    // eslint-disable-next-line vtex/prefer-early-return
    if (referenceId && id && !isProductShelf) {
      const idReference =
        document?.getElementById?.(referenceId)?.getBoundingClientRect().top ??
        0

      const currentItemReference =
        document?.getElementById?.(id)?.getBoundingClientRect().top ?? 0

      const headerHeight =
        document.getElementById('main-header')?.offsetHeight ?? 0

      if (
        idReference - currentItemReference <= headerHeight + 30 &&
        idReference - currentItemReference >= -120 &&
        inView(id)
      ) {
        trigger()
      }

      return
    }

    if (inView(id) && singleActivation) {
      setVisible(true)
    }

    if (inView(id) && !singleActivation) {
      trigger()
    }
  }

  useEffect(() => {
    if (!HAS_WINDOW || hasTriggered) {
      return
    }

    if (id && isProductShelf) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setHasTriggered(true)
              trigger()
              observer.unobserve(entry.target)
            }
          })
        },
        {
          root: null, // Usa a viewport como área de observação
          rootMargin: '0px', // Margem adicional (pode ser ajustado conforme necessário)
          threshold: 0.5,
        }
      )

      const targetElement = document.getElementById(id)

      if (targetElement) {
        observer.observe(targetElement)
      }
    }

    window.addEventListener('scroll', handleVisibility)

    return () => window.removeEventListener('scroll', handleVisibility)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HAS_WINDOW, hasTriggered])

  useEffect(() => {
    if (visible && singleActivation && !isProductShelf) {
      trigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, singleActivation, isProductShelf])

  return (
    <div id={id} className={className}>
      {children}
    </div>
  )
}

export default WhenVisible
