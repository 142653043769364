import loadable from '@loadable/component'
import { useLocation } from '@reach/router'
import React, { useState } from 'react'

import { MaintenanceStore } from './components/sections/MaintenanceStore'
import { RestrictMode } from './components/sections/RestrictMode/RestrictMode'
import { SearchOverlay } from './components/ui/SearchOverlay/SearchOverlay'
import { clubHeaderPaths } from './constants'
import { ClubContextProvider } from './contexts/ClubContext'
import { CardContextProvider } from './contexts/CardContext'
import { CmsLandingPageProvider } from './contexts/CmsLandingPage'
import { FreeShippingRulerProvider } from './contexts/FreeShippingRulerContext'
import { MultiplePageContextProvider } from './contexts/MultipleSearchContext'
import { SearchOverlayContextProvider } from './contexts/SearchOverlayContext'
import { StoreContextProvider } from './contexts/StoreContext'

import type { StoreContextProps } from './contexts/StoreContext'

import '@plurix/ecom-components/dist/styles/carousel.global.css'

const CommonHeaderRender = loadable(
  () => import('./components/common/Renders/CommonHeaderRender')
)

const CommonModalRender = loadable(
  () => import('./components/common/Renders/CommonModalRender')
)

const CommonFooterRender = loadable(
  () => import('./components/common/Renders/CommonFooterRender')
)

const CommonDesktopNavigationRender = loadable(
  () => import('./components/common/Renders/CommonDesktopNavigationRender')
)

function Layout({
  account,
  secureSubdomain,
  checkoutUrl,
  loginUrl,
  accountUrl,
  salesChannels,
  chatUrl,
  devFeatureToggle,
  featureToggle,
  logo,
  allCategories,
  menuFirst,
  menuSecond,
  departments,
  socialMediaLinks,
  storeInfos,
  pricesAndHours,
  cookiesText,
  loyaltyClubTexts,
  loyaltyClubBanner,
  loyaltyClubDownloadImages,
  loyaltyMosaicList,
  footerPaymentMethods,
  plpBanners,
  children,
  freeShippingBaseValue,
  notificationsBar,
  downloadModal,
  salesWhatsappOffers,
  utmPromotionBond,
  maintenanceStore,
  restrictedMode,
  sections,
  foodRestrictionSection,
  storageRestrictionSection,
  scratchPageContent,
}: StoreContextProps) {
  const [developmentMode, setDevelopmentMode] = useState(false)
  const location = useLocation()
  const isClubHeader = clubHeaderPaths.some((path) =>
    location.pathname.includes(path)
  )

  if (
    process.env.NODE_ENV === 'production' &&
    !developmentMode &&
    account === 'smrholding'
  ) {
    return (
      <RestrictMode
        passKey={restrictedMode?.passKey}
        setDevelopmentMode={setDevelopmentMode}
      />
    )
  }

  if (maintenanceStore?.isActive) {
    return (
      <MaintenanceStore
        isActive={maintenanceStore?.isActive}
        logo={maintenanceStore?.logo}
        maintenanceImage={maintenanceStore?.maintenanceImage}
      />
    )
  }

  return (
    <div id="layout">
      <StoreContextProvider
        // store infos
        account={account}
        secureSubdomain={secureSubdomain}
        checkoutUrl={checkoutUrl}
        loginUrl={loginUrl}
        accountUrl={accountUrl}
        salesChannels={salesChannels}
        chatUrl={chatUrl}
        devFeatureToggle={devFeatureToggle}
        // cms
        featureToggle={featureToggle}
        logo={logo}
        allCategories={allCategories}
        footerPaymentMethods={footerPaymentMethods}
        menuFirst={menuFirst}
        menuSecond={menuSecond}
        departments={departments}
        socialMediaLinks={socialMediaLinks}
        storeInfos={storeInfos}
        pricesAndHours={pricesAndHours}
        cookiesText={cookiesText}
        loyaltyClubTexts={loyaltyClubTexts}
        loyaltyClubBanner={loyaltyClubBanner}
        loyaltyClubDownloadImages={loyaltyClubDownloadImages}
        loyaltyMosaicList={loyaltyMosaicList}
        plpBanners={plpBanners}
        freeShippingBaseValue={freeShippingBaseValue}
        notificationsBar={notificationsBar}
        downloadModal={downloadModal}
        salesWhatsappOffers={salesWhatsappOffers}
        utmPromotionBond={utmPromotionBond}
        maintenanceStore={maintenanceStore}
        sections={sections}
        foodRestrictionSection={foodRestrictionSection}
        storageRestrictionSection={storageRestrictionSection}
        scratchPageContent={scratchPageContent}
      >
        <FreeShippingRulerProvider>
          <ClubContextProvider>
            <CardContextProvider>
              <MultiplePageContextProvider>
                <SearchOverlayContextProvider>
                  <CmsLandingPageProvider>
                    <CommonHeaderRender isClubHeader={isClubHeader} />
                    <main>
                      <SearchOverlay />
                      {!isClubHeader && <CommonDesktopNavigationRender />}
                      {children}
                      <CommonFooterRender />
                      <CommonModalRender />
                    </main>
                  </CmsLandingPageProvider>
                </SearchOverlayContextProvider>
              </MultiplePageContextProvider>
            </CardContextProvider>
          </ClubContextProvider>
        </FreeShippingRulerProvider>
      </StoreContextProvider>
    </div>
  )
}

export default Layout
