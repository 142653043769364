import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import { useAnotherScPrices } from '../../../../hooks/useAnotherScPrices'
import { useGetProductsByIds } from '../../../../hooks/useGetProductsByIds'
import { useProductWithLowStock } from '../../../../hooks/useProductWithLowStock'
import ProductCard from '../../../product/ProductCard'

import type { CampaignpProductGrid } from '../../../../types/Campaigns'
import './campaign-product-grid-card.scss'

export const CampaignProductGrid = ({
  productGrid,
  loading,
}: CampaignpProductGrid) => {
  const { products, loading: productsLoading } = useGetProductsByIds(
    productGrid.map(({ sku }) => sku)
  )

  const { productsWithLowStock, loading: productsWithLowStockLoading } =
    useProductWithLowStock(products)

  const { getAnotherScPrices } = useAnotherScPrices(productsWithLowStock)

  if (productsLoading || loading || productsWithLowStockLoading) {
    return (
      <section className="grid-content">
        <Skeleton
          margin={8}
          backgroundColor="var(--color-grayScale-faded)"
          table={{ columns: 4, rows: 1 }}
          height={400}
        />
      </section>
    )
  }

  const productsInStock = productsWithLowStock?.filter(
    (product) =>
      product?.offers?.offers?.[0]?.availability ===
      'https://schema.org/InStock'
  )

  if (!productsInStock?.length) return null

  return (
    <section className="grid-content">
      <div className=" campaignProductGridContainer">
        {productsInStock.map((item) => {
          const isAvailable =
            item?.offers?.offers?.[0]?.availability ===
            'https://schema.org/InStock'

          if (!isAvailable) {
            return null
          }

          const anotherScPrices = getAnotherScPrices(item.sku)

          return (
            <div
              key={`product-card-${item?.id}`}
              className="campaignProductGridCard"
            >
              <ProductCard
                titleLevel={3}
                product={item}
                location="landing-page"
                {...anotherScPrices}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}
