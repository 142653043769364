export const formatCategoryList = (categoryList?: string[]) => {
  const categories = categoryList?.map((categoryLine: string) => {
    const parts = categoryLine.split('/')
    const last = parts.pop()

    return last !== '' ? last : parts.pop()
  })

  return categories
}
