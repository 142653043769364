import React from 'react'

import { Shortcut, PointsShortcut } from '../../../../components/ui/Shortcut'

import type { Shortcuts as ShortcutsProps } from '../../../../types/ClubCard/ClubCardUser'

import './shortcuts.scss'

const LIMIT_SHORTCUTS = 6

export const Shortcuts = ({ visibility, list, location }: ShortcutsProps) => {
  if (!visibility || !list?.length) {
    return null
  }

  const listShortcuts = list.slice(0, LIMIT_SHORTCUTS)

  return (
    <div
      className={`club-card-user-shortcuts shortcuts-${listShortcuts.length}`}
    >
      {listShortcuts?.map((itemShortcut, index) => {
        if (itemShortcut.refKey === 'Points') {
          return (
            <PointsShortcut
              key={`${itemShortcut.label}-${index}`}
              location={location}
              {...itemShortcut}
            />
          )
        }

        return (
          <Shortcut
            key={`${itemShortcut.label}-${index}`}
            text={itemShortcut.label}
            icon={itemShortcut.icon}
            href={itemShortcut.href}
            location={location}
            deepLink={itemShortcut.deepLink}
          />
        )
      })}
    </div>
  )
}
