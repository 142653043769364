import { emptyOrder } from '../constants'

import type { PhysicalOrdersResponse, OrderItem } from '../types/RepeatOrder'

export const formatPhysicalOrder = (
  physicalOrder: PhysicalOrdersResponse | undefined
) => {
  if (!physicalOrder) {
    return { data: { customerOrder: emptyOrder } }
  }

  const formattedOrder = {
    data: {
      customerOrder: {
        items: [] as OrderItem[],
        totals: [
          { id: 'Items', value: 0 },
          { id: 'Discounts', value: 0 },
          { id: 'Shipping', value: 0 },
          { id: 'Tax', value: 0 },
        ],
        creationDate: `${physicalOrder.purchaseDate}T03:00:00Z`,
        orderId: physicalOrder.transactionId,
      },
    },
  }

  physicalOrder.products.forEach(({ items }) => {
    items.forEach(({ itemId: id, quantity, name }) => {
      formattedOrder.data.customerOrder.items.push({
        id,
        quantity,
        name,
      })
    })
  })

  return formattedOrder
}
