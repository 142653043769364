import React from 'react'

import { FAQUsefulLinkCards } from '../FAQUsefulLinkCards'
import './faq-links.scss'

interface FAQLinksProps {
  insideAccordion?: boolean
}

export const FAQLinks = ({ insideAccordion }: FAQLinksProps) => {
  return (
    <section className="faq-links-container">
      {!insideAccordion && (
        <>
          <h2 className="faq-links-title">Links Úteis</h2>
          <FAQUsefulLinkCards />
        </>
      )}
    </section>
  )
}
