import axios from 'axios'

interface GetLowProductsProps {
  postalCode?: string
  salesChannel?: string
  skus?: string[]
}

interface GetLowProductsData {
  lowStockCmsMessage: string
  skus: { sku: string; hasLowStock: boolean }[]
}

export const getLowProducts = async ({
  postalCode,
  salesChannel,
  skus,
}: GetLowProductsProps) => {
  try {
    const { data }: { data: GetLowProductsData } = await axios.post(
      `/api/products/getLowProducts`,
      {
        postalCode,
        salesChannel,
        skus,
      }
    )

    return data
  } catch (err) {
    throw new Error(`request low products data: ${err.message}`)
  }
}
