import React, { useEffect } from 'react'

import type { ReactNode, CSSProperties } from 'react'
import './generic-modal.scss'

interface GenericModalProps {
  children: ReactNode
  isOpenModal?: boolean
  onClickOverlay: () => void
  className?: string
  overlayStyles?: CSSProperties
  modalContainerStyles?: CSSProperties
  overlayAriaLabel?: string
}

export const GenericModal = ({
  children,
  isOpenModal,
  onClickOverlay,
  className,
  overlayStyles,
  modalContainerStyles,
  overlayAriaLabel,
}: GenericModalProps) => {
  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpenModal])

  if (!isOpenModal) {
    return null
  }

  return (
    <div
      className={`generic-modal-container ${className || ''}`}
      onClick={onClickOverlay}
      aria-label={overlayAriaLabel}
      style={overlayStyles}
    >
      <div
        className="generic-modal-subcontainer"
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          event.stopPropagation()
        }
        style={modalContainerStyles}
      >
        {children}
      </div>
    </div>
  )
}
