export async function scrollToElement(
  element: string | null,
  offset: number,
  isHeader = false
) {
  if (!element || typeof window === 'undefined') {
    return
  }

  try {
    const scrollY = window?.scrollY ?? 0
    const elementToScroll = document.querySelector(element)
    const elementToScrollTop =
      elementToScroll?.getBoundingClientRect()?.top ?? 0

    const topNavigationHeight =
      document.querySelector('#common-desktop-navigation-render')
        ?.clientHeight ?? 0

    const headerHeight =
      document.querySelector('#main-header')?.clientHeight ?? 0

    const offsetTotal = isHeader
      ? topNavigationHeight + headerHeight + offset
      : offset

    window?.scrollTo({
      top: elementToScrollTop + scrollY - offsetTotal,
      behavior: 'smooth',
    })
  } catch (error) {
    console.error('Could not scroll to element:', error)
  }
}
