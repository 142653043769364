import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'

import { useRegionalizationContext } from './RegionalizationContext'
import { useFreeShippingRuler } from '../hooks/useFreeShippingRuler'

import type { GetFreeShipping } from '../types/FreeShippingRuler'

interface ShippingRulerContextProps {
  children?: React.ReactNode
  hasFreeShipping?: boolean
  setHasFreeShipping?: (hasFreeShipping?: boolean) => void
  shippingBaseValue?: GetFreeShipping['shippingBaseValue']
  valueForFreeShipping?: GetFreeShipping['valueForFreeShipping']
  isValidating?: boolean
  isDelivery?: boolean
  resquestLoading?: boolean
}

const FreeShippingRulerContext =
  createContext<ShippingRulerContextProps | null>(null)

export const FreeShippingRulerProvider = ({
  children,
}: ShippingRulerContextProps) => {
  const [hasFreeShipping, setHasFreeShipping] = useState<boolean | undefined>(
    false
  )

  const {
    shippingBaseValue,
    valueForFreeShipping,
    isValidating,
    resquestLoading,
  } = useFreeShippingRuler()

  const { regionalized } = useRegionalizationContext()

  const isDelivery = regionalized === 'delivery'

  useEffect(() => {
    if (shippingBaseValue > 0) {
      setHasFreeShipping(true)
    } else {
      setHasFreeShipping(false)
    }
  }, [shippingBaseValue])

  const exportValues = useMemo(() => {
    return {
      hasFreeShipping,
      setHasFreeShipping,
      shippingBaseValue,
      valueForFreeShipping,
      isValidating,
      isDelivery,
      resquestLoading,
    }
  }, [
    hasFreeShipping,
    setHasFreeShipping,
    shippingBaseValue,
    valueForFreeShipping,
    isValidating,
    isDelivery,
    resquestLoading,
  ])

  return (
    <FreeShippingRulerContext.Provider value={exportValues}>
      {children}
    </FreeShippingRulerContext.Provider>
  )
}

export const useFreeShippingRulerContext = () => {
  const context = useContext(FreeShippingRulerContext)

  if (!context) {
    throw new Error(
      'useFreeShippingRulerContext must be used within a StoreContextProvider'
    )
  }

  return context
}
