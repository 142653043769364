import './accordion-v2.scss'

import React, { useState } from 'react'

import { AccordionV2 } from './AccordionV2'

import type { HandleSelectAccordionOption } from './AccordionV2'

interface AccordionV2ListProps<TAccordion> {
  listAccordion: TAccordion[]
  render: (accordion: TAccordion) => {
    title: string
    contentInternal: React.ReactNode
  }
  handleSelect?: HandleSelectAccordionOption
}

export function AccordionV2List<TAccordion>({
  listAccordion,
  render,
  handleSelect,
}: AccordionV2ListProps<TAccordion>) {
  const [selectedTitle, setSelectedTitle] = useState<string>()

  return (
    <div className="accordionV2-list">
      {listAccordion?.map((itemAccordion) => {
        const accordion = render(itemAccordion)

        return (
          <AccordionV2
            key={accordion?.title}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
            title={accordion?.title}
            handleSelect={handleSelect}
          >
            {accordion?.contentInternal}
          </AccordionV2>
        )
      })}
    </div>
  )
}
