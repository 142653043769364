import React from 'react'

import { ScratchClubClient } from '..'

import type { ScratchClubClientProps } from '../ScratchClubClient/ScratchClubClient'

import './scratch-participate.scss'

type ScratchParticipateProps = ScratchClubClientProps

export const ScratchParticipate = (props: ScratchParticipateProps) => {
  return (
    <div className="scratch-participate">
      <ScratchClubClient {...props} />
    </div>
  )
}
