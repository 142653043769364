import React, { useEffect, useState } from 'react'

import { InstitutionalBanner } from './InstitutionalBanner'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { NavigationInstitutional } from '../../ui/NavigationInstitutional'
import './institutional.scss'

type InstitutionalBannerWithCarousel =
  | string
  | string[]
  | InstitucionalCarouselImages // single banner or an array of banners

type InstitutionalBanner = string | string[] // single banner or an array of banners

export type InstitucionalCarouselImages = [{ image: string; link: string }]

export interface InstitutionalBanners {
  srcTopImageMobile: InstitutionalBannerWithCarousel
  srcTopImage: InstitutionalBannerWithCarousel
  srcBottomImageMobile: InstitutionalBanner
  srcBottomImage: InstitutionalBanner
  alt: string
}

export interface InstitutionalProps {
  children: React.ReactNode
  title: string
  banners?: InstitutionalBanners[]
}

export const Institutional = ({
  children,
  title,
  banners,
}: InstitutionalProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  return (
    <div className="institutional-container grid-content">
      <div>
        <NavigationInstitutional titleText={title} />
      </div>

      <h3 className="institutional-title">{title}</h3>

      <InstitutionalBanner banners={banners} width={width} position="Top" />

      {children}

      <InstitutionalBanner banners={banners} width={width} position="Bottom" />
    </div>
  )
}
