import React from 'react'

import { TabLayout } from '../../../common/TabLayout/TabLayout'

import type { CampaignShelfButtonsData } from '../../../../types/Campaigns'

export const CampaignShelfButtons = ({
  banners,
  loading,
}: CampaignShelfButtonsData) => {
  return (
    <TabLayout
      data={banners}
      loading={loading}
      shelfTypeDefault={banners?.[0].shelfType}
      shelfValueDefault={banners?.[0].shelfValue}
    />
  )
}
