import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import { Link } from 'gatsby'
import React from 'react'

import { dynamicShowcasesInfos } from './dynamicShowcasesInfos'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../utils/carouselProps'
import { replaceStringBreadcrumb } from '../../../utils/replaceStrings'
import ProductCard from '../../product/ProductCard'
import WhenVisible from '../../ui/WhenVisible/WhenVisible'

import type { ProductsQueryResponse } from '../../../types/Product'

interface DynamicShowcaseCarouselProps {
  id: string
  title: string
  products: ProductsQueryResponse['node'][]
}

export const DynamicShowcaseCarousel = ({
  id,
  title,
  products,
}: DynamicShowcaseCarouselProps) => {
  const { width } = useWindowDimensions()
  const { viewItemListEvent, genericTaggingEvent } = useAnalyticsEvents()
  const { getAnotherScPrices } = useAnotherScPrices(products)

  if (!width) {
    return <Skeleton height={500} />
  }

  const nonRecommendationSkus: string[] = []

  const productsInStock = products.filter((product) => {
    // If the product is not a recommendation, add its SKU to the list used to generate the link
    if (!product.isRecommendation) {
      nonRecommendationSkus.push(product.sku)
    }

    return (
      product?.offers?.offers?.[0]?.availability ===
      'https://schema.org/InStock'
    )
  })

  if (productsInStock.length <= 0) {
    return null
  }

  const { firstNonRecommendationIndex, firstRecommendationIndex, classes } =
    dynamicShowcasesInfos(productsInStock)

  const link = `/${replaceStringBreadcrumb(
    title
  )}?dynamicShowcaseSkus=${nonRecommendationSkus.join(',')}`

  const props = carouselProps(width, undefined, undefined, {
    backgroundColor: 'var(--color-main)',
  })

  const handleViewItemList = () => {
    viewItemListEvent(products, id)
  }

  const handleSeeAll = () => {
    genericTaggingEvent('see_all', { name: id })
  }

  return (
    <>
      <div className="carousel-product-header">
        <h3 className="carousel-product-header-title">{title}</h3>
        <Link to={link} onClick={handleSeeAll} data-store-link>
          Ver Tudo
        </Link>
      </div>

      <div className="div-content-shelf-product">
        <WhenVisible id={id} trigger={handleViewItemList} singleActivation>
          <Carousel {...props}>
            {productsInStock.map((product, index) => {
              const anotherScPrices = getAnotherScPrices(product.sku)

              return (
                <div
                  key={product.id}
                  data-is-recommendation={product.isRecommendation}
                  className={classes[index]}
                >
                  {index === firstNonRecommendationIndex && (
                    <h4>Compre Novamente</h4>
                  )}

                  {index === firstRecommendationIndex && (
                    <h4>Recomendações para Você</h4>
                  )}

                  <ProductCard
                    product={product}
                    location={id}
                    titleLevel={5}
                    {...anotherScPrices}
                  />
                </div>
              )
            })}
          </Carousel>
        </WhenVisible>
      </div>
    </>
  )
}
