import React from 'react'

export const FiltersIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="3.14746"
      y1="1"
      x2="3.14746"
      y2="4.85294"
      stroke="white"
      strokeLinecap="round"
    />
    <line
      x1="3.14746"
      y1="13.353"
      x2="3.14746"
      y2="15.0001"
      stroke="white"
      strokeLinecap="round"
    />
    <circle cx="2.86765" cy="10.4265" r="2.36765" stroke="white" />
    <line
      x1="10.9707"
      y1="15"
      x2="10.9707"
      y2="11.1471"
      stroke="white"
      strokeLinecap="round"
    />
    <line
      x1="10.9707"
      y1="2.64697"
      x2="10.9707"
      y2="0.999914"
      stroke="white"
      strokeLinecap="round"
    />
    <circle
      cx="11.2505"
      cy="5.57351"
      r="2.36765"
      transform="rotate(-180 11.2505 5.57351)"
      stroke="white"
    />
  </svg>
)
