import React, { useEffect, useState } from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { Image } from '../../../ui/Image'

interface ProductCardImageProps {
  imageUrl: string
  imageAlt: string
}

export const ProductCardImage = ({
  imageUrl,
  imageAlt,
}: ProductCardImageProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  return (
    <div className="card-image">
      <Image
        objectFit="contain"
        baseUrl={imageUrl}
        layout="fullWidth"
        sourceWidth={width <= 768 ? 96 : 144}
        sourceHeight={width <= 768 ? 96 : 144}
        backgroundColor="var(--color-grayScale-faded)"
        options={{
          fitIn: true,
        }}
        alt={imageAlt}
        loading="lazy"
      />
    </div>
  )
}
