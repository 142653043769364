import { Skeleton } from '@acctglobal/skeleton'
import React, { useMemo } from 'react'

import { useAnotherScPrices } from '../../../../../../../hooks/useAnotherScPrices'
import useWindowDimensions from '../../../../../../../hooks/useWindowDimensions'
import ProductCard from '../../../../../../product/ProductCard'

import type { ProductsQueryResponse } from '../../../../../../../types/Product'

interface ProductContainerProps {
  requestLoading: boolean
  products: ProductsQueryResponse[]
}

export const ProductContainer = ({
  requestLoading,
  products: productsList = [],
}: ProductContainerProps) => {
  const { width } = useWindowDimensions()

  const products = productsList.map(({ node }) => node)
  const { getAnotherScPrices } = useAnotherScPrices(products)

  const tableSkeletonProp = useMemo(() => {
    if (width) {
      if (width < 768) {
        return { rows: 2, columns: 2 }
      }

      if (width < 1280) {
        return { rows: 2, columns: 4 }
      }

      return { rows: 2, columns: 5 }
    }

    return undefined
  }, [width])

  if (!width || width <= 0 || (requestLoading && !products?.length)) {
    return (
      <Skeleton
        table={tableSkeletonProp}
        height={395}
        backgroundColor="var(--color-grayScale-faded)"
      />
    )
  }

  return (
    <div className="restrictionProductsPage-productContent-products">
      {products?.map((product) => {
        const anotherScPrices = getAnotherScPrices(product.sku)

        return (
          <ProductCard
            key={product.id}
            product={product}
            bordered
            location="restrição-alimentar"
            titleLevel={3}
            {...anotherScPrices}
          />
        )
      })}
    </div>
  )
}
