import type { SliderState } from '@faststore/ui'
import type React from 'react'

export const customCarouselEvents = (
  autoPlay: boolean | undefined,
  setPause: React.Dispatch<React.SetStateAction<boolean>>,
  transition: {
    duration: number
    property: string
  },
  sliderState: SliderState,
  childrenCount: number,
  sliderDispatch: React.Dispatch<any>
) => {
  // handle events
  const onMouseEnter = () => {
    if (autoPlay) {
      setPause(false)
    }
  }

  const onMouseLeave = () => {
    if (autoPlay) {
      setPause(true)
    }
  }

  // transitions
  const slidingTransition = `${transition.property} ${transition.duration}ms`

  const transitionStyle = sliderState.sliding ? slidingTransition : undefined

  const onTransitionEnd = () => {
    sliderDispatch({
      type: 'STOP_SLIDE',
    })
    if (sliderState.currentItem >= childrenCount) {
      sliderDispatch({
        type: 'GO_TO_PAGE',
        payload: {
          pageIndex: 0,
          shouldSlide: false,
        },
      })
    }

    if (sliderState.currentItem < 0) {
      sliderDispatch({
        type: 'GO_TO_PAGE',
        payload: {
          pageIndex: sliderState.totalPages - 1,
          shouldSlide: false,
        },
      })
    }
  }

  return {
    onMouseEnter,
    onMouseLeave,
    transitionStyle,
    onTransitionEnd,
  }
}
