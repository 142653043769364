import React from 'react'

interface BoldTextPartsProps {
  text: string
  highlightChar?: string
}

export function BoldTextParts({
  text,
  highlightChar = '*',
}: BoldTextPartsProps) {
  const regex = new RegExp(`\\${highlightChar}(.*?)\\${highlightChar}`, 'g') // get expressions between highlightChar
  const formattedText = text.replace(regex, '<strong>$1</strong>')

  return <p dangerouslySetInnerHTML={{ __html: formattedText }} />
}
