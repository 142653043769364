import axios from 'axios'

import { emptyAdress } from '../constants'

import type {
  DeliveryInfos,
  HandleStatusProps,
  GetSellerResponse,
  AddressCDN,
  Productlist,
} from '../types/Regionalization'
import type { Address } from '@plurix/ecom-components'
import type { CartSimulationBody, CartSimulationResponse } from 'src/types/Club'

const formatCurrency = (value?: number) => {
  const num = !value ? 0.0 : value / 100

  return num
}

export const deliveryInfosObjectConvert = (item?: DeliveryInfos[]) => {
  return {
    deliveryPrice: formatCurrency(item?.[0]?.listPrice),
    deliveryName: item?.[0]?.deliveryChannel,
    shippingEstimate: item?.[0]?.shippingEstimate,
    method: item?.[0]?.deliveryChannel,
  }
}

export const shippingInfosResolve = (data?: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  logisticsInfo?: { slas: object[] }[]
}) => {
  const validateSlas = data?.logisticsInfo?.filter?.((item) => {
    return item?.slas?.length > 0
  })

  const deliveryChannelFilter: DeliveryInfos[] | undefined =
    validateSlas?.[0]?.slas?.filter?.((item: { deliveryChannel?: string }) => {
      return item?.deliveryChannel === 'delivery'
    })

  const deliveryInfos = deliveryInfosObjectConvert(deliveryChannelFilter)

  return { deliveryInfos, price: deliveryInfos?.deliveryPrice }
}

export const statusMessage = (
  status: 'FAILED' | 'SUCCESS' | 'LOADING' | 'AVAILABILITY'
) => {
  const menssage: HandleStatusProps = {
    AVAILABILITY: {
      status: 'failed',
      loading: false,
      message: 'Que Pena, ainda não entregamos na sua região.',
    },
    FAILED: {
      loading: false,
      message: 'Ocorreu um erro',
      status: 'failed',
    },
    SUCCESS: {},
    LOADING: { loading: true, message: 'Carregando...' },
  }

  return menssage[status]
}

export const updateDeliveryMethod = async (
  method: string,
  orderFormId: string
) => {
  try {
    const { status } = await axios.patch('/api/updateDeliveryMethod', {
      method,
      orderFormId,
    })

    return status
  } catch (err) {
    if (err.response.status !== 304) {
      throw new Error(`Um erro ocorreu: ${err.response.status}`)
    }

    return {}
  }
}

export const getSeller = async (postalCode: string) => {
  try {
    const { data } = await axios.patch<GetSellerResponse>('/api/getSeller', {
      inputValue: postalCode,
    })

    const sellerId: string | undefined = data?.[0]?.sellers?.[0]?.id

    return { hasValidSeller: Boolean(sellerId), sellerId }
  } catch {
    return { hasValidSeller: false, sellerId: '' }
  }
}

export const getAddress = async (postalCode: string) => {
  try {
    const { data: viacepAddress }: { data: Address } = await axios.get(
      `https://viacep.com.br/ws/${postalCode}/json/`
    )

    // default validation
    if (viacepAddress.localidade) {
      return { address: viacepAddress }
    }

    // validation if viacep doesn't find the address
    const { data: cdnAddress }: { data: AddressCDN } = await axios.get(
      `https://cdn.apicep.com/file/apicep/${postalCode}.json`
    )

    // format in the same model than viacep response
    const cdnAddressFormated: Address = {
      ...emptyAdress,
      localidade: cdnAddress.city,
      uf: cdnAddress.state,
      cep: cdnAddress.code,
      logradouro: cdnAddress.address ?? cdnAddress.city,
    }

    return { address: cdnAddressFormated }
  } catch (err) {
    try {
      const { data: brasilAPIAddress } = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${postalCode}`
      )

      const brasilAPIAddressFormated: Address = {
        ...emptyAdress,
        localidade: brasilAPIAddress.city,
        uf: brasilAPIAddress.state,
        cep: brasilAPIAddress.cep,
        logradouro: brasilAPIAddress.street ?? brasilAPIAddress.city,
        bairro: brasilAPIAddress.neighborhood,
      }

      return { address: brasilAPIAddressFormated }
    } catch {
      return { address: undefined }
    }
  }
}

export const cartSimulation = async (
  postalCode: string,
  productList?: Productlist
) => {
  const itemSimulation = {
    id: productList?.edges?.[0]?.node?.id ?? '',
    quantity: 1,
    seller: '1' as const,
  }

  const cartSimulationBody: CartSimulationBody = {
    items: [itemSimulation],
    country: 'BRA',
    postalCode,
    allItemsInTheSameSimulation: true,
  }

  const simulation = await axios
    .post<CartSimulationResponse>('/api/cartSimulation', cartSimulationBody)
    .then((reponse) => shippingInfosResolve(reponse.data))
    .catch((err) => {
      throw new Error(`Um erro ocorreu: ${err}`)
    })

  return simulation
}

export const handleDeliveryPrice = async (
  productList?: Productlist,
  postalCode?: string,
  setDeliveryPrice?: (price: number) => void
) => {
  if (productList && postalCode) {
    const price = await cartSimulation(postalCode, productList)
      .then((res) => res.price)
      .catch((err) => {
        throw new Error(`Um erro ocorreu: ${err.response.status}`)
      })

    setDeliveryPrice?.(price)
  }
}
