import React from 'react'

import type { Icon } from '../../types/FAQ'

export const CartIcon = ({
  width = 27,
  height = 29,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M7.66665 28.3333C6.99998 28.3333 6.43332 28.1 5.96665 27.6333C5.49998 27.1667 5.26665 26.6 5.26665 25.9333C5.26665 25.2667 5.49998 24.7 5.96665 24.2333C6.43332 23.7667 6.99998 23.5333 7.66665 23.5333C8.33332 23.5333 8.89998 23.7667 9.36665 24.2333C9.83332 24.7 10.0667 25.2667 10.0667 25.9333C10.0667 26.6 9.83332 27.1667 9.36665 27.6333C8.89998 28.1 8.33332 28.3333 7.66665 28.3333ZM21.1333 28.3333C20.4667 28.3333 19.9 28.1 19.4333 27.6333C18.9667 27.1667 18.7333 26.6 18.7333 25.9333C18.7333 25.2667 18.9667 24.7 19.4333 24.2333C19.9 23.7667 20.4667 23.5333 21.1333 23.5333C21.8 23.5333 22.3667 23.7667 22.8333 24.2333C23.3 24.7 23.5333 25.2667 23.5333 25.9333C23.5333 26.6 23.3 27.1667 22.8333 27.6333C22.3667 28.1 21.8 28.3333 21.1333 28.3333ZM14 11.5333C13.6889 11.5333 13.4222 11.4222 13.2 11.2C12.9778 10.9778 12.8666 10.7111 12.8666 10.4C12.8666 10.0889 12.9778 9.82222 13.2 9.59999C13.4222 9.37777 13.6889 9.26666 14 9.26666C14.3111 9.26666 14.5778 9.37777 14.8 9.59999C15.0222 9.82222 15.1333 10.0889 15.1333 10.4C15.1333 10.7111 15.0222 10.9778 14.8 11.2C14.5778 11.4222 14.3111 11.5333 14 11.5333ZM13 7V0.333328H15V7H13ZM7.66665 21.4333C6.73332 21.4333 6.04998 21.0556 5.61665 20.3C5.18332 19.5444 5.18887 18.7778 5.63332 18L7.66665 14.3L2.66665 3.66666H0.0666504V1.66666H3.93332L9.59998 13.8H19.3333L24.5333 4.46666L26.2667 5.4L21.1667 14.6333C20.9667 15.0111 20.6944 15.3 20.35 15.5C20.0055 15.7 19.6 15.8 19.1333 15.8H9.13332L7.06665 19.4333H23.5333V21.4333H7.66665Z"
          fill={color}
        />
      ) : (
        <path
          d="M7.66665 28.3333C6.99998 28.3333 6.43332 28.1 5.96665 27.6333C5.49998 27.1667 5.26665 26.6 5.26665 25.9333C5.26665 25.2667 5.49998 24.7 5.96665 24.2333C6.43332 23.7667 6.99998 23.5333 7.66665 23.5333C8.33332 23.5333 8.89998 23.7667 9.36665 24.2333C9.83332 24.7 10.0667 25.2667 10.0667 25.9333C10.0667 26.6 9.83332 27.1667 9.36665 27.6333C8.89998 28.1 8.33332 28.3333 7.66665 28.3333ZM21.1333 28.3333C20.4667 28.3333 19.9 28.1 19.4333 27.6333C18.9667 27.1667 18.7333 26.6 18.7333 25.9333C18.7333 25.2667 18.9667 24.7 19.4333 24.2333C19.9 23.7667 20.4667 23.5333 21.1333 23.5333C21.8 23.5333 22.3667 23.7667 22.8333 24.2333C23.3 24.7 23.5333 25.2667 23.5333 25.9333C23.5333 26.6 23.3 27.1667 22.8333 27.6333C22.3667 28.1 21.8 28.3333 21.1333 28.3333ZM14 11.5333C13.6889 11.5333 13.4222 11.4222 13.2 11.2C12.9778 10.9778 12.8667 10.7111 12.8667 10.4C12.8667 10.0889 12.9778 9.82223 13.2 9.60001C13.4222 9.37779 13.6889 9.26668 14 9.26668C14.3111 9.26668 14.5778 9.37779 14.8 9.60001C15.0222 9.82223 15.1333 10.0889 15.1333 10.4C15.1333 10.7111 15.0222 10.9778 14.8 11.2C14.5778 11.4222 14.3111 11.5333 14 11.5333ZM13 7.00001V0.333344H15V7.00001H13ZM7.66665 21.4333C6.73332 21.4333 6.04998 21.0556 5.61665 20.3C5.18332 19.5445 5.18887 18.7778 5.63332 18L7.66665 14.3L2.66665 3.66668H0.0666504V1.66668H3.93332L9.59998 13.8H19.3333L24.5333 4.46668L26.2667 5.40001L21.1667 14.6333C20.9667 15.0111 20.6944 15.3 20.35 15.5C20.0055 15.7 19.6 15.8 19.1333 15.8H9.13332L7.06665 19.4333H23.5333V21.4333H7.66665Z"
          fill={color}
        />
      )}
    </svg>
  )
}
