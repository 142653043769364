import { Icon as UIIcon } from '@faststore/ui'
import React from 'react'

import { AccordionArrows } from '../../../../assets/AccordionArrows'

import type { ReactNode } from 'react'

interface OpenMobileFilterButtonProps {
  text: string
  icon?: ReactNode
  setIsOpen: () => void
}

export const OpenMobileFilterButton = ({
  text,
  icon,
  setIsOpen,
}: OpenMobileFilterButtonProps) => {
  return (
    <button className="open-mobile-filter-button" onClick={setIsOpen}>
      <div className="open-mobile-filter-content">
        {icon && <UIIcon component={icon} />}
        {text}
      </div>
      <div className="open-mobile-filter-content">
        <AccordionArrows
          width={16}
          height={16}
          color="var(--color-grayScale-white)"
          side="down"
        />
      </div>
    </button>
  )
}
