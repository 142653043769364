import React from 'react'

import { useAnalyticsEvents } from '../../hooks/useAnalyticsEvents'
import { Banner } from '../ui/Banner'

import type { CommonCmsBanner } from '../../types/CMS'

interface Props {
  data?: CommonCmsBanner
}

export const CouponsBanner = ({ data }: Props) => {
  const { src, srcMobile, visibility, href, alt } = data ?? {}
  const { genericTaggingEvent } = useAnalyticsEvents()

  if (!src || !srcMobile || !visibility) {
    return null
  }

  return (
    <Banner
      href={href ?? '/'}
      src={src}
      srcMobile={srcMobile}
      alt={alt ?? ''}
      bannerWidth={768}
      bannerHeight={112}
      bannerDeskWidth={1414}
      bannerDeskHeight={214}
      handleClick={() =>
        genericTaggingEvent('discount_coupon', {
          name: 'Clicou no banner da home',
        })
      }
    />
  )
}
