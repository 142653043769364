import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React, { useMemo } from 'react'

import { ProductShelfSkeleton } from '.'
import { ProductShelfContainer } from './ProductShelfContainer/ProductShelfContainer'
import { ProductShelfHeader } from './ProductShelfHeader/ProductShelfHeader'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../utils/carouselProps'
import { verifyProductInStock } from '../../../utils/products/verifyProductInStock'
import ProductCard from '../../product/ProductCard'
import { SeeMoreCard } from '../../sections/MultipleSearch/MultipleSearchShelfs/SeeMoreCard/SeeMoreCard'
import WhenVisible from '../WhenVisible/WhenVisible'

import type { CarouselPropsWithoutChildren } from '../../../hooks/useVerticalShelfCarouselProps'
import type { ProductsQueryResponse } from '../../../types/Product'
import type { Variant } from '../../product/ProductCard'

import './CarouselWithSeeMore.scss'
import './product-shelf.scss'

export interface ProductShelfProps {
  products?: ProductsQueryResponse['node'][]
  limit?: number
  id: string
  variant?: Variant
  titleLevel?: number
  hasQuickView?: boolean
  backgroundColor?: string
  loading?: boolean
  title: string
  link?: string
  propsCarousel?: CarouselPropsWithoutChildren
  customQtyItems?: number
  hasSeeMore?: boolean
  SeeMoreText?: string
  description?: string
  titleColor?: string
  buttonSeeAll?: boolean
  typeShelf?: string
}

export function ProductShelf({
  products = [],
  limit,
  id,
  variant = 'vertical',
  titleLevel = 3,
  hasQuickView,
  backgroundColor,
  loading,
  title,
  link,
  propsCarousel,
  titleColor,
  description,
  typeShelf,
  customQtyItems,
  SeeMoreText,
}: ProductShelfProps) {
  // Filter by inStock products and limit that if the "limit" prop exist
  const limitedProductsInStock: ProductsQueryResponse['node'][] =
    useMemo(() => {
      const validatedProducts = []

      for (const product of products) {
        if (verifyProductInStock(product)) {
          validatedProducts.push(product)
        }

        if (limit && validatedProducts.length >= limit) {
          break
        }
      }

      return validatedProducts
    }, [products])

  const { getAnotherScPrices } = useAnotherScPrices(limitedProductsInStock)
  const { viewItemListEvent, genericTaggingEvent } = useAnalyticsEvents()
  const { width } = useWindowDimensions()

  const carouselFinalProps: CarouselPropsWithoutChildren = {
    ...carouselProps(width ?? customQtyItems ?? 0),
    ...propsCarousel,
  }

  const handleViewItemList = () => {
    viewItemListEvent(limitedProductsInStock, id)
  }

  const handleSeeAll = () => {
    genericTaggingEvent('see_all', { name: id })
  }

  const hasSeeAll = limitedProductsInStock.length > 4

  // SSR
  if (!width) {
    return (
      <section
        data-shelf-card-variant={variant}
        className="div-content-shelf-product grid-content"
      >
        <Skeleton height={500} backgroundColor={backgroundColor} />
      </section>
    )
  }

  if (loading) {
    return (
      <section
        data-shelf-card-variant={variant}
        className="div-content-shelf-product grid-content"
      >
        <ProductShelfSkeleton
          backgroundColor={backgroundColor}
          variant={variant}
          propsCarousel={carouselFinalProps}
        />
      </section>
    )
  }

  if (limitedProductsInStock.length <= 0) {
    return null
  }

  return (
    <ProductShelfContainer type={typeShelf ?? 'default'} variant={variant}>
      <ProductShelfHeader
        type={typeShelf ?? 'default'}
        title={title}
        titleLevel={titleLevel - 1}
        titleColor={titleColor}
        link={link ?? ''}
        handleSeeAll={() => handleSeeAll}
        dataStoreLink={true}
        hasSeeAll={hasSeeAll}
        description={description ?? ''}
      />

      <WhenVisible
        id={id}
        trigger={handleViewItemList}
        singleActivation
        className="div-content-shelf-product"
      >
        <Carousel id={id} {...carouselFinalProps}>
          {typeShelf === 'club'
            ? limitedProductsInStock.map((product, index) => {
                if (index <= 4) {
                  const anotherScPrices = getAnotherScPrices(product.sku)

                  return (
                    <ProductCard
                      key={product.id}
                      product={product}
                      location={id}
                      {...anotherScPrices}
                      titleLevel={titleLevel}
                    />
                  )
                }
                return null
              })
            : limitedProductsInStock.map((product) => (
                <div
                  key={product.id}
                  className={
                    variant === 'horizontal'
                      ? 'horizontal-product-card-wrapper'
                      : undefined
                  }
                >
                  <ProductCard
                    product={product}
                    location={id}
                    titleLevel={titleLevel}
                    hasQuickView={hasQuickView}
                    variant={variant}
                    {...getAnotherScPrices(product.sku)}
                  />
                </div>
              ))}

          {typeShelf === 'club' && hasSeeAll && (
            <SeeMoreCard
              seeMoreLink={link ?? ''}
              text={SeeMoreText}
              location={id}
            />
          )}
        </Carousel>
      </WhenVisible>
    </ProductShelfContainer>
  )
}
