import React from 'react'

import { LastSeenProducts } from './LastSeenProducts'

interface AlreadyVisitedShelfProps {
  data?: { isActive: boolean }
}

export function AlreadyVisitedShelf({ data }: AlreadyVisitedShelfProps) {
  if (!data?.isActive) {
    return null
  }

  return <LastSeenProducts />
}
