import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React from 'react'

import { useAnotherScPrices } from '../../../../../hooks/useAnotherScPrices'
import { useProductWithLowStock } from '../../../../../hooks/useProductWithLowStock'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../../../utils/carouselProps'
import ProductCard from '../../../../product/ProductCard'
import { SeeMoreCard } from '../SeeMoreCard/SeeMoreCard'

import type { ProductsQueryResponse } from '../../../../../types/Product'

interface ShelfContentProps {
  products: ProductsQueryResponse['node'][]
  seeMoreLink: string
  disableSeeMoreCard?: boolean
  location: string
}

export const ShelfContent = ({
  products,
  seeMoreLink,
  disableSeeMoreCard,
  location,
}: ShelfContentProps) => {
  const { getAnotherScPrices } = useAnotherScPrices(products)
  const { productsWithLowStock, loading } = useProductWithLowStock(products)
  const { width } = useWindowDimensions()

  if (!width || loading) {
    return <Skeleton height={500} />
  }

  const { arrow, bullet } = carouselProps(width)

  return (
    <Carousel arrow={arrow} bullet={bullet}>
      {productsWithLowStock?.map((product) => {
        const anotherScPrices = getAnotherScPrices(product.sku)

        return (
          <ProductCard
            key={product.id}
            product={product}
            location={location}
            titleLevel={3}
            {...anotherScPrices}
          />
        )
      })}

      {!disableSeeMoreCard && (
        <SeeMoreCard
          key={seeMoreLink}
          seeMoreLink={seeMoreLink}
          location={location}
        />
      )}
    </Carousel>
  )
}
