import { Skeleton } from '@acctglobal/skeleton'
import { Coupons, Modal, Onboarding } from '@plurix/ecom-components'
import { Link, navigate } from 'gatsby'
import React, { useState } from 'react'

import { AccordionArrows } from '../assets/AccordionArrows'
import SuccessIcon from '../assets/SuccessIcon'
import { WomanJumping } from '../assets/WomanJumping'
import { ClubProductsSection } from '../components/sections/Club/ClubProducts'
import { DownloadApps } from '../components/sections/ClubRegistered/DownloadApps'
import { getMainContent } from '../components/sections/ClubRegistered/getMainContent'
import { ToKnow } from '../components/sections/ClubRegistered/ToKnow'
import { HAS_WINDOW } from '../constants'
import { useStoreContext } from '../contexts/StoreContext'
import { useAnalyticsEvents } from '../hooks/useAnalyticsEvents'
import { useHasActiveCoupons } from '../hooks/useHasActiveCoupons'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useCart } from '../sdk/cart'

import '../styles/pages/club-registered.scss'
import '@plurix/ecom-components/dist/styles/onboarding.global.css'
import '@plurix/ecom-components/dist/styles/modal.global.css'

const toggleNoScroll = () => {
  if (HAS_WINDOW) {
    document.getElementById('layout')?.classList.toggle('no-scroll')
  }
}

export const ClubRegisteredPage = () => {
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false)

  const { account, hasClub, hasClubRegister, socialMediaLinks } =
    useStoreContext()

  const { couponsLoading, hasActiveCoupons } = useHasActiveCoupons()
  const { id: orderFormId, items } = useCart()
  const { width } = useWindowDimensions()
  const { genericTaggingEvent } = useAnalyticsEvents()

  const mainContent = getMainContent({
    couponsLoading,
    hasActiveCoupons,
    hasClub,
    hasClubRegister,
  })

  const isMobile = !width || width < 769
  const customQtyItems = isMobile ? undefined : 2.5

  const handleToggleOnboardingModal = () => {
    setIsOnboardingModalOpen(!isOnboardingModalOpen)
    toggleNoScroll()
  }

  const handleOnboardingFinishAction = () => {
    toggleNoScroll()
    navigate('/')
  }

  const handleKnowMoreAction = () => {
    genericTaggingEvent('ofertas_pelo_whatsapp', {
      clickText: 'Conhecer',
    })

    handleToggleOnboardingModal()
  }

  return (
    <div className="registered-container">
      <Link to="/" className="grid-content">
        <AccordionArrows color="var(--color-support-links)" side="left" />
        <span>Voltar para a Loja</span>
      </Link>

      <div className="success-message-container">
        <div>
          <SuccessIcon />
          <p>Sua conta foi criada com sucesso!</p>
        </div>

        <p>Agora é só economizar no site e na loja física</p>
      </div>

      <div className="main-content grid-content">
        {mainContent === 'loading' && (
          <Skeleton
            height={isMobile ? 400 : 500}
            backgroundColor="var(--color-grayScale-faded)"
          />
        )}

        {mainContent === 'coupons' && (
          <>
            <div className="coupons-header">
              <h1>Aproveite os Cupons Abaixo</h1>

              <Link
                to="/cupons-de-oferta"
                onClick={() => {
                  genericTaggingEvent('ofertas_pelo_whatsapp', {
                    clickText: 'Ver Tudo',
                  })
                }}
              >
                Ver Tudo
              </Link>
            </div>

            <Coupons
              account={account}
              builder="faststore"
              hasItemsInCart={items.length > 0}
              orderFormId={orderFormId}
              genericTaggingEvent={genericTaggingEvent}
              disableFirstEmptyModal
            />
          </>
        )}

        {mainContent === 'club-shelf' && (
          <ClubProductsSection
            customQtyItems={customQtyItems}
            location="vitrine-ofertas-clube-registrado"
          />
        )}

        {mainContent === 'default' && (
          <div className="svg-container">
            <WomanJumping />
          </div>
        )}
      </div>

      <div className="grid-content">
        <DownloadApps />
        <ToKnow handleKnowMoreAction={handleKnowMoreAction} />
      </div>

      {isOnboardingModalOpen && (
        <Modal
          className="quick-view-modal"
          isOpen={isOnboardingModalOpen}
          setIsOpen={setIsOnboardingModalOpen}
          title=""
          hideHeader
        >
          <div className="quick-view-container onboarding-container">
            <Onboarding
              socialMediaLinks={{
                facebook: socialMediaLinks?.linkFacebook,
                instagram: socialMediaLinks?.linkInstagram,
              }}
              handleCloseAction={handleToggleOnboardingModal}
              handleFinishAction={handleOnboardingFinishAction}
              genericTaggingEvent={genericTaggingEvent}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}
