import axios from 'axios'
import { useState, useMemo, useCallback, useEffect } from 'react'

import { useClubContext } from '../contexts/ClubContext'
import { useRegionalizationContext } from '../contexts/RegionalizationContext'
import { useStoreContext } from '../contexts/StoreContext'
import { truncPrice } from '../utils/prices/truncPrice'
import { verifyProductClub } from '../utils/products/verifyProductClub'

import type { CartSimulationBody, CartSimulationResponse } from '../types/Club'

interface Product {
  sku: string
  skuSpecifications: SKUSpecification[] | null
}

type SKUSpecification = {
  field: { originalName: string }
  values: { originalName: string }[]
}

type PricesMap = Map<string, AnotherScPrices>

interface AnotherScPrices {
  defaultScListPrice?: number
  clubPrice?: number
  sellingPrice?: number
}

const defaultPricesMap: PricesMap = new Map()

export function useAnotherScPrices(
  products: Product[] = [],
  allItemsInTheSameSimulation = false
) {
  const [prices, setPrices] = useState(defaultPricesMap)
  const [loadingPrices, setLoadingPrices] = useState(true)

  const { postalCode, currentSellerId } = useRegionalizationContext()
  const { hasClub, salesChannels, hasClubWithRegion } = useStoreContext()
  const { isClubClient } = useClubContext()

  // If current seller or club products changes, it will do a new cart simulation request
  const clubProducts = useMemo(() => {
    return {
      products: products.filter(({ skuSpecifications }) =>
        verifyProductClub({
          skuSpecifications,
          currentSellerId,
          hasClubWithRegion,
        })
      ),
      currentSellerId,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(products), currentSellerId, hasClubWithRegion])

  const getAnotherScPrices = useCallback(
    (sku: string) => prices.get(sku),
    [prices]
  )

  useEffect(() => {
    // Toggle disabled or hasn't club products
    if (
      !hasClub ||
      !salesChannels.club ||
      !clubProducts.products.length ||
      products.length === 0
    ) {
      setPrices(defaultPricesMap)
      setLoadingPrices(false)

      return
    }

    async function fetchPrices() {
      try {
        const salesChannel = isClubClient
          ? salesChannels.default
          : salesChannels.club

        const items = clubProducts.products.map(({ sku }) => ({
          id: sku,
          quantity: 1,
          seller: '1' as const,
        }))

        const cartSimulationBody: CartSimulationBody = {
          items,
          country: 'BRA',
          postalCode,
          salesChannel,
          allItemsInTheSameSimulation,
        }

        setLoadingPrices(true)

        const { data } = await axios.post<CartSimulationResponse>(
          '/api/cartSimulation',
          cartSimulationBody
        )

        setPrices(() => {
          const updatedPricesMap: PricesMap = new Map()

          data.items.forEach((item) => {
            const { id, listPrice, unitMultiplier, sellingPrice } = item

            // Prevent products without stock or with invalid prices
            if (id && listPrice && unitMultiplier && sellingPrice) {
              const productPrices = {
                defaultScListPrice: isClubClient
                  ? truncPrice((listPrice / 100) * unitMultiplier)
                  : undefined,
                clubPrice: isClubClient ? undefined : sellingPrice / 100,
                sellingPrice: sellingPrice / 100,
              }

              updatedPricesMap.set(id, productPrices)
            }
          })

          return new Map(updatedPricesMap)
        })
      } catch {
        setPrices(defaultPricesMap)
      } finally {
        setLoadingPrices(false)
      }
    }

    fetchPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClub, salesChannels, isClubClient, JSON.stringify(clubProducts)])

  return { getAnotherScPrices, loadingPrices }
}
