import React from 'react'

import { Institutional } from '../components/sections/Institutional'

import type { InstitutionalProps } from '../components/sections/Institutional'

export const InstitutionalPage = ({
  children,
  title,
  banners,
}: InstitutionalProps) => {
  return (
    <Institutional title={title} banners={banners}>
      {children}
    </Institutional>
  )
}
