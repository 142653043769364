import { HAS_DOCUMENT, HAS_WINDOW } from '../constants'

export const getUtmUrlParams = () => {
  if (!HAS_WINDOW) {
    return {}
  }

  const { search } = window.location
  const params = new URLSearchParams(search)

  const fbclid = params.get('fbclid')
  const utmSource = params.get('utm_source')
  const utmMedium = params.get('utm_medium')
  const utmCampaign = params.get('utm_campaign')

  const utmParams: { [key: string]: string } = {}

  if (fbclid) {
    utmParams.fbclid = fbclid
  }

  if (utmSource) {
    utmParams.utm_source = utmSource
  }

  if (utmMedium) {
    utmParams.utm_medium = utmMedium
  }

  if (utmCampaign) {
    utmParams.utm_campaign = utmCampaign
  }

  return utmParams
}

export const getUtmCookie = () => {
  if (!HAS_DOCUMENT) return {}

  const utmInCookie = JSON.parse(
    document.cookie
      ?.split(';')
      ?.map((cookie) => ({
        cookie: cookie.split('=')?.[0]?.trim(),
        value: cookie.split('=')?.[1]?.trim(),
      }))
      ?.find(({ cookie }) => cookie === 'utmParams')?.value ?? '{}'
  )

  return utmInCookie
}

export const utmUrlComplement = () => {
  const currentUtmCookieValue = getUtmCookie()

  const hasUtmsInCookie = Object.keys(currentUtmCookieValue).length > 0

  if (hasUtmsInCookie) {
    return `&${Object.entries(currentUtmCookieValue)
      ?.map((item) => `${item[0]}=${item[1]}`)
      ?.join('&')}`
  }

  return ''
}

const addTermsInUrl = (terms: { [key: string]: string }) => {
  if (!HAS_WINDOW) {
    return
  }

  const { search } = window.location
  const params = new URLSearchParams(search)

  Object.entries(terms).map((items) => params.set(items[0], items[1] as string))
  window.history.pushState({}, '', `?${params.toString()}`)
}

export const handleUtmParams = () => {
  const currentUtmUrlParams = getUtmUrlParams()
  const UrlUtmsLength = Object.keys(currentUtmUrlParams).length
  const hasUrlUtms = UrlUtmsLength > 0

  const curretUtmCookie = getUtmCookie()
  const curretUtmCookieLenth = Object.keys(curretUtmCookie).length
  const hasUtmsInCookie = curretUtmCookieLenth > 0

  const setUtmCookie = () => {
    document.cookie = `utmParams=${JSON.stringify(
      currentUtmUrlParams
    )};expires=session;path=/`
  }

  if (!hasUrlUtms && hasUtmsInCookie) {
    addTermsInUrl(curretUtmCookie)
  }

  if (hasUrlUtms && !hasUtmsInCookie) {
    setUtmCookie()
  }

  if (hasUrlUtms && hasUtmsInCookie) {
    if (curretUtmCookieLenth > UrlUtmsLength) {
      addTermsInUrl(curretUtmCookie)
    } else {
      setUtmCookie()
    }
  }
}
