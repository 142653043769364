import React from 'react'

import { discount_desc } from '../../../constants'
import { useVerticalShelfCarouselProps } from '../../../hooks/useVerticalShelfCarouselProps'
import { ProductShelfByFilters } from '../ProductShelf/ProductShelfByFilters'

interface HistorySearchShelfProps {
  term: string
}

const PRODUCTS_TO_FETCH = 8
const PRODUCTS_TO_SHOW = 4

export const HistorySearchShelf = ({ term }: HistorySearchShelfProps) => {
  const propsCarousel = useVerticalShelfCarouselProps()

  return (
    <ProductShelfByFilters
      queryInput={{
        sort: discount_desc,
        term: term,
        firstItems: PRODUCTS_TO_FETCH,
      }}
      limit={PRODUCTS_TO_SHOW}
      id="vitrine-relacionado-com-suas-buscas"
      variant="horizontal"
      backgroundColor="var(--color-grayScale-light)"
      title="Você também pode gostar"
      propsCarousel={propsCarousel}
    />
  )
}
