import { Link } from 'gatsby'
import React from 'react'

import ArrowIcon from '../../../../assets/ArrowIcon'
import HomeIcon from '../../../../assets/HomeIcon'

import './multiple-search-navigation.scss'

interface NavigationInstitutionalProps {
  breadcrumb: string[]
  navigateTriggers?: (() => void)[]
  backButton: {
    backText?: string
    link?: string
    trigger?: () => void
  }
}

export const MultipleSearchNavigation = ({
  backButton,
  breadcrumb,
  navigateTriggers,
}: NavigationInstitutionalProps) => {
  const { backText, link: backButtonLink, trigger } = backButton

  return (
    <div className="multiple-search-navigation-container">
      <div className="breadcrumb-about">
        <Link to="/">
          <HomeIcon />
        </Link>
        {breadcrumb.map((item: string, index: number) => (
          <>
            <ArrowIcon rotate="Left" />
            <p onClick={() => navigateTriggers?.[index]?.()}>{item}</p>
          </>
        ))}
      </div>

      <div className="back-step">
        <ArrowIcon rotate="Right" color="var(--color-support-links)" />
        <Link to={backButtonLink ?? ''} onClick={() => trigger?.()}>
          <p>{backText}</p>
        </Link>
      </div>
    </div>
  )
}
