import React from 'react'

export interface ProductShelfContainerProps {
  children: React.ReactNode
  type: string
  variant: string
}

export const ProductShelfContainer = ({
  children,
  type,
  variant,
}: ProductShelfContainerProps) => {
  return (
    <div
      data-shelf-card-variant={variant}
      className={
        type === 'club'
          ? 'page__section shelf-section-grid shelf-section-content-grid carousel-with-see-more-container'
          : 'grid-content'
      }
    >
      {children}
    </div>
  )
}
