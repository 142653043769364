import React from 'react'

import { ShelfContent } from './ShelfContent/ShelfContent'
import { ShelfHeader } from './ShelfHeader/ShelfHeader'
import { defaultSort } from '../../../../constants'
import { MultipleSearchNotFoundMessage } from '../MultipleSearchNotFoundMessage'

import type { ProductsQueryResponse } from '../../../../types/Product'
import './multiple-search-shelfs.scss'

interface MultipleSearchShelfs {
  haveMoreItems?: boolean
  loading?: boolean
  products: ProductsQueryResponse['node'][]
  sectionName: string
}

export const MultipleSearchShelfs = ({
  products,
  sectionName,
}: MultipleSearchShelfs) => {
  if (products.length < 1) {
    return <MultipleSearchNotFoundMessage sectionName={sectionName} />
  }

  return (
    <div className="shelf-section-content-grid multiple-search-shelf-container">
      <ShelfHeader itemName={sectionName} />
      <ShelfContent
        seeMoreLink={`/s/?q=${sectionName}&sort=${defaultSort}&page=0`}
        products={products}
        location="lista de compras"
      />
    </div>
  )
}
