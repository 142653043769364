import React from 'react'

import { MultipleSearchNavigation } from '../../MultipleSearch/MultipleSearchNavigation'

export const BuyAgainNavigation = () => {
  return (
    <MultipleSearchNavigation
      backButton={{ backText: 'Voltar', trigger: () => window.history.back() }}
      breadcrumb={['Comprar novamente']}
    />
  )
}
