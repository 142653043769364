import { useLocation } from '@reach/router'
import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FoodRestrictionPageHeader } from './components/FoodRestrictionPageHeader/FoodRestrictionPageHeader'
import { FoodRestrictionProductsContent } from './components/FoodRestrictionProductsContent/FoodRestrictionProductsContent'
import { HAS_WINDOW, defaultSort } from '../../../../constants'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { selectFacetFilter } from '../../../../utils/foodRestrictionFunctions'
import { FoodRestrictionDesktopFilter } from '../../../sections/FoodRestriction/FoodRestrictionDesktopFilter'

import type { RestrictionFacet } from '../../../../types/FoodRestriction'
import type { ProductsQueryResponse } from '../../../../types/Product'

import './food-restriction-search-page-content.scss'

interface FilterProps {
  title: string
  facet: string
  currentSeter?: (currentFacet: string) => void
  options: FacetsProps[]
}

interface FacetsProps {
  value: string
  selected: boolean
  quantity: number
  label: string
}

export const FoodRestrictionSearchContent = () => {
  const [facets, setFacets] = useState<{ key: string; value: string }[]>([])

  const [currentRestriction, setCurrentRestriction] = useState<string>('')
  const [initialFacets, setInitialFacets] = useState<RestrictionFacet[]>([])
  const [categorieFacetsKey, setCategorieFacetsKey] = useState('')
  const [subcategorieFacetsKey, setSubcategorieFacetsKey] = useState('')

  const [filterFacets, setFilterFacets] = useState<RestrictionFacet[]>([])
  const [selectedCategorie, setSelectedCategorie] = useState<string>('Todos')
  const [selectedSubcategorie, setSelectedSubcategorie] = useState<string>('')
  const [sort, setSort] = useState(defaultSort)
  const [totalCount, setTotalCount] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [products, setProducts] = useState<ProductsQueryResponse['node'][]>([])

  const location = useLocation()

  const [requestLoading, setResquetLoading] = useState(false)

  const [categoriesFacets, setCategoryFacets] = useState<FacetsProps[]>()
  const [mainMenuCategories, setMainMenuCategories] = useState<FacetsProps[]>([
    { label: 'Todos', value: '', quantity: 0, selected: false },
  ])

  const [subcategoryFacets, setSubcategoryFacets] = useState<FacetsProps[]>([])

  const ITEMS_PER_PAGE = 20

  const DEFAULT_FACETS = [
    { key: 'category-1', value: 'alimentos' },
    { key: 'category-1', value: 'bebidas' },
  ]

  const DEFAULT_RESTRICTIONS: { [key: string]: string } = {
    organico: 'Orgânico',
    vegano: 'Vegano',
    'sem-lactose': 'Sem Lactose',
    'sem-gluten': 'Sem Glúten',
    'sem-acucar': 'Sem Açucar',
  }

  const { isMobile } = useWindowDimensions()

  const { facetParam, currentRestrictionParam } = useMemo(() => {
    if (!HAS_WINDOW) {
      return {}
    }

    const { search } = window.location
    const params = new URLSearchParams(search)
    const restrictionParam = params.get('restriction')

    if (!restrictionParam) {
      return {}
    }

    return {
      facetParam: [{ key: restrictionParam, value: 'true' }],
      currentRestrictionParam:
        DEFAULT_RESTRICTIONS[restrictionParam] ?? restrictionParam,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HAS_WINDOW, location.search])

  const getData = useCallback(async () => {
    if (!facets.length) {
      return
    }

    try {
      setResquetLoading(true)
      const { data } = await axios.post('/api/restrictionBrowsing', {
        facets,
        sort,
        after: `${pagination * ITEMS_PER_PAGE}`,
      })

      setResquetLoading(false)

      const {
        products: SearchedProducts,
        totalCount: SearchedTotalCount,
        categories,
        subcategories,
        categorieKey,
        subcategorieKey,
      } = await data

      if (pagination > 0) {
        setProducts((currentProducts) => [
          ...currentProducts,
          ...SearchedProducts,
        ])
      } else {
        setProducts(SearchedProducts)
      }

      setMainMenuCategories([
        { label: 'Todos', value: '', quantity: 0, selected: false },
        ...categories,
      ])

      setTotalCount(SearchedTotalCount)
      setCategoryFacets(categories)
      setSubcategoryFacets(subcategories)
      setCategorieFacetsKey(categorieKey)
      setSubcategorieFacetsKey(subcategorieKey)
    } catch (err) {
      console.error('Erro in getRestrictProducts function', err)
    }
  }, [sort, facets, pagination])

  const allFacets = useMemo(() => {
    if (!categoriesFacets) {
      return
    }

    const allFacetsReplaced: FilterProps[] = []

    allFacetsReplaced[0] = {
      title: 'Categoria',
      options: categoriesFacets?.map(
        ({ label, value, quantity, selected }: FacetsProps) => {
          return { label, value, quantity, selected }
        }
      ),
      facet: categorieFacetsKey,
    }

    allFacetsReplaced[1] = {
      title: 'SubCategoria',
      options: subcategoryFacets?.map(
        ({ value, quantity, selected, label }: FacetsProps) => {
          return { label, value, quantity, selected }
        }
      ),
      facet: subcategorieFacetsKey,
    }

    return allFacetsReplaced
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategoryFacets, categoriesFacets])

  const handleFacets = useCallback(
    (newFacet: RestrictionFacet[]) => {
      setFacets([...initialFacets, ...newFacet])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facets, selectedCategorie, selectedSubcategorie]
  )

  const maximumPaginationCalculation = (range: number, maxPerPage: number) => {
    const pages = Math.floor(range / maxPerPage)
    const remainder = range % maxPerPage

    if (remainder > 0) {
      return pages + 1
    }

    return pages
  }

  const maxPagination = useMemo(() => {
    const maxValue = maximumPaginationCalculation(totalCount, ITEMS_PER_PAGE)

    return pagination + 1 < maxValue
  }, [totalCount, pagination])

  const layoutLoading = useMemo(() => {
    return requestLoading && !products?.length
  }, [requestLoading, products])

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, facets, pagination])

  useEffect(() => {
    if (facetParam && currentRestrictionParam) {
      setCurrentRestriction(currentRestrictionParam)
      setFacets([...DEFAULT_FACETS, ...facetParam])
      setInitialFacets([...DEFAULT_FACETS, ...facetParam])

      return
    }

    setFacets(DEFAULT_FACETS)
    setInitialFacets(DEFAULT_FACETS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facetParam, currentRestrictionParam])

  const hasProductRestriction = useMemo(() => {
    return !!totalCount && totalCount > 0
  }, [totalCount])

  const handleMainMenuItem = useCallback(
    (value: string, label: string, key: string) => {
      const valueValidation = value !== ''

      setSelectedCategorie(label)

      if (valueValidation) {
        handleFacets([{ key, value }])
        selectFacetFilter({
          facets: filterFacets,
          setFacets: setFilterFacets,
          key,
          value,
          categorieFacetsKey,
        })

        return
      }

      setFacets(initialFacets)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categorieFacetsKey, filterFacets, initialFacets]
  )

  return (
    <>
      <FoodRestrictionPageHeader
        restriction={currentRestriction}
        hasProductRestriction={hasProductRestriction}
        handleMenuItem={handleMainMenuItem}
        mainMenuCategories={mainMenuCategories}
        layoutLoading={layoutLoading || mainMenuCategories.length <= 1}
        selectedCategorie={selectedCategorie}
        categorieFacetsKey={categorieFacetsKey}
      />

      <div
        className={`restrictionProductsPage-content / grid-content ${
          hasProductRestriction ? '' : 'product-not-found'
        }`}
      >
        {!isMobile && (
          <FoodRestrictionDesktopFilter
            requestLoading={requestLoading}
            handleFacets={handleFacets}
            allFacets={allFacets}
            selectedCategorie={selectedCategorie}
            setSelectedCategorie={setSelectedCategorie}
            setSelectedSubcategorie={setSelectedSubcategorie}
            filterFacets={filterFacets}
            setFilterFacets={setFilterFacets}
            categorieFacetsKey={categorieFacetsKey}
            subcategorieFacetsKey={subcategorieFacetsKey}
          />
        )}

        <FoodRestrictionProductsContent
          products={products}
          selectedCategorie={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          setSelectedSubcategorie={setSelectedSubcategorie}
          setSort={setSort}
          sort={sort}
          handlePagination={() => setPagination(pagination + 1)}
          totalCount={totalCount}
          hasProductRestriction={hasProductRestriction}
          requestLoading={requestLoading}
          maxPagination={maxPagination}
          layoutLoading={layoutLoading}
          handleFacets={handleFacets}
          allFacets={allFacets}
          filterFacets={filterFacets}
          setFilterFacets={setFilterFacets}
          categorieFacetsKey={categorieFacetsKey}
          subcategorieFacetsKey={subcategorieFacetsKey}
        />
      </div>
    </>
  )
}
