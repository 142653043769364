import { truncPrice } from './prices/truncPrice'

import type { CustomerOrder, ProductsData } from '../types/RepeatOrder'

export const updateOrderTotalValue = (
  order: CustomerOrder,
  productsData: ProductsData['products']
): CustomerOrder => {
  const totalValue = order.items.reduce((total, item) => {
    const product = productsData.find(({ sku }) => sku === item.id)

    if (!product) {
      return total
    }

    return product.customOffers.spotPriceCustom * item.quantity + total
  }, 0)

  const updatedTotals = order.totals?.map((total) => {
    if (total.id === 'Items') {
      return { ...total, value: truncPrice(totalValue * 100) }
    }

    return total
  })

  return {
    ...order,
    totals: updatedTotals,
  }
}
