export const replaceStringBreadcrumb = (
  breadcrumbHref: string,
  initBar = false
) => {
  // replace special characters (including commas) to place in slug
  const initBarValue = initBar ? '/' : ''

  return (
    initBarValue +
    breadcrumbHref
      .toLocaleLowerCase()
      .normalize('NFD')
      .replaceAll(/[\u0300-\u036f,]/g, '')
      .replaceAll(' ', '-')
      .replaceAll('--', '-')
  )
}

export const replaceToShowFilterPrice = (filterPriceValue: string) => {
  // transform this: 10-to-20 to this: R$10,00 - R$20,00
  const filterPriceReplaced = filterPriceValue
    .split('-')
    .map((filterPrice, index) => {
      if (index === 0) {
        if (!Number.isNaN(Number(filterPrice))) {
          return Number(filterPrice).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        }

        return 'Grátis'
      }

      if (index === 2) {
        if (!Number.isNaN(Number(filterPrice))) {
          return Number(filterPrice).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        }

        return 'Máximo'
      }

      return '-'
    })
    .join(' ')

  return filterPriceReplaced
}

export const replaceToSearchFilterPrice = (filterPriceValue: string) => {
  // transform this format 10-to-20 to this: 10:20
  return filterPriceValue.replaceAll('-', '').replace('to', ':')
}

export const capitalizeText = (text: string) => {
  // first letter uppercase in texts
  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1)
}
