import { useEffect, useState } from 'react'

import { HAS_WINDOW } from '../constants'
import { useCart } from '../sdk/cart'

import type { OrderItem } from '../types/RepeatOrder'

type Order = {
  orderId: string
  items: OrderItem[]
}

export const useRepeatOrdersPersistence = (orders?: Order[]) => {
  const [lastOrders, setLastOrders] = useState<Order[]>([])
  const [repeatedOrders, setRepeatedOrders] = useState<string[]>([])

  const { items: cartItems } = useCart()

  const repeatOrder = (orderId: string) => {
    setRepeatedOrders((prev) => {
      const updatedOrders = [...prev, orderId]

      localStorage.setItem('repeatedOrders', JSON.stringify(updatedOrders))

      return updatedOrders
    })
  }

  useEffect(() => {
    if (!HAS_WINDOW) {
      return
    }

    if (orders) {
      localStorage.setItem('lastOrders', JSON.stringify(orders))
    }

    const currentLastOrdersStorage: Order[] = JSON.parse(
      localStorage.getItem('lastOrders') ?? '[]'
    )

    const currentRepeatedOrdersStorage: string[] = JSON.parse(
      localStorage.getItem('repeatedOrders') ?? '[]'
    )

    setLastOrders(currentLastOrdersStorage)
    setRepeatedOrders(currentRepeatedOrdersStorage)
  }, [JSON.stringify(orders)])

  useEffect(() => {
    if (!HAS_WINDOW || !repeatedOrders.length) {
      return
    }

    const repeatedOrdersInCart = lastOrders
      .filter(({ items }) => {
        const orderSkus = items.map(({ id }) => id)

        const hasOrderProductsInCart = cartItems.some(({ itemOffered }) =>
          orderSkus.includes(itemOffered.sku)
        )

        return hasOrderProductsInCart
      })
      .map(({ orderId }) => orderId)

    if (repeatedOrders.every((order) => repeatedOrdersInCart.includes(order))) {
      return
    }

    localStorage.setItem('repeatedOrders', JSON.stringify(repeatedOrdersInCart))
    setRepeatedOrders(repeatedOrdersInCart)
  }, [JSON.stringify(cartItems), JSON.stringify(repeatedOrders)])

  return { repeatedOrders, repeatOrder }
}
