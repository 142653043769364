import React from 'react'

import { CleanMultipleSearchIcon } from '../../../../assets/CleanMultipleSearchIcon'
import './suggested-kit-clear-button.scss'

interface SuggestedKitClearButtonProps {
  text: string
  onClick: () => void
}

export const SuggestedKitClearButton = ({
  text,
  onClick,
}: SuggestedKitClearButtonProps) => {
  return (
    <button className="suggested-kit-clear-button" onClick={onClick}>
      <CleanMultipleSearchIcon />
      <p className="suggested-kit-clear-text">{text}</p>
    </button>
  )
}
