import React from 'react'

import CloseIconV2 from '../../../../assets/CloseIconV2'

import './multiple-search-badge.scss'

interface MultipleSearchBadgeProps {
  term: string
  multipleTerms: string[]
  setTMultipleTerms: (multipleTerms: string[]) => void
}

export const MultipleSearchBadge = ({
  term,
  multipleTerms,
  setTMultipleTerms,
}: MultipleSearchBadgeProps) => {
  const handleRemoveTerm = () => {
    const newTerms = multipleTerms.filter((currentTerm) => currentTerm !== term)

    setTMultipleTerms(newTerms)
    sessionStorage.setItem('multipleSearchTerms', JSON.stringify(newTerms))
  }

  return (
    <div className="multiple-search-badge-container">
      <span className="multiple-search-badge-term">{term}</span>
      <button
        className="multiple-search-badge-delete"
        onClick={handleRemoveTerm}
      >
        <CloseIconV2 width={16} height={16} color="var(--color-main)" />
      </button>
    </div>
  )
}
