import React, { useState, useCallback } from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { useBuyProduct } from '../../../../hooks/useBuyProduct'
import CleanButton from '../../../common/CleanButton'
import { ProductCardActions } from '../../../product/ProductCard/components/ProductCardActions'
import { Image } from '../../../ui/Image'
import WhenVisible from '../../../ui/WhenVisible/WhenVisible'
import { QuickView } from '../../QuickView'

import type { ProductsQueryResponse } from '../../../../types/Product'

import './tabloid-product.scss'

interface TabloidProductCardProps {
  sectionName: string
  product: ProductsQueryResponse['node']
  position: number
  menuAutoNavigation: (index: number, sectionName: string) => void
  isUserRegion: boolean
}

export const TabloidProductCard: React.FC<TabloidProductCardProps> = ({
  sectionName,
  product,
  position,
  menuAutoNavigation,
  isUserRegion,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [openClubModal, setOpenClubModal] = useState(false)

  const { selectItemEvent } = useAnalyticsEvents()
  const { buyProps, cartProductId, cartProductQty } = useBuyProduct(product)
  const handleOpenModal = useCallback(() => {
    selectItemEvent(product)
    setOpenModal(true)
  }, [selectItemEvent, product])

  if (
    !product ||
    !sectionName ||
    product.offers?.offers?.[0]?.availability ===
      'https://schema.org/OutOfStock'
  )
    return null

  const {
    unitMultiplier,
    measurementUnit,
    id,
    priceWithoutPromotions,
    isVariantOf: { name },
    image: [{ alternateName, url }],
    customOffers: { listPriceCustom, spotPriceCustom },
  } = product

  const priceFormatted = (spotPriceCustom / unitMultiplier)
    .toFixed(2)
    .split('.')

  const listPriceFormatted = listPriceCustom.toFixed(2).split('.')

  const location = `tabloid-${sectionName
    .toLowerCase()
    .replace(/[\s,]+/g, '-')}`

  return (
    <CleanButton
      className="tabloid-product-card-container"
      onClick={handleOpenModal}
    >
      {openModal && (
        <QuickView
          openQuickView={openModal}
          setOpenQuickView={setOpenModal}
          product={product}
          openClubModal={openClubModal}
          setOpenClubModal={setOpenClubModal}
        />
      )}

      <WhenVisible
        id={`${sectionName}-${id}-0`}
        trigger={() => menuAutoNavigation(position, sectionName)}
        className="tabloid-product-card"
      >
        <div id={`${sectionName}-${id}-${position}`}>
          {sectionName === 'Cliente Exclusivo' && (
            <div className="tabloid-exclusive-product">
              <strong>{sectionName}</strong>
            </div>
          )}

          <div className="tabloid-product-image">
            <div className="tabloid-product-image-container">
              <Image
                objectFit="contain"
                baseUrl={url}
                layout="fullWidth"
                width={232}
                height={232}
                backgroundColor="var(--color-grayScale-faded)"
                options={{ fitIn: true }}
                alt={alternateName}
                loading="lazy"
              />
            </div>

            <div className="tabloid-product-name-container">
              <h3 className="tabloid-product-name">{name}</h3>
            </div>

            <div className="tabloid-price-container">
              {listPriceCustom > priceWithoutPromotions && (
                <p>
                  <span className="price-prev">
                    {`${listPriceFormatted[0]},${listPriceFormatted[1]}`}
                  </span>
                </p>
              )}
              <p>
                <span className="currency">R$</span>
                <span className="price-int">{priceFormatted[0]},</span>
                <span className="price-cents">
                  {priceFormatted[1]}
                  <br />
                  <span className="price-unit">
                    {measurementUnit === 'kg' ? 'Quilo' : 'Cada'}
                  </span>
                </span>
              </p>
            </div>
          </div>
          {isUserRegion && (
            <CleanButton
              className="tabloid-product-actions"
              onClick={(event) => event.stopPropagation()}
            >
              <ProductCardActions
                product={product}
                location={location}
                setOpenClubModal={setOpenClubModal}
                buyProps={buyProps}
                cartProductId={cartProductId}
                cartProductQty={cartProductQty}
              />
            </CleanButton>
          )}
        </div>
      </WhenVisible>
    </CleanButton>
  )
}
