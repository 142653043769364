import React from 'react'

import useWindowDimensions from '../../../../../../hooks/useWindowDimensions'
import { FoodRestrictionMobileFilter } from '../../../../../sections/FoodRestriction/FoodRestrictionMobileFilter'
import Select from '../../../../../ui/Select'

import type { RestrictionFacet } from '../../../../../../types/FoodRestriction'
import './food-content-header.scss'

interface FacetsProps {
  value: string
  selected: boolean
  quantity: number
  label: string
}

interface FoodRestrictionSearchPageHeaderProps {
  requestLoading: boolean
  title: string
  totalCount: number
  setSort: (sort: string) => void
  sort: string
  hasProductRestriction: boolean | 0
  handleFacets: (newFacet: RestrictionFacet[]) => void
  allFacets?: {
    facet: string
    title: string
    options: FacetsProps[]
  }[]
  selectedCategorie: string
  setSelectedCategorie: (selectedCategorie: string) => void
  setSelectedSubcategorie?: (subcategorie: string) => void
  filterFacets: RestrictionFacet[]
  setFilterFacets: (filterFacets: RestrictionFacet[]) => void
  categorieFacetsKey: string
  subcategorieFacetsKey: string
}

export const FoodRestrictiontHeader = ({
  title,
  totalCount,
  setSort,
  sort,
  hasProductRestriction,
  handleFacets,
  allFacets,
  requestLoading,
  selectedCategorie,
  setSelectedCategorie,
  setSelectedSubcategorie,
  filterFacets,
  setFilterFacets,
  categorieFacetsKey,
  subcategorieFacetsKey,
}: FoodRestrictionSearchPageHeaderProps) => {
  const { isMobile } = useWindowDimensions()

  const DEFAULT_SORTING = {
    score_desc: 'Relevância',
    price_desc: 'Preço, decrescente',
    price_asc: 'Preço, ascendente',
    orders_desc: 'Mais vendidos',
    name_asc: 'Nome, A-Z',
    name_desc: 'Nome, Z-A',
    release_desc: 'Data de lançamento',
    discount_desc: 'Desconto',
  }

  return (
    <div className="restrictionProductsPage-productContent-header">
      {!isMobile && hasProductRestriction && (
        <>
          <div className="restrictionProductsPage-productContent-header-title">
            <h1>{title}</h1>
          </div>
        </>
      )}

      {isMobile && hasProductRestriction && (
        <FoodRestrictionMobileFilter
          handleFacets={handleFacets}
          allFacets={allFacets}
          requestLoading={requestLoading}
          selectedCategorie={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          setSelectedSubcategorie={setSelectedSubcategorie}
          filterFacets={filterFacets}
          setFilterFacets={setFilterFacets}
          categorieFacetsKey={categorieFacetsKey}
          subcategorieFacetsKey={subcategorieFacetsKey}
        />
      )}

      <div className="restrictionProductsPage-productContent-header-information">
        <p>
          <span>{totalCount}</span> produtos encontrados
        </p>
        {!isMobile && (
          <Select
            id="restrictionProductsPage-select"
            options={DEFAULT_SORTING}
            onChange={(e) => setSort(e.target.value)}
            value={sort}
            testId="search-sort"
          />
        )}
      </div>
    </div>
  )
}
