import axios from 'axios'
import { useEffect, useState } from 'react'

export interface TabloidNbb {
  urlFlyer: string
}

export const useTabloidNbb = (cid?: string) => {
  const [urlTabloidNbb, setUrlTabloidNbb] = useState<string>('')

  useEffect(() => {
    const request = async () => {
      const {
        data: { urlFlyer },
      } = await axios.get<TabloidNbb>(`/api/user/urlFlyer${cid}`)

      setUrlTabloidNbb(urlFlyer)
    }

    request()
  }, [])

  return { urlTabloidNbb }
}
