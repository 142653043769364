import './points.scss'

import React from 'react'

import { ButtonPoints } from './components/ButtonPoints'
import { GiftIcon } from '../../../../assets/GiftIcon'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { AdaptiveCarousel } from '../../../common/AdaptiveCarousel'
import { BoldTextParts } from '../../../common/BoldTextParts'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { Image } from '../../../ui/Image'
import { PointsCard } from '../../../ui/Points/PointsCard'
import { PointsTextCard } from '../../../ui/Points/PointsTextCard'
import { StatusMessage } from '../../../ui/StatusMessage'

import type { Balance } from '../../../../contexts/CardContext'
import type {
  ImageWithAlt,
  Points as PointBenefitsProps,
} from '../../../../types/ClubCard/ClubCardBenefits'

export interface PointsProps extends PointBenefitsProps {
  balance?: Balance
  button?: {
    href?: string
    text?: string
  }
  pointsIcon?: ImageWithAlt
  className?: string
  lastList?: {
    title: string
    redirect: string
    isVisible?: boolean
    onClick?: (e: React.MouseEvent) => void
  }
  colorConfig?: {
    titleColor?: string
    containerColor?: string
    textColor?: string
  }
}

export const Points = ({
  visibility,
  title,
  text,
  list,
  icon,
  balance,
  button,
  pointsIcon,
  className = '',
  lastList,
  colorConfig,
}: PointsProps) => {
  const { width, isMobile } = useWindowDimensions()
  const isWidthMobile = width && width <= 768
  const openNewTab = !!balance?.data
  const hasNoBalance = !balance?.loading && !balance?.data

  if (!visibility || !list?.length) {
    return null
  }

  return (
    <div
      data-testid="points-benefits"
      className={`club-card-benefits-points
        grid-content
        ${className}
        ${balance?.loading ? 'loading' : ''}`}
    >
      {title && (
        <DynamicTitle
          className="points-title"
          titleLevel={2}
          title={title}
          titleColor={colorConfig?.titleColor}
        />
      )}
      {button?.href && button?.text && (
        <div className={`points-infos ${hasNoBalance ? 'notBalance' : ''}`}>
          <div className="points-infos-quantity">
            {hasNoBalance ? (
              <StatusMessage
                Icon={<GiftIcon />}
                message="Você ainda não possui pontos. Utilize o seu *+amigo Cartão* e acumule pontos para trocar por recompensas incríveis."
                className="statusWarning"
              />
            ) : (
              <>
                <p className="points-infos-quantity-text">
                  {!balance?.loading && (
                    <>{balance?.data?.toLocaleString('pt-BR')}</>
                  )}
                </p>

                {pointsIcon?.alt && pointsIcon?.src && (
                  <Image
                    alt={pointsIcon.alt}
                    baseUrl={pointsIcon.src}
                    width={20}
                    height={20}
                    objectFit="contain"
                  />
                )}
              </>
            )}
          </div>
          {!isWidthMobile && (
            <ButtonPoints {...button} openNewTab={openNewTab} />
          )}
        </div>
      )}
      {text && (!hasNoBalance || !button?.href || !button?.text) && (
        <div className="points-subTitle">
          <BoldTextParts text={text} />
        </div>
      )}
      <AdaptiveCarousel
        quantity={list?.length}
        quantityToCarousel={8}
        activeBlur={false}
        forceCarousel={!!isMobile}
        propsCarousel={{
          gapItems: 0,
          qtyItems: !isWidthMobile ? 7 : 2,
          itemWidthProp: 138,
        }}
      >
        {list?.map((itemPoint, index) => (
          <PointsCard
            key={`${itemPoint?.text}-${index}`}
            icon={icon}
            image={itemPoint?.image}
            points={itemPoint?.quantity}
            text={itemPoint?.text}
          />
        ))}
        {lastList && lastList?.isVisible && !isWidthMobile && (
          <PointsTextCard
            key={lastList?.title}
            text={lastList?.title}
            redirect={lastList?.redirect}
            onClick={lastList?.onClick}
            className="pointItemButton"
            textColor={colorConfig?.textColor}
            containerColor={colorConfig?.containerColor}
          />
        )}
      </AdaptiveCarousel>
      {isWidthMobile &&
        (lastList?.isVisible ? (
          <ButtonPoints
            text={lastList?.title}
            href={lastList?.redirect}
            className="last-list-button"
          />
        ) : (
          <ButtonPoints {...button} openNewTab={openNewTab} />
        ))}
    </div>
  )
}
