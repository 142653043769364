import type { Especifications } from '../types/Especifications'

export const formatSeals = (
  productRestriction: string[],
  restrictionTags: string[] | undefined
) => {
  const especificationsFirstTwo = [...productRestriction].slice(0, 2)

  const hasRequiredRestrictions = especificationsFirstTwo.every((item) =>
    restrictionTags?.some((requiredItem) => requiredItem === item)
  )

  const totalLength = [...productRestriction].length
  const HasMoreThanTwoEspecifications =
    totalLength > 2
      ? [...especificationsFirstTwo, '+']
      : especificationsFirstTwo

  const productEspecificationsAll = (
    especificationsFirstTwo.length === 2 && hasRequiredRestrictions
      ? [especificationsFirstTwo[0], '+']
      : HasMoreThanTwoEspecifications
  ) as Especifications[]

  return { productEspecificationsAll }
}
