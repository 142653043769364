import React from 'react'

import Spinner from '../../../../assets/SpinnerIcon'
import { useProductWithLowStock } from '../../../../hooks/useProductWithLowStock'
import { TabloidBanners } from '../TabloidBanners'
import { TabloidProductCard } from '../TabloidProductCard'

interface TabloidCardProps {
  sectionName: string
  products: any
  menuAutoNavigation: (index: number, sectionName: string) => void
  categoryBanners?: {
    src?: string
    srcMobile?: string
  }[]
  isUserRegion: boolean
  index: number
}

export const TabloidCard = ({
  sectionName,
  products,
  menuAutoNavigation,
  categoryBanners,
  isUserRegion,
  index,
}: TabloidCardProps) => {
  const { productsWithLowStock, loading } = useProductWithLowStock(products)

  if (!sectionName || !productsWithLowStock?.length) return null

  if (loading) {
    return (
      <div className="loader-container">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="tabloid-section" key={sectionName}>
      <TabloidBanners
        mobileBanner={{
          url: categoryBanners?.[index]?.srcMobile ?? '',
          width: 288,
          height: 80,
        }}
        desktopBanner={{
          url: categoryBanners?.[index]?.src ?? '',
          width: 1412,
          height: 144,
        }}
        alt="Banner promocional"
      />

      <h2 id={sectionName}>{sectionName}</h2>
      <div className="tabloid-products-container">
        {productsWithLowStock.map((product) => (
          <TabloidProductCard
            key={product.id}
            sectionName={sectionName}
            product={product}
            position={index}
            menuAutoNavigation={menuAutoNavigation}
            isUserRegion={isUserRegion}
          />
        ))}
      </div>
    </div>
  )
}
