import { graphql, useStaticQuery } from 'gatsby'

const useCmsRegionalizationInfos = () => {
  const data = useStaticQuery(graphql`
    query RegionalizationInfosQuery {
      cmsRegionalization {
        sections {
          data
        }
      }
    }
  `)

  const pricesAndHours = data?.cmsRegionalization?.sections?.[0]?.data
  const pickupConfirmScreenText =
    data?.cmsRegionalization?.sections?.[1]?.data?.deliveryInformation

  return { pricesAndHours, pickupConfirmScreenText }
}

export default useCmsRegionalizationInfos
