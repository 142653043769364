import React from 'react'

import { Shortcut } from './Shortcut'
import { useCardContext } from '../../../contexts/CardContext'

import type { Shortcut as CmsShortcut } from '../../../types/ClubCard/ClubCardUser'

interface PointsShortcutProps extends CmsShortcut {
  location: string
}

export function PointsShortcut({ label, icon, location }: PointsShortcutProps) {
  const { pointsUrl } = useCardContext()

  if (pointsUrl?.error) {
    return null
  }

  return (
    <Shortcut
      text={label}
      icon={icon}
      href={pointsUrl?.data ?? '/'}
      location={location}
      openNewTab
      isLoading={pointsUrl?.loading}
    />
  )
}
