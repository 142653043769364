import { Modal } from '@faststore/ui'
import React from 'react'

import CloseIconV2 from '../../../../assets/CloseIconV2'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'

import './telesales-modal.scss'

interface Props {
  isOpen: boolean
  handleClose: () => void
  modalContent: ModalContent | null
  href: string
  isClub: boolean | null
}

export type ModalContent = {
  title: string
  subtitle: string
  action: 'login' | 'logout'
}

export const TelesalesModal = ({
  isOpen,
  handleClose,
  modalContent,
  href,
  isClub,
}: Props) => {
  const { loginUrl, accountUrl } = useStoreContext()
  const { genericTaggingEvent } = useAnalyticsEvents()

  const returnUrl = encodeURIComponent(href)

  const actionUrl =
    modalContent?.action === 'login'
      ? `${loginUrl}?returnUrl=${returnUrl}`
      : `${accountUrl}`

  return (
    <Modal isOpen={isOpen} onDismiss={handleClose}>
      <div className="modal-container">
        <button
          onClick={() => {
            isClub
              ? genericTaggingEvent('botao_fechar_fazer_login_clienteclube', {
                  click_text: 'Fechar',
                })
              : genericTaggingEvent('botao_fechar_fazer_login_possui_conta', {
                  click_text: 'Fechar',
                })
            handleClose()
          }}
          className="close-button"
        >
          <CloseIconV2 color="#8D8D8D" width={20} height={20} />
        </button>
        <h1>{modalContent?.title}</h1>
        <h2>{modalContent?.subtitle}</h2>
        <a href={actionUrl}>
          <button
            onClick={() => {
              isClub
                ? genericTaggingEvent('botao_fazer_login_clienteclube', {
                    click_text: 'Fazer Login',
                  })
                : genericTaggingEvent('botao_fazer_login_conta', {
                    click_text: 'Fazer Login',
                  })
            }}
          >
            Fazer Login
          </button>
        </a>
      </div>
    </Modal>
  )
}
