import { Carousel } from '@plurix/ecom-components'
import React, { memo } from 'react'

import { useRepeatOrders } from '../../../hooks/useRepeatOrders'
import { useRepeatOrdersPersistence } from '../../../hooks/useRepeatOrdersPersistence'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import OrderCard from '../../sections/RepeatOrder/OrderCard'

import './repeat-order-section.scss'

const RepeatOrderSection = () => {
  const { width } = useWindowDimensions()
  const { lastOrders } = useRepeatOrders(4)
  const { repeatedOrders, repeatOrder } = useRepeatOrdersPersistence(lastOrders)

  if (!lastOrders?.length) {
    return null
  }

  const hasArrow = () => {
    if (width && width > 600) {
      if (width > 980 && lastOrders.length <= 3) return false

      return true
    }

    return false
  }

  return (
    <section
      id="repeat-order-section"
      className="grid-section grid-content mb-unset"
    >
      <div className="repeat-order-section-container">
        <div className="repeat-order-section-header">
          <h2 className="repeat-order-section-title">Repetir Pedido</h2>
        </div>

        <Carousel
          bullet={{
            bulletEnableColor: 'var(--color-main)',
            bulletDisableColor: 'var(--color-grayScale-dark)',
            isVisible: !(width && width > 1279 && lastOrders.length < 4),
            style: {
              width: 8,
              height: 8,
              margin: '0px 2px',
              border: 0,
              borderRadius: '50%',
              padding: '0',
            },
          }}
          arrow={{
            isVisible: hasArrow(),
            iconColor: 'var(--color-infoFeedback)',
            style: {
              width: 24,
              height: 24,
              borderRadius: 0,
              cursor: 'pointer',
              backgroundColor: 'transparent',
              margin: 0,
            },
          }}
        >
          {lastOrders.map((order) => {
            const { creationDate, items, orderId, type } = order

            if (!items.length) {
              return
            }

            const date = new Date(creationDate)
            const formattedDate = date.toLocaleDateString('pt-BR')

            return (
              <OrderCard
                key={orderId}
                type={type}
                creationDate={formattedDate}
                items={items}
                orderId={orderId}
                itemsQuantity={items.length - 3 > 0 ? items.length - 2 : 0}
                isRepeated={repeatedOrders.includes(orderId)}
                repeatOrder={repeatOrder}
              />
            )
          })}
        </Carousel>
      </div>
    </section>
  )
}

export default memo(RepeatOrderSection)
