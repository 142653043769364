import useSlider from '@faststore/ui/dist/hooks/useSlider/useSlider'
import Bullets from '@faststore/ui/dist/molecules/Bullets'
import {
  RightArrowIcon,
  LeftArrowIcon,
} from '@faststore/ui/dist/molecules/Carousel/Arrows'
import useSlideVisibility from '@faststore/ui/dist/molecules/Carousel/hooks/useSlideVisibility'
import IconButton from '@faststore/ui/dist/molecules/IconButton'
import React, { createElement, useEffect, useMemo, useState } from 'react'

import { customCarouselEvents } from './customCarouselEvents'
import { customCarouselProps } from './customCarouselProps'
import { HAS_WINDOW } from '../../../constants'

interface TransformMap {
  [key: number]: number
}

interface CustomCarouselProps {
  autoPlay?: boolean
  timeInterval?: number
  infiniteMode?: boolean
  controls?: string
  testId?: string
  transition?: {
    duration: number
    property: string
  }
  children?: React.ReactNode[]
  id?: string
  swipeableConfigOverrides?: object
}

const createTransformValues = (infinite: boolean, totalItems: number) => {
  const transformMap: TransformMap = {}
  const slideWidth = 100 / totalItems

  for (let idx = 0; idx < totalItems; ++idx) {
    const currIdx = infinite ? idx - 1 : idx
    const transformValue = -(slideWidth * idx)

    transformMap[currIdx] = transformValue
  }

  return transformMap
}

function CustomCarousel({
  autoPlay,
  timeInterval = 5000,
  infiniteMode = true,
  controls = 'complete',
  testId = 'store-carousel',
  transition = {
    duration: 400,
    property: 'transform',
  },
  children,
  id = 'store-carousel',
  ...swipeableConfigOverrides
}: CustomCarouselProps) {
  const [pause, setPause] = useState(true)

  const childrenArray = React.Children.toArray(children)
  const childrenCount = childrenArray.length

  const { handlers, slide, sliderState, sliderDispatch } = useSlider({
    totalItems: childrenCount,
    itemsPerPage: 1,
    infiniteMode,
    ...swipeableConfigOverrides,
  })

  const {
    numberOfSlides,
    showNavigationArrows,
    showPaginationBullets,
    slides,
    disablePrevious,
    disableNext,
    pausedWithAutoPlay,
  } = customCarouselProps(
    childrenCount,
    infiniteMode,
    controls,
    children,
    sliderState,
    pause,
    autoPlay
  )

  const { onMouseEnter, onMouseLeave, transitionStyle, onTransitionEnd } =
    customCarouselEvents(
      autoPlay,
      setPause,
      transition,
      sliderState,
      childrenCount,
      sliderDispatch
    )

  const transformValues = useMemo(
    () => createTransformValues(infiniteMode, numberOfSlides),
    [numberOfSlides, infiniteMode]
  )

  const { isItemVisible, shouldRenderItem } = useSlideVisibility({
    itemsPerPage: sliderState.itemsPerPage,
    currentSlide: sliderState.currentItem,
    totalItems: childrenCount,
  })

  const slidePrevious = () => {
    if (disablePrevious) {
      return
    }

    slide('previous', sliderDispatch)
  }

  const slideNext = () => {
    if (disableNext) {
      return
    }

    slide('next', sliderDispatch)
  }

  // accessible behavior for tablist
  const handleBulletsKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowLeft': {
        slidePrevious()
        break
      }

      case 'ArrowRight': {
        slideNext()
        break
      }

      case 'Home': {
        slide(0, sliderDispatch)
        break
      }

      case 'End': {
        slide(childrenCount - 1, sliderDispatch)
        break
      }

      default:
    }
  }

  useEffect(() => {
    if (!pausedWithAutoPlay) {
      return
    }

    const interval = setInterval(() => slideNext(), timeInterval)

    return () => clearInterval(interval)
  }, [pause, HAS_WINDOW, autoPlay])

  return createElement(
    'section',
    {
      onMouseEnter,
      onMouseLeave,
      id,
      'data-store-carousel': true,
      'data-testid': testId,
      'aria-label': 'carousel',
      'aria-roledescription': 'carousel',
    },
    createElement(
      'div',
      {
        'data-carousel-track-container': true,
        style: { overflow: 'hidden', width: '100%' },
        ...handlers,
      },
      createElement(
        'div',
        {
          'data-carousel-track': true,
          style: {
            display: 'flex',
            transition: transitionStyle,
            width: `${numberOfSlides * 100}%`,
            transform: `translate3d(${
              transformValues[sliderState.currentPage]
            }%, 0, 0)`,
          },
          onTransitionEnd,
          'aria-live': 'polite',
        },
        slides.map((currentSlide, idx) =>
          React.createElement(
            'div',
            {
              role: 'tabpanel',
              'aria-roledescription': 'slide',
              key: idx,
              id: `carousel-item-${idx}`,
              'data-carousel-item': true,
              style: { width: '100%' },
              'data-visible':
                isItemVisible(idx - Number(infiniteMode)) || undefined,
            },
            shouldRenderItem(idx - Number(infiniteMode)) ? currentSlide : null
          )
        )
      )
    ),
    showNavigationArrows &&
      React.createElement(
        'div',
        { 'data-carousel-controls': true },
        React.createElement(IconButton, {
          'aria-label': 'previous',
          'aria-controls': id,
          onClick: slidePrevious,
          icon: React.createElement(LeftArrowIcon, null),
        }),
        React.createElement(IconButton, {
          'aria-label': 'next',
          'aria-controls': id,
          onClick: slideNext,
          icon: React.createElement(RightArrowIcon, null),
        })
      ),
    showPaginationBullets &&
      React.createElement(
        'div',
        { 'data-carousel-bullets': true },
        React.createElement(Bullets, {
          tabIndex: 0,
          totalQuantity: childrenCount,
          activeBullet: sliderState.currentPage,
          onClick: (_, idx) => {
            if (sliderState.sliding) {
              return
            }

            slide(idx, sliderDispatch)
          },
          ariaControlsGenerator: (idx) => `carousel-item-${idx}`,
          onKeyDown: handleBulletsKeyDown,
          onFocus: (event) => {
            event.currentTarget.focus()
          },
        })
      )
  )
}

export default CustomCarousel
