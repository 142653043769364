import React from 'react'

import { useClubContext } from '../../../../../contexts/ClubContext'
import { useStoreContext } from '../../../../../contexts/StoreContext'
import { ProductDetailsPrices } from '../../../ProductDetails/components/ProductDetailsPrices'

import type { ProductsQueryResponse } from '../../../../../types/Product'

interface TelesalesPriceProps {
  data: ProductsQueryResponse['node']
  defaultScListPrice?: number
  clubPrice?: number
}

export const TelesalesModalPrice = ({
  data,
  defaultScListPrice,
  clubPrice,
}: TelesalesPriceProps) => {
  const { hasClub } = useStoreContext()
  const { isClubClient } = useClubContext()

  const {
    measurementUnit,
    unitMultiplier,
    customOffers: {
      listPriceCustom,
      spotPriceKg,
      spotPriceCustom,
      hasDiscount,
    },
  } = data

  const clubValidation = !!(hasClub && isClubClient && defaultScListPrice)

  return (
    <article className="telesales-modal__price-container">
      <ProductDetailsPrices
        listPriceCustom={listPriceCustom}
        spotPriceKg={spotPriceKg}
        spotPriceCustom={spotPriceCustom}
        hasDiscount={hasDiscount}
        unitMultiplier={unitMultiplier}
        measurementUnit={measurementUnit}
        clubValidation={clubValidation}
        clubPrice={clubPrice}
        defaultScListPrice={defaultScListPrice}
        isClubClient={isClubClient}
      />
    </article>
  )
}
