import React from 'react'

import { Image } from '../Image'
import './product-card-top-flag.scss'

interface ProductCardTopFlagProps {
  colorCode?: string
  flagText?: string
  flagIcon?: string
  colorText?: string
}

export const ProductCardTopFlag = ({
  colorCode,
  flagText,
  flagIcon,
  colorText,
}: ProductCardTopFlagProps) => {
  return (
    <div
      className="product-card-top-flag"
      style={{ backgroundColor: colorCode }}
    >
      <p className="product-card-top-text" style={{ color: colorText }}>
        {flagText}
      </p>
      {flagIcon && (
        <div className="product-card-top-flag-icon">
          <Image
            objectFit="contain"
            baseUrl={flagIcon}
            layout="fullWidth"
            width={16}
            height={15}
            backgroundColor="var(--color-grayScale-faded)"
            options={{
              fitIn: true,
            }}
            alt={`Icone da promoção ${flagText}`}
            loading="lazy"
          />
        </div>
      )}
    </div>
  )
}
