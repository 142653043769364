import React from 'react'

import { useLocalStorageContext } from '../../contexts/LocalStorageContext'
import { HistorySearchShelf } from '../sections/HistorySearchShelf'

interface YouMayAlsoLikeShelfProps {
  data?: { isActive: boolean }
}

export const YouMayAlsoLikeShelf = ({ data }: YouMayAlsoLikeShelfProps) => {
  const { lastSearched } = useLocalStorageContext()

  const term = lastSearched?.[0]?.text

  if (!data?.isActive || !term) {
    return null
  }

  return <HistorySearchShelf term={term} />
}
