import React, { useState } from 'react'

import type { CampaignCommonQuestionsData } from '../../../../types/Campaigns'

import './campaign-common-questions.scss'
import ArrowIcon from '../../../../assets/ArrowIcon'

import { Skeleton } from '@acctglobal/skeleton'

export const CampaignCommonQuestions = ({
  commonQuestions,
  loading,
}: CampaignCommonQuestionsData) => {
  const [questionSelected, setQuestionSelected] = useState<number>(0)

  if (loading) {
    return (
      <section className="grid-content">
        <Skeleton
          width="100%"
          height={280}
          backgroundColor="var(--color-main-lightest)"
          animation
        />
      </section>
    )
  }

  return (
    <section className="grid-content">
      <div className="campaignCommonQuestionsContainer">
        <p className="title">Perguntas frequentes</p>
        {commonQuestions.map(({ answer, question }, idx) => {
          const newId = idx + 1
          const selected = questionSelected === newId

          return (
            <section
              className="content"
              onClick={() => {
                setQuestionSelected(selected ? 0 : newId)
              }}
            >
              <label className="question">
                {question}{' '}
                <ArrowIcon
                  width="24"
                  rotate={selected ? 'Up' : 'Down'}
                  color="var(--color-main)"
                />
              </label>
              {selected && <p className="answer">{answer}</p>}
            </section>
          )
        })}
      </div>
    </section>
  )
}
