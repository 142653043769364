import axios from 'axios'
import { useEffect, useState } from 'react'

import { useRegionalizationContext } from '../contexts/RegionalizationContext'
import { useSession } from '../sdk/session'
import { createCookie, deleteCookie } from '../utils/cookies'

import type { ProductsData } from '../types/RepeatOrder'

export const useMostPurchased = () => {
  const [loadingSkus, setLoadingSkus] = useState(false)
  const [skus, setSkus] = useState<string[]>([])

  const [loadingProducts, setLoadingProducts] = useState(false)
  const [products, setProducts] = useState<ProductsData['products']>([])

  const loading = loadingSkus || loadingProducts

  const { postalCode } = useRegionalizationContext()
  const { channel } = useSession()

  const PAGE_SIZE_SHELF = 5

  useEffect(() => {
    const fetchSkus = async () => {
      try {
        if (!postalCode || postalCode.length < 9) {
          return
        }

        setLoadingSkus(true)

        const { data } = await axios.get<string[]>(
          '/api/v2/products/mostPurchased',
          {
            params: {
              builder: 'faststore',
              postalCode,
              origin: 'home',
              pageSize: PAGE_SIZE_SHELF,
              random: Math.random(),
            },
          }
        )

        if (data && Array.isArray(data)) {
          setSkus(data)
          createCookie('mostpurchasedhome', JSON.stringify(data))
        }
      } catch {
        // Delete cached data when authentication expires
        deleteCookie('mostpurchasedhome')
        setSkus([])
      } finally {
        setLoadingSkus(false)
      }
    }

    fetchSkus()
  }, [postalCode])

  useEffect(() => {
    if (skus.length <= 0 || !channel) {
      return
    }

    const fetchProducts = async () => {
      try {
        setLoadingProducts(true)

        const {
          data: { products: productsResponse },
        } = await axios.post<ProductsData>('/api/products/getProductsByIds', {
          ids: skus.slice(0, 5),
          channel,
        })

        if (productsResponse && Array.isArray(productsResponse)) {
          setProducts(productsResponse)
        }
      } catch {
        setProducts([])
      } finally {
        setLoadingProducts(false)
      }
    }

    fetchProducts()
  }, [skus, channel])

  return { loading, products }
}
