import React from 'react'

import { useFAQContext } from '../../../../contexts/FAQContext'
import { textHighlighted } from '../../../../utils/dropdownSearchFunctions'
import Button from '../../../ui/Button'

interface FAQInputDropdownProps {
  questionSearch?: string
  setQuestionSearch?: (questionSearch: string) => void
  filteredQuestions?: {
    topic: string
    questions: {
      question: string
      answer: string
    }[]
  }[]
}

export const FAQInputDropdown = ({
  questionSearch,
  filteredQuestions,
}: FAQInputDropdownProps) => {
  const { FAQsuggestionNavigation } = useFAQContext()

  if (!filteredQuestions || filteredQuestions?.length <= 0) {
    return null
  }

  return (
    <div className="faq-input-dropdown-container">
      <div className="faq-input-dropdown-subcontainer">
        <h2 className="faq-input-dropdown-title">SUGESTÕES</h2>
        {filteredQuestions?.map((suggestion) => {
          const questions = suggestion.questions.map((item) => item.question)
          const { topic } = suggestion

          return questions.map((question) => {
            return (
              <Button
                className="faq-input-dropdown-item"
                onClick={() => FAQsuggestionNavigation?.(question, topic)}
                key={question}
              >
                <span
                  className="faq-input-dropdown-item-text"
                  dangerouslySetInnerHTML={{
                    __html: textHighlighted(question, questionSearch ?? ''),
                  }}
                />
              </Button>
            )
          })
        })}
      </div>
    </div>
  )
}
