import { Link } from 'gatsby'
import React from 'react'

import { ChatIcon } from '../../../../assets/faqIcons'
import { useFAQContext } from '../../../../contexts/FAQContext'
import { useStoreContext } from '../../../../contexts/StoreContext'

import './faq-virtual-service-cards.scss'

export const FAQVirtualServiceCards = () => {
  const { chatUrl } = useStoreContext()
  const { isMobile } = useFAQContext()

  return (
    <div className="faq-virtual-service">
      <Link
        to={chatUrl}
        style={{ cursor: 'pointer' }}
        className="faq-virtual-service-container"
      >
        <div className="faq-virtual-service-header">
          <ChatIcon />
          <h3 className="faq-virtual-service-icon-title">
            Falar com Atendente
          </h3>
        </div>
      </Link>

      {!isMobile && (
        <p className="faq-virtual-service-icon-title">
          O atendimento pode demorar mais um pouco, mas aqui você consegue
          esclarecer alguma dúvida que você não conseguiu esclarecer na nossa
          sessão de Atendimento e Solução de Problemas
        </p>
      )}
    </div>
  )
}
