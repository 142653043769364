import { navigate } from 'gatsby'
import React from 'react'

import { useClubContext } from '../../../contexts/ClubContext'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { showSection } from '../../../utils/showSection'

import type { showSectionVariables } from '../../../utils/showSection'

import './club-advantages.scss'

interface Props {
  isActive: boolean
  title: string
  description: string
  list: {
    benefit: string
    icon: {
      src: string
      alt: string
    }
  }[]
  button: {
    title: string
    buttonRedirect: string
  }
  visibilityConfig: showSectionVariables
  colorConfig?: {
    titleColor?: string
    containerColor?: string
    buttonTextColor?: string
    descriptionColor?: string
  }
}

export const ClubAdvantages = (props: Props) => {
  const {
    isActive,
    title,
    description,
    list,
    button: { title: titleButton, buttonRedirect },
    visibilityConfig,
    colorConfig,
  } = props
  const { genericTaggingEvent } = useAnalyticsEvents()
  const { isClubClient } = useClubContext()

  if (!showSection(visibilityConfig) || !isActive) return

  return (
    <div id="club-advantages" className="container-club-advantages">
      <h1 style={{ color: colorConfig?.titleColor }}>{title}</h1>
      <h3 style={{ color: colorConfig?.descriptionColor }}>{description}</h3>
      <div className="container-icons">
        {list?.map(({ benefit, icon }, index) => (
          <div className="container-icon" key={`${benefit}-${index}`}>
            <img src={icon.src} alt={icon.alt} width={56} height={56} />
            <p>{benefit}</p>
          </div>
        ))}
      </div>
      {!isClubClient && (
        <button
          type="button"
          className="button-club-advantages"
          onClick={() => {
            navigate(buttonRedirect)
            genericTaggingEvent('friend_club_plus', {})
          }}
          style={{
            color: colorConfig?.buttonTextColor,
            backgroundColor: colorConfig?.containerColor,
          }}
        >
          {titleButton}
        </button>
      )}
    </div>
  )
}
