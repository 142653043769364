import React from 'react'

import { ProductsByIdsPLP } from '../components/PLP/ProductsByIdsPLP'
import { useLastSeenProducts } from '../hooks/lastSeenProducts/useLastSeenProducts'

export function LastSeenProductsPage() {
  const { isLoading, products } = useLastSeenProducts()

  return (
    <ProductsByIdsPLP
      title="O que te interessou"
      location="plp-ultimos-produtos-vistos"
      loading={isLoading}
      products={products}
    />
  )
}
