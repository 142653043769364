import './button-points.scss'

import { Link } from 'gatsby'
import React from 'react'

import { BoldTextParts } from '../../../../../common/BoldTextParts'

interface ButtonPointsProps {
  href?: string
  text?: string
  openNewTab?: boolean
  className?: string
}

export const ButtonPoints = ({
  text,
  href,
  openNewTab,
  className = '',
}: ButtonPointsProps) => {
  if (!text || !href) {
    return null
  }

  return (
    <Link
      className={`buttonPoints-infos-button ${className}`}
      to={href}
      target={openNewTab ? '_blank' : undefined}
    >
      <BoldTextParts text={text} />
    </Link>
  )
}
