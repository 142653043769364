import { Button } from '@faststore/ui'
import React from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { useRepeatOrdersPersistence } from '../../../../hooks/useRepeatOrdersPersistence'
import { formatBrazilianPrice } from '../../../../utils/formatPrices'
import { addManyProductsToCart } from '../../../../utils/products/addManyProductsToCart'

import type { ProductsQueryResponse } from '../../../../types/Product'
import type { CustomerOrder } from '../../../../types/RepeatOrder'

interface AddProductsToCartProps {
  order: CustomerOrder
  products: ProductsQueryResponse['node'][]
}

export const AddProductsToCart = ({
  order: { orderId, items, totals },
  products,
}: AddProductsToCartProps) => {
  const { repeatedOrders, repeatOrder } = useRepeatOrdersPersistence()
  const { genericTaggingEvent } = useAnalyticsEvents()

  const total =
    totals?.find((totalItem) => totalItem.id === 'Items')?.value || 0

  const isRepeated = repeatedOrders.includes(orderId)

  const handleRepeatOrder = async () => {
    addManyProductsToCart(products, items)
    repeatOrder(orderId)

    genericTaggingEvent('repeat_order', { name: 'add all products button' })
  }

  return (
    <div className="product-list-add-items">
      <div className="product-list-add-products-total">
        <span>Valor Total:&nbsp;</span>
        <span>{formatBrazilianPrice(total / 100)}</span>
      </div>

      <Button
        onClick={handleRepeatOrder}
        className={`product-list-add-products-btn${
          isRepeated ? '--disabled' : ''
        }`}
        disabled={isRepeated}
      >
        {isRepeated ? (
          <span>Adicionado ao carrinho</span>
        ) : (
          <span>Adicionar Pedido ao Carrinho</span>
        )}
      </Button>
    </div>
  )
}
