import React from 'react'

import { FilterCheck } from '../../../../assets/FilterCheck'
import './food-restriction-filter-Option.scss'

interface FoodRestrictionFilterOptionProps {
  isChecked: boolean
  text: string
  quantity: number
  triger: () => void
}

export const FoodRestrictionFilterOption = ({
  isChecked,
  text,
  quantity,
  triger,
}: FoodRestrictionFilterOptionProps) => {
  return (
    <button onClick={() => triger()} className="restriction-filter-option">
      <FilterCheck isChecked={isChecked} />
      <p className="restriction-filter-option-text">
        {text} ({quantity})
      </p>
    </button>
  )
}
