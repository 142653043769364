import axios from 'axios'
import { useEffect, useState } from 'react'

type ApiResponse = { coupons: Coupon[] } | { error: string }

interface Coupon {
  couponName: string
  couponValue: string
  couponDescription: string
  couponRules: string[]
  couponInitialDate: string
  couponExpirationDate: string
}

export const useHasActiveCoupons = () => {
  const [couponsLoading, setCouponsLoading] = useState(true)
  const [hasActiveCoupons, setHasActiveCoupons] = useState(false)

  useEffect(() => {
    const fetchCoupons = async () => {
      setCouponsLoading(true)

      try {
        const { data } = await axios.get<ApiResponse>(
          `/api/coupon/list?builder=faststore&random=${Math.random()}`
        )

        if ('coupons' in data && data.coupons.length > 0) {
          setHasActiveCoupons(true)
        } else {
          setHasActiveCoupons(false)
        }
      } finally {
        setCouponsLoading(false)
      }
    }

    fetchCoupons()
  }, [])

  return { couponsLoading, hasActiveCoupons }
}
