import { Modal, Button } from '@faststore/ui'
import { Carousel } from '@plurix/ecom-components'
import React, { useEffect, useState } from 'react'

import { hasPlusFriendBadge } from '../../../../../devFeatureToggle.json'
import { CloseIcon } from '../../../assets/CloseIcon'
import { GlassIcon } from '../../../assets/GlassIcon'
import { DiscountBadge } from '../../ui/Badge'
import { ClubProductTag } from '../../ui/ClubProductTag'
import { Image } from '../../ui/Image'

import type { ProductDetailsGallery } from '../../../types/ProductDetailsGallery'

export const GalleryImagesCarousel = ({
  productImages,
  listPriceCustom,
  spotPriceCustom,
  clubValidation,
  hasDiscount,
}: ProductDetailsGallery) => {
  const [selectedImage, setSelectedImage] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const layout = document.getElementById('layout')

  useEffect(() => {
    if (layout) {
      if (modalOpen) {
        layout.classList.add('no-scroll')
      } else {
        layout.classList.remove('no-scroll')
      }
    }
  }, [modalOpen])

  return (
    <>
      <div className="product-details__image-principal">
        {clubValidation ? (
          <ClubProductTag
            hasPlusFriendBadge={hasPlusFriendBadge}
            text="Clube"
          />
        ) : (
          <div className="discount-favorite">
            {hasDiscount && (
              <DiscountBadge
                listPrice={listPriceCustom}
                spotPrice={spotPriceCustom}
              />
            )}
          </div>
        )}
        <Carousel
          gapItems={0}
          bullet={{
            isVisible: false,
            numeric: productImages.length > 1,
          }}
          qtyItems={1}
        >
          {productImages.map((image, index) => (
            <div key={image.url}>
              <Image
                baseUrl={image.url}
                sourceWidth={720}
                aspectRatio={1}
                width={768}
                breakpoints={[250, 360, 480, 720]}
                layout="constrained"
                backgroundColor="var(--color-grayScale-faded)"
                options={{
                  fitIn: true,
                }}
                alt={image.alternateName}
                loading="lazy"
                objectFit="contain"
              />
              <Button
                className="zoom"
                onClick={() => {
                  setSelectedImage(index)
                  setModalOpen(true)
                }}
              >
                <GlassIcon />
              </Button>
            </div>
          ))}
        </Carousel>
      </div>

      <Modal className="modal-image" isOpen={modalOpen}>
        <div className="modal-info">
          <Button onClick={() => setModalOpen(false)}>
            <CloseIcon />
          </Button>
          {productImages.length > 1 && (
            <span>
              {selectedImage + 1}/{productImages.length}
            </span>
          )}
        </div>
        <Image
          baseUrl={productImages[selectedImage].url}
          sourceWidth={720}
          aspectRatio={1}
          width={768}
          breakpoints={[250, 360, 480, 720]}
          layout="constrained"
          backgroundColor="var(--color-grayScale-faded)"
          options={{
            fitIn: true,
          }}
          alt={productImages[selectedImage].alternateName}
          loading="lazy"
          objectFit="contain"
        />
      </Modal>
    </>
  )
}
