import { useMemo } from 'react'

import { pesavelUND } from '../constants'
import { useCart } from '../sdk/cart'
import { useProduct } from '../sdk/product/useProduct'
import { formatListPrice } from '../utils/formatPrices'

import type { CartItem } from '../sdk/cart'
import type { ProductsQueryResponse } from '../types/Product'

export const useBuyProduct = (product: ProductsQueryResponse['node']) => {
  const { items } = useCart()
  const { data } = useProduct(product.id, {
    product,
  })

  const additionalProperty = useMemo(() => {
    return data?.product.additionalProperty
  }, [data])

  const {
    unitMultiplier,
    measurementUnit,
    id,
    sku,
    gtin,
    brand,
    sellers,
    categoryTree,
    priceWithoutPromotions,
    isVariantOf,
    isVariantOf: { name },
    image: [img],
    offers: {
      offers: [{ seller }],
    },
    hasClubPrice,
    customOffers,
    customOffers: { listPriceCustom, spotPriceCustom },
    skuSpecifications,
  } = product

  const hasPesavelUnd = skuSpecifications?.some((specification) => {
    return (
      specification.field.originalName === pesavelUND &&
      specification.values.some((value) => value.originalName === 'true')
    )
  })

  const formattedListPrice = useMemo(
    () => formatListPrice(listPriceCustom, unitMultiplier, measurementUnit),
    [listPriceCustom, measurementUnit, unitMultiplier]
  )

  const buyProps: CartItem = {
    id,
    price: spotPriceCustom,
    listPrice: listPriceCustom,
    formattedListPrice,
    quantity: 1,
    seller,
    itemOffered: {
      unitMultiplier,
      measurementUnit,
      sellers,
      categoryTree,
      hasClubPrice,
      priceWithoutPromotions,
      customOffers,
      sku,
      name,
      image: [img],
      brand,
      isVariantOf,
      gtin,
      additionalProperty,
      skuSpecifications,
      hasPesavelUnd,
    },
  }

  const cartProduct = useMemo(() => {
    const productInCart = items?.find(
      (item: CartItem) => item.id.replace(/:.*$/, '') === id
    )

    return {
      cartProductId: productInCart?.id ?? id,
      cartProductQty: productInCart?.quantity ?? 1,
    }
  }, [id, items])

  const { cartProductId, cartProductQty } = cartProduct

  return {
    buyProps,
    cartProductId,
    cartProductQty,
    productData: data,
  }
}
