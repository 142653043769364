import React from 'react'

import CloseIconV2 from '../../../assets/CloseIconV2'

import './engagement-modal.scss'
import { Modal } from '@faststore/ui'

import { useStoreContext } from '../../../contexts/StoreContext'
import { Image } from '../../ui/Image'

interface EngagementModalProps {
  handleCloseModal: () => void
  isModalOpen: boolean
}

function EngagementModal({
  handleCloseModal,
  isModalOpen,
}: EngagementModalProps) {
  const { downloadModal, loyaltyClubDownloadImages, logo } = useStoreContext()

  if (!logo || !downloadModal || !loyaltyClubDownloadImages) return null

  const { banner, title, description } = downloadModal

  const { androidAppButton, iOSAppButton, androidAppLink, iOSAppLink } =
    loyaltyClubDownloadImages

  return (
    <Modal isOpen={isModalOpen}>
      <div className="engagement-modal">
        <button
          onClick={handleCloseModal}
          className="engagement-modal-close-button"
        >
          <CloseIconV2 color="#000" width={20} height={20} />
        </button>
        <div className="engagement-modal-infos">
          <span className="engagement-modal-title">{title}</span>

          {logo.image && (
            <img
              src={logo.image}
              alt={logo.alt}
              className="engagement-modal-logo"
            />
          )}

          <span className="engagement-modal-description">{description}</span>
          <div className="engagement-modal-download">
            <a href={iOSAppLink} target="_blank" rel="noreferrer">
              <Image
                baseUrl={iOSAppButton}
                alt="App Store"
                width={120}
                height={38}
                id="engagement-app-store"
              />
            </a>
            <a href={androidAppLink} target="_blank" rel="noreferrer">
              <Image
                baseUrl={androidAppButton}
                alt="Google Play"
                width={120}
                height={38}
                id="engagement-google-play"
              />
            </a>
          </div>
        </div>
        <div className="engagement-modal-image">
          <Image
            baseUrl={banner}
            alt="QR Code"
            width={330}
            height={414}
            className="engagement-modal-qr-code"
          />
        </div>
      </div>
    </Modal>
  )
}

export default EngagementModal
