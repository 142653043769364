import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React, { useState, useEffect, useMemo } from 'react'

import { useGetProductsByIds } from '../../../../hooks/useGetProductsByIds'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import {
  separateSkus,
  separateProductsByBrand,
} from '../../../../utils/highlightBrandsFunctions'
import { QuickView } from '../../QuickView'
import { HighlightBrandsCard } from '../HighlightBrandsCard'

import type { HighlightBrands } from '../../../../types/CMS'
import type { HighlightProductIndex } from '../../../../types/Product'
import './highlight-brands-carousel.scss'

interface HighlightBrandsCarouselProps {
  data?: HighlightBrands
}

export const HighlightBrandsCarousel = ({
  data: highlightBrands,
}: HighlightBrandsCarouselProps) => {
  const [openQuickView, setOpenQuickView] = useState(false)
  const [selectedHighlightIndex, setSelectedHighlightIndex] =
    useState<HighlightProductIndex>({})

  const { width, isMobile } = useWindowDimensions()

  const staleProductSkus = useMemo(
    () => separateSkus(highlightBrands),
    [highlightBrands]
  )

  const { loading, products } = useGetProductsByIds(staleProductSkus)

  const productsSeparatedByBrand = useMemo(() => {
    if (!highlightBrands) {
      return
    }

    return separateProductsByBrand(highlightBrands, products)
  }, [highlightBrands, products])

  useEffect(() => {
    !openQuickView && setSelectedHighlightIndex({})
  }, [openQuickView])

  const arrow = {
    isVisible: !!(width && width > 1280),
    iconColor: 'var(--color-main)',
    style: {
      margin: '5px',
      cursor: 'pointer',
      backgroundColor: 'var(--color-main-faded)',
    },
  }

  const bullet = {
    bulletEnableColor: 'var(--color-main)',
    bulletDisableColor: 'var(--color-grayScale-lightest)',
    isVisible: true,
    style: {
      width: 8,
      height: 8,
      margin: '0px 2px',
      borderRadius: '50%',
      padding: 0,
    },
  }

  if (loading) {
    return (
      <section className="highlight-brands-skeleton grid-content">
        <Skeleton
          width="100%"
          height={230}
          backgroundColor="var(--color-grayScale-faded)"
          borderRadius={8}
        />
      </section>
    )
  }

  if (!productsSeparatedByBrand?.length || !highlightBrands?.visibility) {
    return null
  }

  return (
    <>
      <section className="highlight-brands-carousel-container grid-content shelf-section-grid shelf-section-content-grid">
        {highlightBrands?.carouselTitle && (
          <div className="highlight-brands-carousel-header">
            <h2 className="highlight-brands-carousel-title">
              {highlightBrands.carouselTitle}
            </h2>
          </div>
        )}

        <Carousel arrow={arrow} bullet={bullet} gapItems={isMobile ? 4 : 8}>
          {productsSeparatedByBrand?.map(
            ({ logo, href, productSkus }, index) =>
              productSkus?.length ? (
                <HighlightBrandsCard
                  logo={logo}
                  href={href}
                  brandProducts={productSkus}
                  setSelectedHighlightIndex={setSelectedHighlightIndex}
                  brandIndex={index + 1}
                  setOpenQuickView={setOpenQuickView}
                  key={logo}
                />
              ) : null
          )}
        </Carousel>
      </section>

      {openQuickView &&
        selectedHighlightIndex?.brandIndex &&
        selectedHighlightIndex?.productIndex && (
          <QuickView
            openQuickView={openQuickView}
            setOpenQuickView={setOpenQuickView}
            product={
              productsSeparatedByBrand[selectedHighlightIndex?.brandIndex - 1]
                .productSkus[selectedHighlightIndex?.productIndex - 1]
            }
            openClubModal={false}
            setOpenClubModal={() => {}}
            addToCardEventLocation={`destaque ${
              productsSeparatedByBrand[selectedHighlightIndex?.brandIndex - 1]
                .productSkus[selectedHighlightIndex?.productIndex - 1].brand
                .name
            }`}
          />
        )}
    </>
  )
}
