import React from 'react'

export const MobileIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_42802_4717"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_42802_4717)">
        <path
          d="M10.5 20H14.5V19H10.5V20ZM7.5 23C6.95 23 6.47917 22.8042 6.0875 22.4125C5.69583 22.0208 5.5 21.55 5.5 21V3C5.5 2.45 5.69583 1.97917 6.0875 1.5875C6.47917 1.19583 6.95 1 7.5 1H17.5C18.05 1 18.5208 1.19583 18.9125 1.5875C19.3042 1.97917 19.5 2.45 19.5 3V21C19.5 21.55 19.3042 22.0208 18.9125 22.4125C18.5208 22.8042 18.05 23 17.5 23H7.5ZM7.5 18V21H17.5V18H7.5ZM7.5 16H17.5V6H7.5V16ZM7.5 4H17.5V3H7.5V4Z"
          fill="#2C3490"
        />
      </g>
    </svg>
  )
}
