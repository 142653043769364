import useWindowDimensions from './useWindowDimensions'

import type { CarouselProps } from '@plurix/ecom-components/dist/packages/Carousel/Carousel'

export type CarouselPropsWithoutChildren = Omit<CarouselProps, 'children'>

export function useVerticalShelfCarouselProps(): CarouselPropsWithoutChildren {
  const { isMobile } = useWindowDimensions()

  return {
    arrow: { isVisible: false },
    gapItems: 8,
    itemWidthProp: 290,
    bullet: { isVisible: false },
    isMobile,
  }
}
