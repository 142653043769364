import React from 'react'

interface AlertCloseIconProps {
  color?: string
}

const CloseIcon = ({ color }: AlertCloseIconProps) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12 4-8 8M4 4l8 8"
      stroke={color ?? 'var(--color-grayScale)'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CloseIcon
