import { formatCategoryList } from './formatCategoryList'
import { formatListPrice } from './formatPrices'

import type { CartItem } from '../sdk/cart'
import type { Product, ProductsQueryResponse } from '../types/Product'

interface FormatItemTaggingProps {
  [key: string]: string | number | null | undefined
}

export const formatItemTagging = (item?: CartItem) => {
  const productItem: FormatItemTaggingProps = {
    item_name: item?.itemOffered?.name ?? null,
    item_id: item?.itemOffered?.sku ?? null,
    price: item?.price ?? null,
    item_brand: item?.itemOffered?.brand?.name ?? null,
    item_category: null,
    item_variant: item?.itemOffered?.isVariantOf?.name ?? null,
    quantity: item?.quantity ?? null,
  }

  const categoryList = formatCategoryList(item?.itemOffered?.categoryTree)

  if (!categoryList || !categoryList.length) {
    return productItem
  }

  delete productItem.item_category

  categoryList.forEach((category, index) => {
    productItem[`item_category${index === 0 ? '' : index + 1}`] = category
  })

  return productItem
}

export const formatViewListItemTagging = (
  productList:
    | ProductsQueryResponse[]
    | ProductsQueryResponse['node'][]
    | Product[],
  listName?: string
) => {
  const formatedItem: { [key: string]: string | number | undefined }[] = []

  productList.map((product: any) => {
    if (product?.node) {
      formatedItem.push({
        item_id: product.node.id,
        item_name: product.node.name,
        item_brand: product.node.brand?.name,
        ...fomatedCategoryItem(product.node.categoryTree),
        item_list_name: listName ?? '',
        price: formatListPrice(
          product.node.offers?.offers?.[0]?.price,
          product.node.unitMultiplier,
          product.node.measurementUnit
        ),
        quantity: 1,
      })
    } else {
      formatedItem.push({
        item_id: product?.id,
        item_name: product?.name,
        item_brand: product?.brand?.name,
        ...fomatedCategoryItem(product?.categoryTree),
        item_list_name: listName ?? '',
        price: formatListPrice(
          product?.offers?.offers?.[0]?.price,
          product?.unitMultiplier,
          product?.measurementUnit
        ),
        quantity: 1,
      })
    }
  })

  return formatedItem
}

const fomatedCategoryItem = (categories: string[]) => {
  const categoryFormated: { [key: string]: string } = {}
  const ordenedCategories = [...categories].reverse()

  ordenedCategories?.forEach((item, idx) => {
    categoryFormated[`item_category${idx > 0 ? idx : ''}`] =
      item?.split('/')?.[idx + 1] ?? ''
  })

  return categoryFormated
}

export const formattedSelectItem = (product: ProductsQueryResponse['node']) => {
  const productItem: FormatItemTaggingProps = {
    item_id: product?.isVariantOf?.productGroupID,
    item_name: product?.isVariantOf?.name,
    item_brand: product?.brand?.name ?? null,
    ...fomatedCategoryItem(product?.categoryTree),
    price: formatListPrice(
      product?.offers?.offers?.[0]?.price,
      product?.unitMultiplier,
      product?.measurementUnit
    ),
  }

  return productItem
}
