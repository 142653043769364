import { Skeleton } from '@acctglobal/skeleton'
import React, { useMemo, useState } from 'react'

import { useCurrentOrder } from '../../../../hooks/useCurrentOrder'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { OrderStatus } from '../components/Status/OrderStatus'

export interface Order {
  creationDate: string
  status: string
  orderId: string
  deliveryChannel: string[]
  shippingData: ShippingData
}

export interface ShippingData {
  logisticsInfo: LogisticsInfo[]
}

export interface LogisticsInfo {
  deliveryWindow: DeliveryWindow
}

export interface DeliveryWindow {
  startDateUtc: string
  endDateUtc: string
}

export const OrderStatusHandler = () => {
  const { loading, orderDeliveryMethod, shippingEstimate, hasOrder, orderId } =
    useCurrentOrder()

  const { isMobile } = useWindowDimensions()

  const [hiddenPopUp, setHiddenPopUp] = useState(false)

  const content = useMemo(() => {
    if ((!hasOrder && !loading) || hiddenPopUp) {
      return null
    }

    return (
      <OrderStatus
        closeButtonHandler={isMobile ? undefined : () => setHiddenPopUp(true)}
        isPopUp={!isMobile}
        orderId={orderId}
        shippingMethod={orderDeliveryMethod}
        shippingEstimate={shippingEstimate}
      />
    )
  }, [
    orderId,
    orderDeliveryMethod,
    shippingEstimate,
    isMobile,
    hasOrder,
    hiddenPopUp,
  ])

  if (loading && isMobile) {
    return (
      <section style={{ width: '100%', marginTop: '16px' }}>
        <Skeleton
          height={70}
          width="100%"
          backgroundColor="var(--color-grayScale-faded)"
        />
      </section>
    )
  }

  if (isMobile && hasOrder) {
    return <div className="grid-content">{content}</div>
  }

  return content
}
