import React from 'react'

import { formatBrazilianPrice } from '../../../utils/formatPrices'

interface PriceCustomProps {
  price?: number
  className?: string
  id?: string
}

export const PriceCustom = ({ price, className, id }: PriceCustomProps) => {
  if (!price) {
    return null
  }

  return (
    <span className={className} data-testid={id}>
      {formatBrazilianPrice(price)}
    </span>
  )
}
