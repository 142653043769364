import axios from 'axios'
import { useState, useRef, useCallback, useEffect, useMemo } from 'react'

import { HAS_WINDOW } from '../../constants'
import { useRegionalizationContext } from '../../contexts/RegionalizationContext'
import { useGetProductsByIds } from '../useGetProductsByIds'
import { eventEmitter, LOCAL_STORAGE_KEY } from './lastSeenProductsConstants'

type LastSeenProductsResponse = {
  lastSeenProducts: string[]
  hasSeeMore: boolean
}

export function useLastSeenProducts(limit?: number) {
  const [apiData, setApiData] = useState<LastSeenProductsResponse>()
  const [loadingApiData, setLoadingApiData] = useState(false)
  const { postalCode } = useRegionalizationContext()
  const lastSeenProductsRef = useRef<string>('')

  const fetchLastSeenProducts = useCallback(async () => {
    if (!HAS_WINDOW) {
      return
    }

    // User is typing the postal code
    if (postalCode && postalCode.length < 9) {
      return
    }

    try {
      const storageLastSeenProducts = localStorage.getItem(LOCAL_STORAGE_KEY)

      setLoadingApiData(true)

      const { data } = await axios.get<LastSeenProductsResponse>(
        '/api/loyalty/lastSeenProducts',
        {
          params: {
            postalCode,
            limit,
            storageLastSeenProducts,
          },
        }
      )

      setApiData(data)
    } catch {
      setApiData(undefined)
    } finally {
      setLoadingApiData(false)
    }
  }, [postalCode])

  useEffect(() => {
    fetchLastSeenProducts()
  }, [fetchLastSeenProducts])

  // Listening updates to refetch API
  useEffect(() => {
    const handleLastSeenProductsUpdated = (newProducts: string) => {
      if (newProducts !== lastSeenProductsRef.current) {
        lastSeenProductsRef.current = newProducts
        fetchLastSeenProducts()
      }
    }

    eventEmitter.on('lastSeenProductsUpdated', handleLastSeenProductsUpdated)

    return () => {
      eventEmitter.off('lastSeenProductsUpdated', handleLastSeenProductsUpdated)
    }
  }, [fetchLastSeenProducts])

  const { products, loading } = useGetProductsByIds(apiData?.lastSeenProducts)

  const isLoading = useMemo(() => {
    return loadingApiData || loading
  }, [loadingApiData, loading])

  return { products, hasSeeMore: apiData?.hasSeeMore, isLoading }
}
