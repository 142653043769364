import axios from 'axios'
import { useState, useEffect } from 'react'

import { useSession } from '../sdk/session'

import type { ProductsData } from '../types/RepeatOrder'

export function useGetProductsByIds(ids?: string[]) {
  const [products, setProducts] = useState<ProductsData['products']>([])
  const [loading, setLoading] = useState(false)
  const { channel } = useSession()

  useEffect(() => {
    async function fetchProductsDetails() {
      try {
        setLoading(true)

        const {
          data: { products: productsDetailsList },
        } = await axios.post<ProductsData>('/api/products/getProductsByIds', {
          ids,
          channel,
        })

        setProducts(productsDetailsList)
      } catch {
        setProducts([])
      } finally {
        setLoading(false)
      }
    }

    if (ids?.length) {
      fetchProductsDetails()
    }
  }, [JSON.stringify(ids), JSON.stringify(channel)])

  return { loading, products }
}
