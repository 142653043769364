import React, { useEffect, useState, useCallback } from 'react'

import { MobileFilterModal } from './MobileFilterModal'
import { OpenMobileFilterButton } from './OpenMobileFilterButton'
import { FiltersIcon } from '../../../../assets/FiltersIcon'
import { checkUncategorizedFilter } from '../../../../utils/foodRestrictionFunctions'

import type { RestrictionFacet } from '../../../../types/FoodRestriction'
import './restriction-mobile-filter.scss'

interface FacetsProps {
  value: string
  selected: boolean
  quantity: number
  label: string
}

interface FoodRestrictionMobileFilterProps {
  handleFacets: (newFacet: RestrictionFacet[]) => void
  allFacets?: {
    facet: string
    title: string
    options: FacetsProps[]
  }[]
  requestLoading: boolean
  selectedCategorie: string
  setSelectedCategorie: (selectedCategorie: string) => void
  setSelectedSubcategorie?: (selectedSubcategorie: string) => void
  filterFacets: RestrictionFacet[]
  setFilterFacets: (filterFacets: RestrictionFacet[]) => void
  categorieFacetsKey: string
  subcategorieFacetsKey: string
}

export const FoodRestrictionMobileFilter = ({
  allFacets,
  handleFacets,
  requestLoading,
  selectedCategorie,
  setSelectedCategorie,
  setSelectedSubcategorie,
  filterFacets,
  setFilterFacets,
  categorieFacetsKey,
  subcategorieFacetsKey,
}: FoodRestrictionMobileFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [openMobileAccordion, setOpenMobileAccordion] = useState<string[]>([])

  const setters = useCallback(
    (label: string, facet: string) => {
      if (label === selectedCategorie) {
        setSelectedCategorie('Todos')

        return
      }

      const seters: { [key: string]: () => void } = {
        [categorieFacetsKey]: () => setSelectedCategorie(label),
        [subcategorieFacetsKey]: () => setSelectedSubcategorie?.(label),
      }

      seters[facet]()
    },
    [selectedCategorie, allFacets]
  )

  useEffect(() => {
    checkUncategorizedFilter({
      facets: filterFacets,
      setFacets: setFilterFacets,
      allFacets,
    })
  }, [allFacets])

  useEffect(() => {
    handleFacets(filterFacets)
  }, [filterFacets])

  return (
    <div className="restriction-mobile-filter-container">
      <OpenMobileFilterButton
        text="Todos os filtros"
        icon={<FiltersIcon />}
        setIsOpen={() => {
          setIsOpen(true)
          setOpenMobileAccordion(['Categoria', 'SubCategoria'])
        }}
      />
      <OpenMobileFilterButton
        text="Subcategorias"
        setIsOpen={() => {
          setIsOpen(true)
          setOpenMobileAccordion(['SubCategoria'])
        }}
      />

      {isOpen && (
        <MobileFilterModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          openMobileAccordion={openMobileAccordion}
          handleFacets={handleFacets}
          allFacets={allFacets}
          filterFacets={filterFacets}
          setFilterFacets={setFilterFacets}
          requestLoading={requestLoading}
          setters={setters}
          selectedCategorie={selectedCategorie}
          categorieFacetsKey={categorieFacetsKey}
        />
      )}
    </div>
  )
}
