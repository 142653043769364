/* eslint-disable no-restricted-globals */
import React, { createContext, useContext, useEffect, useState } from 'react'

import { HAS_WINDOW } from '../constants'
import { useLandingPageCms } from '../hooks/useLandingPageCms'

import type {
  CampaignsResponse,
  CampaignMenu,
  CampaignBannerData,
  CampaignCommonQuestionsData,
  CampaignMosaicData,
  CampaignShelfBannerData,
  CampaignShelfData,
  CampaignTextData,
  CampaignVideoData,
} from '../types/Campaigns'
import type { MenuLines } from '../types/CMS'

export interface CmsLandingPageProps {
  children?: React.ReactNode
  cmsDataLandingPage?: CampaignsResponse
  loading?: boolean
  campaignMenuSecond?: MenuLines[]
}

const CmsLandingPageContext = createContext<CmsLandingPageProps | null>(null)

export const CmsLandingPageProvider = ({ children }: CmsLandingPageProps) => {
  const [cmsDataLandingPage, setcmsDataLandingPage] =
    useState<CampaignsResponse>()

  const [campaignParam, setCampaignParam] = useState<string | undefined>()
  const [campaignMenuSecond, setCampaignMenuSecond] = useState<MenuLines[]>()

  const getCampaignParam = (search: string) => {
    const queryParams = new URLSearchParams(search)
    let currentLpName = ''

    if (queryParams.has('namelp')) {
      currentLpName = queryParams.get('namelp') ?? ''
    } else {
      currentLpName = queryParams.get('nameLp') ?? ''
    }

    return currentLpName?.replace('-', '+')
  }

  useEffect(() => {
    const search = location?.search

    if (!HAS_WINDOW) return

    const param = getCampaignParam(search)

    if (param && param !== campaignParam) setCampaignParam(param)
  }, [HAS_WINDOW && location.search])

  const { cmsData, loading } =
    useLandingPageCms<CampaignsResponse>(campaignParam)

  const isCampaignMenu = (
    data:
      | CampaignMenu
      | CampaignBannerData
      | CampaignTextData
      | CampaignVideoData
      | CampaignMosaicData
      | CampaignShelfData
      | CampaignShelfBannerData
      | CampaignCommonQuestionsData
  ): data is CampaignMenu => {
    return data && typeof data === 'object' && 'submenu' in data
  }

  useEffect(() => {
    if (!cmsData) return

    setcmsDataLandingPage(cmsData)

    const findMenu = cmsData.sections.find(
      (item) => item.name === 'Menu' && item.data && isCampaignMenu(item.data)
    )?.data as CampaignMenu

    setCampaignMenuSecond(findMenu?.submenu)
  }, [cmsData])

  return (
    <CmsLandingPageContext.Provider
      value={{
        cmsDataLandingPage,
        loading,
        campaignMenuSecond,
      }}
    >
      {children}
    </CmsLandingPageContext.Provider>
  )
}

export const useCmsLandingPageContext = () => {
  const context = useContext(CmsLandingPageContext)

  if (!context) {
    throw new Error(
      'useCmsLandingPageContext must be used within a CmsLandingPageProvider'
    )
  }

  return context
}
