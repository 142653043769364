import React from 'react'

import { ProductShelf, ProductShelfProps } from '../../ui/ProductShelf'

interface ProductShelfBySkusProps extends ProductShelfProps {
  type?: 'club' | 'default'
}

export const ProductShelfBySkus = ({
  products,
  id,
  variant,
  hasQuickView,
  backgroundColor,
  loading,
  title,
  link,
  propsCarousel,
  type,
  SeeMoreText,
}: ProductShelfBySkusProps) => {
  return (
    <ProductShelf
      products={products}
      id={id}
      variant={variant}
      hasQuickView={hasQuickView}
      backgroundColor={backgroundColor}
      loading={loading}
      title={title}
      link={link}
      propsCarousel={propsCarousel}
      typeShelf={type}
      SeeMoreText={SeeMoreText}
    />
  )
}
