import React from 'react'

import { Banner } from '../ui/Banner'

import type { CommonCmsBanner } from '../../types/CMS'

interface Props {
  data?: CommonCmsBanner
}

export const OffersBanner = ({ data }: Props) => {
  const { src, srcMobile, visibility, href, alt } = data ?? {}

  if (!src || !srcMobile || !visibility) {
    return null
  }

  return (
    <Banner
      href={href ?? '/'}
      src={src}
      srcMobile={srcMobile}
      alt={alt ?? ''}
      bannerWidth={768}
      bannerHeight={128}
      bannerDeskWidth={1410}
      bannerDeskHeight={160}
    />
  )
}
