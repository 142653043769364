import { graphql, useStaticQuery } from 'gatsby'

interface MenuProps {
  title: string
  url: string
}

const useCmsExternalMenus = () => {
  const data = useStaticQuery(graphql`
    query Navigation {
      cmsNavigation {
        sections {
          name
          data
        }
      }
    }
  `)

  const menuFirst: MenuProps[] | undefined =
    data?.cmsNavigation?.sections?.[0]?.data?.Menu

  const menuSecond: MenuProps[] | undefined =
    data?.cmsNavigation?.sections?.[1]?.data?.Menu

  return {
    menuFirst,
    menuSecond,
  }
}

export default useCmsExternalMenus
