import React from 'react'

import './clubProductTag.scss'
import { ProductCardTopFlagImage } from '../ProductCardTopFlagImage'
interface ClubProductTagProps {
  text: string
  afterPriceLocation?: boolean
  hasPlusFriendBadge?: boolean
}

export const ClubProductTag = ({
  text,
  afterPriceLocation,
  hasPlusFriendBadge,
}: ClubProductTagProps) => {
  return (
    <div>
      {hasPlusFriendBadge ? (
        <ProductCardTopFlagImage />
      ) : (
        <div
          className={
            afterPriceLocation
              ? `club-product-flag-after-price `
              : 'club-product-flag'
          }
        >
          <p>{text}</p>
        </div>
      )}
    </div>
  )
}
