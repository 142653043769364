import React from 'react'

interface CheckCircleIconProps {
  size?: number
  color?: string
}

export function CheckCircleIcon({
  size = 12,
  color = 'var(--color-grayScale-white)',
}: CheckCircleIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-circle-icon"
    >
      <path
        d="M9.107 4.549C9.3005 4.746 9.2975 5.0625 9.1 5.256L6.887 7.4285C6.4955 7.8125 5.9915 8.004 5.487 8.004C4.988 8.004 4.489 7.816 4.099 7.4395L3.1495 6.506C2.9525 6.3125 2.95 5.996 3.1435 5.799C3.3365 5.6015 3.654 5.599 3.8505 5.793L4.797 6.7235C5.185 7.0985 5.7975 7.0965 6.1875 6.7145L8.4 4.5425C8.5965 4.3485 8.912 4.352 9.107 4.549ZM12 6C12 9.3085 9.3085 12 6 12C2.6915 12 0 9.3085 0 6C0 2.6915 2.6915 0 6 0C9.3085 0 12 2.6915 12 6ZM11 6C11 3.243 8.757 1 6 1C3.243 1 1 3.243 1 6C1 8.757 3.243 11 6 11C8.757 11 11 8.757 11 6Z"
        fill={color}
      />
    </svg>
  )
}
