import { Skeleton } from '@acctglobal/skeleton'
import React, { useState, useEffect, useCallback } from 'react'

import {
  selectFacetFilter,
  checkUncategorizedFilter,
} from '../../../../utils/foodRestrictionFunctions'
import { FoodRestrictionFilterAccordion } from '../FoodRestrictionFilterAccordion'
import { FoodRestrictionFilterOption } from '../FoodRestrictionFilterOption'

import type {
  RestrictionFacet,
  FacetsProps,
} from '../../../../types/FoodRestriction'
import './restriction-desktop-filter.scss'

interface Props {
  handleFacets: (newFacet: RestrictionFacet[]) => void
  allFacets?: {
    facet: string
    title: string
    options: FacetsProps[]
  }[]
  requestLoading: boolean
  selectedCategorie: string
  setSelectedCategorie: (selectedCategorie: string) => void
  setSelectedSubcategorie?: (selectedSubcategorie: string) => void
  filterFacets: RestrictionFacet[]
  setFilterFacets: (filterFacets: RestrictionFacet[]) => void
  categorieFacetsKey: string
  subcategorieFacetsKey: string
}

export const FoodRestrictionDesktopFilter = ({
  allFacets,
  handleFacets,
  selectedCategorie,
  setSelectedCategorie,
  setSelectedSubcategorie,
  requestLoading,
  filterFacets,
  setFilterFacets,
  categorieFacetsKey,
  subcategorieFacetsKey,
}: Props) => {
  const [openDesktopAccordion, setOpenDesktopAccordion] =
    useState<boolean>(false)

  const setters = useCallback(
    (label: string, facet: string) => {
      if (label === selectedCategorie) {
        setSelectedCategorie('Todos')

        return
      }

      const seters: { [key: string]: () => void } = {
        [categorieFacetsKey]: () => setSelectedCategorie(label),
        [subcategorieFacetsKey]: () => setSelectedSubcategorie?.(label),
      }

      seters[facet]()
    },
    [selectedCategorie, allFacets]
  )

  useEffect(() => {
    checkUncategorizedFilter({
      facets: filterFacets,
      setFacets: setFilterFacets,
      allFacets,
      setOpenDesktopAccordion,
    })
  }, [allFacets])

  useEffect(() => {
    if (!openDesktopAccordion) {
      setOpenDesktopAccordion(!!filterFacets.length)
    }

    handleFacets(filterFacets)
  }, [filterFacets, openDesktopAccordion])

  if (requestLoading && !allFacets) {
    return (
      <div className="restriction-desktop-filter-container-skeleton">
        <Skeleton
          width={237}
          height={400}
          backgroundColor="var(--color-grayScale-faded)"
        />
      </div>
    )
  }

  return (
    <div className="restriction-desktop-filter-container">
      <h2 className="restriction-desktop-filter-title">Filtrar por</h2>
      {allFacets?.map(({ title, options, facet }) => (
        <FoodRestrictionFilterAccordion
          title={title}
          openDesktopAccordion={openDesktopAccordion}
          key={title}
        >
          {requestLoading ? (
            <Skeleton
              width={186}
              height={80}
              backgroundColor="var(--color-grayScale-faded)"
            />
          ) : (
            <>
              {options.map((option) => (
                <FoodRestrictionFilterOption
                  triger={() => {
                    setters(option.label, facet)
                    selectFacetFilter({
                      facets: filterFacets,
                      setFacets: setFilterFacets,
                      key: facet,
                      value: option.value,
                      setOpenDesktopAccordion,
                      categorieFacetsKey,
                    })
                  }}
                  isChecked={
                    option.label === selectedCategorie || option.selected
                  }
                  text={option.label}
                  quantity={option.quantity}
                  key={option.label}
                />
              ))}
            </>
          )}
        </FoodRestrictionFilterAccordion>
      ))}
    </div>
  )
}
