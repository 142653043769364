import { Skeleton } from '@acctglobal/skeleton'
import React, { useEffect, useState } from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { GalleryImages } from '../../GalleryImages/GalleryImages'
import { GalleryImagesCarousel } from '../../GalleryImages/GalleryImagesCarousel'

import type { ProductDetailsGallery as ProductDetailsGalleryProps } from '../../../../types/ProductDetailsGallery'

export const ProductDetailsGallery = ({
  productImages,
  listPriceCustom,
  spotPriceCustom,
  clubValidation,
  hasDiscount,
}: ProductDetailsGalleryProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  return (
    <section className="product-details__image">
      {width ? (
        <>
          {width < 768 ? (
            // mobile gallery
            <GalleryImagesCarousel
              clubValidation={clubValidation}
              productImages={productImages}
              listPriceCustom={listPriceCustom}
              spotPriceCustom={spotPriceCustom}
              hasDiscount={hasDiscount}
            />
          ) : (
            // desktop gallery
            <GalleryImages
              clubValidation={clubValidation}
              productImages={productImages}
              listPriceCustom={listPriceCustom}
              spotPriceCustom={spotPriceCustom}
              hasDiscount={hasDiscount}
            />
          )}
        </>
      ) : (
        <div data-testid="loading-skeleton" className="pdp-loading">
          <Skeleton
            responsiveImageSSR={{ srcWidth: 735, srcHeight: 794 }}
            backgroundColor="var(--color-grayScale-faded)"
          />
          <Skeleton
            responsiveImageSSR={{ srcWidth: 485, srcHeight: 436 }}
            backgroundColor="var(--color-grayScale-faded)"
          />
        </div>
      )}
    </section>
  )
}
