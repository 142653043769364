import React from 'react'

import { Banner } from '../../../ui/Banner'

import type { ScratchPageContent } from '../../../../types/CMS'
import type { MouseEvent } from 'react'

import './scratch-intro.scss'

interface ScratchIntroProps {
  handleBanner: (event: MouseEvent<HTMLElement>) => void
  scratchPageContent?: ScratchPageContent
}

export const ScratchIntro = ({
  handleBanner,
  scratchPageContent,
}: ScratchIntroProps) => {
  return (
    <article className="grid-content scratch-intro">
      <h1>{scratchPageContent?.mainTitle}</h1>

      <p>{scratchPageContent?.mainSubtitle}</p>

      <Banner
        href=""
        src={scratchPageContent?.banner.src}
        srcMobile={scratchPageContent?.banner.srcMobile}
        alt={scratchPageContent?.banner.alt}
        bannerWidth={720}
        bannerHeight={240}
        bannerDeskWidth={1216}
        bannerDeskHeight={336}
        handleClick={handleBanner}
      />
    </article>
  )
}
