import { Modal } from '@faststore/ui'
import React, { useMemo } from 'react'

import { TelesalesModalHeader } from './components/TelesalesModalHeader'
import { TelesalesModalImage } from './components/TelesalesModalImage'
import { TelesalesModalPrice } from './components/TelesalesModalPrice'
import { TelesalesModalTitle } from './components/TelesalesModalTitle'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { useProduct } from '../../../../sdk/product/useProduct'
import { formatEspecifications } from '../../../../utils/formatEspecifications'
import ProductMoreInfos from '../../../ui/ProductMoreInfos/ProductMoreInfos'
import { ProductDetailsActions } from '../../ProductDetails/components/ProductDetailsActions'

import type { CartItem } from '../../../../sdk/cart'
import type { ProductsQueryResponse } from '../../../../types/Product'

import './telesales-modal.scss'

interface TelesalesModalProps {
  openModal: boolean
  setOpenModal: (isOpen: boolean) => void
  product: ProductsQueryResponse['node']
  buyProps: CartItem
  price: number
  cartProductId: string
  hasClub: boolean | undefined
  cartProductQty: number
  isClubClient: boolean
  defaultScListPrice?: number
  clubPrice?: number
  spotPriceCustom: number
}

export const TelesalesModalProducts = ({
  openModal,
  setOpenModal,
  product: telesalesProduct,
  buyProps,
  cartProductId,
  hasClub,
  cartProductQty,
  isClubClient,
  defaultScListPrice,
  clubPrice,
  spotPriceCustom,
}: TelesalesModalProps) => {
  const { data } = useProduct(telesalesProduct?.id ?? '', {
    product: telesalesProduct,
  })

  const {
    product: {
      image,
      sku,
      isVariantOf: { name },
      offers: {
        offers: [{ availability }],
      },
      measurementUnit,
      unitMultiplier,
      description,
      additionalProperty,
    },
  } = data

  const { foodRestrictionSection } = useStoreContext()
  const restrictionTags = foodRestrictionSection?.map(
    ({ specificationName }) => specificationName
  )

  const { productRestriction } = useMemo(
    () => formatEspecifications(additionalProperty, restrictionTags),
    [additionalProperty, restrictionTags]
  )

  const buyDisabled = availability !== 'https://schema.org/InStock'

  return (
    <Modal className="telesales-modal" isOpen={openModal}>
      <div className="telesales-modal__container">
        <TelesalesModalHeader closeModal={() => setOpenModal(false)} />
        <TelesalesModalImage images={image} />
        <TelesalesModalTitle
          productName={name}
          productRestriction={productRestriction}
        />
        <TelesalesModalPrice
          data={data.product}
          defaultScListPrice={defaultScListPrice}
          clubPrice={clubPrice}
        />

        <ProductDetailsActions
          hasClub={hasClub}
          cartProductId={cartProductId}
          sku={sku}
          unitMultiplier={unitMultiplier}
          measurementUnit={measurementUnit}
          cartProductQty={cartProductQty}
          buyProps={buyProps}
          buyDisabled={buyDisabled}
          setOpenClubModal={() => {}}
          clubPrice={clubPrice}
          isClubClient={isClubClient}
          location="plp-telesales-search-page-0"
          spotPriceCustom={spotPriceCustom}
        />

        <ProductMoreInfos
          title="Descrição do produto"
          description={description}
          headerStyleClass="telesales-more-info__container"
        />
      </div>
    </Modal>
  )
}

export default TelesalesModalProducts
