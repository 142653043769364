import React from 'react'

import { ArrowIcon } from '../../../../assets/faqIcons'
import Link from '../../../ui/Link'
import { usefulLinks } from '../defaultData'
import './faq-useful-link-cards.scss'

export const FAQUsefulLinkCards = () => {
  const fulLinks = usefulLinks()

  return (
    <div className="faq-links-useful">
      {fulLinks?.map(({ title, icon, links }) => (
        <div className="faq-useful-link-container" key={title}>
          <div className="faq-useful-link-header">
            {icon}
            <h3 className="faq-useful-link-icon-title">{title}</h3>
          </div>

          <div className="faq-useful-link-body">
            {links?.map(({ text, href }) => (
              <Link className="faq-useful-link-item" to={href} key={text}>
                {text}
                <ArrowIcon />
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
