import { Skeleton } from '@acctglobal/skeleton'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Image } from '../Image'

import type { MouseEvent, CSSProperties } from 'react'

import './banner.scss'

interface BannerProps {
  href?: string
  src?: string
  srcMobile?: string
  alt?: string
  bannerWidth?: number
  bannerHeight?: number
  bannerDeskWidth?: number
  bannerDeskHeight?: number
  handleClick?: (event: MouseEvent<HTMLElement>) => Promise<void> | void
  customStyle?: CSSProperties
  openNewTab?: boolean
  className?: string
}

export const Banner = ({
  href = '',
  src,
  srcMobile,
  alt = '',
  bannerWidth = 768,
  bannerHeight = 128,
  bannerDeskWidth = 1410,
  bannerDeskHeight = 160,
  handleClick,
  customStyle,
  openNewTab,
  className = 'grid-content',
}: BannerProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  const isMobile = width <= 768

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  if (width === 0) {
    return (
      <div className="container-skeleton grid-content">
        <Skeleton height={160} backgroundColor="var(--color-grayScale-faded)" />
      </div>
    )
  }

  if (!srcMobile || srcMobile === '' || !src || src === '') {
    return null
  }

  return (
    <div className={`banner-container ${className}`} data-testid="banner">
      <Link
        key={alt}
        to={href}
        onClick={handleClick}
        style={customStyle}
        target={`${openNewTab ? '_blank' : ''}`}
      >
        <Image
          baseUrl={isMobile ? srcMobile : src}
          layout="fullWidth"
          sourceWidth={isMobile ? bannerWidth : bannerDeskWidth}
          sourceHeight={isMobile ? bannerHeight : bannerDeskHeight}
          backgroundColor="var(--color-grayScale-faded)"
          options={{
            fitIn: true,
          }}
          alt={alt}
          loading="lazy"
        />
      </Link>
    </div>
  )
}
