import React from 'react'

export const ConfirmIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13.0002C10.3137 13.0002 13 10.314 13 7.00024C13 3.68654 10.3137 1.00024 7 1.00024C3.68629 1.00024 1 3.68654 1 7.00024C1 10.314 3.68629 13.0002 7 13.0002Z"
      fill="var(--color-positiveFeedback)"
      stroke="var(--color-positiveFeedback)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 7.00024L6 9.00024L10 5.00024"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
