import React, { useState, useMemo, useEffect } from 'react'

import { MultipleSearchHeader } from '../components/sections/MultipleSearch/MultipleSearchHeader'
import { MultipleSearchMoreButton } from '../components/sections/MultipleSearch/MultipleSearchMoreButton/MultipleSearchMoreButton'
import { MultipleSearchShelfs } from '../components/sections/MultipleSearch/MultipleSearchShelfs'
import { SearchView } from '../components/sections/MultipleSearch/SearchView/SearchView'
import { HAS_WINDOW } from '../constants'
import { useMultipleSearchContext } from '../contexts/MultipleSearchContext'

import type { MultipleSearchedProductsProps } from '../types/Product'

import '../styles/pages/multiple-search.scss'

export const MultipleSearchPage = () => {
  const [multipleTerms, setMultipleTerms] = useState<string[]>([])
  const [hasActionParam, setHasActionParam] = useState(false)

  const {
    loading,
    addSearchTerms: addTerms,
    hasPagination,
    multipleSearchedProducts,
    nextPage,
    deleteTermUrl,
    isProductsView,
    setIsProductsView,
    setMultipleSearchedProducts,
    setCurrentPage,
  } = useMultipleSearchContext()

  const changeView =
    isProductsView &&
    multipleSearchedProducts &&
    multipleSearchedProducts?.length > 0

  const { hasTerms, limitReached } = useMemo(() => {
    return {
      hasTerms: multipleTerms.length >= 1,
      limitReached: multipleTerms.length >= 15,
    }
  }, [multipleTerms])

  const handleBackButton = () => {
    setCurrentPage?.(0)
    setIsProductsView?.(false)
    deleteTermUrl?.()
    setMultipleSearchedProducts?.([])
  }

  useEffect(() => {
    if (!HAS_WINDOW) {
      return
    }

    const { search } = window.location
    const params = new URLSearchParams(search)

    const currentParams = params.get('terms')

    if (!currentParams || currentParams?.split(',')?.length < 1) {
      const storedTerms = sessionStorage.getItem('multipleSearchTerms')

      if (storedTerms) {
        setMultipleTerms(JSON.parse(storedTerms))
      }

      return
    }

    setMultipleTerms(currentParams?.split(','))
  }, [])

  useEffect(() => {
    if (!HAS_WINDOW) return

    const urlParams = new URLSearchParams(window.location.search)
    const actionParam = urlParams.get('action')
    const termsParams = urlParams.get('terms')
    const separatedTerms = termsParams?.split(',')?.map((term) => term.trim())

    if (actionParam && separatedTerms && separatedTerms?.length > 0) {
      setHasActionParam(true)
      addTerms?.(separatedTerms)
    }
  }, [addTerms])

  return (
    <>
      <MultipleSearchHeader
        terms={multipleTerms}
        isProductsView={changeView}
        hasTerms={hasTerms}
        limitReached={limitReached}
        backButtonProps={{
          backText: 'Voltar',
          link: isProductsView ? '' : '/',
          trigger: handleBackButton,
        }}
      />

      {changeView || hasActionParam ? (
        <>
          {multipleSearchedProducts?.map(
            (section: MultipleSearchedProductsProps) => {
              return (
                <>
                  <MultipleSearchShelfs
                    loading={loading}
                    haveMoreItems={hasPagination}
                    products={section.products}
                    sectionName={section.name}
                  />
                </>
              )
            }
          )}

          <MultipleSearchMoreButton
            hasPagination={hasPagination}
            loading={loading}
            nextPage={nextPage}
          />
        </>
      ) : (
        <SearchView
          multipleTerms={multipleTerms}
          setMultipleTerms={setMultipleTerms}
          handleTerms={addTerms}
          hasTerms={hasTerms}
          limitReached={limitReached}
          loading={loading}
        />
      )}
    </>
  )
}
