import React from 'react'

export const DownloadIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="download-icon"
    >
      <mask
        id="mask0_3159_35916"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3159_35916)">
        <path
          d="M20 26.6666L11.6666 18.3333L14 15.9166L18.3333 20.25V6.66663H21.6666V20.25L26 15.9166L28.3333 18.3333L20 26.6666ZM9.99996 33.3333C9.08329 33.3333 8.29857 33.0069 7.64579 32.3541C6.99301 31.7013 6.66663 30.9166 6.66663 30V25H9.99996V30H30V25H33.3333V30C33.3333 30.9166 33.0069 31.7013 32.3541 32.3541C31.7013 33.0069 30.9166 33.3333 30 33.3333H9.99996Z"
          fill="var(--color-support-links)"
        />
      </g>
    </svg>
  )
}
