import React from 'react'

import { ConfirmIcon } from '../../../../assets/ConfirmIcon'
import { useMultipleSearchContext } from '../../../../contexts/MultipleSearchContext'
import WhenVisible from '../../../ui/WhenVisible/WhenVisible'

import './multiple-search-not-found-message.scss'

interface MultipleSearchNotFoundMessageProps {
  sectionName: string
}

const Li = ({ text }: { text: string }) => (
  <li>
    <ConfirmIcon />
    {text}
  </li>
)

export const MultipleSearchNotFoundMessage = ({
  sectionName,
}: MultipleSearchNotFoundMessageProps) => {
  const { searchedTerms, menuAutoNavigation } = useMultipleSearchContext()
  const searchedTermsIndex = searchedTerms?.indexOf(sectionName)

  return (
    <WhenVisible
      id={sectionName}
      trigger={() =>
        (searchedTermsIndex ?? -1) >= 0 &&
        menuAutoNavigation?.(searchedTermsIndex ?? -1, sectionName)
      }
      className="grid-content"
      referenceId="carousel-menu-content"
    >
      <div className="container-not-found-message grid-content">
        <div className="not-found-titles">
          <p id={sectionName} className="not-found-term">
            {sectionName}
          </p>
          <p className="search-term-not-found">
            Você buscou por <strong>{`"${sectionName}"`}</strong>
          </p>
        </div>
        <div className="not-found-message">
          <p>Nenhum produto foi encontrado</p>
        </div>

        <div className="not-found-content">
          <h2>O que faço agora?</h2>

          <ul>
            <Li text="Verifique os termos digitados" />
            <Li text="Use termos gerais do produto ou menos palavras chaves" />
            <Li text="Tente pesquisar por um item menos específico e refine os resultados" />
          </ul>
        </div>
      </div>
    </WhenVisible>
  )
}
