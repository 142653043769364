import React from 'react'

import { DynamicTitle } from '../../../../common/DynamicTitle'

import './product-card-name.scss'

interface ProductCardNameProps {
  titleLevel: number
  productName: string
}

export const ProductCardName = ({
  titleLevel,
  productName,
}: ProductCardNameProps) => {
  return (
    <DynamicTitle
      className="product-card-name-container"
      titleLevel={titleLevel}
      title={productName}
    />
  )
}
