import { graphql, useStaticQuery } from 'gatsby'

import type {
  BannerWithLink,
  MenuBannerWithLink,
} from '../local_modules/types/CMS'

const useCmsFullCategoriesMenu = () => {
  const { cmsFullCategoriesMenu } = useStaticQuery(graphql`
    query FullCategories {
      cmsFullCategoriesMenu {
        sections {
          name
          data
        }
      }
    }
  `)

  const sections = cmsFullCategoriesMenu?.sections

  const allCategories = sections?.find(
    (item: { name: string }) => item.name === 'Menu'
  )?.data?.departament

  const departments: MenuBannerWithLink[] | undefined = sections
    ?.filter((item: { name: string }) => item.name === 'department')
    ?.map((item: { data: BannerWithLink }) => item.data)

  return { allCategories, departments }
}

export default useCmsFullCategoriesMenu
