import React, { useEffect, useState } from 'react'

import { RenderCampaigns } from '../components/sections/Campaigns/RenderCampaigns'
import { useCmsLandingPageContext } from '../contexts/CmsLandingPage'

import '../styles/pages/campaigns.scss'

interface CampaignsPageProps {
  segment: string
}

export const CampaignsPage = ({ segment }: CampaignsPageProps) => {
  const [backgroundPageColor, setBackgroundPageColor] = useState<string>()
  const { cmsDataLandingPage, loading } = useCmsLandingPageContext()

  useEffect(() => {
    const backgroundColorConfig =
      cmsDataLandingPage?.pageConfig?.backgroundColor

    if (backgroundColorConfig && !backgroundPageColor) {
      setBackgroundPageColor(backgroundColorConfig)

      return
    }

    if (cmsDataLandingPage?.sections && !backgroundColorConfig) {
      setBackgroundPageColor('var(--color-assistant-main)')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsDataLandingPage])

  return (
    <div
      className="campaigns-page-container"
      style={{
        backgroundColor: backgroundPageColor ?? 'var(--color-grayScale-white)',
      }}
    >
      <RenderCampaigns
        loading={loading}
        cmsDataLandingPage={cmsDataLandingPage}
        segment={segment}
      />
    </div>
  )
}
