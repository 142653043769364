import type { ProductsQueryResponse } from '../../../types/Product'

type Classes =
  | 'first-of-section'
  | 'last-of-section'
  | 'first-of-section last-of-section'

interface Infos {
  firstRecommendationIndex: number | null
  firstNonRecommendationIndex: number | null
  classes: { [key: number]: Classes }
}

export function dynamicShowcasesInfos(
  products: ProductsQueryResponse['node'][]
): Infos {
  const classes: { [key: number]: Classes } = {}

  let firstRecommendationIndex: number | null = null
  let lastRecommendationIndex: number | null = null
  let firstNonRecommendationIndex: number | null = null
  let lastNonRecommendationIndex: number | null = null

  // Set the first and last indexes of each section
  products.forEach((product, index) => {
    if (product.isRecommendation) {
      if (firstRecommendationIndex === null) {
        firstRecommendationIndex = index
      }

      lastRecommendationIndex = index
    } else {
      if (firstNonRecommendationIndex === null) {
        firstNonRecommendationIndex = index
      }

      lastNonRecommendationIndex = index
    }
  })

  // Assign the classes to the first and last indexes of each section
  function assignClass(index: number | null, className: Classes) {
    if (index !== null) {
      if (classes[index]) {
        classes[index] = `${classes[index]} ${className}` as Classes
      } else {
        classes[index] = className
      }
    }
  }

  // Assign the classes
  assignClass(firstRecommendationIndex, 'first-of-section')
  assignClass(firstNonRecommendationIndex, 'first-of-section')
  assignClass(lastRecommendationIndex, 'last-of-section')
  assignClass(lastNonRecommendationIndex, 'last-of-section')

  return {
    firstRecommendationIndex,
    firstNonRecommendationIndex,
    classes,
  }
}
