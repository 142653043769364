import React from 'react'

import { BuyAgainNavigation } from '../components/sections/BuyAgain/BuyAgainNavigation'
import { BuyAgainProductListing } from '../components/sections/BuyAgain/BuyAgainProductListing'

export const BuyAgainPage = () => (
  <article className="buy-again">
    <div className="grid-content">
      <BuyAgainNavigation />
    </div>

    <div className="buy-again-plp">
      <div className="grid-content">
        <h1 className="buy-again-title" data-testid="buy-again-title">
          Comprar novamente
        </h1>
      </div>

      <BuyAgainProductListing />
    </div>
  </article>
)
