export const createCookie = (
  name: string,
  value: string,
  secondsToExpire?: number
) => {
  const cookieParts = [`${name}=${encodeURIComponent(value)}`]

  if (secondsToExpire) {
    const expirationDate = new Date()

    expirationDate.setTime(expirationDate.getTime() + secondsToExpire * 1000)

    cookieParts.push(`expires=${expirationDate.toUTCString()}`)
  }

  cookieParts.push('path=/')

  document.cookie = cookieParts.join('; ')
}
