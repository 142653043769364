import React from 'react'

import './render-errors.scss'

import Image from '../../../assets/without_product_repeat_order.png'

export const NoOrderError = () => {
  return (
    <div data-testid="no-order-error" className="no-order-container">
      <h2 className="error-title">
        Você ainda não realizou nenhum pedido, sendo assim não existem produtos
        que você sempre leva
      </h2>

      <div className="error-image-container no-order-image">
        <img src={Image} width={328} height={308} />
      </div>

      <p className="no-order-message">
        Os produtos que você sempre leva estarão disponíveis aqui para agilizar
        suas compras
      </p>
    </div>
  )
}
