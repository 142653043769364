import React from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { showSection } from '../../../../utils/showSection'
import { Shortcuts } from '../../../ClubCard/User/Shortcuts'
import { Banner } from '../../Banner'

import type { Shortcut } from '../../../../types/ClubCard/ClubCardUser'
import type { GenericBannerInfos } from '../../../../types/CMS'
import type { showSectionVariables } from '../../../../utils/showSection'

import './campaign-shortcuts.scss'

interface Props {
  banner: {
    visibility: boolean
    mobile: GenericBannerInfos
    desktop: GenericBannerInfos
    promotionName: string
    href: string
    alt: string
  }
  list: Shortcut[]
  visibility: boolean
  visibilityConfig: showSectionVariables
}

export const CampaignShortcuts = (props: Props) => {
  const { banner, list, visibility, visibilityConfig } = props
  const { width } = useWindowDimensions()

  if (!showSection(visibilityConfig) || !visibility) return

  return (
    <div className="club-card-user-shortcut-container grid-content">
      <Shortcuts visibility={visibility} list={list} location="clube" />
      {width && width > 720 && <Banner data={banner} />}
    </div>
  )
}
