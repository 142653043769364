import React from 'react'

import { TabLayout } from '../../../common/TabLayout/TabLayout'

import type { CampaignShelfBannerData } from '../../../../types/Campaigns'

export const CampaignShelfBanner = ({
  banners,
  loading,
}: CampaignShelfBannerData) => {
  return (
    <TabLayout
      data={banners}
      loading={loading}
      shelfTypeDefault={banners?.[0].shelfType}
      shelfValueDefault={banners?.[0].shelfValue}
    />
  )
}
