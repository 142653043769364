import { navigate } from 'gatsby'
import React, { useMemo } from 'react'

import { useFAQContext } from '../../../../contexts/FAQContext'
import { deleteQueryParam } from '../../../../utils/FAQQueryParamFunctions'
import './faq-navigation.scss'
import { MultipleSearchNavigation } from '../../MultipleSearch/MultipleSearchNavigation'

export const FAQNavigation = () => {
  const {
    isMobile,
    activeTopic,
    setActiveTopic,
    setShowAsideMenu,
    activeQuestion,
    setActiveQuestion,
  } = useFAQContext()

  const goBack = () => {
    if (activeQuestion !== '') {
      deleteQueryParam('question')
      setActiveQuestion?.('')

      return
    }

    if (activeQuestion === '' && activeTopic !== '') {
      setShowAsideMenu?.(true)
      deleteQueryParam('topic')
      setActiveTopic?.('')

      return
    }

    if (activeQuestion === '' && activeTopic === '') {
      navigate('/')
    }
  }

  const breadcrumb = useMemo(() => {
    const defaultItem = ['Atendimento e Solução de Problemas']
    const finalItems: string[] = [...defaultItem]

    if (!isMobile) {
      return defaultItem
    }

    if (activeTopic) {
      finalItems.push(activeTopic ?? '')
    }

    if (activeTopic === '' && finalItems.includes(activeTopic)) {
      const index = finalItems.indexOf(activeTopic)

      if (index !== -1) {
        finalItems.splice(index, 1)
      }
    }

    if (activeQuestion === '' && finalItems.includes(activeQuestion)) {
      const index = finalItems.indexOf(activeQuestion)

      if (index !== -1) {
        finalItems.splice(index, 1)
      }
    }

    if (activeQuestion) {
      finalItems.push(activeQuestion ?? '')
    }

    return finalItems
  }, [activeQuestion, activeTopic, isMobile])

  const triggers = [
    () => {
      setActiveQuestion?.('')
      deleteQueryParam('question')
      setActiveTopic?.('')
      deleteQueryParam('topi')
    },
    () => {
      setActiveQuestion?.('')
      deleteQueryParam('question')
    },
    () => {},
  ]

  return (
    <>
      <MultipleSearchNavigation
        backButton={{ backText: 'Voltar', trigger: () => goBack() }}
        navigateTriggers={triggers}
        breadcrumb={breadcrumb}
      />
    </>
  )
}
