import { Button } from '@faststore/ui'
import React from 'react'

import type {
  HighlightProductIndex,
  ProductsQueryResponse,
} from '../../../../types/Product'
import './highlight-brands-product.scss'

interface HighlightBrandsProductProps {
  product: ProductsQueryResponse['node']
  setSelectedHighlightIndex: (
    selectedHighlightIndex: HighlightProductIndex
  ) => void
  brandIndex: number
  productIndex: number
  setOpenQuickView: (openQuickView: boolean) => void
}

export const HighlightBrandsProduct = ({
  product,
  setSelectedHighlightIndex,
  brandIndex,
  productIndex,
  setOpenQuickView,
}: HighlightBrandsProductProps) => {
  return (
    <>
      <Button
        className="highlight-brands-product-container"
        onClick={() => {
          setSelectedHighlightIndex({ brandIndex, productIndex })
          setOpenQuickView(true)
        }}
      >
        <img
          className="highlight-brands-product-image"
          src={product?.image?.[0].url}
          alt={product?.name}
        />
      </Button>
    </>
  )
}
