import React from 'react'

interface ErrorOctagonIconProps {
  color?: string
}

export function ErrorOctagonIcon({
  color = 'var(--color-negativeFeedback-darkest)',
}: ErrorOctagonIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6554 7.02933L24.9687 2.34267C23.458 0.832 21.4487 0 19.3127 0H12.6847C10.5487 0 8.53938 0.832 7.02738 2.34267L2.34071 7.02933C0.830047 8.54133 -0.00195312 10.5507 -0.00195312 12.6867V19.3147C-0.00195312 21.4507 0.830047 23.46 2.34071 24.972L7.02738 29.6587C8.53938 31.1693 10.5487 32.0013 12.6847 32.0013H19.3127C21.45 32.0013 23.458 31.1693 24.9687 29.6587L29.6554 24.9733C31.1674 23.4627 31.9994 21.4533 31.9994 19.316V12.688C31.9994 10.5507 31.1674 8.54 29.6554 7.02933ZM29.3327 19.3133C29.3327 20.7387 28.778 22.0773 27.77 23.0853L23.0834 27.772C22.0754 28.7787 20.7367 29.3333 19.3127 29.3333H12.6847C11.2607 29.3333 9.92071 28.7787 8.91271 27.772L4.22738 23.0853C3.21938 22.0773 2.66471 20.7387 2.66471 19.3133V12.6853C2.66471 11.2613 3.21938 9.92133 4.22605 8.91333L8.91271 4.228C9.92071 3.22 11.2594 2.66533 12.6847 2.66533H19.3127C20.7367 2.66533 22.0754 3.22 23.0834 4.22667L27.77 8.91333C28.778 9.92 29.3327 11.2587 29.3327 12.684V19.3133ZM21.918 11.9653L17.8607 16.0227L21.918 20.08C22.4394 20.6013 22.4394 21.444 21.918 21.9653C21.658 22.2253 21.3167 22.356 20.9754 22.356C20.634 22.356 20.2927 22.2253 20.0327 21.9653L15.9754 17.908L11.918 21.9653C11.658 22.2253 11.3167 22.356 10.9754 22.356C10.634 22.356 10.2927 22.2253 10.0327 21.9653C9.51138 21.444 9.51138 20.6013 10.0327 20.08L14.09 16.0227L10.0327 11.9653C9.51138 11.444 9.51138 10.6013 10.0327 10.08C10.554 9.55867 11.3967 9.55867 11.918 10.08L15.9754 14.1373L20.0327 10.08C20.554 9.55867 21.3967 9.55867 21.918 10.08C22.4394 10.6013 22.4394 11.444 21.918 11.9653Z"
        fill={color}
      />
    </svg>
  )
}
