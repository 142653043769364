import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import { Mosaic } from '../../Mosaic'

import type { CampaignMosaicData } from '../../../../types/Campaigns'
import './campaign-mosaic.scss'

export const CampaignMosaic = ({ rows, loading }: CampaignMosaicData) => {
  if (loading) {
    return (
      <section className=" campaign-mosaic-container grid-content">
        <Skeleton
          table={{ rows: 1, columns: 3 }}
          height={275}
          backgroundColor="var(--color-grayScale-light)"
        />
      </section>
    )
  }

  return (
    <section className="campaign-mosaic-container">
      <Mosaic data={{ rows, isActive: true }} />
    </section>
  )
}
