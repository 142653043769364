import React from 'react'

import type { CampaignTextData } from '../../../../types/Campaigns'
import './campaign-text.scss'

export const CampaignText = ({
  description,
  title,
  loading,
}: CampaignTextData) => {
  if (loading || !title || !description) return null

  return (
    <section className="grid-content campaign-text">
      <h1 className="campaign-text--title">{title}</h1>
      <p className="campaign-text--description">{description}</p>
    </section>
  )
}
