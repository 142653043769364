import { Link } from 'gatsby'
import React from 'react'

import ArrowIcon from '../../../assets/ArrowIcon'
import HomeIcon from '../../../assets/HomeIcon'

import './navigation-institutional.scss'

interface NavigationInstitutionalProps {
  titleText: string
  backText?: string
}

export const NavigationInstitutional = ({
  titleText,
  backText = 'Voltar',
}: NavigationInstitutionalProps) => {
  return (
    <div
      className="navigation-institutional-container"
      data-testid="navigation-institutional-container"
    >
      <div className="breadcrumb-about">
        <Link to="/">
          <HomeIcon />
        </Link>
        <ArrowIcon rotate="Left" />
        <p data-testid="navigation-institutional-title">{titleText}</p>
      </div>

      <div className="back-step">
        <ArrowIcon rotate="Right" color="var(--color-support-links)" />
        <Link to="/">
          <p>{backText}</p>
        </Link>
      </div>
    </div>
  )
}
