import React from 'react'

import { FoodRestrictionFlagLink } from '../../../FoodRestriction/FoodRestrictionFlagLink'

interface TelesalesModalTitleProps {
  productName: string
  productRestriction: string[] | undefined
}

export const TelesalesModalTitle = ({
  productName,
  productRestriction,
}: TelesalesModalTitleProps) => {
  const terms =
    productRestriction?.map((restriction) => ({
      term: restriction,
    })) ?? []

  return (
    <article className="telesales-modal__title">
      {terms.length > 0 && (
        <>
          <h3 className="restriction-flag-link-title">Restrição Alimentar</h3>
          <FoodRestrictionFlagLink restrictionTerms={terms} />
        </>
      )}
      <h2>{productName}</h2>
    </article>
  )
}
