import { bannersDimensions } from './bannersDimensions'

import type { MosaicSection } from '../../../types/CMS'

export const skeletonDimensions = (mosaic: MosaicSection) =>
  mosaic.rows.reduce(
    (acc, { banners }) => {
      // prevent rows with banners lenght different from 1 to 3
      if (banners.length <= 0 || banners.length >= 4) {
        return acc
      }

      const rowBanners = banners.length as 1 | 2 | 3
      const { mobileHeight, desktopHeight } = bannersDimensions[rowBanners]

      return {
        ...acc,
        mobileSkeletonHeight: acc.mobileSkeletonHeight + (mobileHeight + 8), // 8 is mobile gap
        desktopSkeletonHeight: acc.desktopSkeletonHeight + (desktopHeight + 24), // 24 is desktop gap
      }
    },
    {
      mobileSkeletonHeight: 0,
      desktopSkeletonHeight: 0,
    }
  )
