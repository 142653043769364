import React from 'react'

import type { CampaignVideoData } from '../../../../types/Campaigns'

import './campaign-video.scss'
import { Skeleton } from '@acctglobal/skeleton'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import {
  extractYouTubeVideoId,
  extractYouTubeVideoLink,
} from '../../../../utils/extractYoutubeVideo'

export const CampaignVideo = ({ src, loading }: CampaignVideoData) => {
  const videoId = extractYouTubeVideoId(src)
  const videoLink = videoId ? extractYouTubeVideoLink(videoId) : ''
  const { isMobile } = useWindowDimensions()

  if (loading) {
    return (
      <section className="grid-content">
        <div data-testid="skeleton">
          <Skeleton
            backgroundColor="var(--color-grayScale-faded)"
            width="100%"
            height={isMobile ? 139 : 600}
          />
        </div>
      </section>
    )
  }

  return (
    <section className="grid-content">
      <iframe
        className="campaign-video"
        title="YouTube Video"
        src={videoLink}
        allowFullScreen
      />
    </section>
  )
}
