import { graphql, useStaticQuery } from 'gatsby'

const useCmsCookiesText = () => {
  const data = useStaticQuery(graphql`
    query CookiesTextQuery {
      cmsCookiesText {
        sections {
          data
        }
      }
    }
  `)

  const cookiesText: string | undefined =
    data?.cmsCookiesText?.sections?.[0]?.data?.text

  return { cookiesText }
}

export default useCmsCookiesText
