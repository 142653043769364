import React from 'react'

import type { Icon } from '../../types/FAQ'

export const ShippingIcon = ({
  width = 30,
  height = 22,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M6.46659 21.6333C5.3777 21.6333 4.44992 21.25 3.68325 20.4833C2.91659 19.7167 2.53325 18.7889 2.53325 17.7H0.333252V2.33333C0.333252 1.8 0.533252 1.33333 0.933252 0.933332C1.33325 0.533332 1.79992 0.333332 2.33325 0.333332H21.6333V5.9H25.1333L29.6666 11.9333V17.7H27.2999C27.2999 18.7889 26.9166 19.7167 26.1499 20.4833C25.3833 21.25 24.4555 21.6333 23.3666 21.6333C22.2777 21.6333 21.3499 21.25 20.5833 20.4833C19.8166 19.7167 19.4333 18.7889 19.4333 17.7H10.3999C10.3999 18.7889 10.0166 19.7167 9.24992 20.4833C8.48325 21.25 7.55547 21.6333 6.46659 21.6333ZM6.46659 19.6333C6.99992 19.6333 7.45547 19.4444 7.83325 19.0667C8.21103 18.6889 8.39992 18.2333 8.39992 17.7C8.39992 17.1667 8.21103 16.7111 7.83325 16.3333C7.45547 15.9556 6.99992 15.7667 6.46659 15.7667C5.93325 15.7667 5.4777 15.9556 5.09992 16.3333C4.72214 16.7111 4.53325 17.1667 4.53325 17.7C4.53325 18.2333 4.72214 18.6889 5.09992 19.0667C5.4777 19.4444 5.93325 19.6333 6.46659 19.6333ZM23.3666 19.6333C23.8999 19.6333 24.3555 19.4444 24.7333 19.0667C25.111 18.6889 25.2999 18.2333 25.2999 17.7C25.2999 17.1667 25.111 16.7111 24.7333 16.3333C24.3555 15.9556 23.8999 15.7667 23.3666 15.7667C22.8333 15.7667 22.3777 15.9556 21.9999 16.3333C21.6221 16.7111 21.4333 17.1667 21.4333 17.7C21.4333 18.2333 21.6221 18.6889 21.9999 19.0667C22.3777 19.4444 22.8333 19.6333 23.3666 19.6333ZM21.6333 12.8333H27.8333L24.1333 7.9H21.6333V12.8333Z"
          fill={color}
        />
      ) : (
        <path
          d="M6.46671 21.6333C5.37782 21.6333 4.45004 21.25 3.68337 20.4833C2.91671 19.7167 2.53337 18.7889 2.53337 17.7H0.333374V2.33334C0.333374 1.80001 0.533374 1.33334 0.933374 0.933343C1.33337 0.533343 1.80004 0.333344 2.33337 0.333344H21.6334V5.90001H25.1334L29.6667 11.9333V17.7H27.3C27.3 18.7889 26.9167 19.7167 26.15 20.4833C25.3834 21.25 24.4556 21.6333 23.3667 21.6333C22.2778 21.6333 21.35 21.25 20.5834 20.4833C19.8167 19.7167 19.4334 18.7889 19.4334 17.7H10.4C10.4 18.7889 10.0167 19.7167 9.25004 20.4833C8.48337 21.25 7.5556 21.6333 6.46671 21.6333ZM6.46671 19.6333C7.00004 19.6333 7.4556 19.4445 7.83337 19.0667C8.21115 18.6889 8.40004 18.2333 8.40004 17.7C8.40004 17.1667 8.21115 16.7111 7.83337 16.3333C7.4556 15.9556 7.00004 15.7667 6.46671 15.7667C5.93337 15.7667 5.47782 15.9556 5.10004 16.3333C4.72226 16.7111 4.53337 17.1667 4.53337 17.7C4.53337 18.2333 4.72226 18.6889 5.10004 19.0667C5.47782 19.4445 5.93337 19.6333 6.46671 19.6333ZM2.33337 15.7H3.06671C3.44449 15.1 3.92226 14.6222 4.50004 14.2667C5.07782 13.9111 5.72226 13.7333 6.43337 13.7333C7.14448 13.7333 7.79449 13.9167 8.38337 14.2833C8.97226 14.65 9.4556 15.1222 9.83337 15.7H19.6334V2.33334H2.33337V15.7ZM23.3667 19.6333C23.9 19.6333 24.3556 19.4445 24.7334 19.0667C25.1112 18.6889 25.3 18.2333 25.3 17.7C25.3 17.1667 25.1112 16.7111 24.7334 16.3333C24.3556 15.9556 23.9 15.7667 23.3667 15.7667C22.8334 15.7667 22.3778 15.9556 22 16.3333C21.6223 16.7111 21.4334 17.1667 21.4334 17.7C21.4334 18.2333 21.6223 18.6889 22 19.0667C22.3778 19.4445 22.8334 19.6333 23.3667 19.6333ZM21.6334 12.8333H27.8334L24.1334 7.90001H21.6334V12.8333Z"
          fill={color}
        />
      )}
    </svg>
  )
}
