import React from 'react'

import { NavigationInstitutional } from '../../../../../ui/NavigationInstitutional'

import './food-restriction-page-header.scss'
import { FoodRestrictionNavigationBar } from '../FoodRestrictionNavigationBar/FoodRestrictionNavigationBar'

import type { FacetsProps } from '../../../../../../types/FoodRestriction'

export const FoodRestrictionPageHeader = ({
  restriction,
  hasProductRestriction,
  mainMenuCategories,
  selectedCategorie,
  handleMenuItem,
  layoutLoading,
  categorieFacetsKey,
}: {
  layoutLoading: boolean
  handleMenuItem: (value: string, label: string, key: string) => void
  hasProductRestriction: boolean
  restriction?: string
  mainMenuCategories: FacetsProps[]
  selectedCategorie: string
  categorieFacetsKey: string
}) => {
  return (
    <>
      <div className="restrictionProductsPage-container-header / grid-content">
        <NavigationInstitutional titleText="Restrição Alimentar" />
        <div className="restrictionProductsPage-container-header-showingResults">
          {hasProductRestriction ? (
            <h1>
              Mostrando resultados para <span>"{restriction}"</span>
            </h1>
          ) : (
            <div>
              <h1>
                {' '}
                Não encontramos produtos <span>"{restriction}"</span>
              </h1>
              <h1>Nos desculpe pelo inconveniente </h1>
            </div>
          )}
        </div>
      </div>

      {hasProductRestriction && (
        <FoodRestrictionNavigationBar
          handleMenuItem={handleMenuItem}
          layoutLoading={layoutLoading}
          mainMenuCategories={mainMenuCategories}
          selectedCategorie={selectedCategorie}
          categorieFacetsKey={categorieFacetsKey}
        />
      )}
    </>
  )
}
