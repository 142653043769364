import React, { useState } from 'react'

import { AccordionArrows } from '../../../../assets/AccordionArrows'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

import type { ReactNode } from 'react'
import './food-restriction-filter-accordion.scss'

interface FoodRestrictionFilterAccordionProps {
  title: string
  children: ReactNode
  openMobileAccordion?: string[]
  openDesktopAccordion?: boolean
}

export const FoodRestrictionFilterAccordion = ({
  title,
  openMobileAccordion,
  openDesktopAccordion = false,
  children,
}: FoodRestrictionFilterAccordionProps) => {
  const mobileAccordionBehavior = !openMobileAccordion
    ? false
    : openMobileAccordion.includes(title)

  const [isOpen, setIsOpen] = useState(
    openMobileAccordion ? mobileAccordionBehavior : openDesktopAccordion
  )

  const { isMobile } = useWindowDimensions()

  return (
    <div
      className="restriction-filter-accordion-container"
      style={{ paddingBottom: isOpen ? '16px' : 0 }}
    >
      <button
        className="restriction-filter-accordion-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <AccordionArrows
          side={isMobile ? 'down' : 'right'}
          color={
            isMobile
              ? 'var(--color-grayScale-darkest)'
              : 'var(--color-grayScale)'
          }
        />
      </button>
      <div
        className="restriction-filter-accordion-content"
        style={{ display: isOpen ? 'flex' : 'none' }}
      >
        {children}
      </div>
    </div>
  )
}
