import React from 'react'

import ArrowIcon from '../../../../assets/ArrowIcon'
import { useFAQContext } from '../../../../contexts/FAQContext'

import type { TopicOptions } from '../../../../types/FAQ'

import './faq-aside-menu.scss'

interface MenuItemProps {
  title: TopicOptions
  description: string
  icon: React.ReactNode
  activeIcon?: React.ReactNode
}

export const MenuItem = ({
  title,
  description,
  icon,
  activeIcon,
}: MenuItemProps) => {
  const { activeTopic, handleChangeTopic, isMobile } = useFAQContext()
  const isActiveTopic = activeTopic === title

  return (
    <button
      onClick={() => handleChangeTopic?.(title)}
      className={`faq-aside-menu-item ${isActiveTopic ? 'active' : ''}`}
    >
      <i>{isActiveTopic && activeIcon ? activeIcon : icon}</i>
      <div className="faq-aside-menu-item-infos">
        <div>
          <h3>{title}</h3>
          {isMobile && <ArrowIcon rotate="Left" color="var(--color-main)" />}
        </div>
        <p>{description}</p>
      </div>
    </button>
  )
}
