import React from 'react'

import { InfoOutlineIcon } from '../../../../assets/InfoOutlineIcon'
import PencilIcon from '../../../../assets/PencilIcon'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

import './telesales-header.scss'

interface Props {
  handleRedirect: () => void
}

export const TelesalesHeader = ({ handleRedirect }: Props) => {
  const { isMobile } = useWindowDimensions()

  return (
    <div className={`telesales-header-link ${isMobile && 'grid-content'}`}>
      <span className="telesales-header-title">
        Aqui está sua lista de compras
      </span>
      {isMobile && (
        <div className="telesales-header-info">
          <div className="telesales-header-info-container">
            <InfoOutlineIcon
              color="var(--color-grayScale-darkest)"
              width={16}
              height={16}
            />
          </div>
          <span>
            <b>Dica:</b> Navegue pela aba dos termos buscados abaixo e encontre
            seus produtos
          </span>
        </div>
      )}

      <div className={`telesales-container ${!isMobile && 'grid-content '}`}>
        <button className="telesales-header-list" onClick={handleRedirect}>
          <PencilIcon color="var(--color-main)" />
          Alterar minha lista de compras
        </button>
      </div>
    </div>
  )
}
