import { useClubContext } from '../contexts/ClubContext'

export type showSectionFilter = 'ambos' | 'isLoggedIn' | 'notLoggedIn'

export type showSectionVariables = {
  club: showSectionFilter
  cartonista: showSectionFilter
}

export const defaultVisibilityConfig: showSectionVariables = {
  club: 'ambos',
  cartonista: 'ambos',
}

export function showSection(params: showSectionVariables): boolean {
  const { club, cartonista } = params
  const { isClubClient, userInfos } = useClubContext()

  let isClubValid = false
  let isCartonistaValid = false

  if (
    club === 'ambos' ||
    (club === 'isLoggedIn' && isClubClient) ||
    (club === 'notLoggedIn' && !isClubClient)
  ) {
    isClubValid = true
  }

  if (
    cartonista === 'ambos' ||
    (cartonista === 'isLoggedIn' && userInfos?.cartonista) ||
    (cartonista === 'notLoggedIn' && !userInfos?.cartonista)
  ) {
    isCartonistaValid = true
  }

  return isClubValid && isCartonistaValid
}
