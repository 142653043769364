import { Link } from 'gatsby'
import React from 'react'

import { convertTextToParameter } from '../../../../utils/convertTextToParameter'
import { ProductEspecificationsSeals } from '../../../common/ProductEspecificationsSeals'

import './food-restriction-flag-link.scss'

interface FoodRestrictionFlagLinkProps {
  restrictionTerms: {
    term: string
  }[]
}

export const FoodRestrictionFlagLink = ({
  restrictionTerms,
}: FoodRestrictionFlagLinkProps) => {
  return (
    <div className="restriction-flag-link-container">
      <div className="product-especifications-container">
        {restrictionTerms.map((restriction) => (
          <Link
            className="especification-link"
            to={`/restricao-alimentar?restriction=${convertTextToParameter(
              restriction.term
            )}`}
            key={restriction.term}
          >
            <ProductEspecificationsSeals text={restriction.term} />
          </Link>
        ))}
      </div>
    </div>
  )
}
