import React from 'react'

import type { AllStoreProps } from '../../../types/StoreInfoProps'

import './storeInfo.scss'
import { formatStoreInfo } from '../../../utils/formatStoreInfo'

export const StoreInfo = ({ storeList }: AllStoreProps) => {
  const allStores = formatStoreInfo(storeList)

  return (
    <div className="store-container">
      <div className="store-grid">
        {allStores?.map((storesRegion) => (
          <>
            <div>
              <h1 className="store-city">{storesRegion.city}</h1>
              {storesRegion?.stores?.map((store) => (
                <div key={store.name}>
                  <h2 className="store-title">{store.name}</h2>
                  <div className="store-content">
                    <p>{store.address}</p>
                    <p>
                      {store.neighborhood} - {store.city}
                    </p>
                    <p>Fone: {store.phone}</p>
                  </div>
                  <div className="store-hours-container">
                    <h2 className="store-hours">Horário de Funcionamento:</h2>
                    <p className="store-openingHours">{store.openingHours}</p>
                  </div>
                  <a
                    className="store-link"
                    href={store.googleMapsLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Como chegar
                  </a>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
