import React from 'react'

import type { Icon } from '../../types/FAQ'

export const LikeIcon = ({
  width = 29,
  height = 27,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M22.0334 27H6.46675V9.93331L15.7334 0.333313L16.8334 1.19998C17.0779 1.37776 17.239 1.57776 17.3167 1.79998C17.3945 2.0222 17.4334 2.27776 17.4334 2.56665V2.89998L15.9334 9.93331H26.6667C27.1779 9.93331 27.639 10.1389 28.0501 10.55C28.4612 10.9611 28.6667 11.4222 28.6667 11.9333V14.6666C28.6667 14.9111 28.639 15.1944 28.5834 15.5166C28.5279 15.8389 28.4445 16.1222 28.3334 16.3666L24.4667 25.3C24.2667 25.7666 23.939 26.1666 23.4834 26.5C23.0279 26.8333 22.5445 27 22.0334 27ZM4.46675 9.93331V27H0.666748V9.93331H4.46675Z"
          fill={color}
        />
      ) : (
        <path
          d="M21.8666 27H7.06663V9.93337L16.3333 0.333374L17.6333 1.36671C17.7666 1.47782 17.8666 1.63337 17.9333 1.83337C18 2.03337 18.0333 2.27782 18.0333 2.56671V2.90004L16.5333 9.93337H26.5C27.0333 9.93337 27.5 10.1334 27.9 10.5334C28.3 10.9334 28.5 11.4 28.5 11.9334V14.6667C28.5 14.8223 28.5166 14.9834 28.55 15.15C28.5833 15.3167 28.5666 15.4778 28.5 15.6334L24.3 25.3C24.1 25.7667 23.7722 26.1667 23.3166 26.5C22.8611 26.8334 22.3777 27 21.8666 27ZM9.06664 25H22.3L26.5 15.0334V11.9334H14.0666L15.8333 3.63337L9.06664 10.7667V25ZM7.06663 9.93337V11.9334H2.6333V25H7.06663V27H0.633301V9.93337H7.06663Z"
          fill={color}
        />
      )}
    </svg>
  )
}
