import { gql } from '@faststore/graphql-utils'

import { useQuery } from '../graphql/useQuery'
import { useSession } from '../session'

const DEFAULT_SUGGESTIONS_LIMIT = 5

const query = gql`
  query SearchSuggestionsQuery(
    $limit: Int!
    $term: String!
    $selectedFacets: [IStoreSelectedFacet!]
  ) {
    search(first: $limit, term: $term, selectedFacets: $selectedFacets) {
      suggestions {
        terms {
          value
        }
        products {
          ...ProductSummary_product
        }
      }
    }
  }
`

function useSuggestions(
  term: string,
  limit: number = DEFAULT_SUGGESTIONS_LIMIT
) {
  const { channel, locale } = useSession()

  const { data, error } = useQuery<any, any>(query, {
    limit,
    term,
    selectedFacets: [
      { key: 'channel', value: channel ?? '' },
      { key: 'locale', value: locale },
    ],
  })

  return {
    terms: (data?.search.suggestions.terms ?? []).slice(0, limit),
    products: (data?.search.suggestions.products ?? []).slice(0, limit),
    isLoading: !error && !data,
  }
}

export default useSuggestions
