import React, { useEffect, useMemo } from 'react'
import { CardActions } from '@faststore/ui'
import CloseIconV2 from '../../../assets/CloseIconV2'
import Link from '../../ui/Link'
import Button from '../../ui/Button'
import { GenericModal } from '../../ui/GenericModal'
import { PriceCustom } from '../../ui/PriceCustom'
import ProductMoreInfos from '../../ui/ProductMoreInfos/ProductMoreInfos'
import { FoodRestrictionFlagLink } from '../FoodRestriction/FoodRestrictionFlagLink'
import { ProductDetailsActions } from '../ProductDetails/components/ProductDetailsActions'
import { ProductDetailsGallery } from '../ProductDetails/components/ProductDetailsGallery'
import { ProductDetailsHeader } from '../ProductDetails/components/ProductDetailsHeader'
import { ProductDetailsPrices } from '../ProductDetails/components/ProductDetailsPrices'
import { StorageDetailsFlag } from '../ProductDetails/components/StorageDetailsFlag'
import { useRegisterLastSeenProduct } from '../../../hooks/lastSeenProducts/useRegisterLastSeenProduct'
import { useStoreContext } from '../../../contexts/StoreContext'
import { useClubContext } from '../../../contexts/ClubContext'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import { useBuyProduct } from '../../../hooks/useBuyProduct'
import { useRegionalizationContext } from '../../../contexts/RegionalizationContext'
import { formatEspecifications } from '../../../utils/formatEspecifications'
import { formatUndQtd, formatUnd } from '../../../utils/formatPrices'
import { pesavelUND } from '../../../constants'

import type { ProductsQueryResponse } from '../../../types/Product'

import './quick-view.scss'

interface QuickViewProps {
  openQuickView: boolean
  setOpenQuickView: (openQuickView: boolean) => void
  product: ProductsQueryResponse['node']
  openClubModal: boolean
  setOpenClubModal: (openClubModal: boolean) => void
  addToCardEventLocation?: string
  defaultScListPrice?: number
  clubPrice?: number
}

export const QuickView = ({
  openQuickView,
  setOpenQuickView,
  product: staleProduct,
  openClubModal,
  setOpenClubModal,
  addToCardEventLocation = 'quick_view',
  defaultScListPrice: defaultScListPriceProp,
  clubPrice: clubPriceProp,
}: QuickViewProps) => {
  useRegisterLastSeenProduct(staleProduct.sku)

  const { buyProps, cartProductId, cartProductQty, productData } =
    useBuyProduct(staleProduct)
  const { hasClub } = useStoreContext()
  const { isClubClient } = useClubContext()

  // Prevent request if these prices are already available
  const anotherScPrices = useAnotherScPrices(
    defaultScListPriceProp || clubPriceProp ? undefined : [staleProduct]
  ).getAnotherScPrices(staleProduct.sku) ?? {
    defaultScListPrice: defaultScListPriceProp,
    clubPrice: clubPriceProp,
  }

  const { openRegionModal } = useRegionalizationContext()

  const {
    product: {
      measurementUnit,
      unitMultiplier,
      sku,
      description,
      brand,
      isVariantOf: { name },
      image: productImages,
      offers: {
        offers: [{ availability }],
      },
      customOffers: {
        listPriceCustom,
        spotPriceKg,
        spotPriceCustom,
        hasDiscount,
      },
      additionalProperty,
    },
  } = productData

  const buyDisabled = availability !== 'https://schema.org/InStock'
  const isMeasured = measurementUnit !== 'un'
  const clubValidation = !!(
    hasClub &&
    isClubClient &&
    anotherScPrices.defaultScListPrice
  )

  const hasPesavelUnd = staleProduct.skuSpecifications?.some(
    (specification) => {
      return (
        specification.field.originalName === pesavelUND &&
        specification.values.some((value) => value.originalName === 'true')
      )
    }
  )

  const hasUndQtd = useMemo(
    () => formatUndQtd(hasPesavelUnd, unitMultiplier),
    [hasPesavelUnd, unitMultiplier]
  )

  const hasUnd = useMemo(
    () => formatUnd(hasPesavelUnd, unitMultiplier, measurementUnit),
    [hasPesavelUnd, unitMultiplier, measurementUnit]
  )

  useEffect(() => {
    if (openClubModal || openRegionModal) {
      setOpenQuickView(false)
    }
  }, [openClubModal, openRegionModal, setOpenQuickView])

  const { foodRestrictionSection, storageRestrictionSection } =
    useStoreContext()

  const restrictionTags = foodRestrictionSection?.map(
    ({ specificationName }) => specificationName
  )

  const storageTags = storageRestrictionSection?.map(
    ({ specificationName }) => specificationName
  )

  const { productRestriction, productStorage } = useMemo(
    () =>
      formatEspecifications(additionalProperty, restrictionTags, storageTags),
    [additionalProperty, restrictionTags, storageTags]
  )

  const terms =
    productRestriction?.map((restriction) => ({
      term: restriction,
    })) ?? []

  function handleCloseModal() {
    setOpenQuickView(false)
  }

  return (
    <GenericModal
      isOpenModal={openQuickView}
      onClickOverlay={handleCloseModal}
      className="quick-view-modal"
      overlayAriaLabel="quick-view-modal"
    >
      <div className="quick-view-container">
        <div className="quick-view-header">
          <Link
            to={`/${staleProduct.slug}/p`}
            onClick={handleCloseModal}
            className="quick-view-pdp-link"
          >
            Ver mais
          </Link>
          <Button className="quick-view-close-modal" onClick={handleCloseModal}>
            <CloseIconV2 color="var(--color-main)" />
          </Button>
        </div>

        <div className="quick-view-content">
          <div className="quick-view-details">
            <ProductDetailsGallery
              clubValidation={clubValidation}
              productImages={productImages}
              listPriceCustom={listPriceCustom}
              spotPriceCustom={spotPriceKg ?? spotPriceCustom}
              hasDiscount={hasDiscount}
            />

            <div className="quick-view-product-infos">
              <ProductDetailsHeader name={name} brand={brand.name} />

              <ProductDetailsPrices
                listPriceCustom={listPriceCustom}
                spotPriceKg={spotPriceKg}
                spotPriceCustom={spotPriceCustom}
                hasDiscount={hasDiscount}
                unitMultiplier={unitMultiplier}
                measurementUnit={measurementUnit}
                clubValidation={clubValidation}
                {...anotherScPrices}
                isClubClient={isClubClient}
                hasUndQtd={hasUndQtd}
                hasUnd={hasUnd}
                hasPesavelUnd={hasPesavelUnd}
              />

              {terms.length > 0 && (
                <section className="restriction-flag-link-content">
                  <h3 className="restriction-flag-link-title">
                    Restrição Alimentar
                  </h3>
                  <FoodRestrictionFlagLink restrictionTerms={terms} />
                </section>
              )}

              <ProductMoreInfos
                title="Descrição do Produto"
                description={description}
              />

              {productStorage.length > 0 && (
                <StorageDetailsFlag
                  title="Armazenamento"
                  storage={productStorage[0]}
                />
              )}
            </div>
          </div>
        </div>

        <div className="quick-view-footer">
          <div className="quick-view-footer-left">
            <PriceCustom
              price={spotPriceCustom}
              className="quick-view-footer-price"
            />
            {isMeasured && (
              <span className="quick-view-footer-multiplier">
                /{hasUndQtd}
                {hasUnd}
              </span>
            )}
          </div>

          <CardActions>
            <ProductDetailsActions
              hasClub={hasClub}
              cartProductId={cartProductId}
              sku={sku}
              unitMultiplier={unitMultiplier}
              measurementUnit={measurementUnit}
              cartProductQty={cartProductQty}
              buyProps={buyProps}
              buyDisabled={buyDisabled}
              setOpenClubModal={setOpenClubModal}
              clubPrice={anotherScPrices.clubPrice}
              isClubClient={isClubClient}
              location={addToCardEventLocation}
              buttonText="Adicionar"
              hasPesavelUnd={hasPesavelUnd}
              spotPriceCustom={spotPriceCustom}
            />
          </CardActions>
        </div>
      </div>
    </GenericModal>
  )
}
