import { HAS_WINDOW } from '../constants'

import type { TopicOptions } from '../types/FAQ'

export const extractSearchParameters = (searchParam: string) => {
  const decodedUrl = decodeURIComponent(searchParam.replace('?topic=', ''))

  const extractedSearchParameter: TopicOptions | '' = decodedUrl
  const questionIndex = decodedUrl.indexOf('&question=')
  const extractQuestion =
    questionIndex !== -1 ? decodedUrl.slice(questionIndex + 10) : undefined

  return {
    topic:
      questionIndex !== -1
        ? decodedUrl.slice(0, questionIndex)
        : extractedSearchParameter,
    question: extractQuestion,
  }
}

export const setQueryParams = (param: string, value: string) => {
  if (!HAS_WINDOW) {
    return
  }

  const { search } = window.location
  const params = new URLSearchParams(search)

  params.set(param, value)
  window.history.pushState({}, '', `?${params.toString()}`)
}

export const deleteQueryParam = (param: string) => {
  if (!HAS_WINDOW) {
    return
  }

  const url = new URL(window.location.href)

  url.searchParams.delete(param)
  history.pushState({}, '', url)
}
