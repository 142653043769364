import React from 'react'

import './tabloid-scroll-top.scss'
import ArrowIcon from '../../../../assets/ArrowIcon'

const TabloidScrollTop = () => {
  const topFunction = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const mybutton = document.getElementById('scroll-button-id')

  window.onscroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      if (mybutton) mybutton.classList.add('scroll-visible')
    } else if (mybutton) mybutton.classList.remove('scroll-visible')
  }

  return (
    <button
      className="scroll-button"
      id="scroll-button-id"
      onClick={topFunction}
    >
      <ArrowIcon color="#2C3490" />
    </button>
  )
}

export default TabloidScrollTop
