import './discounts.scss'

import React from 'react'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { AdaptiveCarousel } from '../../../common/AdaptiveCarousel'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { DiscountCard } from '../../../ui/DiscountCard'

import type { Discounts as DiscountsProps } from '../../../../types/ClubCard/ClubCardBenefits'

export const Discounts = ({
  visibility,
  title,
  tag,
  logo,
  list,
}: DiscountsProps) => {
  const { width, isMobile } = useWindowDimensions()
  const isMobileWidth = width && width <= 768

  if (!visibility || !list?.length) {
    return null
  }

  return (
    <div className="club-card-benefits-discounts grid-content">
      {title && (
        <DynamicTitle
          className="discounts-title"
          titleLevel={2}
          title={title}
        />
      )}
      <AdaptiveCarousel
        quantity={list?.length}
        quantityToCarousel={8}
        activeBlur={false}
        forceCarousel={!!isMobile}
        propsCarousel={{
          gapItems: 0,
          qtyItems: !isMobileWidth ? 3 : 2,
          itemWidthProp: !isMobileWidth ? 268 : 134,
        }}
      >
        {list?.map((itemDiscount) => (
          <DiscountCard
            key={itemDiscount?.text}
            image={itemDiscount?.image}
            tag={tag}
            text={itemDiscount?.text}
            imageBottom={logo}
            isMobile={!!isMobileWidth}
          />
        ))}
      </AdaptiveCarousel>
    </div>
  )
}
