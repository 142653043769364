import React, { useEffect, useState } from 'react'

import { TabloidContent } from '../components/sections/Tabloids/TabloidContent'
import ValidityRange from '../components/sections/Tabloids/TabloidData'
import { TabloidNotFound } from '../components/sections/Tabloids/TabloidNotFound'
import TabloidScrollTop from '../components/sections/Tabloids/TabloidScrollTop'
import { TabloidSelect } from '../components/sections/Tabloids/TabloidSelect'
import { TabloidShareLink } from '../components/sections/Tabloids/TabloidShareLink'
import { TabloidWarning } from '../components/sections/Tabloids/TabloidWarning'
import { NbbTabloid } from '../components/tabloid/NbbTabloid'
import { NavigationInstitutional } from '../components/ui/NavigationInstitutional'
import { HAS_WINDOW } from '../constants'
import { useStoreContext } from '../contexts/StoreContext'
import { useTabloidsData } from '../hooks/useTabloids'

interface TabloidsPageProps {
  tabloidNbbIsActive: boolean
  cid?: string
}

export const TabloidsPage = ({
  tabloidNbbIsActive,
  cid,
}: TabloidsPageProps) => {
  const { hasTabloidV2 } = useStoreContext()
  const [activeTabloid, setActiveTabloid] = useState<string>('')
  const { tabloidData, error } = useTabloidsData(activeTabloid)

  useEffect(() => {
    if (!HAS_WINDOW) {
      return
    }

    const handleRegionWarning = () => {
      if (window.scrollY > 400) {
        window.document.querySelector('header')?.classList.add('inTabloidPage')
      }

      if (window.scrollY < 360) {
        window.document
          .querySelector('header')
          ?.classList.remove('inTabloidPage')
      }
    }

    window.addEventListener('scroll', handleRegionWarning)

    return () => window.removeEventListener('scroll', handleRegionWarning)
  }, [HAS_WINDOW])

  const topBanner = tabloidData?.topBanner
  const tabloidNames = tabloidData?.tabloidNames
  const sectionBanners = tabloidData?.sectionBanners
  const actvTabloid = tabloidData?.activeTabloid?.name
  const allProducts = tabloidData?.allProducts
  const dataMin = tabloidData?.activeTabloid?.validity?.from
  const dataMax = tabloidData?.activeTabloid?.validity?.to
  const isUserRegion = tabloidData?.isUserRegion

  useEffect(() => {
    if (actvTabloid) {
      setActiveTabloid(actvTabloid)
    }
  }, [actvTabloid])

  if (
    !tabloidNbbIsActive &&
    activeTabloid !== 'default' &&
    (tabloidData?.tabloidNames.length === 0 ||
      error === 'Invalid or expired date')
  ) {
    return <TabloidNotFound />
  }

  if (tabloidNbbIsActive) return <NbbTabloid cid={cid} />

  return (
    <>
      {hasTabloidV2 && (
        <>
          {tabloidNames ? (
            <div className="grid-content">
              <NavigationInstitutional titleText="Folhetos de Oferta" />
              <TabloidSelect
                title="Folheto de Ofertas:"
                activeTabloid={activeTabloid}
                setActiveTabloid={setActiveTabloid}
                tabloidsOptions={tabloidNames}
              />
              {activeTabloid && (
                <ValidityRange
                  validityMin={dataMin ?? ''}
                  validityMax={dataMax ?? ''}
                />
              )}
              {!isUserRegion && <TabloidWarning />}
              {activeTabloid && <TabloidShareLink />}
            </div>
          ) : null}

          {allProducts && activeTabloid && activeTabloid !== 'default' ? (
            <>
              <TabloidScrollTop />
              <TabloidContent
                topBanner={topBanner}
                clusterDefaultProducts={allProducts}
                activeTabloid={activeTabloid}
                categoryBanners={sectionBanners}
                isUserRegion={isUserRegion ?? false}
              />
            </>
          ) : null}
        </>
      )}
    </>
  )
}
