import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { ProductShelfById } from '../../sections/ProductShelfById'
import Button from '../../ui/Button'
import { Image } from '../../ui/Image'

import type {
  CampaignBanners,
  CampaignButtons,
  CampaignLoading,
  CampaignShelfData,
} from '../../../types/Campaigns'

import './tab-layout.scss'

interface TabLayoutProps extends CampaignLoading {
  data: CampaignBanners | CampaignButtons
  loading: boolean | undefined
  shelfTypeDefault?: 'category' | 'cluster' | 'brand' | 'link'
  shelfValueDefault?: string
}

// Type guard function to check if item is of type CampaignBanners
const isCampaignBanner = (item: any): item is CampaignBanners[number] => {
  return (
    'shelfType' in item &&
    'shelfValue' in item &&
    'src' in item &&
    'alt' in item
  )
}

export const TabLayout = ({
  data,
  loading,
  shelfTypeDefault = 'link',
  shelfValueDefault = '',
}: TabLayoutProps) => {
  const { width: widthHook, isMobile } = useWindowDimensions()
  const [screenWidth, setScreenWidth] = useState(0)
  const [shelfType, setShelfType] =
    useState<CampaignShelfData['shelfType']>(shelfTypeDefault)

  const [shelfValue, setShelfValue] =
    useState<CampaignShelfData['shelfValue']>(shelfValueDefault)

  useEffect(() => {
    if (widthHook) {
      setScreenWidth(widthHook)
    }
  }, [widthHook])

  const itensDesktop = data.length >= 4 ? 4 : data.length
  const itensVerifyMobile = isMobile ? 2 : itensDesktop
  const itens = data.length >= 2 ? itensVerifyMobile : 1
  const styleInline =
    data && !isCampaignBanner(data?.[0])
      ? {
          display: 'grid',
          gridTemplateColumns: `repeat(${itens}, minmax(160px, 100%))`,
          gap: '16px',
        }
      : {}

  return (
    <>
      <section
        className="grid-content tab-layout-container"
        style={styleInline}
      >
        {Array.isArray(data) &&
          data.map((item, index) => (
            <Button
              className={`tab-layout-button ${
                isCampaignBanner(item) ? 'banner' : 'buttons'
              }`}
              style={
                isCampaignBanner(item)
                  ? {}
                  : { backgroundColor: item?.color ?? 'var(--color-main)' }
              }
              onClick={() => {
                if (item.shelfType !== 'link') {
                  setShelfType(item.shelfType)
                  setShelfValue(item.shelfValue)
                } else {
                  navigate(item.shelfValue)
                }
              }}
              key={`button-${index}`}
            >
              {isCampaignBanner(item) ? (
                <Image
                  baseUrl={screenWidth <= 768 ? item.srcMobile : item.src}
                  sourceWidth={screenWidth <= 768 ? 234 : 454}
                  sourceHeight={screenWidth <= 768 ? 234 : 256}
                  alt={item.alt}
                  layout="fullWidth"
                  backgroundColor="var(--color-grayScale-faded)"
                />
              ) : (
                item.text
              )}

              {isCampaignBanner(item) && (
                <span
                  className={`tab-layout-img-background ${
                    shelfValue === item.shelfValue ? 'selected' : ''
                  }`}
                />
              )}
            </Button>
          ))}
      </section>

      {shelfType !== 'link' && (
        <ProductShelfById
          data={{
            shelfType,
            shelfValue,
            visibility: true,
          }}
          loading={loading}
        />
      )}
    </>
  )
}
