import React, { useState } from 'react'

import { FeedbackPopup } from './FeedbackPopup'
import { ShareTabloidIcon } from '../../../../assets/ShareTabloidIcon'
import { HAS_WINDOW } from '../../../../constants'
import './share-tabloid-link.scss'

export const TabloidShareLink = () => {
  const [feedbackVisible, setFeedbackVisible] = useState(false)

  const handleShare = () => {
    if (!HAS_WINDOW) {
      return
    }

    navigator?.clipboard?.writeText(window.location.href)
    setFeedbackVisible(true)

    setTimeout(() => setFeedbackVisible(false), 3000)
  }

  return (
    <>
      <button className="share-tabloid-link-button" onClick={handleShare}>
        <ShareTabloidIcon />
        <span className="share-tabloid-link-text">Compartilhar Folheto</span>
      </button>

      {feedbackVisible && <FeedbackPopup />}
    </>
  )
}
