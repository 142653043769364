import React from 'react'

interface AccordionArrowsProps {
  width?: number
  height?: number
  color?: string
  side?: 'top' | 'right' | 'down' | 'left'
}

const direction = {
  top: 180,
  right: 270,
  down: 0,
  left: 90,
}

export const AccordionArrows = ({
  width = 24,
  height = 24,
  color = 'var(--color-grayScale)',
  side = 'down',
}: AccordionArrowsProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={{
        transform: `rotate(${direction[side]}deg)`,
      }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 10L12 16L18 10"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
