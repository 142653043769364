import './points-wrapper.scss'

import React from 'react'

import { useCardContext } from '../../../../contexts/CardContext'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { StatusMessage } from '../../../ui/StatusMessage'
import { Points } from '../../Benefits/Points'

import type { Points as PointsProps } from '../../../../types/ClubCard/ClubCardUser'

export const PointsWrapper = ({
  visibility,
  title,
  text,
  linkText,
  linkTextWithoutPoints,
  hrefWithoutPoints,
  pointsIcon,
  icon,
  list,
  lastList,
  colorConfig,
}: PointsProps) => {
  const { balance, pointsUrl } = useCardContext()

  if (!visibility || !list?.length) {
    return null
  }

  if (balance?.error || pointsUrl?.error) {
    return (
      <div className="club-card-benefits-points grid-content">
        {title && (
          <DynamicTitle
            className="points-title"
            titleLevel={2}
            title={title}
            titleColor={colorConfig?.titleColor}
          />
        )}
        <StatusMessage />
      </div>
    )
  }

  return (
    <Points
      visibility={visibility}
      title={title}
      text={text}
      icon={icon}
      list={list}
      balance={balance}
      button={{
        href: balance?.data ? (pointsUrl?.data ?? '/') : hrefWithoutPoints,
        text: balance?.data ? linkText : linkTextWithoutPoints,
      }}
      pointsIcon={pointsIcon}
      className="club-card-user-points"
      lastList={lastList}
      colorConfig={colorConfig}
    />
  )
}
