import React from 'react'

import { formatBrazilianPrice } from '../../../../utils/formatPrices'
import { ProductCardName } from '../../../product/ProductCard/components/ProductCardName/ProductCardName'
import { ProductCardPrices } from '../../../product/ProductCard/components/ProductCardPrices'
import { Image } from '../../../ui/Image'

import type { ProductsQueryResponse } from '../../../../types/Product'
import type { CustomerOrder } from '../../../../types/RepeatOrder'

interface ProductCardProps {
  product: ProductsQueryResponse['node']
  order: CustomerOrder
  titleLevel: number
  defaultScListPrice?: number
  clubPrice?: number
}

interface Item {
  quantity: number
  id: string
}

export const ProductCard = ({
  product,
  order,
  titleLevel,
  defaultScListPrice,
  clubPrice,
}: ProductCardProps) => {
  const convertOrderItemsToObject = order.items.reduce((accumulator, value) => {
    return { ...accumulator, [value.id]: value }
  }, {} as Item)

  const isMeasured = product.measurementUnit !== 'un'
  const itemOrderSelected =
    convertOrderItemsToObject[
      product.sku as keyof typeof convertOrderItemsToObject
    ] || ({} as any)

  const priceFormatted = formatBrazilianPrice(
    product.customOffers.spotPriceCustom
  )

  const handleUnitMultiplierMoreOne =
    product.unitMultiplier >= 1
      ? product.unitMultiplier
      : product.unitMultiplier * 1000

  const handleMeasurementUnit =
    product.unitMultiplier * 1000 >= 1000 ? product.measurementUnit : 'g'

  const spotPriceCustom = isMeasured
    ? product.customOffers.spotPriceCustom
    : product.customOffers.spotPriceCustom *
      parseInt(itemOrderSelected.quantity, 10)

  const handlePluralOfProduct = `${itemOrderSelected.quantity} unidade${
    parseInt(itemOrderSelected.quantity, 10) > 1 ? 's' : ''
  }`

  return (
    <div className="product-list-item">
      <div className="product-list-image">
        <Image
          objectFit="contain"
          baseUrl={product.image[0].url}
          layout="fullWidth"
          sourceWidth={55}
          sourceHeight={55}
          backgroundColor="var(--color-grayScale-faded)"
          options={{
            fitIn: true,
          }}
          alt={product.image[0].alternateName}
          loading="lazy"
        />
      </div>
      <div className="product-list-info">
        <ProductCardName productName={product.name} titleLevel={titleLevel} />
        <ProductCardPrices
          listPriceCustom={product.customOffers.listPriceCustom}
          spotPriceKg={product.customOffers.spotPriceKg}
          spotPriceCustom={spotPriceCustom}
          hasDiscount={product.customOffers.hasDiscount}
          unitMultiplier={product.unitMultiplier}
          measurementUnit={product.measurementUnit}
          defaultScListPrice={defaultScListPrice}
          clubPrice={clubPrice}
        />
        {isMeasured ? (
          <div className="product-multiplier-container">
            <span className="product-multiplier-custom">
              {handleUnitMultiplierMoreOne}
              {handleMeasurementUnit}
            </span>
          </div>
        ) : (
          <div className="product-multiplier-container">
            <span className="product-spot">{priceFormatted}</span>
            <span className="product-multiplier-custom">
              {handlePluralOfProduct}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
