import React from 'react'

const direction = {
  Up: 180,
  Down: 1,
  Left: 270,
  Right: 90,
}

type Props = {
  rotate?: 'Down' | 'Left' | 'Right' | 'Up'
  color?: string | false
  width?: string
}

const ArrowIcon = ({ rotate = 'Up', color, width }: Props) => (
  <svg
    style={{
      transform: `rotate(${direction[rotate]}deg)`,
    }}
    width={width ?? '16'}
    height={width ?? '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 7L8 11L12 7"
      stroke={color || 'var(--color-grayScale-darkest) '}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowIcon
