import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import React from 'react'

import { RenderCMS } from '../components/sections/RenderCMS'
import { OrderStatusHandler } from '../components/ui/Orders/OrderStatusHandler'
import { useHomeContent } from '../hooks/useHomeContent'
import { useSession } from '../sdk/session'

import type { DynamicShowcase } from '../components/HomePage/DynamicShowcases'

import '../styles/pages/index.scss'

interface HomePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string | null
        description: string | null
        titleTemplate: string | null
      } | null
    } | null
  }
  location: { pathname: string; host: string }
  dynamicShowcases: DynamicShowcase[]
  loading: boolean
}

export const HomePage = ({
  data: { site },
  location: { pathname, host },
  loading,
  dynamicShowcases,
}: HomePageProps) => {
  const { locale } = useSession()
  const { preview, sections } = useHomeContent()

  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = `https://${host}${pathname}`

  return (
    <>
      <GatsbySeo
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        language={locale}
        canonical={siteUrl}
        openGraph={{
          type: 'website',
          url: siteUrl,
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/s/?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        }}
      />

      <h1 className="title-header">{title} - Página Inicial</h1>

      <OrderStatusHandler />

      <RenderCMS
        sections={sections}
        preview={preview}
        loadingDynamicShowcases={loading}
        dynamicShowcases={dynamicShowcases}
      />
    </>
  )
}
