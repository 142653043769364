import { Breadcrumb as UIBreadcrumb } from '@faststore/ui'
import React, { memo } from 'react'

import ArrowIcon from '../../../assets/ArrowIcon'
import HomeIcon from '../../../assets/HomeIcon'
import {
  capitalizeText,
  replaceStringBreadcrumb,
} from '../../../utils/replaceStrings'
import Link from '../Link'

import type { BreadcrumbProps as UIBreadcrumbProps } from '@faststore/ui'
import './breadcrumb.scss'

export interface ItemElement {
  item: string
  name: string
  position: number
}

export interface BreadcrumbProps extends UIBreadcrumbProps {
  breadcrumbList: ItemElement[]
}

function Breadcrumb({ breadcrumbList }: BreadcrumbProps) {
  if (breadcrumbList.length === 0) {
    return null
  }

  return (
    <UIBreadcrumb
      divider={
        <div data-breadcrumb-divider>
          <ArrowIcon rotate="Left" />
        </div>
      }
    >
      <Link aria-label="home" to="/">
        <HomeIcon />
      </Link>

      {breadcrumbList.map(({ item, name }, index) => {
        return breadcrumbList.length === index + 1 ? (
          <span key={name}>{capitalizeText(name)}</span>
        ) : (
          <Link to={replaceStringBreadcrumb(item)} key={name}>
            {capitalizeText(name).substring(0, 16)}
            {name.length > 16 ? '...' : ''}
          </Link>
        )
      })}
    </UIBreadcrumb>
  )
}

export default memo(Breadcrumb)
