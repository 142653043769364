import { Link } from 'gatsby'
import React, { useRef } from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useCascadePopup } from '../../../hooks/useCascadePopup'

interface ToKnowProps {
  handleKnowMoreAction: () => void
}

export const ToKnow = ({ handleKnowMoreAction }: ToKnowProps) => {
  const toKnowPopupRef = useRef<HTMLDivElement | null>(null)

  const { customPopupHeight } = useCascadePopup(toKnowPopupRef)
  const { genericTaggingEvent } = useAnalyticsEvents()

  return (
    <div
      className="to-know-container"
      ref={toKnowPopupRef}
      style={{ height: customPopupHeight }}
    >
      <div>
        <p>
          Quer conhecer como o site se personaliza conforme você compra usando
          sua conta?
        </p>

        <div>
          <Link
            to="/"
            onClick={() => {
              genericTaggingEvent('ofertas_pelo_whatsapp', {
                clickText: 'Não, Obrigado',
              })
            }}
          >
            Não, Obrigado
          </Link>

          <button onClick={handleKnowMoreAction}>Conhecer</button>
        </div>
      </div>
    </div>
  )
}
