import React from 'react'

import { FAQInput } from '../FAQInput'
import './faq-header.scss'

export const FAQHeader = () => {
  return (
    <div className="faq-header-container">
      <h1 className="faq-header-title">Atendimento e Solução de Problemas</h1>
      <h2 className="faq-header-subtitle">Qual é sua dúvida?</h2>

      <FAQInput placeholderText="Digite sua dúvida aqui" />
    </div>
  )
}
