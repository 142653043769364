import React from 'react'

import { ProductShelfById } from '../../ProductShelfById'

import type { ProductShelfByIdDataProps } from '../../ProductShelfById'

type Props = {
  loading?: boolean
  shelfValue?: string
  shelfType?: 'category' | 'cluster' | 'brand'
  visibility?: boolean
  shelfTitle?: string
  seeAllLink?: string
}

export const CampaignProductShelfById = (data: Props) => {
  const dataProductShelfById = {
    shelfValue: data.shelfValue,
    shelfType: data.shelfType,
    visibility: data.visibility,
    shelfTitle: data.shelfTitle,
    seeAllLink: data.seeAllLink,
  } as ProductShelfByIdDataProps

  return (
    <>
      <ProductShelfById data={dataProductShelfById} />
    </>
  )
}
