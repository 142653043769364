import React from 'react'

import type { Icon } from '../../types/FAQ'

export const UserIcon = ({
  width = 22,
  height = 22,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M10.9999 10.9667C9.53325 10.9667 8.33325 10.5 7.39992 9.56666C6.46659 8.63333 5.99992 7.43333 5.99992 5.96666C5.99992 4.49999 6.46659 3.29999 7.39992 2.36666C8.33325 1.43333 9.53325 0.96666 10.9999 0.96666C12.4666 0.96666 13.6666 1.43333 14.5999 2.36666C15.5333 3.29999 15.9999 4.49999 15.9999 5.96666C15.9999 7.43333 15.5333 8.63333 14.5999 9.56666C13.6666 10.5 12.4666 10.9667 10.9999 10.9667ZM0.333252 21.6667V18.5333C0.333252 17.6889 0.544363 16.9667 0.966585 16.3667C1.38881 15.7667 1.93325 15.3111 2.59992 15C4.08881 14.3333 5.51659 13.8333 6.88325 13.5C8.24992 13.1667 9.62214 13 10.9999 13C12.3777 13 13.7444 13.1722 15.0999 13.5167C16.4555 13.8611 17.8777 14.3555 19.3666 15C20.0555 15.3111 20.611 15.7667 21.0333 16.3667C21.4555 16.9667 21.6666 17.6889 21.6666 18.5333V21.6667H0.333252Z"
          fill={color}
        />
      ) : (
        <path
          d="M11 10.9667C9.53337 10.9667 8.33337 10.5 7.40004 9.56667C6.46671 8.63334 6.00004 7.43334 6.00004 5.96668C6.00004 4.50001 6.46671 3.30001 7.40004 2.36668C8.33337 1.43334 9.53337 0.966675 11 0.966675C12.4667 0.966675 13.6667 1.43334 14.6 2.36668C15.5334 3.30001 16 4.50001 16 5.96668C16 7.43334 15.5334 8.63334 14.6 9.56667C13.6667 10.5 12.4667 10.9667 11 10.9667ZM0.333374 21.6667V18.5333C0.333374 17.6889 0.544485 16.9667 0.966707 16.3667C1.38893 15.7667 1.93337 15.3111 2.60004 15C4.08893 14.3333 5.51671 13.8333 6.88337 13.5C8.25004 13.1667 9.62226 13 11 13C12.3778 13 13.7445 13.1722 15.1 13.5167C16.4556 13.8611 17.8778 14.3556 19.3667 15C20.0556 15.3111 20.6112 15.7667 21.0334 16.3667C21.4556 16.9667 21.6667 17.6889 21.6667 18.5333V21.6667H0.333374ZM2.33337 19.6667H19.6667V18.5333C19.6667 18.1778 19.5612 17.8389 19.35 17.5167C19.1389 17.1945 18.8778 16.9556 18.5667 16.8C17.1445 16.1111 15.8445 15.6389 14.6667 15.3833C13.4889 15.1278 12.2667 15 11 15C9.73337 15 8.50004 15.1278 7.30004 15.3833C6.10004 15.6389 4.80004 16.1111 3.40004 16.8C3.08893 16.9556 2.83337 17.1945 2.63337 17.5167C2.43337 17.8389 2.33337 18.1778 2.33337 18.5333V19.6667ZM11 8.96667C11.8667 8.96667 12.5834 8.68334 13.15 8.11667C13.7167 7.55001 14 6.83334 14 5.96668C14 5.10001 13.7167 4.38334 13.15 3.81667C12.5834 3.25001 11.8667 2.96667 11 2.96667C10.1334 2.96667 9.41671 3.25001 8.85004 3.81667C8.28337 4.38334 8.00004 5.10001 8.00004 5.96668C8.00004 6.83334 8.28337 7.55001 8.85004 8.11667C9.41671 8.68334 10.1334 8.96667 11 8.96667Z"
          fill={color}
        />
      )}
    </svg>
  )
}
