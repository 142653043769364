import React from 'react'

import LoadingGif from '../../../assets/LoadingGif'
import { useTabloidNbb } from '../../../hooks/useTabloidNbb'
import './nbb-tabloid.scss'

interface NbbTabloidProps {
  cid?: string
}

export const NbbTabloid = ({ cid }: NbbTabloidProps) => {
  const { urlTabloidNbb } = useTabloidNbb(cid)

  return (
    <div>
      {urlTabloidNbb ? (
        <iframe
          src={urlTabloidNbb}
          width="100%"
          style={{
            borderStyle: 'none',
            minHeight: '600px',
            marginTop: '5px',
            marginBottom: '50px',
          }}
        />
      ) : (
        <div className="loading-container">
          <p>Carregando ofertas</p>
          <LoadingGif />
        </div>
      )}
    </div>
  )
}
