import React from 'react'

const LoadingGif = () => (
  <img
    height={16}
    width={16}
    src="https://nebula-cdn.kampyle.com/us/resources/onsite/images/kloader.gif"
    alt="loading"
  />
)

export default LoadingGif
