import React from 'react'

import {
  formatSearchedItems,
  textHighlighted,
} from '../../../../utils/dropdownSearchFunctions'
import Button from '../../../ui/Button'

import type { SearchItem } from '../../../../types/SearchDropdown'

import './multiple-search-input-dropdown.scss'

interface MultipleSearchInputDropdownProps {
  title: string
  term: string
  setTerm: (term: string) => void
  multipleTerms: string[]
  setMultipleTerms: (multipleTerms: string[]) => void
  setDropdownIsOpen: (dropdownIsOpen: boolean) => void
  suggestion: { value: string }[]
  lastSearched: SearchItem[] | undefined
  searchIsSuggestion: boolean
}

export const MultipleSearchInputDropdown = ({
  title,
  term,
  setTerm,
  multipleTerms,
  setMultipleTerms,
  setDropdownIsOpen,
  suggestion,
  lastSearched,
  searchIsSuggestion,
}: MultipleSearchInputDropdownProps) => {
  const handleDropdownItem = (item: string) => {
    setDropdownIsOpen(false)
    setMultipleTerms([...multipleTerms, item])
    setTerm('')
  }

  if (!suggestion && !lastSearched && !searchIsSuggestion) {
    return null
  }

  const sortedSuggestions =
    !searchIsSuggestion && lastSearched
      ? lastSearched
      : formatSearchedItems(suggestion, term)

  return (
    <div className="multiple-search-input-dropdown-container">
      <div className="multiple-search-input-dropdown-content">
        <h2 className="multiple-search-input-dropdown-title">{title}</h2>

        <>
          {sortedSuggestions.map((item: SearchItem, index) => (
            <Button
              className="multiple-search-input-dropdown-item"
              onClick={() => handleDropdownItem(item.text)}
              key={`multiple-search-input-dropdown-item-${item.text}-${index}`}
            >
              <span
                className="multiple-search-input-dropdown-item-text"
                dangerouslySetInnerHTML={{
                  __html:
                    !searchIsSuggestion && lastSearched
                      ? textHighlighted(item.text, term, false)
                      : textHighlighted(item.text, term),
                }}
              />
            </Button>
          ))}
        </>
      </div>
    </div>
  )
}
