import React from 'react'

import { hasPlusFriendBadge } from '../../../../../../devFeatureToggle.json'
import { truncPrice } from '../../../../utils/prices/truncPrice'
import { DiscountBadge } from '../../../ui/Badge'
import { ClubProductTag } from '../../../ui/ClubProductTag'
import { PriceCustom } from '../../../ui/PriceCustom'
import { ProductDetailsClubPriceFlag } from '../../../ui/ProductDetailsClubPriceFlag'

interface ProductDetailsPriceProps {
  listPriceCustom: number
  spotPriceKg: number | null
  spotPriceCustom: number
  hasDiscount: boolean
  unitMultiplier: number
  measurementUnit?: string
  clubValidation?: boolean
  clubPrice?: number
  defaultScListPrice?: number
  isClubClient?: boolean
  hasUndQtd?: number | string
  hasUnd?: string
  hasPesavelUnd?: boolean
}

export const ProductDetailsPrices = ({
  listPriceCustom,
  spotPriceKg,
  spotPriceCustom,
  hasDiscount,
  unitMultiplier,
  measurementUnit,
  clubValidation,
  clubPrice,
  defaultScListPrice,
  isClubClient,
  hasUndQtd,
  hasUnd,
  hasPesavelUnd,
}: ProductDetailsPriceProps) => {
  const clubPriceIsDiffFromSpotPrice = Boolean(
    clubPrice && clubPrice !== spotPriceCustom
  )

  const defaultScListPriceIsDiffFromSpotPrice = Boolean(
    defaultScListPrice && defaultScListPrice !== spotPriceCustom
  )

  const isMeasured = Boolean(measurementUnit !== 'un')
  const notHaveStock = listPriceCustom === 0

  const listPriceToShow =
    defaultScListPrice ??
    (hasPesavelUnd
      ? listPriceCustom
      : truncPrice(listPriceCustom * unitMultiplier))

  if (notHaveStock) return null

  return (
    <div className="product-details__prices">
      {(!!defaultScListPriceIsDiffFromSpotPrice || hasDiscount) && (
        <div>
          <PriceCustom price={listPriceToShow} className="old-price-pdp" />
          {isMeasured && (
            <span className="old-price-pdp">
              /{hasUndQtd}
              {unitMultiplier * 1000 >= 1000 ? measurementUnit : 'g'}{' '}
            </span>
          )}

          <DiscountBadge
            listPrice={listPriceToShow}
            spotPrice={spotPriceCustom}
          />
        </div>
      )}

      {isMeasured && !!spotPriceKg && hasPesavelUnd && (
        <div>
          <PriceCustom price={spotPriceKg} className="new-measured-price-pdp" />
          <span className="new-measured-price-pdp">/{measurementUnit} </span>
        </div>
      )}

      <div className="new-price-pdp-container">
        <div className="new-price-pdp-subcontainer-left">
          <PriceCustom price={spotPriceCustom} className="new-price-pdp" />
          {isMeasured && (
            <span className="new-price-pdp-multiplier">
              /{hasUndQtd}
              {hasUnd}
            </span>
          )}

          {clubValidation && (
            <ClubProductTag
              hasPlusFriendBadge={hasPlusFriendBadge}
              text="Clube"
              afterPriceLocation
            />
          )}
        </div>

        {isMeasured && !!spotPriceKg && (
          <div>
            <PriceCustom
              price={spotPriceKg}
              className="new-measured-price-pdp"
            />
            <span className="new-measured-price-pdp">/{measurementUnit} </span>
          </div>
        )}
      </div>

      {clubPriceIsDiffFromSpotPrice && !isClubClient && (
        <div className="club-price-pdp-container">
          <ProductDetailsClubPriceFlag
            unitMultiplier={unitMultiplier}
            measurementUnit={measurementUnit}
            clubRegistrationLink="/club-informacao"
            clubPrice={clubPrice}
            hasUndQtd={hasUndQtd}
            hasUnd={hasUnd}
          />
        </div>
      )}
    </div>
  )
}
