import React, { useEffect } from 'react'

import { useClubContext } from '../../../contexts/ClubContext'
import { useMostPurchased } from '../../../hooks/useMostPurchased'
import { useProductWithLowStock } from '../../../hooks/useProductWithLowStock'
import { ProductShelfSkeleton } from '../ProductShelf'
import { ProductShelfBySkus } from '../ProductShelf/ProductShelfBySkus'

export const BuyAgainShelf = () => {
  const { loading, products } = useMostPurchased()
  const { productsWithLowStock, loading: productsWithLowStockLoading } =
    useProductWithLowStock(products)

  const { setProductsMostPurchased } = useClubContext()

  useEffect(() => {
    if (products.length > 0) {
      setProductsMostPurchased?.(products)
    }
  }, [products])

  if ((products.length && productsWithLowStockLoading) || loading) {
    return (
      <section className="page__section / shelf-section-grid shelf-section-content-grid">
        <ProductShelfSkeleton />
      </section>
    )
  }

  if (!products?.length || !productsWithLowStock?.length) {
    return null
  }

  return (
    <ProductShelfBySkus
      type="club"
      products={productsWithLowStock}
      title="Comprar novamente"
      link="/compre-novamente"
      SeeMoreText={'Seus produtos preferidos!'}
      id={'vitrine-compre-novamente'}
      loading={productsWithLowStockLoading}
    />
  )
}
