import { Modal } from '@faststore/ui'
import React, { useRef, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import CloseIconV2 from '../../../assets/CloseIconV2'
import { HAS_WINDOW } from '../../../constants'

import type { Dispatch, SetStateAction } from 'react'

import './modal-quick-view.scss'

interface ModalQuickViewProps {
  children: React.ReactNode
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  title: string
  Footer?: React.ReactNode
  className?: string
}

export const ModalQuickView = (props: ModalQuickViewProps) => {
  const { children, isOpen, setIsOpen, title, Footer, className } = props

  useEffect(() => {
    if (!HAS_WINDOW) {
      return
    }

    if (isOpen) {
      window.document.body.classList.add('no-scroll')
    } else {
      window.document.body.classList.remove('no-scroll')
    }

    return () => {
      window.document.body.classList.remove('no-scroll')
    }
  }, [isOpen])

  const modalQuickViewScrollContainerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (footerRef.current && modalQuickViewScrollContainerRef.current) {
      modalQuickViewScrollContainerRef.current.style.maxHeight = `calc(100% - 56px - ${footerRef.current.offsetHeight}px)`
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  const handleCloseModal = () => setIsOpen(false)

  return (
    <Modal isOpen={isOpen} id="modal-quick-view" className={className}>
      <div id="modal-quick-view-content-container">
        <OutsideClickHandler onOutsideClick={handleCloseModal}>
          <div id="modal-quick-view-content">
            <header>
              <h2>{title}</h2>

              <button onClick={handleCloseModal}>
                <CloseIconV2 color="var(--color-main)" />
              </button>
            </header>

            <div
              id="modal-quick-view-scroll"
              ref={modalQuickViewScrollContainerRef}
            >
              {children}
            </div>

            {Footer && <footer ref={footerRef}>{Footer}</footer>}
          </div>
        </OutsideClickHandler>
      </div>
    </Modal>
  )
}
