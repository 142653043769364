import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'

import {
  HAS_WINDOW,
  activateOrderStatus,
  orderAditionalFields,
} from '../constants'
import useWindowDimensions from './useWindowDimensions'
import { useClubContext } from '../contexts/ClubContext'
import { getShippingEstimateDetails } from '../utils/orderStatus'

import type { shippingEstimate } from '../components/ui/Orders/components/Status/OrderStatus'
import type { Order } from '../types/Order'

export const useCurrentOrder = () => {
  const [orderDeliveryMethod, setOrderDeliveryMethod] = useState<
    string | undefined
  >()

  const [orderId, setOrderId] = useState<string | undefined>()
  const [shippingEstimate, setShippingEstimate] = useState<
    shippingEstimate | undefined
  >()

  const [loading, setLoading] = useState(false)
  const { isMobile } = useWindowDimensions()
  const { isClubClient } = useClubContext()

  const getLastOrder = (data: Order[]): Order | null => {
    const currentDate = new Date()
    const closestOrder = data.reduce<{ order: Order | null; diff: number }>(
      (closest, order) => {
        const orderDate = new Date(order.data.customerOrder.creationDate)
        const diff = Math.abs(orderDate.getTime() - currentDate.getTime())
        const endDate = new Date(
          order.data.customerOrder.shippingData.logisticsInfo[0]?.deliveryWindow
            ?.endDateUtc ?? ''
        )

        if (endDate < currentDate) {
          return closest
        }

        if (diff < closest.diff) {
          return { order, diff }
        }

        return closest
      },
      { order: null, diff: Infinity }
    )

    return closestOrder.order
  }

  const getCurrentOrder = async () => {
    if (!HAS_WINDOW || !isClubClient) {
      return
    }

    try {
      setLoading(true)
      const { data }: { data: Order[] } = await axios.get(
        `/api/v2/orders?status=${activateOrderStatus}&orderAdditionalFields=${orderAditionalFields}`
      )

      if (!data) {
        return
      }

      const lastOrder = getLastOrder(data)

      const currentOrderId = sessionStorage.getItem('currentOrder') ?? ''

      if (typeof lastOrder !== 'undefined') {
        const { orderId, shippingData } = lastOrder?.data.customerOrder ?? {}
        const { logisticsInfo } = shippingData ?? {}

        const deliveryMethod = logisticsInfo?.[0].deliveryChannel
        const deliveyWindow = logisticsInfo?.[0]?.deliveryWindow

        if (currentOrderId === orderId && !isMobile) {
          return
        }

        deliveyWindow &&
          setShippingEstimate(getShippingEstimateDetails(deliveyWindow))
        deliveryMethod && setOrderDeliveryMethod(deliveryMethod)
        orderId && setOrderId(orderId)
        orderId && sessionStorage.setItem('currentOrder', orderId)
      }
    } catch (err) {
      console.error('getCurrentOrder', err)
    } finally {
      setLoading(false)
    }
  }

  const hasOrder = useMemo(
    () =>
      typeof orderDeliveryMethod !== 'undefined' &&
      typeof orderId !== 'undefined' &&
      typeof shippingEstimate !== 'undefined',

    [orderDeliveryMethod, orderId, shippingEstimate]
  )

  useEffect(() => {
    if (hasOrder) {
      return
    }

    getCurrentOrder()
  }, [hasOrder])

  return { loading, shippingEstimate, orderDeliveryMethod, hasOrder, orderId }
}
