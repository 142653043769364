import axios from 'axios'
import { useState, useEffect } from 'react'

import { useClubContext } from '../contexts/ClubContext'
import { useRegionalizationContext } from '../contexts/RegionalizationContext'
import { useStoreContext } from '../contexts/StoreContext'

import type { ProductsQueryResponse } from '../types/Product'

export const useGetProduct = (slug: string) => {
  const [product, setProduct] = useState<ProductsQueryResponse['node']>()
  const [loading, setLoading] = useState(false)
  const { salesChannels } = useStoreContext()
  const { isClubClient } = useClubContext()
  const { postalCode } = useRegionalizationContext()
  const activeSalesChannel = isClubClient
    ? salesChannels.club?.toString()
    : salesChannels.default?.toString()

  useEffect(() => {
    const request = async () => {
      try {
        setLoading(true)

        const { data } = await axios.post(
          `/api/getProduct`,
          {
            slug,
            postalCode,
            activeSalesChannel,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        )

        if (!data) {
          return
        }

        setProduct(data.product)
      } catch {
        throw new Error('Fail in products request')
      } finally {
        setLoading(false)
      }
    }

    if (slug && activeSalesChannel) {
      request()
    }
  }, [activeSalesChannel, postalCode, slug])

  return { loading, product }
}
