import { Link } from 'gatsby'
import React, { useEffect } from 'react'

import CloseIconV2 from '../../../assets/CloseIconV2'
import { DiscountClubIcon } from '../../../assets/DiscountClubIcon'
import { textClubAccount } from '../../../constants'
import { useLocalStorageContext } from '../../../contexts/LocalStorageContext'
import { useStoreContext } from '../../../contexts/StoreContext'
import { formatBrazilianPrice } from '../../../utils/formatPrices'
import { ProductCardImage } from '../../product/ProductCard/components/ProductCardImage'
import { PriceCustom } from '../../ui/PriceCustom'

import type { CartItem } from '../../../sdk/cart'

import './club-modal.scss'

interface ClubModalProps {
  setOpenClubModal: (openClubModal: boolean) => void
  buyProps: CartItem
  clubPrice?: number
}

export const ClubModal = ({
  setOpenClubModal,
  clubPrice,
  buyProps: {
    itemOffered: {
      name,
      image: [{ url, alternateName }],
      unitMultiplier,
      measurementUnit,
    },
    price,
  },
}: ClubModalProps) => {
  const { setLocalStorage } = useLocalStorageContext()
  const { loyaltyClubTexts, secureSubdomain, account } = useStoreContext()
  const isMeasured = measurementUnit !== 'un'

  useEffect(() => {
    setLocalStorage?.('clubModalViewed', 'true')
  }, [])

  return (
    <div className="club-modal-container">
      <div className="modal-title-container">
        <h1>Produto adicionado ao carrinho</h1>
        <button
          onClick={() => setOpenClubModal(false)}
          data-testid="close-modal"
        >
          <CloseIconV2 color="var(--color-main)" />
        </button>
      </div>
      <div className="club-product-container">
        <ProductCardImage imageUrl={url} imageAlt={alternateName} />
        <div className="club-product-infos">
          <p className="club-product-name">{name}</p>
          <p className="club-product-price">
            <strong>{formatBrazilianPrice(price)}</strong>
            {isMeasured && (
              <span data-testid="original-price-measured-infos">
                /{unitMultiplier >= 1 ? unitMultiplier : unitMultiplier * 1000}
                {unitMultiplier * 1000 >= 1000 ? measurementUnit : 'g'}
              </span>
            )}
          </p>
        </div>
      </div>

      <div className="club-product-ad">
        <div className="club-title-container">
          <DiscountClubIcon />
          <p>Promoção {textClubAccount(account)} </p>
        </div>
        <div className="club-product-ad-content">
          <p className="club-product-ad-info">Se Cadastre e Pague</p>
          <strong data-testid="club-price">
            <PriceCustom className="club-price" price={clubPrice ?? 0} />
          </strong>
          {isMeasured && (
            <p
              className="club-price-measured-infos"
              data-testid="club-price-measured-infos"
            >
              /{unitMultiplier >= 1 ? unitMultiplier : unitMultiplier * 1000}
              {unitMultiplier * 1000 >= 1000 ? measurementUnit : 'g'}
            </p>
          )}{' '}
          <Link to={`${secureSubdomain}/club-informacao`} className="club-more">
            Saber mais
          </Link>
        </div>
      </div>

      <p className="club-warning">{loyaltyClubTexts?.warning}</p>
      <p className="free-disclaimer">É inteiramente Gratuito</p>
      <Link className="club-signup" to={`${secureSubdomain}/register`}>
        <button>Me Cadastrar</button>
      </Link>

      <button className="club-recuse" onClick={() => setOpenClubModal(false)}>
        Não, obrigado
      </button>
    </div>
  )
}
