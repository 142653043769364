import React from 'react'

interface BagIconProps {
  width?: number
  height?: number
  color?: string
}

export const BagIcon = ({
  width = 32,
  height = 32,
  color = 'var(--color-main)',
}: BagIconProps = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33337 29.3588C6.80004 29.3588 6.33337 29.1588 5.93337 28.7588C5.53337 28.3588 5.33337 27.8921 5.33337 27.3588V10.0255C5.33337 9.49214 5.53337 9.02547 5.93337 8.62547C6.33337 8.22547 6.80004 8.02547 7.33337 8.02547H11V7.69214C11 6.29214 11.4834 5.10881 12.45 4.14214C13.4167 3.17547 14.6 2.69214 16 2.69214C17.4 2.69214 18.5834 3.17547 19.55 4.14214C20.5167 5.10881 21 6.29214 21 7.69214V8.02547H24.6667C25.2 8.02547 25.6667 8.22547 26.0667 8.62547C26.4667 9.02547 26.6667 9.49214 26.6667 10.0255V27.3588C26.6667 27.8921 26.4667 28.3588 26.0667 28.7588C25.6667 29.1588 25.2 29.3588 24.6667 29.3588H7.33337ZM7.33337 27.3588H24.6667V10.0255H21V13.0255C21 13.3144 20.9056 13.5532 20.7167 13.7421C20.5278 13.931 20.2889 14.0255 20 14.0255C19.7112 14.0255 19.4723 13.931 19.2834 13.7421C19.0945 13.5532 19 13.3144 19 13.0255V10.0255H13V13.0255C13 13.3144 12.9056 13.5532 12.7167 13.7421C12.5278 13.931 12.2889 14.0255 12 14.0255C11.7112 14.0255 11.4723 13.931 11.2834 13.7421C11.0945 13.5532 11 13.3144 11 13.0255V10.0255H7.33337V27.3588ZM13 8.02547H19V7.69214C19 6.84769 18.7112 6.13658 18.1334 5.55881C17.5556 4.98103 16.8445 4.69214 16 4.69214C15.1556 4.69214 14.4445 4.98103 13.8667 5.55881C13.2889 6.13658 13 6.84769 13 7.69214V8.02547Z"
        fill={color}
      />
    </svg>
  )
}
