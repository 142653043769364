import * as React from 'react'

interface MultipleSearchIconProps {
  width?: number
  height?: number
  color?: string
}

export const MultipleSearchInputIcon = ({
  width = 24,
  height = 24,
  color = 'var(--color-main)',
}: MultipleSearchIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2 11.5C19.2 15.7526 15.7526 19.2 11.5 19.2C7.24741 19.2 3.8 15.7526 3.8 11.5C3.8 7.24741 7.24741 3.8 11.5 3.8C15.7526 3.8 19.2 7.24741 19.2 11.5ZM17.4573 18.9005C15.8279 20.2138 13.7558 21 11.5 21C6.25329 21 2 16.7467 2 11.5C2 6.25329 6.25329 2 11.5 2C16.7467 2 21 6.25329 21 11.5C21 13.8431 20.1518 15.988 18.7456 17.6445L21.7315 20.4952C22.091 20.8385 22.091 21.395 21.7315 21.7382C21.372 22.0814 20.7891 22.0814 20.4296 21.7382L17.4573 18.9005Z"
      fill={color}
    />
  </svg>
)
