import { Link } from 'gatsby'
import React, { useState } from 'react'

import { benefitsListMapper } from './benefitsListMapper'
import ArrowIcon from '../../../../assets/ArrowIcon'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { List } from '../../../ui/List'

import type { ClubCardBenefitsPageSectionData } from '../../../../types/ClubCard/ClubCardBenefits'

import './benefits.scss'

interface BenefitsProps {
  benefits: ClubCardBenefitsPageSectionData['benefits']
  link: string
}

export function Benefits({ benefits, link }: BenefitsProps) {
  const { list, moreBenefits, visibility, title, linkText, text } = benefits

  const [isMoreBenefitsOpen, setIsMoreBenefitsOpen] = useState(false)
  const { genericTaggingEvent } = useAnalyticsEvents()

  const benefitsList = benefitsListMapper(list)
  const moreBenefitsList = benefitsListMapper(moreBenefits?.list)

  const toggleMoreBenefits = () => {
    setIsMoreBenefitsOpen((prevState) => !prevState)
  }

  const handleNavigate = () => {
    genericTaggingEvent('subscribe_club_plus', { name: 'card_beneficios' })
  }

  if (!visibility) {
    return null
  }

  return (
    <div className="club-card-benefits-benefits grid-content">
      <DynamicTitle className="benefits-title" title={title} titleLevel={2} />

      <div className="benefits-container">
        <List list={benefitsList} />

        {moreBenefitsList.length > 0 && (
          <div className="more-benefits-container">
            <button className="more-benefits" onClick={toggleMoreBenefits}>
              {moreBenefits?.text}

              <ArrowIcon
                width="24"
                rotate={isMoreBenefitsOpen ? 'Up' : 'Down'}
                color="var(--loyalty-color-icon-secondary)"
              />
            </button>

            {isMoreBenefitsOpen && <List list={moreBenefitsList} />}
          </div>
        )}

        <Link
          className="more-benefits-link"
          to={link}
          onClick={handleNavigate}
          target="_blank"
        >
          {linkText}
        </Link>

        <p className="benefits-text">{text}</p>
      </div>
    </div>
  )
}
