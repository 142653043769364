import React from 'react'

interface LikeProps {
  width?: number
  height?: number
  color?: string
}

export const Like = ({
  width = 42,
  height = 41,
  color = 'var(--color-main)',
}: LikeProps = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.05 40.4443H8.7V14.8443L22.6 0.444336L24.25 1.74434C24.6167 2.011 24.8583 2.311 24.975 2.64434C25.0917 2.97767 25.15 3.361 25.15 3.79434V4.29434L22.9 14.8443H39C39.7667 14.8443 40.4583 15.1527 41.075 15.7693C41.6917 16.386 42 17.0777 42 17.8443V21.9443C42 22.311 41.9583 22.736 41.875 23.2193C41.7917 23.7027 41.6667 24.1277 41.5 24.4943L35.7 37.8943C35.4 38.5943 34.9083 39.1943 34.225 39.6943C33.5417 40.1943 32.8167 40.4443 32.05 40.4443ZM5.7 14.8443V40.4443H0V14.8443H5.7Z"
        fill={color}
      />
    </svg>
  )
}
