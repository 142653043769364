import axios from 'axios'
import { useEffect, useState } from 'react'

import { navigateUsingWindow } from '../utils/navigation/navigateUsingWindow'

export const useAcceptedScratchTerms = (promotionId?: string) => {
  const [loading, setLoading] = useState(true)
  const [redirected, setRedirected] = useState(false)

  useEffect(() => {
    const getAcceptedTerms = async () => {
      try {
        setLoading(true)

        const { data } = await axios.get<{ url: string }>(
          '/api/scratch/scratchLink',
          {
            params: {
              promotionId,
              random: Math.random(),
            },
          }
        )

        if (data.url) {
          navigateUsingWindow(data.url)
          setRedirected(true)
        }
      } finally {
        setLoading(false)
      }
    }

    getAcceptedTerms()
  }, [])

  return { loading, redirected }
}
