import React from 'react'

import { SuggestedKitModalHeader } from '../SuggestedKitModalHeader'
import './suggested-kit-list.scss'
import { SuggestedKitsCard } from '../SuggestedKitsCard'

import type { SuggestedKits } from '../../../../types/CMS'

import { clearTaggingTitle } from '../../../../utils/tagging/clearTaggingTitle'

import type { ModalScreen } from '../SuggestedKitsCarousel'

interface SuggestedKitListProps {
  setIsOpenModal: (isOpenModal: boolean) => void
  suggestedKits?: SuggestedKits
  setModalScreen?: (modalScreen: ModalScreen) => void
  setSelectedKitIndex: (selectedKitIndex: number) => void
  genericTaggingEvent: (name: string, params: object) => void
}

export const SuggestedKitList = ({
  setIsOpenModal,
  suggestedKits,
  setModalScreen,
  setSelectedKitIndex,
  genericTaggingEvent,
}: SuggestedKitListProps) => {
  const descriptionSettings = {
    title: 'Lista de Kits',
    subtitle: 'Kits montados especialmente pensando em você!',
  }

  const { subtitle, title } = descriptionSettings ?? {}

  const handleGenericTaggingEvent = () =>
    genericTaggingEvent('kits_pra_voce', {
      ecommerce: { name: clearTaggingTitle(title) },
    })

  return (
    <div className="suggested-kit-list-container">
      <SuggestedKitModalHeader
        onClose={() => setIsOpenModal(false)}
        viewTitle="Kits Pra Você"
      />

      <div className="suggested-kit-list-content">
        {descriptionSettings && (
          <section className="suggested-kit-modal-header-description-container">
            {title && (
              <p className="suggested-kit-modal-header-description-container-title">
                {title}
              </p>
            )}
            {subtitle && (
              <p className="suggested-kit-modal-header-description-container-subtitle">
                {subtitle}
              </p>
            )}
          </section>
        )}
        {suggestedKits?.kits.map(({ image, title }, idx) => (
          <SuggestedKitsCard
            isList
            image={image}
            title={title}
            index={idx}
            setSelectedKitIndex={setSelectedKitIndex}
            setIsOpenModal={setIsOpenModal}
            setModalScreen={setModalScreen}
            key={title}
            handleGenericTaggingEvent={handleGenericTaggingEvent}
          />
        ))}
      </div>
    </div>
  )
}
