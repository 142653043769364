import React from 'react'

import ArrowIcon from '../../../assets/ArrowIcon'
import { defaultSort } from '../../../constants'

import './back-button.scss'
import { Link } from 'gatsby'

export const BackButton = () => {
  const handleBack = () => {
    if (
      window?.location?.search !==
      `?clubProducts&preco2=true&sort=${defaultSort}&page=0`
    ) {
      window.history.go(-1)
    } else {
      window.history.go(-2)
    }
  }

  return (
    <div className="back-step">
      <ArrowIcon rotate="Right" color="var(--color-support-links)" />
      <Link to="" onClick={handleBack}>
        <p>Voltar</p>
      </Link>
    </div>
  )
}
