import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import React from 'react'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../utils/carouselProps'
import { createArray } from '../../../utils/createArrayForNumber'

import './product-shelf-skeleton.scss'

interface ProductShelfSkeletonProps {
  backgroundColor?: string
}

export const ProductShelfSkeleton = ({
  backgroundColor = 'var(--color-grayScale-faded)',
}: ProductShelfSkeletonProps) => {
  const { width } = useWindowDimensions()

  if (!width) {
    return <Skeleton height={500} backgroundColor={backgroundColor} />
  }

  const props = carouselProps(width)

  return (
    <div className="product-shelf-skeleton">
      <div className="carousel-product-header">
        <h2
          className="carousel-product-header-title"
          style={{ backgroundColor }}
        >
          Título do carrossel
        </h2>
        <a data-store-link style={{ backgroundColor }}>
          Ver Tudo
        </a>
      </div>

      <div className="div-content-shelf-product">
        <Carousel {...props}>
          {createArray(10).map((item) => (
            <div
              key={item}
              className="product-card"
              data-store-card="true"
              data-card-variant="vertical"
              data-card-bordered="false"
              data-card-out-of-stock="false"
              style={{ backgroundColor }}
            />
          ))}
        </Carousel>
      </div>
    </div>
  )
}
