import React from 'react'

import './telesales-buy-bar.scss'
import PencilIcon from '../../../../assets/PencilIcon'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { useCart } from '../../../../sdk/cart'
import { useCheckoutButton } from '../../../../sdk/cart/useCheckoutButton'
import { formatBrazilianPrice } from '../../../../utils/formatPrices'

interface Props {
  haveTerms: boolean
  handleRedirect: () => void
  isClub: boolean | null
}

export const TelesalesBuyBar = ({
  haveTerms,
  handleRedirect,
  isClub,
}: Props) => {
  const { genericTaggingEvent } = useAnalyticsEvents()
  const { total } = useCart()
  const { onClick } = useCheckoutButton()

  const totalFormated = formatBrazilianPrice(total)

  const buttonText = haveTerms
    ? `${totalFormated} - Finalizar Compra`
    : 'Buscar produtos'

  return (
    <section className="telesales-buy-container">
      <div className="grid-content buttons-container">
        <button
          onClick={() => {
            genericTaggingEvent('alterar_lista_de_compras', {
              click_text: 'Alterar minha lista de compras',
            })
            handleRedirect()
          }}
          className="telesales-edit-terms-button"
        >
          <PencilIcon color="var(--color-main)" />
          Alterar lista de compras
        </button>
        <button
          className="telesales-buy-button"
          disabled={total === 0}
          onClick={(e) => {
            isClub
              ? genericTaggingEvent('botao_finalizar_compra_clienteclube', {
                  click_text: 'Finalizar Compra',
                })
              : genericTaggingEvent('botao_finalizar_compra_conta', {
                  click_text: 'Finalizar Compra',
                })
            onClick(e)
          }}
        >
          {buttonText}
        </button>
      </div>
    </section>
  )
}
