export function validateToggles<T extends readonly string[]>(
  featureToggle: Record<string, boolean> | undefined,
  devFeatureToggle: Record<string, boolean> | undefined,
  keys: T
) {
  const toggles = {} as Record<T[number], boolean | undefined>

  keys.forEach((key: T[number]) => {
    toggles[key] = featureToggle?.[key] ?? devFeatureToggle?.[key]
  })

  return toggles
}
