import axios from 'axios'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { HAS_WINDOW } from '../constants'
import { useLocalStorageContext } from './LocalStorageContext'
import { useAnalyticsEvents } from '../hooks/useAnalyticsEvents'
import { cartStore } from '../sdk/cart'
import { useProductsQuery } from '../sdk/product/useProductsQuery'
import { sessionStore, validateSession, useSession } from '../sdk/session'
import {
  statusMessage,
  updateDeliveryMethod,
  getSeller,
  getAddress,
  cartSimulation,
  handleDeliveryPrice,
} from '../utils/deliveryFunctions'

import type { CartItem } from '../sdk/cart'
import type {
  CustomShippingProps,
  DeliveryProps,
  RegionalizationContextProps,
} from '../types/Regionalization'
import type { PikcupPointType, Status } from '@plurix/ecom-components'

const RegionalizationContext =
  createContext<RegionalizationContextProps | null>(null)

const extractSellerId = (sellerId?: string) => sellerId?.match(/\d+/)?.[0]

export const RegionalizationProvider = ({
  children,
  simulationPostalCode,
  mainSeller,
  pickupConfirmScreenText,
}: RegionalizationContextProps) => {
  const [deliveryInfos, setDeliveryInfos] = useState<
    DeliveryProps | undefined
  >()

  const [currentSellerId, setCurrentSellerId] = useState(
    extractSellerId(mainSeller)
  )

  const [postalCode, setPostalCode] = useState<string | undefined>('')
  const [regionalized, setRegionalized] = useState<string | undefined>()
  const [openRegionModal, setOpenRegionModal] = useState(false)
  const [city, setCity] = useState<string | undefined>('')
  const [uf, setUf] = useState<string | undefined>('')
  const [status, setStatus] = useState<Status>({})
  const [hasSeller, setHasSeller] = useState(false)
  const [pickupTimes, setPickupTimes] =
    useState<PikcupPointType['businessHours']['businessHoursWeek']>()

  const [pickupName, setPickupName] = useState<string | undefined>('')
  const [deliveryPrice, setDeliveryPrice] = useState<number | undefined>(0)
  const [product, setProduct] = useState<CartItem>()
  const { getLocalStorageItem, setLocalStorage } = useLocalStorageContext()
  const { addItemEvent, sendPostalCodeEvent } = useAnalyticsEvents()
  const currentSession = useSession()
  const { data: productList } = useProductsQuery({})
  const [currentPostalCode, setCurrentPostalCode] = useState('')
  const [sellerLoading, setSellerLoading] = useState(false)
  const [locationItem, setLocationItem] = useState('')

  const catalogRegionalize = async (postalCode?: string) => {
    try {
      const { isValidating: _, ...newSession } = currentSession
      const valideSession = await validateSession({
        ...newSession,
        postalCode: postalCode ?? '',
      })

      if (valideSession) {
        if (product) {
          cartStore.addItem(product)
          addItemEvent(product, locationItem)
          setProduct?.(undefined)
        }

        sessionStore.set(valideSession)
      }
    } catch (err) {
      throw new Error(`Um erro ocorreu: ${err}`)
    }
  }

  const regionShippingValidation = async (postalCode: string) => {
    try {
      setStatus(statusMessage('LOADING'))

      const response = await axios.post('/api/shipping/getExpressEligibility', {
        items: [
          {
            id: productList?.edges?.[0]?.node?.id ?? '',
            quantity: 1,
            seller: '1',
          },
        ],
        postalCode,
        country: 'BRA',
        builder: 'faststore',
      })

      const shippingData: CustomShippingProps = {
        shippingList: response.data.shippingList,
        isExpressEligible: response.data.isExpressEligible,
        expressShippingCmsQuantity: response.data.expressShippingCmsQuantity,
      }

      return shippingData
    } catch (err) {
      setStatus(statusMessage('FAILED'))
      throw new Error(`Error: ${err}`)
    }
  }

  const regionValidate = useCallback(
    async (postalCode: string, updateDeliveryInfos?: boolean) => {
      try {
        setStatus(statusMessage('LOADING'))

        const data = await Promise.all([
          getSeller(postalCode),
          getAddress(postalCode),
          cartSimulation(postalCode, productList),
          regionShippingValidation(postalCode),
        ])

        const hasValidSeller = data?.[0]?.hasValidSeller
        const address = data?.[1]?.address
        const deliveryInfos = data?.[2].deliveryInfos
        const customShipping = data?.[3]
        const isValid = !!(hasValidSeller && address?.localidade)

        sendPostalCodeEvent({
          postalCode,
          isValid,
          region: `${address?.localidade} - ${address?.uf}`,
        })

        if (isValid) {
          updateDeliveryInfos &&
            setLocalStorage?.('deliveryInfos', JSON.stringify(deliveryInfos))
          setStatus(statusMessage('SUCCESS'))
          setDeliveryInfos(deliveryInfos)
          setCity?.(address?.localidade)
          setUf?.(address?.uf)
        } else {
          setStatus(statusMessage('AVAILABILITY'))
        }

        return { isValid, address, deliveryInfos, customShipping }
      } catch (err) {
        setStatus(statusMessage('AVAILABILITY'))
        throw new Error(`Error: ${err}`)
      }
    },
    [productList]
  )

  useEffect(() => {
    handleDeliveryPrice(productList, simulationPostalCode, setDeliveryPrice)
  }, [productList])

  useEffect(() => {
    if (postalCode === currentPostalCode) {
      return
    }

    // User is typing the postal code
    if (postalCode && postalCode.length < 9) {
      return
    }

    const sellerValidate = async (postalCode: string) => {
      try {
        setSellerLoading(true)
        const { hasValidSeller, sellerId } = await getSeller(postalCode)

        setHasSeller(hasValidSeller)
        setCurrentSellerId(extractSellerId(sellerId))
        setCurrentPostalCode(postalCode)
      } catch (err) {
        throw new Error(`Error: ${err}`)
      } finally {
        setSellerLoading(false)
      }
    }

    if (postalCode && postalCode !== '') {
      sellerValidate(postalCode)
    }
  }, [postalCode, currentPostalCode])

  useEffect(() => {
    const deliveryMethod = getLocalStorageItem?.()?.regionalized
    const deliveryInfos = getLocalStorageItem?.()?.deliveryInfos
    const postalCode = getLocalStorageItem?.()?.userAddressInfos?.postalCode

    setPickupTimes(getLocalStorageItem?.()?.pickUpPointInfos?.pickupTimes)
    setRegionalized(deliveryMethod)
    setDeliveryInfos(deliveryInfos)
    setCity(getLocalStorageItem?.()?.userAddressInfos?.city)
    setUf(getLocalStorageItem?.()?.userAddressInfos?.uf)
    setPostalCode(postalCode)
    setPickupName(getLocalStorageItem?.()?.pickUpPointInfos?.name)
  }, [HAS_WINDOW])

  useEffect(() => {
    if (Object.keys(deliveryInfos ?? {}).length > 0) {
      return
    }

    const storageDeliveryMethod = getLocalStorageItem?.()?.regionalized
    const storageDeliveryInfos = getLocalStorageItem?.()?.deliveryInfos
    const storegePostalCode =
      getLocalStorageItem?.()?.userAddressInfos?.postalCode

    const validateDeliveryInfos =
      storageDeliveryMethod === 'delivery' &&
      !Object.keys(storageDeliveryInfos ?? {}).length &&
      typeof storegePostalCode === 'string' &&
      typeof productList !== 'undefined'

    if (validateDeliveryInfos) {
      regionValidate(storegePostalCode, true)
    }
  }, [HAS_WINDOW, productList])

  return (
    <RegionalizationContext.Provider
      value={{
        city,
        setCity,
        uf,
        pickupConfirmScreenText,
        setUf,
        deliveryPrice,
        openRegionModal,
        setOpenRegionModal,
        product,
        setProduct,
        regionValidate,
        catalogRegionalize,
        status,
        setStatus,
        pickupTimes,
        setPickupTimes,
        currentSellerId,
        postalCode,
        setPostalCode,
        regionalized,
        setRegionalized,
        pickupName,
        setPickupName,
        deliveryInfos,
        sellerLoading,
        updateDeliveryMethod,
        simulationPostalCode,
        hasSeller,
        setLocationItem,
      }}
    >
      {children}
    </RegionalizationContext.Provider>
  )
}

export const useRegionalizationContext = () => {
  const context = useContext(RegionalizationContext)

  if (!context) {
    throw new Error(
      'useRegionalizationContext must be used within a RegionalizationProvider'
    )
  }

  return context
}
