import React from 'react'

export const WindowIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="window-icon"
    >
      <mask
        id="mask0_3286_6886"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3286_6886)">
        <path
          d="M5.33329 29.3333C4.59996 29.3333 3.97218 29.0722 3.44996 28.55C2.92774 28.0278 2.66663 27.4 2.66663 26.6667V14.6667C2.66663 13.9333 2.92774 13.3055 3.44996 12.7833C3.97218 12.2611 4.59996 12 5.33329 12H7.99996V5.33332C7.99996 4.59999 8.26107 3.97221 8.78329 3.44999C9.30551 2.92777 9.93329 2.66666 10.6666 2.66666H26.6666C27.4 2.66666 28.0277 2.92777 28.55 3.44999C29.0722 3.97221 29.3333 4.59999 29.3333 5.33332V17.3333C29.3333 18.0667 29.0722 18.6944 28.55 19.2167C28.0277 19.7389 27.4 20 26.6666 20H24V26.6667C24 27.4 23.7388 28.0278 23.2166 28.55C22.6944 29.0722 22.0666 29.3333 21.3333 29.3333H5.33329ZM5.33329 26.6667H21.3333V17.3333H5.33329V26.6667ZM24 17.3333H26.6666V7.99999H10.6666V12H21.3333C22.0666 12 22.6944 12.2611 23.2166 12.7833C23.7388 13.3055 24 13.9333 24 14.6667V17.3333Z"
          fill="var(--color-main)"
        />
      </g>
    </svg>
  )
}
