export const getCookieByName = (
  allCookies: string | undefined,
  name: string
): string | undefined => {
  if (!allCookies) {
    return undefined
  }

  const cookies: { [key: string]: string } = Object.fromEntries(
    allCookies
      .split(';')
      .map((cookie) => cookie.split('=').map((cookiePart) => cookiePart.trim()))
  )

  return cookies[name]
}
