import './faq.scss'

import React from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { AccordionV2List } from '../../../ui/AccordionV2'

import type { FAQ as FAQProps } from '../../../../types/ClubCard/ClubCardBenefits'
import type { HandleSelectAccordionOption } from '../../../ui/AccordionV2'

export const FAQ = ({ visibility, title, questions }: FAQProps) => {
  const { activeQuestionEvent } = useAnalyticsEvents()

  const handleSelect: HandleSelectAccordionOption = ({ isSelected, title }) => {
    if (!isSelected) {
      activeQuestionEvent(title)
    }
  }

  if (!visibility) {
    return null
  }

  return (
    <div className="club-card-benefits-faq grid-content">
      {title && (
        <DynamicTitle className="faq-title" titleLevel={2} title={title} />
      )}

      <AccordionV2List
        handleSelect={handleSelect}
        listAccordion={questions}
        render={(accordion) => ({
          title: accordion?.question,
          contentInternal: (
            <div
              className="faq-answer"
              dangerouslySetInnerHTML={{
                __html: accordion?.answer,
              }}
            />
          ),
        })}
      />
    </div>
  )
}
