import { useEffect, useMemo, useState, useCallback } from 'react'

import { useClubContext } from '../contexts/ClubContext'
import { useRegionalizationContext } from '../contexts/RegionalizationContext'
import { useStoreContext } from '../contexts/StoreContext'
import { getLowProducts } from '../utils/getLowProducts'

import type { ProductsQueryResponse } from '../types/Product'

const mapProductsWithLowStock = (
  products: ProductsQueryResponse['node'][],
  lowStockSkus: { sku: string; hasLowStock: boolean }[]
) => {
  return products.map((product) => {
    const lowStock = lowStockSkus.some(
      ({ sku, hasLowStock }) => sku === product.sku && hasLowStock
    )

    return { ...product, lowStock }
  })
}

const mapProductsWithoutLowStock = (
  products: ProductsQueryResponse['node'][]
) => {
  return products.map((product) => ({ ...product, lowStock: false }))
}

export const useProductWithLowStock = (
  products?: ProductsQueryResponse['node'][]
) => {
  const [loading, setLoading] = useState(true)
  const { salesChannels: staleSalesChannels } = useStoreContext()
  const { postalCode, simulationPostalCode } = useRegionalizationContext()
  const { isClubClient } = useClubContext()
  const [productsWithLowStock, setProductsWithLowStock] = useState(products)
  const { hasChangeOrder } = useStoreContext()

  // eslint-disable-next-line no-unneeded-ternary
  const currentPostalCode = postalCode ? postalCode : simulationPostalCode

  const skus = useMemo(
    () => products?.map((product) => product.sku) ?? [],
    [products]
  )

  const salesChannel = useMemo(
    () =>
      (
        (isClubClient
          ? staleSalesChannels?.club
          : staleSalesChannels?.default) ?? 1
      ).toString(),
    [isClubClient, staleSalesChannels]
  )

  const fetchLowStockProducts = useCallback(async () => {
    try {
      const { skus: lowStockSkus } = await getLowProducts({
        postalCode: currentPostalCode,
        salesChannel,
        skus,
      })

      setProductsWithLowStock(
        mapProductsWithLowStock(products ?? [], lowStockSkus)
      )
    } finally {
      setLoading(false)
    }
  }, [currentPostalCode, salesChannel, skus, products])

  useEffect(() => {
    if (!products) return

    const shouldFetchLowStock =
      currentPostalCode && salesChannel && skus.length && hasChangeOrder

    if (shouldFetchLowStock) {
      setLoading(true)
      fetchLowStockProducts()
    } else {
      setProductsWithLowStock(mapProductsWithoutLowStock(products))
      setLoading(false)
    }
  }, [
    products,
    currentPostalCode,
    salesChannel,
    skus,
    fetchLowStockProducts,
    hasChangeOrder,
  ])

  return { productsWithLowStock, loading }
}
