import { useMemo, useState } from 'react'

import { useCart } from './index'
import { useClubContext } from '../../contexts/ClubContext'
import { useRegionalizationContext } from '../../contexts/RegionalizationContext'
import { useStoreContext } from '../../contexts/StoreContext'
import { orderFormProductsValidate } from '../../utils/orderFormProductsValidate'
import { utmUrlComplement } from '../../utils/utmTools'

export const useCheckoutButton = (returnUrl = '/') => {
  const [loading, setLoading] = useState(false)

  const { isValidating, id, items } = useCart()
  const { checkoutUrl } = useStoreContext()
  const { regionalized } = useRegionalizationContext()
  const { updateOrderForm } = useClubContext()

  const curretUtmParams = utmUrlComplement()

  const formatedValidateItems = useMemo(
    () =>
      items.map((cartItem) => {
        return {
          id: cartItem.itemOffered.sku,
          quantity: cartItem.quantity,
        }
      }),
    [items]
  )

  const redirect = () =>
    (window.location.href = `${checkoutUrl}?orderFormId=${id}${curretUtmParams}&returnUrl=${returnUrl}&shipping=${regionalized}`)

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const handleGoToCheckout = async () => {
      setLoading(true)

      await Promise.all([
        updateOrderForm?.(id),
        orderFormProductsValidate(id, formatedValidateItems),
      ]).finally(() => {
        redirect()
        setLoading(false)
      })
    }

    if (!isValidating && id) {
      handleGoToCheckout()
    }
  }

  return {
    onClick,
    disabled: isValidating || loading,
    'data-testid': 'checkout-button',
  }
}
