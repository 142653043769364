import React from 'react'

export const GiftIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="gift-icon"
    >
      <mask
        id="mask0_3286_6891"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3286_6891)">
        <path
          d="M5.33329 29.3333V14.6666H2.66663V6.66665H9.59996C9.48885 6.46665 9.41663 6.25554 9.38329 6.03331C9.34996 5.81109 9.33329 5.57776 9.33329 5.33331C9.33329 4.2222 9.72218 3.27776 10.5 2.49998C11.2777 1.7222 12.2222 1.33331 13.3333 1.33331C13.8444 1.33331 14.3222 1.42776 14.7666 1.61665C15.2111 1.80554 15.6222 2.06665 16 2.39998C16.3777 2.04442 16.7888 1.77776 17.2333 1.59998C17.6777 1.4222 18.1555 1.33331 18.6666 1.33331C19.7777 1.33331 20.7222 1.7222 21.5 2.49998C22.2777 3.27776 22.6666 4.2222 22.6666 5.33331C22.6666 5.57776 22.6444 5.80554 22.6 6.01665C22.5555 6.22776 22.4888 6.44442 22.4 6.66665H29.3333V14.6666H26.6666V29.3333H5.33329ZM18.6666 3.99998C18.2888 3.99998 17.9722 4.12776 17.7166 4.38331C17.4611 4.63887 17.3333 4.95554 17.3333 5.33331C17.3333 5.71109 17.4611 6.02776 17.7166 6.28331C17.9722 6.53887 18.2888 6.66665 18.6666 6.66665C19.0444 6.66665 19.3611 6.53887 19.6166 6.28331C19.8722 6.02776 20 5.71109 20 5.33331C20 4.95554 19.8722 4.63887 19.6166 4.38331C19.3611 4.12776 19.0444 3.99998 18.6666 3.99998ZM12 5.33331C12 5.71109 12.1277 6.02776 12.3833 6.28331C12.6388 6.53887 12.9555 6.66665 13.3333 6.66665C13.7111 6.66665 14.0277 6.53887 14.2833 6.28331C14.5388 6.02776 14.6666 5.71109 14.6666 5.33331C14.6666 4.95554 14.5388 4.63887 14.2833 4.38331C14.0277 4.12776 13.7111 3.99998 13.3333 3.99998C12.9555 3.99998 12.6388 4.12776 12.3833 4.38331C12.1277 4.63887 12 4.95554 12 5.33331ZM5.33329 9.33331V12H14.6666V9.33331H5.33329ZM14.6666 26.6666V14.6666H7.99996V26.6666H14.6666ZM17.3333 26.6666H24V14.6666H17.3333V26.6666ZM26.6666 12V9.33331H17.3333V12H26.6666Z"
          fill="var(--color-main)"
        />
      </g>
    </svg>
  )
}
