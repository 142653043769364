import React from 'react'

import type { Icon } from '../../types/FAQ'

export const MoneyIcon = ({
  width = 30,
  height = 22,
  color = 'var(--color-main)',
  filled,
}: Icon = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          d="M2.33325 21.6667C1.7777 21.6667 1.30547 21.4722 0.916585 21.0833C0.527696 20.6944 0.333252 20.2222 0.333252 19.6667V4.33333H2.33325V19.6667H25.6666V21.6667H2.33325ZM6.33325 17.6667C5.7777 17.6667 5.30547 17.4722 4.91659 17.0833C4.5277 16.6944 4.33325 16.2222 4.33325 15.6667V2.33333C4.33325 1.77778 4.5277 1.30555 4.91659 0.916665C5.30547 0.527777 5.7777 0.333332 6.33325 0.333332H27.6666C28.2221 0.333332 28.6944 0.527777 29.0833 0.916665C29.4721 1.30555 29.6666 1.77778 29.6666 2.33333V15.6667C29.6666 16.2222 29.4721 16.6944 29.0833 17.0833C28.6944 17.4722 28.2221 17.6667 27.6666 17.6667H6.33325ZM9.66659 15.6667C9.66659 14.7333 9.34436 13.9444 8.69992 13.3C8.05547 12.6556 7.26659 12.3333 6.33325 12.3333V15.6667H9.66659ZM24.3333 15.6667H27.6666V12.3333C26.7333 12.3333 25.9444 12.6556 25.2999 13.3C24.6555 13.9444 24.3333 14.7333 24.3333 15.6667ZM16.9999 13C18.111 13 19.0555 12.6111 19.8333 11.8333C20.611 11.0556 20.9999 10.1111 20.9999 9C20.9999 7.88889 20.611 6.94444 19.8333 6.16667C19.0555 5.38889 18.111 5 16.9999 5C15.8888 5 14.9444 5.38889 14.1666 6.16667C13.3888 6.94444 12.9999 7.88889 12.9999 9C12.9999 10.1111 13.3888 11.0556 14.1666 11.8333C14.9444 12.6111 15.8888 13 16.9999 13ZM6.33325 5.66667C7.26659 5.66667 8.05547 5.34444 8.69992 4.7C9.34436 4.05555 9.66659 3.26667 9.66659 2.33333H6.33325V5.66667ZM27.6666 5.66667V2.33333H24.3333C24.3333 3.26667 24.6555 4.05555 25.2999 4.7C25.9444 5.34444 26.7333 5.66667 27.6666 5.66667Z"
          fill={color}
        />
      ) : (
        <path
          d="M17 13C15.8889 13 14.9445 12.6111 14.1667 11.8333C13.3889 11.0556 13 10.1111 13 9.00001C13 7.8889 13.3889 6.94445 14.1667 6.16668C14.9445 5.3889 15.8889 5.00001 17 5.00001C18.1112 5.00001 19.0556 5.3889 19.8334 6.16668C20.6112 6.94445 21 7.8889 21 9.00001C21 10.1111 20.6112 11.0556 19.8334 11.8333C19.0556 12.6111 18.1112 13 17 13ZM6.33337 17.6667C5.77782 17.6667 5.3056 17.4722 4.91671 17.0833C4.52782 16.6945 4.33337 16.2222 4.33337 15.6667V2.33334C4.33337 1.77779 4.52782 1.30557 4.91671 0.916677C5.3056 0.527788 5.77782 0.333344 6.33337 0.333344H27.6667C28.2223 0.333344 28.6945 0.527788 29.0834 0.916677C29.4723 1.30557 29.6667 1.77779 29.6667 2.33334V15.6667C29.6667 16.2222 29.4723 16.6945 29.0834 17.0833C28.6945 17.4722 28.2223 17.6667 27.6667 17.6667H6.33337ZM9.66671 15.6667H24.3334C24.3334 14.7333 24.6556 13.9445 25.3 13.3C25.9445 12.6556 26.7334 12.3333 27.6667 12.3333V5.66668C26.7334 5.66668 25.9445 5.34445 25.3 4.70001C24.6556 4.05557 24.3334 3.26668 24.3334 2.33334H9.66671C9.66671 3.26668 9.34449 4.05557 8.70004 4.70001C8.0556 5.34445 7.26671 5.66668 6.33337 5.66668V12.3333C7.26671 12.3333 8.0556 12.6556 8.70004 13.3C9.34449 13.9445 9.66671 14.7333 9.66671 15.6667ZM25.6667 21.6667H2.33337C1.77782 21.6667 1.3056 21.4722 0.916707 21.0833C0.527818 20.6945 0.333374 20.2222 0.333374 19.6667V4.33334H2.33337V19.6667H25.6667V21.6667Z"
          fill={color}
        />
      )}
    </svg>
  )
}
