import { Skeleton } from '@acctglobal/skeleton'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'

import { bannersDimensions } from './bannersDimensions'
import { MosaicBanner } from './MosaicBanner'
import { skeletonDimensions } from './skeletonDimensions'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

import type { MosaicImage } from '../../../types/CMS'
import './mosaic.scss'

interface MosaicProps {
  data?: {
    isActive?: boolean
    rows: {
      banners: MosaicImage[]
    }[]
    title?: string
    className?: string
    hasPriority?: boolean
  }
}

export const Mosaic = ({ data }: MosaicProps) => {
  const [screenWidth, setScreenWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()
  const { selectPromotion } = useAnalyticsEvents()

  useEffect(() => {
    if (widthHook) {
      setScreenWidth(widthHook)
    }
  }, [widthHook])

  if (!data || !data?.rows || !data.isActive) {
    return null
  }

  const { className, hasPriority } = data

  const mosaic = {
    rows: data?.rows,
    isActive: data?.isActive,
    title: data?.title,
  }

  if (screenWidth <= 0) {
    const { mobileSkeletonHeight, desktopSkeletonHeight } =
      skeletonDimensions(mosaic)

    return (
      <div
        className="grid-content mosaic-banners-loading"
        data-testid="mosaic-banners-loading"
      >
        <Skeleton
          backgroundColor="var(--color-grayScale-faded)"
          responsiveImageSSR={{
            srcWidth: bannersDimensions[1].mobileHeight,
            srcHeight: mobileSkeletonHeight,
          }}
        />
        <Skeleton
          responsiveImageSSR={{
            srcWidth: bannersDimensions[1].desktopHeight,
            srcHeight: desktopSkeletonHeight,
          }}
          backgroundColor="var(--color-grayScale-faded)"
        />
      </div>
    )
  }

  return (
    <section
      className={`grid-content mosaic-container ${className ?? ''}`}
      data-testid="mosaic-container"
    >
      {mosaic.title && <h3 className="mosaic-title">{mosaic.title}</h3>}

      {mosaic?.rows.length > 0 && (
        <div className="mosaic-rows-container">
          {mosaic.rows.map(({ banners }) => {
            // prevent rows with banners length different from 1 to 3
            if (banners?.length <= 0) {
              return null
            }

            // if more than 4 banners come in a row, we will only use the first 4
            if (banners.length > 4) {
              banners = banners.slice(0, 4)
              console.warn(
                'Foi cadastrado mais de 4 banners na linha do Mosaico via CMS, por isso limitamos a renderização nos 4 primeiros'
              )
            }

            const rowBanners = banners.length as 1 | 2 | 3 | 4

            const { mobileWidth, mobileHeight, desktopWidth, desktopHeight } =
              bannersDimensions[rowBanners]

            return (
              <div
                key={banners[0]?.src}
                className="mosaic-row"
                style={{ gridTemplateColumns: `repeat(${rowBanners}, 1fr)` }}
              >
                {banners.map(
                  (
                    {
                      srcMobile,
                      src,
                      alt,
                      href,
                      promotionName,
                      isEngagementBanner,
                    },
                    index
                  ) =>
                    !href && !isEngagementBanner ? (
                      <MosaicBanner
                        key={index}
                        screenWidth={screenWidth}
                        srcMobile={srcMobile}
                        src={src}
                        mobileWidth={mobileWidth}
                        desktopWidth={desktopWidth}
                        mobileHeight={mobileHeight}
                        desktopHeight={desktopHeight}
                        alt={alt}
                        hasPriority={hasPriority}
                      />
                    ) : (
                      <Link
                        key={index}
                        to={href ?? ''}
                        onClick={() =>
                          selectPromotion({
                            href,
                            promotionName,
                            locale: window.location.href,
                          })
                        }
                      >
                        <MosaicBanner
                          screenWidth={screenWidth}
                          srcMobile={srcMobile}
                          src={src}
                          mobileWidth={mobileWidth}
                          desktopWidth={desktopWidth}
                          mobileHeight={mobileHeight}
                          desktopHeight={desktopHeight}
                          alt={alt}
                          hasPriority={hasPriority}
                        />
                      </Link>
                    )
                )}
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}
