import React from 'react'

import AppAndroidIcon from '../../../assets/AppAndroidIcon'
import AppIosIcon from '../../../assets/AppIosIcon'
import { useStoreContext } from '../../../contexts/StoreContext'

export const DownloadApps = () => {
  const { loyaltyClubDownloadImages } = useStoreContext()

  const showDownloadApp =
    loyaltyClubDownloadImages?.androidAppLink ||
    loyaltyClubDownloadImages?.iOSAppLink

  if (!showDownloadApp) {
    return null
  }

  return (
    <div className="downloads-container">
      <p>Faça o download do nosso aplicativo</p>

      <div>
        {loyaltyClubDownloadImages?.androidAppLink && (
          <a
            href={loyaltyClubDownloadImages.androidAppLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppAndroidIcon />
          </a>
        )}

        {loyaltyClubDownloadImages?.iOSAppLink && (
          <a
            href={loyaltyClubDownloadImages.iOSAppLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppIosIcon />
          </a>
        )}
      </div>
    </div>
  )
}
