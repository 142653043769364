import axios from 'axios'
import { useEffect } from 'react'

import { eventEmitter, LOCAL_STORAGE_KEY } from './lastSeenProductsConstants'
import { useClubContext } from '../../contexts/ClubContext'

export function useRegisterLastSeenProduct(sku: string) {
  const { isClubClient } = useClubContext()

  useEffect(() => {
    async function registerProduct() {
      try {
        const storageLastSeenProducts = localStorage.getItem(LOCAL_STORAGE_KEY)

        const { data } = await axios.post<string[]>(
          '/api/loyalty/lastSeenProducts',
          { sku, storageLastSeenProducts }
        )

        const dataString = data.join(',')

        localStorage.setItem(LOCAL_STORAGE_KEY, dataString)

        // Signed in users has a queue to insert products - prevent refetch
        if (!isClubClient) {
          eventEmitter.emit('lastSeenProductsUpdated', dataString)
        }
      } catch {
        console.error('Failed to register last seen product')
      }
    }

    registerProduct()
  }, [])
}
