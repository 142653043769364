import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'

import { MultipleSearchAddIcon } from '../../../../assets/MultipleSearchAddIcon'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import useSuggestions from '../../../../sdk/search/useSuggestions'
import useOnClickOutside from '../../../../sdk/ui/useOnClickOutside'
import { MultipleSearchBadge } from '../MultipleSearchBadge'
import { MultipleSearchInputDropdown } from '../MultipleSearchInputDropdown'

import type { SearchItem } from '../../../../types/SearchDropdown'

import './multiple-search-input.scss'

interface MultipleSearchInputProps {
  term: string
  setTerm: (term: string) => void
  multipleTerms: string[]
  setMultipleTerms: (multipleTerms: string[]) => void
  limitReached?: boolean
  placeholderText?: string
  lastSearched: SearchItem[] | undefined
  hasTerms?: boolean
}

export const MultipleSearchInput = ({
  term,
  setTerm,
  multipleTerms,
  setMultipleTerms,
  limitReached,
  placeholderText = 'Monte sua lista de compras',
  lastSearched,
  hasTerms,
}: MultipleSearchInputProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false)
  const dropdownContainer = useRef<HTMLLabelElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { terms: suggestion } = useSuggestions(!term.includes(',') ? term : '')
  const { isMobile } = useWindowDimensions()

  useOnClickOutside(dropdownContainer, () => setDropdownIsOpen(false))

  const hasLastSearched = !!(lastSearched && lastSearched.length > 0)
  const searchIsSuggestion = useMemo(() => term.length >= 3, [term])

  useEffect(() => {
    if (!term.includes(',')) {
      return
    }

    const termWithoutComma = term.replace(',', '').trim()

    setMultipleTerms([...multipleTerms, termWithoutComma])
    setTerm('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleTerms, term])

  useEffect(() => {
    if (inputRef.current && isMobile) {
      inputRef.current.focus()
    }
  }, [isMobile])

  useEffect(() => {
    if (searchIsSuggestion && suggestion) {
      setDropdownIsOpen(true)
    }
  }, [searchIsSuggestion, suggestion])

  const handleMultipleTerms = () => {
    setDropdownIsOpen(false)

    if (!term || multipleTerms.length >= 15) return

    const newTerms = term
      .split(',')
      .map((t) => t.trim())
      .filter(Boolean)
      .slice(0, 15 - multipleTerms.length)

    const updatedTerms = [...multipleTerms, ...newTerms]

    setMultipleTerms(updatedTerms)
    sessionStorage.setItem('multipleSearchTerms', JSON.stringify(updatedTerms))
    setTerm('')
  }

  return (
    <div className="multiple-search-input-container">
      <div className="multiple-search-input-subcontainer">
        {multipleTerms.map((item, index) => (
          <MultipleSearchBadge
            term={item}
            multipleTerms={multipleTerms}
            setTMultipleTerms={setMultipleTerms}
            key={`multiple-search-badge-${index}`}
          />
        ))}

        {!limitReached && (
          <input
            className="multiple-search-input-input"
            type="text"
            value={term}
            onChange={(event) => {
              setTerm(event.target.value)
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
              event.key === 'Enter' && handleMultipleTerms()
            }
            placeholder={hasTerms ? '' : placeholderText}
            disabled={limitReached}
            onFocus={() => {
              if (
                !dropdownIsOpen &&
                (hasLastSearched ||
                  (searchIsSuggestion && suggestion.length > 0))
              ) {
                setDropdownIsOpen(true)
              }
            }}
            ref={inputRef}
          />
        )}
      </div>

      <label
        className="multiple-search-input-suspense-box"
        ref={dropdownContainer}
      >
        <button
          className="multiple-search-input-add-button"
          onClick={handleMultipleTerms}
          disabled={limitReached}
        >
          <MultipleSearchAddIcon
            color={limitReached ? 'var(--color-grayScale-light)' : undefined}
          />
        </button>

        {dropdownIsOpen && (
          <Suspense fallback={null}>
            <MultipleSearchInputDropdown
              suggestion={suggestion}
              title={searchIsSuggestion ? 'Sugestões' : 'Busque novamente'}
              term={term}
              setTerm={setTerm}
              multipleTerms={multipleTerms}
              setMultipleTerms={setMultipleTerms}
              setDropdownIsOpen={setDropdownIsOpen}
              lastSearched={lastSearched}
              searchIsSuggestion={searchIsSuggestion}
            />
          </Suspense>
        )}
      </label>
    </div>
  )
}
