export const validateCPF = (cpf?: string | null) => {
  if (!cpf) {
    return false
  }

  // Remove all non-numeric characters
  cpf = cpf.replace(/[^\d]/g, '')

  // A valid CPF must have 11 digits
  if (cpf.length !== 11) {
    return false
  }

  // Check if all digits are the same
  const allSameDigits = /^(\d)\1{10}$/

  if (allSameDigits.test(cpf)) {
    return false
  }

  // Calculate the first verification digit
  let sum = 0

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * (10 - i)
  }

  const firstVerificationDigit = 11 - (sum % 11)
  const firstDigit = firstVerificationDigit > 9 ? 0 : firstVerificationDigit

  // Calculate the second verification digit
  sum = 0

  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf[i]) * (11 - i)
  }

  const secondVerificationDigit = 11 - (sum % 11)
  const secondDigit = secondVerificationDigit > 9 ? 0 : secondVerificationDigit

  // Check if the calculated verification digits match the CPF digits
  return parseInt(cpf[9]) === firstDigit && parseInt(cpf[10]) === secondDigit
}
