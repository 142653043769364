import { Coupons } from '@plurix/ecom-components'
import React from 'react'

import { ProductGalleryHeader } from '../components/sections/ProductGallery/ProductGalleryHeader'
import Breadcrumb from '../components/ui/Breadcrumb'
import { useStoreContext } from '../contexts/StoreContext'
import { useAnalyticsEvents } from '../hooks/useAnalyticsEvents'
import { useCart } from '../sdk/cart'

import '@plurix/ecom-components/dist/styles/coupons.global.css'
import '../styles/pages/coupons-faststore.global.css'
import '../styles/pages/coupons.scss'

const itemListElement = [{ item: '/', name: 'Meus Cupons', position: 1 }]

export const CouponsPage = () => {
  const { account } = useStoreContext()
  const { id: orderFormId, items } = useCart()
  const { genericTaggingEvent } = useAnalyticsEvents()

  return (
    <div className="container-coupons-page grid-content">
      {itemListElement && <Breadcrumb breadcrumbList={itemListElement} />}

      <ProductGalleryHeader
        itemListElement={[
          { item: '/', name: 'Confira e adicione cupons', position: 1 },
        ]}
        slug="/"
      />

      <Coupons
        account={account}
        builder="faststore"
        hasItemsInCart={items.length > 0}
        orderFormId={orderFormId}
        genericTaggingEvent={genericTaggingEvent}
      />
    </div>
  )
}
