import React from 'react'

export const EmptyScreenSvg = () => (
  <svg
    width="328"
    height="201"
    viewBox="0 0 328 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M167.127 0.0460473C115.672 -1.5168 71.101 36.8863 64.3674 87.2763C57.0046 142.371 97.5276 191.943 147.389 199.882C209.408 209.758 269.563 152.626 264.162 90.6467C260.092 43.951 219.432 1.63454 167.127 0.0460473Z"
      fill="#F4F7FA"
    />
    <path
      d="M245.031 170.748L78.8916 185.748C76.4119 185.95 72.8532 182.67 72.8532 180.181V54.3653C72.8532 52.036 74.7242 50.139 77.0522 50.1068L244.626 47.7885C247 47.7556 251.489 49.5067 251.489 51.8821L248.943 166.502C248.943 168.72 247.241 170.567 245.031 170.748Z"
      fill="url(#paint0_linear_35780_80235)"
    />
    <path
      d="M248.406 171.911L80.8321 185.59C78.3525 185.792 76.2273 183.833 76.2273 181.345V55.5286C76.2273 53.1993 78.0983 51.3023 80.4263 51.2701L248 48.9526C250.374 48.9196 252.317 50.8356 252.317 53.211V167.666C252.318 169.883 250.616 171.73 248.406 171.911Z"
      fill="url(#paint1_linear_35780_80235)"
    />
    <path
      d="M95.6383 180.484L237.578 169.179C239.844 168.999 241.591 167.106 241.591 164.833V56.2419C241.591 53.8079 239.599 51.845 237.165 51.8824L95.2259 54.0621C92.8451 54.0988 90.9339 56.0397 90.9339 58.4217V176.137C90.9332 178.682 93.103 180.686 95.6383 180.484Z"
      fill="url(#paint2_linear_35780_80235)"
    />
    <path
      d="M247.52 114.166C246.7 114.166 246.036 113.501 246.036 112.682V101.675C246.036 100.855 246.7 100.19 247.52 100.19C248.339 100.19 249.004 100.855 249.004 101.675V112.682C249.005 113.501 248.339 114.166 247.52 114.166Z"
      fill="url(#paint3_linear_35780_80235)"
    />
    <path
      opacity="0.9"
      d="M216.533 144.984C203.606 133.706 193.243 138.118 179.568 126.658C162.594 112.434 171.046 99.3175 156.103 86.7919C137.418 71.129 114.839 83.834 100.817 68.1447C97.1466 64.0386 95.1035 59.1501 94.0311 54.249C92.2364 54.7912 90.9324 56.4537 90.9324 58.4203V176.136C90.9324 178.681 93.1015 180.684 95.6376 180.482L231.556 169.657C229.072 161.222 224.646 152.062 216.533 144.984Z"
      fill="white"
    />
    <g opacity="0.3">
      <path
        d="M221.81 109.291L111.388 113.449C109.516 113.52 107.994 112.164 107.994 110.423V103.033C107.994 101.302 109.509 99.8555 111.374 99.802L221.376 96.6272C222.982 96.581 224.327 97.8398 224.384 99.44L224.624 106.269C224.68 107.876 223.422 109.231 221.81 109.291Z"
        fill="#97A6B7"
      />
      <path
        d="M115.84 116.066L125.851 152.682C126.489 155.016 128.74 156.534 131.304 156.364L205.673 151.445C207.986 151.292 209.949 149.586 210.44 147.31L218.04 112.034L115.84 116.066ZM144.207 149.526C144.223 151 142.99 152.276 141.452 152.374L138.79 152.544C137.243 152.643 135.977 151.521 135.964 150.039L135.725 121.423C135.713 119.968 136.948 118.737 138.48 118.673L141.115 118.563C142.638 118.5 143.884 119.621 143.9 121.068L144.207 149.526ZM158.873 148.614C158.895 150.073 157.691 151.335 156.182 151.432L153.572 151.599C152.055 151.695 150.807 150.585 150.789 149.117L150.428 120.784C150.409 119.343 151.615 118.124 153.117 118.062L155.703 117.954C157.197 117.892 158.424 119.003 158.446 120.436L158.873 148.614ZM173.259 147.72C173.287 149.165 172.112 150.413 170.632 150.508L168.072 150.671C166.583 150.766 165.354 149.665 165.33 148.213L164.85 120.157C164.826 118.73 166.004 117.524 167.477 117.462L170.014 117.356C171.479 117.294 172.689 118.395 172.717 119.814L173.259 147.72ZM187.373 146.841C187.407 148.271 186.259 149.507 184.807 149.6L182.295 149.761C180.835 149.854 179.624 148.763 179.593 147.325L179 119.541C178.97 118.128 180.12 116.934 181.567 116.874L184.055 116.77C185.494 116.71 186.686 117.8 186.719 119.206L187.373 146.841ZM201.223 145.98C201.262 147.397 200.141 148.62 198.715 148.712L196.25 148.869C194.818 148.961 193.624 147.879 193.588 146.455L192.886 118.938C192.85 117.538 193.974 116.356 195.394 116.297L197.836 116.195C199.249 116.137 200.423 117.217 200.462 118.609L201.223 145.98Z"
        fill="#97A6B7"
      />
      <path
        d="M160.847 63.627C160.292 63.0357 159.165 63.1236 158.33 63.8255L123.553 93.0178C122.662 93.7666 122.409 94.8657 122.992 95.4724C123.573 96.0783 124.767 95.9603 125.657 95.2108L160.345 65.9687C161.178 65.266 161.403 64.2183 160.847 63.627Z"
        fill="#97A6B7"
      />
      <path
        d="M174.56 63.4916C175.089 62.8996 176.207 62.9809 177.057 63.6726L210.817 91.1416C211.643 91.8134 211.906 92.8436 211.405 93.443C210.903 94.0431 209.824 93.9844 208.996 93.3118L175.144 65.8194C174.292 65.127 174.03 64.0844 174.56 63.4916Z"
        fill="#97A6B7"
      />
    </g>
    <path
      d="M198.052 111.864C196.558 128.018 182.252 140.779 166.049 140.366C149.791 139.951 137.789 126.437 139.293 110.183C140.796 93.9287 155.229 81.1709 171.477 81.686C187.671 82.1996 199.547 95.7099 198.052 111.864Z"
      fill="url(#paint4_linear_35780_80235)"
    />
    <path
      d="M169.055 129.589C158.82 129.589 150.493 121.26 150.493 111.023C150.493 100.786 158.82 92.457 169.055 92.457C179.29 92.457 187.617 100.786 187.617 111.023C187.617 121.26 179.29 129.589 169.055 129.589ZM169.055 99.2858C162.584 99.2858 157.32 104.551 157.32 111.023C157.32 117.495 162.584 122.76 169.055 122.76C175.525 122.76 180.789 117.495 180.789 111.023C180.789 104.551 175.525 99.2858 169.055 99.2858Z"
      fill="white"
    />
    <path
      d="M160.469 123.851C159.598 123.851 158.728 123.52 158.062 122.858C156.725 121.528 156.719 119.367 158.047 118.029L176.578 99.3844C177.907 98.0465 180.068 98.0407 181.406 99.3698C182.743 100.7 182.75 102.861 181.421 104.199L162.89 122.843C162.223 123.514 161.345 123.851 160.469 123.851Z"
      fill="white"
    />
    <g opacity="0.2">
      <path
        d="M166.248 132.585C164.12 128.817 168.427 125.717 168.603 118.204C168.869 106.795 159.201 102.658 160.496 93.034C160.857 90.3582 162.186 86.3555 167.175 81.8604C152.754 83.4796 140.663 95.3802 139.293 110.182C137.79 126.436 149.791 139.95 166.049 140.365C171.592 140.506 176.912 139.106 181.574 136.555C175.507 136.965 168.501 136.572 166.248 132.585Z"
        fill="url(#paint5_linear_35780_80235)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_35780_80235"
        x1="98.4831"
        y1="7.55895"
        x2="139.479"
        y2="77.4778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="0.9964" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_35780_80235"
        x1="145.129"
        y1="57.8019"
        x2="231.467"
        y2="325.923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="0.9964" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_35780_80235"
        x1="90.9335"
        y1="116.19"
        x2="241.591"
        y2="116.19"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.494" stopColor="#EBEFF2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_35780_80235"
        x1="259.644"
        y1="84.5793"
        x2="242.734"
        y2="116.086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="0.9964" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_35780_80235"
        x1="139.465"
        y1="108.321"
        x2="197.881"
        y2="113.723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9085" />
        <stop offset="1" stopColor="#FB6FBB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_35780_80235"
        x1="139.436"
        y1="108.632"
        x2="183.778"
        y2="112.733"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9085" />
        <stop offset="1" stopColor="#FB6FBB" />
      </linearGradient>
    </defs>
  </svg>
)
