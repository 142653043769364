import React, { useMemo } from 'react'

import ArrowIcon from '../../../../assets/ArrowIcon'
import { useFAQContext } from '../../../../contexts/FAQContext'
import './faq-accordion.scss'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { FAQLike } from '../FAQLike'
import { FAQLinks } from '../FAQLinks'

interface AccordionProps {
  question: string
  answer: string
}

export const FAQAccordion = ({ question, answer }: AccordionProps) => {
  const { isMobile, activeQuestion, handleQuestion } = useFAQContext()
  const isActiveQuestion = activeQuestion === question
  const { activeQuestionEvent } = useAnalyticsEvents()
  const handleClick = (question: string) => {
    activeQuestionEvent(question)
  }

  const iconRotate = useMemo(() => {
    if (isMobile) {
      return 'Left'
    }

    return isActiveQuestion ? 'Down' : 'Up'
  }, [isActiveQuestion, isMobile])

  return (
    <section className="faq-accordion">
      <div className="faq-accordion-header">
        <button
          onClick={() => {
            handleClick(question)
            handleQuestion?.(question)
          }}
        >
          <span>{question}</span>
          <i>
            <ArrowIcon rotate={iconRotate} color="var(--color-main)" />
          </i>
        </button>
      </div>

      {activeQuestion === question && (
        <>
          <div
            className="faq-accordion-body"
            dangerouslySetInnerHTML={{
              __html: answer,
            }}
          />
          <FAQLike activeQuestion={question} /> <FAQLinks insideAccordion />
        </>
      )}
    </section>
  )
}
