import { HAS_WINDOW } from '../constants'

export const inView = (id?: string, innerHeight = 0) => {
  if (HAS_WINDOW && id) {
    const element = document.getElementById(id)?.getBoundingClientRect()
    const validation =
      element &&
      element?.top >= 0 &&
      element?.bottom <= window?.innerHeight + innerHeight

    return validation
  }

  return null
}
