import { Tour } from '@plurix/ecom-components'
import React, { useEffect, useState } from 'react'

import { useClubContext } from '../../../contexts/ClubContext'
import { useLocalStorageContext } from '../../../contexts/LocalStorageContext'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'

import '@plurix/ecom-components/dist/styles/tour.global.css'

const steps = [
  {
    selector: '#buy-again-shelf',
    content:
      'Nesta sessão você pode visualizar produtos que você comprou anteriormente e adiciona-los diretamente ao carrinho',
  },
  {
    selector: '#repeat-order-section',
    content:
      'Aqui você consegue ver seus pedidos anteriores, podendo adicionar o pedido todo ao seu carrinho atual',
  },
  {
    selector: '#dynamic-showcases-container',
    content:
      'Nesta sessão você encontra produtos de corredores e categorias que você geralmente mais compra',
  },
]

export const TourUserLogged = () => {
  const [tourIsOpen, setTourIsOpen] = useState(false)

  const { genericTaggingEvent } = useAnalyticsEvents()
  const { productsMostPurchased } = useClubContext()
  const { setLocalStorage, getLocalStorage } = useLocalStorageContext()

  useEffect(() => {
    if (productsMostPurchased && productsMostPurchased.length) {
      const hasOpened = getLocalStorage('tourUserLoggedHasOpened')

      if (!hasOpened) {
        genericTaggingEvent?.('tour_opened', {
          clickedText: 'Tour cliente logado aberto',
          name: 'home',
        })
        setLocalStorage?.('tourUserLoggedHasOpened', 'true')
        setTourIsOpen?.(true)
      }
    }
  }, [productsMostPurchased])

  const handleCloseAction = () => {
    setTourIsOpen(false)
  }

  if (!tourIsOpen) {
    return null
  }

  return (
    <Tour
      steps={steps}
      genericTaggingEvent={genericTaggingEvent}
      handleCloseAction={handleCloseAction}
    />
  )
}
