import type { SuggestedKits } from '../types/CMS'
import type { ProductsData } from '../types/RepeatOrder'

export const separateKitsSkus = (suggestedKits: SuggestedKits) => {
  if (!suggestedKits?.kits) {
    return []
  }

  return [...new Set(suggestedKits.kits.map((kit) => kit.skus).flat())]
}

export const separateProductsByKit = (
  suggestedKits: SuggestedKits,
  products: ProductsData['products']
) => {
  return suggestedKits?.kits?.map(({ image, title, description, skus }) => {
    const currentKitProducts = products?.filter((item) =>
      skus.includes(item.sku)
    )

    return {
      image,
      title,
      description,
      productSkus: currentKitProducts,
    }
  })
}
