import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { setBoldTextParts } from '../../../../utils/setBoldTextParts'
import { DynamicTitle } from '../../../common/DynamicTitle'

import './points-text-card.scss'

interface PointsCardProps {
  text: string
  redirect: string
  className?: string
  onClick?: (e: React.MouseEvent) => void
  textColor?: string
  containerColor?: string
}

export const PointsTextCard = ({
  text,
  redirect,
  className = '',
  onClick,
  textColor,
  containerColor,
}: PointsCardProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()
  const handleClick = (e: React.MouseEvent) => {
    onClick?.(e)
    genericTaggingEvent('order_your_visa_card_now', {})
  }

  return (
    <Link className={className} to={redirect} onClick={handleClick}>
      <div
        className={`pointsTextCard ${className}`}
        style={{ backgroundColor: containerColor }}
      >
        <DynamicTitle
          className="pointsCard-text"
          titleLevel={2}
          title={setBoldTextParts(text)}
          titleColor={textColor}
        />
      </div>
    </Link>
  )
}
