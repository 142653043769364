import { graphql, useStaticQuery } from 'gatsby'

import type { PlpBanners } from '../local_modules/types/CMS'

const useCmsPlp = () => {
  const data = useStaticQuery(graphql`
    query CmsPlpQuery {
      cmsPlp {
        sections {
          data
        }
      }
    }
  `)

  const plpBanners: PlpBanners | undefined = data?.cmsPlp?.sections?.[0]?.data

  return { plpBanners }
}

export default useCmsPlp
