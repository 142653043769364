import { graphql, useStaticQuery } from 'gatsby'

import type { UtmCmsData } from '../local_modules/types/CMS'

const useCmsUtmPromotion = () => {
  const data = useStaticQuery(graphql`
    query CmsUtmPromotion {
      cmsUtmPromotion {
        sections {
          data
        }
      }
    }
  `)

  const utmPromotionBond: UtmCmsData[] = data?.cmsUtmPromotion?.sections

  return { utmPromotionBond }
}

export default useCmsUtmPromotion
