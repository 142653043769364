import React, { useEffect } from 'react'

import Spinner from '../../../assets/SpinnerIcon'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import { useProductWithLowStock } from '../../../hooks/useProductWithLowStock'
import ProductCard from '../ProductCard'

import type { PromotionalProductPrices } from '../../../hooks/useUTMPromotion'
import type { ProductsQueryResponse } from '../../../types/Product'

import './product-grid.scss'

interface ProductGridProps {
  products: ProductsQueryResponse['node'][]
  page: number
  location: string
  promotionalPrices?: PromotionalProductPrices[]
}

function ProductGrid({
  products,
  page,
  location,
  promotionalPrices,
}: ProductGridProps) {
  const { viewItemListEvent } = useAnalyticsEvents()
  const { getAnotherScPrices } = useAnotherScPrices(products)

  const itemName = `plp-${location
    .replace(' ', '-')
    .toLocaleLowerCase()}-page-${page}`

  const { productsWithLowStock, loading } = useProductWithLowStock(products)

  const productsInStock = productsWithLowStock?.filter(
    (product) =>
      product?.offers?.offers?.[0]?.availability ===
      'https://schema.org/InStock'
  )

  useEffect(() => {
    if (productsWithLowStock && productsWithLowStock.length > 0) {
      viewItemListEvent(productsWithLowStock, itemName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemName, page, productsWithLowStock])

  if (!productsInStock?.length) return null

  if (loading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      {productsInStock?.map((product) => {
        const hasPromotionalPrice = promotionalPrices?.find(
          (price) => price.id === product.id
        )

        const anotherScPrices = getAnotherScPrices(product.sku)

        return (
          <li key={product.id}>
            <ProductCard
              hasPromotionalPrice={hasPromotionalPrice}
              product={product}
              bordered
              location={itemName}
              titleLevel={3}
              {...anotherScPrices}
            />
          </li>
        )
      })}
    </>
  )
}

export default ProductGrid
