import React from 'react'

interface ShareTabloidIconProps {
  width?: number
  height?: number
}

export const ShareTabloidIcon = ({
  width = 16,
  height = 16,
}: ShareTabloidIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1529 6.92315C11.9277 6.92315 10.8578 6.28251 10.2425 5.32259L6.53569 7.44129C6.77508 7.91215 6.92254 8.43668 6.92254 9.00029C6.92254 9.34892 6.85558 9.67803 6.75974 9.99485L10.6169 12.1987C11.2487 11.5134 12.147 11.0773 13.1529 11.0773C15.0652 11.0773 16.6142 12.6267 16.6142 14.5387C16.6145 16.4506 15.0651 18 13.1527 18C11.2408 18 9.69137 16.4506 9.69137 14.5387C9.69137 14.1904 9.75833 13.8609 9.85418 13.5428L5.99776 11.3403C5.36552 12.0247 4.46723 12.4616 3.46134 12.4616C1.54896 12.462 0 10.9122 0 9.00014C0 7.08819 1.54939 5.5388 3.46134 5.5388C4.25055 5.5388 4.96955 5.8128 5.55187 6.25712L9.73007 3.83566C9.71612 3.71108 9.6917 3.59027 9.6917 3.46149C9.69198 1.54939 11.2414 0 13.1533 0C15.0657 0.000418534 16.6147 1.54939 16.6147 3.46134C16.6151 5.37344 15.0653 6.92287 13.1529 6.92315ZM13.1529 16.6153C14.3001 16.6153 15.2299 15.6859 15.2299 14.5387C15.2299 13.3915 14.3001 12.4617 13.1529 12.4617C12.0061 12.4621 11.0763 13.3915 11.0763 14.5387C11.0759 15.6858 12.0053 16.6153 13.1529 16.6153ZM3.46077 6.92351C2.3136 6.92351 1.38378 7.8529 1.38378 9.00011C1.3842 10.1465 2.3136 11.0771 3.46077 11.0771C4.60752 11.0771 5.53737 10.1464 5.53737 9.00011C5.53737 7.85336 4.60755 6.92312 3.46077 6.92351ZM13.1522 1.3846C12.005 1.3846 11.0752 2.31442 11.0752 3.46159C11.0756 4.60877 12.005 5.53859 13.1522 5.53859C14.2998 5.53859 15.2292 4.60877 15.2292 3.46159C15.2292 2.31485 14.2993 1.3846 13.1522 1.3846Z"
      fill="var(--color-support-links)"
    />
  </svg>
)
