import { Select as UISelect } from '@faststore/ui'
import React from 'react'

import ArrowIcon from '../../../assets/ArrowIcon'

import type { SelectProps } from '@faststore/ui'

import './select.scss'

interface UISelectProps extends SelectProps {
  id: string
  options: Record<string, string>
  labelText?: string
  textFixed?: string
}

export default function Select({
  id,
  className,
  options,
  onChange,
  labelText,
  value,
  'aria-label': ariaLabel,
  testId,
  textFixed = 'Ver por: ',
}: UISelectProps) {
  return (
    <div data-select className={className}>
      {labelText && <label htmlFor={id}>{labelText}</label>}
      <UISelect
        data-testid={testId}
        onChange={onChange}
        value={value}
        aria-label={ariaLabel}
        id={id}
      >
        {Object.keys(options).map((key) => {
          const filterType = options[key]

          return (
            <option className="option" key={key} value={key}>
              {key === value ? filterType : textFixed + filterType}
            </option>
          )
        })}
      </UISelect>
      <ArrowIcon color="var(--color-grayScale-white)" />
    </div>
  )
}
