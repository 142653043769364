import React from 'react'

interface ErrorIconProps {
  color?: string
}

const ErrorIcon = ({ color }: ErrorIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="8"
      fill={color ?? 'var(--color-negativeFeedback-light)'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48082 6.19357C5.28406 5.99682 5.28406 5.67782 5.48082 5.48106C5.67757 5.28431 5.99657 5.28431 6.19333 5.48106L7.99995 7.28768L9.80657 5.48106C10.0033 5.28431 10.3223 5.28431 10.5191 5.48106C10.7158 5.67782 10.7158 5.99682 10.5191 6.19357L8.71246 8.00019L10.519 9.80675C10.7158 10.0035 10.7158 10.3225 10.519 10.5193C10.3223 10.716 10.0033 10.716 9.80651 10.5193L7.99995 8.7127L6.19339 10.5193C5.99663 10.716 5.67763 10.716 5.48088 10.5193C5.28412 10.3225 5.28412 10.0035 5.48088 9.80675L7.28744 8.00019L5.48082 6.19357Z"
      fill="white"
    />
  </svg>
)

export default ErrorIcon
