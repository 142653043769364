import React from 'react'

import type { SliderState } from '@faststore/ui'

export function customCarouselProps(
  childrenCount: number,
  infiniteMode: boolean,
  controls: string,
  children: React.ReactNode[] | undefined,
  sliderState: SliderState,
  pause: boolean,
  autoPlay: boolean | undefined
) {
  // slides
  const childrenArray = React.Children.toArray(children)
  const numberOfSlides = infiniteMode ? childrenCount + 2 : childrenCount

  // controls
  const showNavigationArrows =
    controls === 'complete' || controls === 'navigationArrows'

  const showPaginationBullets =
    controls === 'complete' || controls === 'paginationBullets'

  // slides render control
  const postRenderedSlides =
    infiniteMode && children ? childrenArray.slice(0, 1) : []

  const preRenderedSlides =
    infiniteMode && children ? childrenArray.slice(childrenCount - 1) : []

  const slides = preRenderedSlides.concat(children ?? [], postRenderedSlides)

  // verify move slides
  const disablePrevious =
    sliderState.sliding || (!infiniteMode && sliderState.currentPage === 0)

  const disableNext =
    sliderState.sliding ||
    (!infiniteMode && sliderState.currentPage === childrenCount - 1)

  const pausedWithAutoPlay = pause && autoPlay

  return {
    numberOfSlides,
    showNavigationArrows,
    showPaginationBullets,
    slides,
    disablePrevious,
    disableNext,
    pausedWithAutoPlay,
  }
}
