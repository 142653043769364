import React from 'react'

import { CampaignNotFoundIcon } from '../../../../assets/CampaignNotFoundIcon'
import './campaign-not-found.scss'

interface CampaignNotFoundProps {
  segment: string
}

export const CampaignNotFound = ({ segment }: CampaignNotFoundProps) => {
  return (
    <section className="grid-content campaign-not-found-container">
      <h1 className="campaign-not-found-title">
        Erro de carregamento da página
      </h1>
      <p className="campaign-not-found-text">
        Desculpe-nos pelo inconveniente. Estamos enfrentando problemas técnicos
        no momento e estamos trabalhando para resolver isso o mais rápido
        possível.
      </p>
      <CampaignNotFoundIcon segment={segment} />
    </section>
  )
}
