import React, { useEffect, useState } from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { sanitizeString } from '../../../utils/sanitizeString'
import { Banner } from '../Banner'
import { SelectBoxFlyerOffers } from './SelectBoxFlyerOffers'

import type { GenericBannerInfos } from '../../../types/CMS'

import './flyer-offers.scss'

interface Banner {
  mobile: GenericBannerInfos
  desktop: GenericBannerInfos
  alt: string
}

interface Props {
  loading: boolean
  visibility: boolean
  regionalizationTabloid: {
    visibility: boolean
    banners: Banner[]
    href: string
    regionalization: string
    title: string
  }[]
}

export const FlyerOffers = (props: Props) => {
  const { visibility, regionalizationTabloid, loading } = props
  const [region, setRegion] = useState('')
  const { genericTaggingEvent } = useAnalyticsEvents()

  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location?.href)

  useEffect(() => {
    setRegion(url.searchParams.get('region') ?? '')
  }, [url.searchParams])

  useEffect(() => {
    if (!region || regionalizationTabloid?.length === 0) {
      return
    }

    genericTaggingEvent('flyer-offers', {
      name: region || sanitizeString(regionalizationTabloid[0].regionalization),
    })
  }, [region])

  if (!regionalizationTabloid || regionalizationTabloid?.length === 0)
    return null

  if (!visibility || loading) return <></>

  const { href, title, banners } =
    regionalizationTabloid?.find(
      (item) => sanitizeString(item.regionalization) === region
    ) ?? regionalizationTabloid[0]

  const regions = regionalizationTabloid?.map(
    ({ regionalization }) => regionalization
  )

  if (!banners) return null

  return (
    <div
      id="folheto-de-ofertas"
      className="banner-container grid-content flyer-offers"
    >
      <SelectBoxFlyerOffers
        title={title}
        regions={regions}
        region={region}
        url={url}
        setRegion={setRegion}
      />

      <div id="folhetos">
        {banners.map((item, index) => (
          <Banner
            data={{ ...item, visibility: true, href: href || undefined }}
            key={`banner-flyer-offers-${index}`}
          />
        ))}
      </div>
    </div>
  )
}
