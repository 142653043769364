import React from 'react'

import Image404 from '../../../assets/404.png'
import './render-errors.scss'

export const FeatureDisabled = () => {
  return (
    <div className="feature-disabled-container">
      <h2 className="error-title error-title-feature-disabled">
        Desculpe, mas essa funcionalidade não está mais disponível.
      </h2>

      <div className="error-image-container">
        <img src={Image404} width={328} height={200} />
      </div>
    </div>
  )
}
