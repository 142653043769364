import React from 'react'

import './maintenance-store.scss'

interface MaintenanceStoreProps {
  isActive: boolean
  logo: string
  maintenanceImage: string
}

export const MaintenanceStore = ({
  isActive,
  logo,
  maintenanceImage,
}: MaintenanceStoreProps) => {
  if (!isActive) {
    return null
  }

  return (
    <section
      className="maintenance-store-container grid-content"
      data-testid="maintenance-store-container"
    >
      <img className="maintenance-store-logo" src={logo} alt="Logo" />
      <div className="maintenance-store-texts">
        <h1 className="maintenance-store-title">
          Nosso site está temporariamente indisponível devido a manutenção.
        </h1>
        <h2 className="maintenance-store-subtitle">
          Voltaremos em breve. Agradecemos sua compreensão!
        </h2>
      </div>
      <img
        className="maimaintenance-store-image"
        src={maintenanceImage}
        alt="Manutenção"
      />
    </section>
  )
}
