import axios from 'axios'
import { useState, useEffect } from 'react'

import { useTabloidsFindProps } from './useTabloidsFindProps'
import { useSession } from '../sdk/session'

import type { TabloidData } from '../types/Tabloids'

export function useTabloidsData(activeTabloid: string) {
  const [isLoading, setIsLoading] = useState(true)
  const [tabloidData, setTabloidData] = useState<TabloidData | null>()
  const [error, setError] = useState(null)
  const { postalCode, salesChannels } = useTabloidsFindProps()
  const { channel } = useSession()

  const parsedChannel: { salesChannel: string; regionId: string } = JSON.parse(
    channel ?? '{}'
  )

  const currentChannel = channel
    ? parsedChannel?.salesChannel
    : salesChannels.default

  useEffect(() => {
    // User is typing the postal code
    if (postalCode && postalCode.length < 9) {
      return
    }

    async function fetchTabloid() {
      if (activeTabloid !== 'default') {
        let url = `/api/tabloid/getTabloids?sc=${currentChannel}&builder=faststore`

        if (postalCode) {
          url += `&postalCode=${postalCode}`
        }

        if (activeTabloid) {
          url += `&tabloid=${activeTabloid}`
        }

        try {
          const response = await axios.get(url)

          if (response.data.errorType) {
            setTabloidData(null)
            setError(response.data.errorType)
          } else {
            setError(null)
            setTabloidData(response.data)
          }
        } catch (error) {
          setError(error)
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchTabloid()
  }, [postalCode, activeTabloid, channel])

  return { tabloidData, isLoading, error }
}
