import React from 'react'

import { AccordionArrows } from '../../../../assets/AccordionArrows'
import CloseIconV2 from '../../../../assets/CloseIconV2'
import Button from '../../../ui/Button'
import './suggested-kit-modal-header.scss'

interface SuggestedKitModalHeaderProps {
  onClose: () => void
  viewTitle: string
  goBack?: () => void
  descriptionSettings?: {
    title?: string
    subtitle?: string
  }
}

export const SuggestedKitModalHeader = ({
  onClose,
  viewTitle,
  goBack,
}: SuggestedKitModalHeaderProps) => {
  return (
    <>
      <div className="suggested-kit-modal-header-container">
        {goBack ? (
          <Button
            onClick={goBack}
            className="suggested-kit-modal-header-back"
            data-testid="suggested-kit-modal-header-back"
          >
            <AccordionArrows
              width={12}
              height={12}
              color="var(--color-main)"
              side="left"
            />
            {viewTitle}
          </Button>
        ) : (
          <p className="suggested-kit-modal-header-text">{viewTitle}</p>
        )}

        <Button
          className="suggested-kit-modal-header-close"
          onClick={onClose}
          data-testid="suggested-kit-modal-header-close"
        >
          <CloseIconV2 color="var(--color-main)" />
        </Button>
      </div>
    </>
  )
}
