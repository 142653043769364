import React from 'react'
import './styles.scss'

interface ValidityRangeProps {
  validityMin: string
  validityMax: string
}

const ValidityRange = ({ validityMin, validityMax }: ValidityRangeProps) => {
  return (
    <div className="validity-range">
      <p>
        Válido de {validityMin} até {validityMax}
      </p>
    </div>
  )
}

export default ValidityRange
