import { Skeleton } from '@acctglobal/skeleton'
import { Link } from 'gatsby'
import React, { useEffect, useState, useMemo } from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import CustomCarousel from '../../ui/CustomCarousel/CustomCarousel'
import { Image } from '../../ui/Image'

import type { RotationBanner } from '../../../types/CMS'

import './carousel-banner.scss'

type Props = {
  data?: RotationBanner
}

export const CarouselBanner = ({ data: carouselBanner }: Props) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook, isMobile } = useWindowDimensions()
  const { genericTaggingEvent } = useAnalyticsEvents()

  const hasShowCases = useMemo(() => {
    if (carouselBanner?.isActive) {
      const validShowcases = carouselBanner?.showcases?.filter((showcase) => {
        return showcase?.srcMobile && showcase?.src
      })

      return validShowcases?.length >= 2
    }

    return false
  }, [carouselBanner])

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  if (!width) {
    return (
      <div className="carousel-loading">
        <Skeleton
          responsiveImageSSR={{ srcWidth: 320, srcHeight: 212 }}
          backgroundColor="var(--color-grayScale-faded)"
        />
        <Skeleton
          responsiveImageSSR={{ srcWidth: 1920, srcHeight: 632 }}
          backgroundColor="var(--color-grayScale-faded)"
        />
      </div>
    )
  }

  if (!hasShowCases) {
    return null
  }

  return (
    <section className="page__section grid-content carousel-banner-container">
      <CustomCarousel
        autoPlay={!isMobile || (carouselBanner?.showcases?.length ?? 0) <= 1}
        timeInterval={5000}
        controls="complete"
        transition={{
          duration: 500,
          property: 'transform',
        }}
      >
        {carouselBanner?.showcases?.map((banner) => (
          <Link
            key={banner?.alt}
            to={banner?.href ?? '/'}
            onClick={() =>
              genericTaggingEvent('select_promotion', {
                location_id: banner?.href,
                promotion_name: banner?.analyticsReference,
                url_promotion: window.location.href,
              })
            }
          >
            <Image
              options={{
                fitIn: true,
              }}
              objectFit="contain"
              baseUrl={width <= 768 ? banner?.srcMobile : banner?.src}
              alt={banner?.alt}
              breakpoints={width <= 768 ? undefined : [768, 1280, 1920]}
              sourceWidth={width <= 768 ? 720 : 1414}
              sourceHeight={width <= 768 ? 240 : 336}
              layout="fullWidth"
              backgroundColor="var(--color-grayScale-faded)"
              loading="lazy"
            />
          </Link>
        ))}
      </CustomCarousel>
    </section>
  )
}
