import React, { useState, useCallback, useEffect, useMemo } from 'react'

import ErrorIcon from '../../../assets/ErrorIcon'
import { PasskeyEyes } from '../../../assets/PasskeyEyes'
import { PlurixLogo } from '../../../assets/PlurixLogo'
import { RestrictModeIcon } from '../../../assets/RestrictModeIcon'
import './restrict-mode.scss'

export const RestrictMode = ({
  setDevelopmentMode,
  passKey,
}: {
  passKey?: string
  setDevelopmentMode?: (developmentMode: boolean) => void
}) => {
  const [inputedKey, setInputedKey] = useState('')
  const [hiddenPasskey, setHiddenPasskey] = useState(true)
  const [inputFocus, setInputFocus] = useState(false)
  const [isValidKey, setiIsValidKey] = useState<boolean>()

  const hasError = useMemo(
    () => typeof isValidKey === 'boolean' && !isValidKey,
    [isValidKey]
  )

  const hasInputFocus = useMemo(
    () => inputFocus || inputedKey !== '',
    [inputFocus, inputedKey]
  )

  const handleInputKey = useCallback(
    (currentKey: string) => setInputedKey(currentKey),
    [setInputedKey]
  )

  const colorHandler = useMemo(() => {
    if (hasError) {
      return 'var(--color-negativeFeedback-light)'
    }

    if (hasInputFocus) {
      return 'var(--color-grayScale-darkest)'
    }
  }, [hasInputFocus, hasError])

  const handleLogin = useCallback(() => {
    if (inputedKey === '') {
      return
    }

    const keyIsValid = passKey === inputedKey

    if (keyIsValid) {
      localStorage.setItem('developerMode', 'true')
      setDevelopmentMode?.(true)
    } else {
      setInputedKey('')
    }

    setiIsValidKey(keyIsValid)
  }, [setiIsValidKey, inputedKey, setDevelopmentMode])

  useEffect(() => {
    const hasDeveloperMode = localStorage.getItem('developerMode') === 'true'

    setDevelopmentMode?.(hasDeveloperMode)
  }, [])

  return (
    <section className="developModeContainer">
      <div className="developModeContent">
        <PlurixLogo />

        <p className="developModeContent-title">
          Acesso restrito, apenas para funcionários autorizados.
        </p>

        <div
          className="developModeContent-access"
          style={{
            borderColor: colorHandler ?? '',
          }}
        >
          <input
            className="input"
            type={hiddenPasskey ? 'password' : 'text'}
            placeholder="Senha"
            value={inputedKey}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            onChange={({ target: { value } }) => handleInputKey(value)}
          />
          <button
            className="eyeButton"
            onClick={() => setHiddenPasskey(!hiddenPasskey)}
          >
            <PasskeyEyes color={colorHandler} open={!hiddenPasskey} />
          </button>
        </div>

        {hasError && (
          <span className="errorMessage">
            <ErrorIcon /> Senha inválida.
          </span>
        )}

        <button
          className="developModeContent-button"
          disabled={inputedKey === ''}
          onClick={() => handleLogin()}
        >
          Entrar
        </button>
      </div>

      <RestrictModeIcon width="328" />
    </section>
  )
}
