import React from 'react'

import { DynamicShowcaseCarousel } from './DynamicShowcaseCarousel'
import { useClubContext } from '../../../contexts/ClubContext'
import { ProductShelfSkeleton } from '../../sections/ProductShelf'

import type { ProductsQueryResponse } from '../../../types/Product'

import './dynamic-showcases.scss'

interface DynamicShowcasesProps {
  data?: { isActive: boolean }
  loading?: boolean
  dynamicShowcases?: DynamicShowcase[]
}

export interface DynamicShowcase {
  name: string
  products: ProductWithRecommendation[]
}

type ProductWithRecommendation = ProductsQueryResponse['node'] & {
  isRecommendation: boolean
}

export const DynamicShowcases = ({
  data,
  loading,
  dynamicShowcases,
}: DynamicShowcasesProps) => {
  const { isClubClient } = useClubContext()

  if (
    !data?.isActive ||
    (!loading && !dynamicShowcases?.length) ||
    !isClubClient
  ) {
    return null
  }

  return (
    <article
      id="dynamic-showcases-container"
      className="page__section shelf-section-grid dynamic-showcases-container"
    >
      <section className="dynamic-showcases-content">
        <h2>Você costuma passar por aqui!</h2>

        {loading ? (
          <>
            <p className="loading-warning">Só um segundinho</p>
            <p className="loading-message">
              Estamos carregando seus corredores preferidos
            </p>

            <div className="shelf-section-grid shelf-section-content-grid">
              <ProductShelfSkeleton backgroundColor="var(--color-grayScale-light)" />
            </div>
          </>
        ) : (
          dynamicShowcases?.map(({ name, products }) => (
            <div
              key={name}
              className="shelf-section-grid shelf-section-content-grid"
            >
              <DynamicShowcaseCarousel
                id={`vitrine-dinamica-${name}`}
                title={name}
                products={products}
              />
            </div>
          ))
        )}
      </section>
    </article>
  )
}
