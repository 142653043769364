import React from 'react'

import ProductListing from '../components/sections/ProductListing'
import Breadcrumb from '../components/ui/Breadcrumb'

import type { ItemElement } from '../components/ui/Breadcrumb'
import '../styles/pages/product-listing.scss'

interface DepartmentPageProps {
  title: string
  slug?: string
  itemListElement?: ItemElement[]
  dynamicShowcaseSkus?: string
}

export const ProductListingPage = ({
  title,
  slug,
  itemListElement,
  dynamicShowcaseSkus,
}: DepartmentPageProps) => {
  return (
    <>
      <div className="product-listing__breadcrumb / grid-content">
        {itemListElement && <Breadcrumb breadcrumbList={itemListElement} />}
      </div>
      <ProductListing
        title={title}
        slug={slug}
        itemListElement={itemListElement}
        dynamicShowcaseSkus={dynamicShowcaseSkus}
      />
    </>
  )
}
