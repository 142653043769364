import axios from 'axios'

import { emptyOrder } from '../constants'
import { useSession } from '../sdk/session'
import { formatPhysicalOrder } from '../utils/formatPhysicalOrder'

import type {
  GetOrdersResponse,
  OrderType,
  PhysicalOrdersResponse,
  ProductsData,
} from '../types/RepeatOrder'

export const useRepeatOrder = () => {
  const { channel } = useSession()

  const getOrderData = async (orderId: string, type: OrderType) => {
    try {
      if (type === 'digital') {
        const { data: digitalOrder } = await axios.get<GetOrdersResponse>(
          `/api/v2/order?orderId=${orderId}&fields=id,quantity`
        )

        return digitalOrder.data.customerOrder
      }

      if (type === 'physical') {
        const { data: physicalOrder } = await axios.get<
          PhysicalOrdersResponse[]
        >(`/api/v1/orders/physicalOrders?orderId=${orderId}&builder=faststore`)

        const formattedPhysicalOrder = formatPhysicalOrder(physicalOrder[0])

        return formattedPhysicalOrder.data.customerOrder
      }

      return emptyOrder
    } catch {
      return emptyOrder
    }
  }

  const getProductsData = async (ids: string[]) => {
    try {
      const {
        data: { products },
      } = await axios.post<ProductsData>('/api/products/getProductsByIds', {
        ids,
        channel,
      })

      return products
    } catch {
      return []
    }
  }

  return { getOrderData, getProductsData }
}
