import {
  Modal,
  Card as UICard,
  CardContent as UICardContent,
} from '@faststore/ui'
import { navigate } from 'gatsby'
import React, { memo, useMemo, useState } from 'react'

import { ProductCardActions } from './components/ProductCardActions'
import { ProductCardImage } from './components/ProductCardImage'
import { HAS_WINDOW, pesavelUND } from '../../../constants'
import { useClubContext } from '../../../contexts/ClubContext'
import { useSpecialPromotionsContext } from '../../../contexts/SpecialPromotionsContext'
import { useStoreContext } from '../../../contexts/StoreContext'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useBuyProduct } from '../../../hooks/useBuyProduct'
import { useSalesforceEvents } from '../../../hooks/useSalesforceEvents'
import { formatEspecifications } from '../../../utils/formatEspecifications'
import { ProductEspecificationsSeals } from '../../common/ProductEspecificationsSeals'
import { ClubModal } from '../../sections/ClubModal'
import { QuickView } from '../../sections/QuickView'
import { TelesalesModalProducts } from '../../sections/Telesales/TelesalesModalProducts'
import Button from '../../ui/Button'
import { ProductCardTopFlag } from '../../ui/ProductCardTopFlag'
import { PromotionFlag } from '../../ui/PromotionFlag'
import { ProductCardName } from './components/ProductCardName/ProductCardName'
import { ProductCardPrices } from './components/ProductCardPrices'
import { hasPlusFriendBadge } from '../../../../../devFeatureToggle.json'
import { ProductCardTopFlagImage } from '../../ui/ProductCardTopFlagImage'

import type { PromotionalProductPrices } from '../../../hooks/useUTMPromotion'
import type { ProductsQueryResponse } from '../../../types/Product'

import './product-card.scss'
import './product-card-horizontal.scss'

export type Variant = 'horizontal' | 'vertical'

interface ProductCardProps {
  product: ProductsQueryResponse['node']
  titleLevel?: number
  location: string
  bordered?: boolean
  outOfStock?: boolean
  variant?: Variant
  hasPromotionalPrice?: PromotionalProductPrices
  defaultScListPrice?: number
  clubPrice?: number
  hasQuickView?: boolean
}
function ProductCard({
  product,
  variant = 'vertical',
  bordered = false,
  outOfStock = false,
  location,
  hasPromotionalPrice,
  defaultScListPrice,
  clubPrice,
  titleLevel = 3,
  hasQuickView = true,
}: ProductCardProps) {
  const {
    unitMultiplier,
    measurementUnit,
    priceWithoutPromotions,
    isVariantOf: { name },
    image: [img],
    customOffers: {
      hasDiscount: hasNativeDiscount,
      listPriceCustom: nativeListPrice,
      spotPriceCustom: nativeSpotPrice,
      spotPriceKg,
    },
  } = product

  const { listPrice: promotionalListPrice, spotPrice: promotionalSpotPrice } =
    hasPromotionalPrice ?? {}

  const hasDiscount = useMemo(() => {
    if (
      promotionalListPrice &&
      promotionalSpotPrice &&
      promotionalSpotPrice !== nativeSpotPrice
    ) {
      return promotionalListPrice > promotionalSpotPrice
    }

    return hasNativeDiscount
  }, [
    promotionalListPrice,
    promotionalSpotPrice,
    nativeSpotPrice,
    hasNativeDiscount,
  ])

  const { listPriceCustom, spotPriceCustom } = useMemo(() => {
    if (
      promotionalListPrice &&
      promotionalSpotPrice &&
      promotionalSpotPrice !== nativeSpotPrice
    ) {
      return {
        listPriceCustom: promotionalListPrice,
        spotPriceCustom: promotionalSpotPrice,
      }
    }

    return {
      listPriceCustom: nativeListPrice,
      spotPriceCustom: nativeSpotPrice,
    }
  }, [
    promotionalListPrice,
    promotionalSpotPrice,
    nativeSpotPrice,
    nativeListPrice,
  ])

  const hasPesavelUnd = product?.skuSpecifications?.some((specification) => {
    return (
      specification.field.originalName === pesavelUND &&
      specification.values.some((value) => value.originalName === 'true')
    )
  })

  const [openClubModal, setOpenClubModal] = useState(false)
  const [openQuickView, setOpenQuickView] = useState(false)
  const [openTelesalesModal, setOpenTelesalesModal] = useState(false)

  const { buyProps, productData, cartProductId, cartProductQty } =
    useBuyProduct(product)

  const { hasClub } = useStoreContext()
  const { isClubClient } = useClubContext()

  const { selectItemEvent } = useAnalyticsEvents()
  const { selectItemEventSalesforce } = useSalesforceEvents()
  const { searchPromotion } = useSpecialPromotionsContext()
  const promotion = searchPromotion?.(product)

  const clubModalCanBeOpened = HAS_WINDOW && hasClub && !isClubClient
  const isClubValid = !!(hasClub && isClubClient && defaultScListPrice)

  const triggerSelectItemEvent = () => {
    selectItemEvent(product)
    selectItemEventSalesforce(product)

    if (hasQuickView) {
      quickViewEvent(product, location)
    }
  }

  const { foodRestrictionSection } = useStoreContext()
  const restrictionTags = foodRestrictionSection?.map(
    ({ specificationName }) => specificationName
  )

  const { productEspecificationsAll } = useMemo(
    () =>
      formatEspecifications(
        productData?.product?.additionalProperty,
        restrictionTags
      ),
    [productData, restrictionTags]
  )

  const hasUndQtd = useMemo(() => {
    if (hasPesavelUnd) {
      return ''
    }

    if (unitMultiplier > 1) {
      return unitMultiplier
    }

    if (unitMultiplier === 1) {
      return ''
    }

    return unitMultiplier * 1000
  }, [hasPesavelUnd, unitMultiplier])

  const hasUnd = useMemo(() => {
    if (hasPesavelUnd) {
      return 'Un'
    }

    if (unitMultiplier * 1000 >= 1000) {
      return measurementUnit
    }

    return 'g'
  }, [hasPesavelUnd, measurementUnit, unitMultiplier])

  const IsTelesales = location === 'plp-telesales-search-page-0'

  const { quickViewEvent } = useAnalyticsEvents()

  const handleClick = () => {
    if (IsTelesales) {
      setOpenTelesalesModal(true)

      return
    }

    triggerSelectItemEvent()

    if (hasQuickView) {
      setOpenQuickView(true)
    } else {
      navigate(`/${product.slug}/p`)
    }
  }

  return (
    <>
      {!IsTelesales && clubModalCanBeOpened && (
        <Modal isOpen={openClubModal} className="modal-regionalization">
          <ClubModal
            setOpenClubModal={setOpenClubModal}
            clubPrice={clubPrice}
            buyProps={buyProps}
          />
        </Modal>
      )}

      {IsTelesales && (
        <TelesalesModalProducts
          openModal={openTelesalesModal}
          setOpenModal={setOpenTelesalesModal}
          price={priceWithoutPromotions}
          buyProps={buyProps}
          cartProductId={cartProductId}
          hasClub={hasClub}
          cartProductQty={cartProductQty}
          product={product}
          isClubClient={isClubClient ?? false}
          defaultScListPrice={defaultScListPrice}
          clubPrice={clubPrice}
          spotPriceCustom={spotPriceCustom}
        />
      )}

      {!openClubModal && !IsTelesales && openQuickView && (
        <QuickView
          openQuickView={openQuickView}
          setOpenQuickView={setOpenQuickView}
          product={product}
          openClubModal={openClubModal}
          setOpenClubModal={setOpenClubModal}
          defaultScListPrice={defaultScListPrice}
          clubPrice={clubPrice}
        />
      )}
      <UICard
        style={outOfStock ? { display: 'none' } : {}}
        className={
          variant === 'vertical' ? 'product-card' : 'product-card-horizontal'
        }
        data-card-variant={variant}
        data-card-bordered={bordered}
        data-card-out-of-stock={outOfStock}
      >
        {isClubValid && hasPlusFriendBadge && <ProductCardTopFlagImage />}
        <Button className="link-to-pdp" onClick={handleClick}>
          <div
            className={
              variant === 'vertical'
                ? undefined
                : 'product-card-horizontal-content'
            }
          >
            <div className="product-image-container">
              {isClubValid && !hasPlusFriendBadge && (
                <ProductCardTopFlag
                  colorCode="var(--color-badge-main)"
                  flagText="Clube"
                  colorText="var(--color-badge-secondary)"
                />
              )}

              {!!promotion && <PromotionFlag {...promotion} />}

              <ProductCardImage
                imageUrl={img.url}
                imageAlt={img.alternateName}
              />
            </div>
            <UICardContent>
              <div className="product-card-content-top">
                <ProductCardName productName={name} titleLevel={titleLevel} />
                {variant === 'vertical' && (
                  <div className="product-especifications-container">
                    {productEspecificationsAll?.map((item) => (
                      <ProductEspecificationsSeals text={item} key={item} />
                    ))}
                  </div>
                )}
              </div>
              <ProductCardPrices
                listPriceCustom={listPriceCustom}
                spotPriceKg={spotPriceKg}
                spotPriceCustom={spotPriceCustom}
                hasDiscount={hasDiscount}
                unitMultiplier={unitMultiplier}
                measurementUnit={measurementUnit}
                clubPrice={clubPrice}
                defaultScListPrice={defaultScListPrice}
                hasPesavelUnd={hasPesavelUnd}
                hasUndQtd={hasUndQtd}
                hasUnd={hasUnd}
              />
            </UICardContent>
          </div>
        </Button>
        <ProductCardActions
          product={product}
          setOpenClubModal={setOpenClubModal}
          location={location}
          hasPesavelUnd={hasPesavelUnd}
          clubPrice={clubPrice}
          buyProps={buyProps}
          cartProductId={cartProductId}
          cartProductQty={cartProductQty}
          isHorizontal={variant === 'horizontal'}
        />
      </UICard>
    </>
  )
}

export default memo(ProductCard)
