import React, { useEffect } from 'react'

import { FAQAsideMenu } from '../components/sections/FAQ/FAQAsideMenu'
import { FAQHeader } from '../components/sections/FAQ/FAQHeader'
import { FAQNavigation } from '../components/sections/FAQ/FAQNavigation'
import { FAQQuestionsAndAnswers } from '../components/sections/FAQ/FAQQuestionsAndAnswers'
import { useFAQContext } from '../contexts/FAQContext'
import '../styles/pages/faq-page.scss'

interface FAQPageProps {
  searchParam?: string
  pathName?: string
}

export const FAQPage = ({ searchParam, pathName }: FAQPageProps) => {
  const {
    isMobile,
    activeTopic,
    showAsideMenu,
    faqData,
    setSearchParam,
    setPathName,
  } = useFAQContext()

  useEffect(() => {
    setPathName?.(pathName)
    setSearchParam?.(searchParam)
  }, [searchParam])

  if (!faqData) {
    return null
  }

  return (
    <article className="grid-content">
      <FAQNavigation />
      {(!activeTopic || !isMobile) && <FAQHeader />}
      <div className="faq-container">
        {showAsideMenu && <FAQAsideMenu />}

        <div className="faq-content">
          {activeTopic !== '' && (
            <>
              <FAQQuestionsAndAnswers />
            </>
          )}
        </div>
      </div>
    </article>
  )
}
