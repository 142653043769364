import React, { useState } from 'react'

import { MultipleSearchInputIcon } from '../../../../assets/MultipleSearchInputIcon'
import { defaultSort } from '../../../../constants'
import { useLocalStorageContext } from '../../../../contexts/LocalStorageContext'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import Button from '../../../ui/Button'
import { CleanMultipleSearch } from '../CleanMultipleSearch'
import { MultipleSearchInput } from '../MultipleSearchInput'

interface SearchViewProps {
  setMultipleTerms: (terms: string[]) => void
  multipleTerms: string[]
  limitReached?: boolean
  hasTerms?: boolean
  loading?: boolean
  handleTerms?: (terms: string[]) => void
}

export const SearchView = ({
  handleTerms,
  setMultipleTerms,
  multipleTerms,
  hasTerms,
  limitReached,
  loading,
}: SearchViewProps) => {
  const [term, setTerm] = useState('')
  const { lastSearched, addLastSearchedItem } = useLocalStorageContext()
  const { genericTaggingEvent } = useAnalyticsEvents()

  const handleSearchMultipleTerms = () => {
    const formattedTerms = multipleTerms.map((item: string) => ({
      text: item,
      link: `/s/?q=${item}&sort=${defaultSort}&page=0`,
    }))

    formattedTerms.forEach(({ text, link }) => {
      addLastSearchedItem?.(text, link)
    })
    handleTerms?.(multipleTerms)
  }

  const sendEventSearchList = () => {
    return genericTaggingEvent('buscar_lista', {
      name: multipleTerms,
    })
  }

  return (
    <div className="grid-content">
      <MultipleSearchInput
        term={term}
        setTerm={setTerm}
        multipleTerms={multipleTerms}
        setMultipleTerms={setMultipleTerms}
        limitReached={limitReached}
        lastSearched={lastSearched}
        hasTerms={hasTerms}
      />

      {hasTerms && (
        <CleanMultipleSearch
          text="Remover todos produtos"
          setTMultipleTerms={setMultipleTerms}
        />
      )}

      <div className="multiple-search-button-container">
        <Button
          className="multiple-search-button"
          disabled={!hasTerms || loading}
          onClick={() => {
            handleSearchMultipleTerms()
            sendEventSearchList()
          }}
        >
          <MultipleSearchInputIcon
            width={16}
            height={16}
            color={
              !hasTerms || loading
                ? 'var(--color-grayScale-light)'
                : 'var(--color-assistant-main)'
            }
          />
          {loading ? 'Buscando Lista...' : 'Buscar Lista'}
        </Button>
      </div>
    </div>
  )
}
