import React from 'react'

import { CartHeaderIconV2 } from '../../../../assets/CartHeaderIconV2'
import { formatBrazilianPrice } from '../../../../utils/formatPrices'
import './suggested-kit-modal-footer.scss'

interface SuggestedKitModalFooterProps {
  text: string
  totalValue: number
  addAllProducts: () => void
  isDisabled: boolean
}

export const SuggestedKitModalFooter = ({
  text,
  totalValue,
  addAllProducts,
  isDisabled,
}: SuggestedKitModalFooterProps) => {
  return (
    <div className="suggested-kit-modal-footer-container">
      <span className="suggested-kit-modal-footer-total">
        Valor Total: {formatBrazilianPrice(totalValue)}
      </span>

      <button
        className="suggested-kit-modal-footer-add-button"
        data-testid="suggested-kit-modal-footer-add-button"
        onClick={addAllProducts}
        disabled={isDisabled}
      >
        <CartHeaderIconV2
          color={
            isDisabled
              ? 'var(--color-positiveFeedback-dark)'
              : 'var(--color-assistant-main'
          }
        />
        {text}
      </button>
    </div>
  )
}
