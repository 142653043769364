import React from 'react'

interface AppIosIconProps {
  width?: number
  height?: number
}

const AppIosIcon = ({ width = 152, height = 48 }: AppIosIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 154 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="app-ios-icon"
  >
    <rect x="0.5" y="0.500549" width="153" height="47" rx="8.5" fill="black" />
    <path
      d="M34.8431 23.3211C34.856 22.329 35.1234 21.3562 35.6202 20.4932C36.117 19.6303 36.8272 18.9053 37.6847 18.3856C37.14 17.6187 36.4214 16.9876 35.5859 16.5423C34.7505 16.0971 33.8213 15.85 32.872 15.8208C30.8471 15.6113 28.8841 17.0152 27.8523 17.0152C26.8004 17.0152 25.2118 15.8416 23.501 15.8763C22.3945 15.9115 21.316 16.2287 20.3708 16.797C19.4256 17.3652 18.6458 18.1652 18.1074 19.1189C15.7753 23.0991 17.5149 28.9487 19.7488 32.166C20.8665 33.7415 22.1727 35.5013 23.882 35.4389C25.5545 35.3705 26.1792 34.3875 28.1981 34.3875C30.1983 34.3875 30.7844 35.4389 32.5282 35.3992C34.323 35.3705 35.4538 33.8168 36.5322 32.2264C37.3353 31.1038 37.9532 29.8632 38.3632 28.5504C37.3205 28.1156 36.4307 27.3879 35.8047 26.458C35.1787 25.5281 34.8443 24.4371 34.8431 23.3211Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M31.5491 13.7048C32.5276 12.5468 33.0097 11.0583 32.893 9.55556C31.398 9.71035 30.017 10.4147 29.0252 11.5283C28.5403 12.0723 28.1689 12.7052 27.9323 13.3908C27.6956 14.0764 27.5984 14.8013 27.6461 15.524C28.3939 15.5316 29.1336 15.3718 29.8097 15.0567C30.4858 14.7416 31.0805 14.2794 31.5491 13.7048Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M55.9857 31.4505H50.278L48.9073 35.4403H46.4897L51.896 20.6791H54.4077L59.8139 35.4403H57.3552L55.9857 31.4505ZM50.8691 29.6094H55.3934L53.1631 23.1343H53.1007L50.8691 29.6094Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M71.4893 30.0599C71.4893 33.4042 69.6735 35.5529 66.9333 35.5529C66.2392 35.5887 65.5488 35.4311 64.9413 35.0981C64.3338 34.7651 63.8338 34.2702 63.4983 33.67H63.4465V39.0006H61.2056V24.6782H63.3747V26.4682H63.4159C63.7667 25.871 64.2753 25.3786 64.8874 25.0436C65.4995 24.7086 66.1923 24.5434 66.8921 24.5656C69.6629 24.5656 71.4893 26.7248 71.4893 30.0599ZM69.186 30.0599C69.186 27.881 68.0437 26.4485 66.3009 26.4485C64.5888 26.4485 63.4371 27.9112 63.4371 30.0599C63.4371 32.2283 64.5888 33.6805 66.3009 33.6805C68.0438 33.6805 69.186 32.2585 69.186 30.0599Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M83.5054 30.0599C83.5054 33.4043 81.6896 35.553 78.9494 35.553C78.2553 35.5888 77.5649 35.4311 76.9574 35.0981C76.35 34.7651 75.8499 34.2702 75.5145 33.6701H75.4626V39.0006H73.2217V24.6783H75.3908V26.4683H75.432C75.7828 25.871 76.2913 25.3787 76.9035 25.0437C77.5156 24.7086 78.2084 24.5435 78.9082 24.5657C81.679 24.5657 83.5054 26.7248 83.5054 30.0599ZM81.2021 30.0599C81.2021 27.881 80.0599 26.4486 78.3171 26.4486C76.6049 26.4486 75.4532 27.9112 75.4532 30.0599C75.4532 32.2284 76.6049 33.6806 78.3171 33.6806C80.0599 33.6806 81.2021 32.2585 81.2021 30.0599Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M91.4468 31.3275C91.6128 32.7913 93.0554 33.7524 95.0266 33.7524C96.9154 33.7524 98.2743 32.7912 98.2743 31.4714C98.2743 30.3256 97.4547 29.6396 95.5141 29.1694L93.5735 28.7086C90.8239 28.0539 89.5474 26.7862 89.5474 24.7292C89.5474 22.1824 91.7989 20.433 94.996 20.433C98.1601 20.433 100.329 22.1824 100.402 24.7292H98.1401C98.0046 23.2561 96.7694 22.367 94.9641 22.367C93.1589 22.367 91.9237 23.2666 91.9237 24.576C91.9237 25.6196 92.7126 26.2337 94.6427 26.7038L96.2924 27.1031C99.3647 27.8193 100.641 29.0359 100.641 31.195C100.641 33.9567 98.4097 35.6863 94.8605 35.6863C91.5398 35.6863 89.2977 33.9973 89.1528 31.3274L91.4468 31.3275Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M105.478 22.1313V24.6782H107.554V26.4276H105.478V32.3606C105.478 33.2823 105.893 33.7118 106.806 33.7118C107.052 33.7076 107.298 33.6905 107.543 33.6607V35.3996C107.133 35.4752 106.716 35.5094 106.298 35.5018C104.088 35.5018 103.226 34.6834 103.226 32.5962V26.4276H101.639V24.6782H103.226V22.1313H105.478Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M108.756 30.0599C108.756 26.6737 110.779 24.5459 113.934 24.5459C117.099 24.5459 119.113 26.6737 119.113 30.0599C119.113 33.4553 117.11 35.5738 113.934 35.5738C110.759 35.5738 108.756 33.4553 108.756 30.0599ZM116.829 30.0599C116.829 27.737 115.749 26.3661 113.934 26.3661C112.118 26.3661 111.039 27.7475 111.039 30.0599C111.039 32.392 112.118 33.7525 113.934 33.7525C115.749 33.7525 116.829 32.392 116.829 30.0599Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M120.96 24.6783H123.098V26.5101H123.15C123.294 25.938 123.634 25.4324 124.112 25.0783C124.591 24.7242 125.178 24.5432 125.776 24.5657C126.034 24.5648 126.291 24.5924 126.543 24.6481V26.7144C126.217 26.6162 125.877 26.5711 125.536 26.5809C125.211 26.5679 124.886 26.6244 124.585 26.7467C124.284 26.869 124.013 27.0542 123.791 27.2894C123.569 27.5246 123.402 27.8044 123.3 28.1095C123.198 28.4146 123.164 28.7378 123.201 29.0569V35.4403H120.96L120.96 24.6783Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M136.875 32.2794C136.573 34.2331 134.643 35.5738 132.174 35.5738C128.998 35.5738 127.027 33.4762 127.027 30.1109C127.027 26.7353 129.009 24.5459 132.08 24.5459C135.1 24.5459 137 26.5913 137 29.8544V30.6113H129.289V30.7448C129.253 31.1408 129.304 31.5398 129.437 31.9153C129.569 32.2907 129.782 32.634 130.06 32.9223C130.337 33.2106 130.674 33.4374 131.048 33.5875C131.421 33.7376 131.823 33.8077 132.226 33.7931C132.755 33.842 133.287 33.7211 133.741 33.4484C134.195 33.1756 134.548 32.7656 134.747 32.2794L136.875 32.2794ZM129.3 29.0674H134.758C134.778 28.7112 134.723 28.3548 134.597 28.0206C134.471 27.6863 134.276 27.3814 134.024 27.1249C133.773 26.8684 133.47 26.6659 133.136 26.5302C132.801 26.3944 132.442 26.3283 132.08 26.3359C131.715 26.3338 131.353 26.4029 131.015 26.5393C130.678 26.6757 130.371 26.8766 130.112 27.1306C129.854 27.3845 129.649 27.6864 129.509 28.0188C129.37 28.3513 129.299 28.7076 129.3 29.0674Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M47.9893 16.6609V9.56832H50.8814C52.2332 9.56832 53.0457 10.2462 53.0457 11.3374C53.0461 11.6959 52.9207 12.0434 52.6908 12.3212C52.461 12.599 52.1408 12.7899 51.7846 12.8616V12.9452C52.2266 12.9658 52.6431 13.1554 52.9456 13.4738C53.2482 13.7922 53.4129 14.2143 53.4049 14.6504C53.4049 15.8989 52.4829 16.661 50.9461 16.661L47.9893 16.6609ZM49.1068 12.5713H50.4327C51.4207 12.5713 51.9341 12.2126 51.9341 11.5394C51.9341 10.8852 51.4501 10.5022 50.6223 10.5022H49.1068V12.5713ZM50.7224 15.7271C51.7293 15.7271 52.2627 15.3237 52.2627 14.572C52.2627 13.8297 51.7092 13.4414 50.6576 13.4414H49.1068V15.7271H50.7224Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M54.5527 15.1472C54.5527 14.1837 55.2805 13.6283 56.5723 13.5494L58.043 13.4658V13.0038C58.043 12.4384 57.6639 12.1192 56.9314 12.1192C56.3332 12.1192 55.9187 12.3357 55.7998 12.7141H54.7623C54.8719 11.7948 55.7491 11.2051 56.9809 11.2051C58.3422 11.2051 59.1099 11.8731 59.1099 13.0038V16.661H58.0784V15.9088H57.9936C57.8215 16.1786 57.5799 16.3985 57.2933 16.5462C57.0068 16.6938 56.6856 16.7639 56.3627 16.7492C56.1347 16.7725 55.9043 16.7486 55.6864 16.6788C55.4684 16.609 55.2677 16.495 55.0972 16.3441C54.9266 16.1931 54.7901 16.0087 54.6963 15.8025C54.6025 15.5964 54.5536 15.3732 54.5527 15.1472ZM58.043 14.6899V14.2424L56.7171 14.326C55.9693 14.3753 55.6302 14.626 55.6302 15.0979C55.6302 15.5796 56.0541 15.86 56.6371 15.86C56.8079 15.877 56.9804 15.86 57.1444 15.81C57.3084 15.76 57.4605 15.6779 57.5917 15.5687C57.7229 15.4596 57.8304 15.3255 57.908 15.1745C57.9855 15.0235 58.0314 14.8587 58.043 14.6899Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M60.6482 9.72558C60.6399 9.58876 60.6735 9.45265 60.7447 9.33487C60.8158 9.2171 60.9211 9.12309 61.0471 9.06501C61.173 9.00694 61.3137 8.98749 61.451 9.00917C61.5883 9.03085 61.7158 9.09267 61.8171 9.18662C61.9183 9.28056 61.9886 9.40232 62.0188 9.5361C62.049 9.66988 62.0378 9.80953 61.9866 9.93695C61.9354 10.0644 61.8466 10.1737 61.7316 10.2508C61.6166 10.3279 61.4808 10.3691 61.3418 10.3693C61.2529 10.3737 61.1641 10.3604 61.0805 10.3302C60.997 10.2999 60.9206 10.2533 60.8558 10.1932C60.791 10.1331 60.7392 10.0607 60.7035 9.9803C60.6679 9.89994 60.649 9.81329 60.6482 9.72558ZM60.8072 11.3084H61.8752V16.661H60.8071L60.8072 11.3084Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M65.5525 14.7636L64.3655 16.6609H63.1891L64.9896 13.9968L63.1738 11.3083H64.4256L65.5926 13.1958H65.6773L66.8337 11.3083H68.0254L66.2355 13.9527L68.0455 16.6609H66.8148L65.6326 14.7636H65.5525Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M68.9912 15.1472C68.9912 14.1837 69.719 13.6283 71.0107 13.5494L72.4815 13.4658V13.0038C72.4815 12.4384 72.1024 12.1192 71.3699 12.1192C70.7717 12.1192 70.3572 12.3357 70.2383 12.7141H69.2008C69.3103 11.7948 70.1876 11.2051 71.4194 11.2051C72.7806 11.2051 73.5484 11.8731 73.5484 13.0038V16.661H72.5169V15.9088H72.4321C72.26 16.1786 72.0183 16.3985 71.7318 16.5462C71.4453 16.6938 71.1241 16.7639 70.8011 16.7492C70.5732 16.7725 70.3428 16.7486 70.1248 16.6788C69.9069 16.609 69.7062 16.495 69.5356 16.3441C69.3651 16.1931 69.2286 16.0087 69.1348 15.8025C69.041 15.5964 68.9921 15.3732 68.9912 15.1472ZM72.4815 14.6899V14.2424L71.1556 14.326C70.4078 14.3753 70.0687 14.626 70.0687 15.0979C70.0687 15.5796 70.4926 15.86 71.0756 15.86C71.2464 15.877 71.4189 15.86 71.5829 15.81C71.7469 15.76 71.899 15.6779 72.0302 15.5687C72.1614 15.4596 72.2689 15.3255 72.3464 15.1745C72.424 15.0235 72.4699 14.8587 72.4815 14.6899Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M75.2266 11.3084H76.2581V12.1291H76.3382C76.4537 11.8442 76.6578 11.6027 76.9212 11.4393C77.1846 11.2759 77.4938 11.1989 77.8043 11.2196C77.9648 11.2174 78.125 11.2322 78.2824 11.2637V12.3061C78.0857 12.2647 77.8853 12.2433 77.6842 12.2423C77.5074 12.2267 77.3293 12.2476 77.1613 12.3039C76.9933 12.3601 76.839 12.4504 76.7085 12.5689C76.578 12.6874 76.474 12.8315 76.4034 12.992C76.3327 13.1525 76.2969 13.3257 76.2982 13.5006V16.661H75.2266L75.2266 11.3084Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M82.4121 11.3083H83.4443V12.1586H83.5244C83.6602 11.8532 83.8893 11.5972 84.1798 11.4263C84.4702 11.2553 84.8076 11.178 85.1447 11.205C85.4089 11.1854 85.6742 11.2247 85.9209 11.3199C86.1676 11.4151 86.3894 11.5638 86.5699 11.755C86.7504 11.9462 86.885 12.175 86.9636 12.4244C87.0422 12.6738 87.0629 12.9374 87.0241 13.1958V16.6608H85.9525V13.4611C85.9525 12.6009 85.5734 12.1731 84.7808 12.1731C84.6015 12.1649 84.4224 12.195 84.2559 12.2614C84.0895 12.3277 83.9395 12.4288 83.8164 12.5576C83.6932 12.6865 83.5998 12.84 83.5425 13.0078C83.4852 13.1755 83.4653 13.3535 83.4843 13.5296V16.6609H82.4122L82.4121 11.3083Z"
      fill="var(--color-grayScale-white)"
    />
    <path
      d="M88.3721 15.1472C88.3721 14.1837 89.0998 13.6283 90.3916 13.5494L91.8624 13.4658V13.0038C91.8624 12.4384 91.4832 12.1192 90.7508 12.1192C90.1526 12.1192 89.7381 12.3357 89.6191 12.7141H88.5817C88.6912 11.7948 89.5685 11.2051 90.8002 11.2051C92.1615 11.2051 92.9293 11.8731 92.9293 13.0038V16.661H91.8977V15.9088H91.8129C91.6408 16.1786 91.3992 16.3985 91.1127 16.5462C90.8261 16.6938 90.505 16.7639 90.182 16.7492C89.9541 16.7725 89.7237 16.7486 89.5057 16.6788C89.2877 16.609 89.087 16.495 88.9165 16.3441C88.746 16.1931 88.6094 16.0087 88.5157 15.8025C88.4219 15.5964 88.373 15.3732 88.3721 15.1472ZM91.8624 14.6899V14.2424L90.5364 14.326C89.7887 14.3753 89.4495 14.626 89.4495 15.0979C89.4495 15.5796 89.8735 15.86 90.4564 15.86C90.6272 15.877 90.7997 15.86 90.9637 15.81C91.1277 15.76 91.2799 15.6779 91.411 15.5688C91.5422 15.4596 91.6498 15.3255 91.7273 15.1745C91.8049 15.0235 91.8508 14.8587 91.8624 14.6899Z"
      fill="var(--color-grayScale-white)"
    />
    <rect
      x="0.5"
      y="0.500549"
      width="153"
      height="47"
      rx="8.5"
      stroke="#A6A6A6"
    />
  </svg>
)

export default AppIosIcon
