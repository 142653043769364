import './accordion-v2.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react'

import ArrowIcon from '../../../assets/ArrowIcon'
import { DynamicTitle } from '../../common/DynamicTitle'

interface AccordionV2Props {
  selectedTitle: string | undefined
  setSelectedTitle: React.Dispatch<React.SetStateAction<string | undefined>>
  title: string
  titleLevel?: number
  children?: React.ReactNode
  className?: string
  handleSelect?: HandleSelectAccordionOption
}

export type HandleSelectAccordionOption = (params: {
  isSelected: boolean
  title: string
}) => void

export const AccordionV2 = ({
  selectedTitle,
  setSelectedTitle,
  title,
  titleLevel = 3,
  children,
  className = '',
  handleSelect,
}: AccordionV2Props) => {
  const [didMount, setDidMount] = useState(false)
  const elementAccordionV2 = useRef<HTMLDivElement | null>(null)
  const isSelected = selectedTitle === title

  const handleAccordion = () => {
    handleSelect?.({ isSelected, title })
    setSelectedTitle(isSelected ? undefined : title)
  }

  const handleSelectAccordion = useCallback(() => {
    const elementCurrentAccordion = elementAccordionV2?.current
    const classContainer = '.accordionV2-container'
    const addSpaceBottom = 12

    if (!elementCurrentAccordion) {
      return
    }

    if (!isSelected) {
      elementCurrentAccordion.style.cssText = `height: ${(elementCurrentAccordion?.querySelector(classContainer)?.clientHeight ?? 0) + addSpaceBottom}px;`

      return
    }

    elementCurrentAccordion.style.cssText = `height: ${(elementCurrentAccordion?.querySelector(classContainer)?.clientHeight ?? 0) + addSpaceBottom + (elementCurrentAccordion?.querySelector('.faq-answer')?.clientHeight ?? 0)}px;`
  }, [isSelected])

  useEffect(() => {
    setDidMount(true)
    handleSelectAccordion()
  }, [handleSelectAccordion])

  if (!title) {
    return null
  }

  return (
    <div
      className={`accordionV2 ${className} ${isSelected ? 'selected' : ''}`}
      ref={elementAccordionV2}
    >
      <button className="accordionV2-container" onClick={handleAccordion}>
        <DynamicTitle
          className="accordionV2-title"
          titleLevel={titleLevel}
          title={title}
        />
        <ArrowIcon
          rotate={isSelected ? 'Up' : 'Down'}
          color="var(--color-main)"
        />
      </button>
      {didMount && children}
    </div>
  )
}
