import React from 'react'

import Spinner from '../../../../assets/SpinnerIcon'
import { NotFoundProducts } from '../NotFoundProducts'

import type { ReactElement } from 'react'

interface RenderProductsProps {
  children: ReactElement
  products: any[]
  selectedTerm: string
  handleRedirect: () => void
  isLoading: boolean
}

export const RenderProducts = ({
  children,
  products,
  selectedTerm,
  handleRedirect,
  isLoading,
}: RenderProductsProps) => {
  if (!products.length) {
    return isLoading ? (
      <div className="telesales-loader grid-content">
        <Spinner />
      </div>
    ) : (
      <NotFoundProducts
        actualTerm={selectedTerm}
        handleRedirect={handleRedirect}
      />
    )
  }

  return children
}
