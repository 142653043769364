import axios from 'axios'
import { useEffect, useState } from 'react'

import { useCart } from '../sdk/cart'

import type { GetFreeShipping } from '../types/FreeShippingRuler'

export const useFreeShippingRuler = () => {
  const [shippingBaseValue, setShippingBaseValue] = useState<number>(0)
  const [valueForFreeShipping, setValueForFreeShipping] = useState<number>(0)
  const [resquestLoading, setResquestLoading] = useState(false)

  const { id, items, isValidating } = useCart()

  useEffect(() => {
    const request = async () => {
      if (!id || !items || (items && items.length < 1)) {
        setValueForFreeShipping(0)

        return
      }

      try {
        setResquestLoading(true)

        const { data }: { data: GetFreeShipping } = await axios.get(
          `/api/shipping/getFreeShipping?orderFormId=${id}&builder=faststore&time=${Math.random()}`
        )

        setResquestLoading(false)

        if (!data) {
          return
        }

        setShippingBaseValue(data.shippingBaseValue)
        setValueForFreeShipping(data.valueForFreeShipping)
      } catch (err) {
        console.error('Error in useFreeShippingRuler request', err)
      }
    }

    request()
  }, [id, items])

  return {
    shippingBaseValue,
    valueForFreeShipping,
    isValidating,
    resquestLoading,
  }
}
