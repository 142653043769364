import React from 'react'

interface ISpinnerIcon {
  color?: string
}

const SpinnerIcon = ({ color }: ISpinnerIcon) => (
  <svg
    width="86"
    height="85"
    data-testid="spinner"
    viewBox="0 0 86 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.8542 0C19.4624 0 0.5 19.0277 0.5 42.5C0.5 65.9723 19.4624 85 42.8542 85C66.246 85 85.2084 65.9723 85.2084 42.5C85.2084 19.0277 66.246 0 42.8542 0ZM42.8542 72.5645C26.3062 72.5645 12.8928 59.105 12.8928 42.5C12.8928 25.895 26.3062 12.4355 42.8542 12.4355C59.4022 12.4355 72.8156 25.895 72.8156 42.5C72.8156 59.105 59.4022 72.5645 42.8542 72.5645Z"
      fill="#F4F4F4"
    />
    <path
      d="M85.5001 42.5248V42.4999C85.5001 42.4751 85.5001 42.4517 85.5001 42.4268C85.4214 19.0628 66.5231 0.14624 43.2188 0.14624C43.1459 0.14624 43.073 0.14624 43.0001 0.14624C41.3567 0.14624 39.7806 0.801317 38.6186 1.96737C37.4565 3.13342 36.8037 4.71492 36.8037 6.36397C36.8037 8.01301 37.4565 9.59452 38.6186 10.7606C39.7806 11.9266 41.3567 12.5817 43.0001 12.5817C43.1532 12.5817 43.3048 12.5817 43.4536 12.5627C59.7027 12.69 72.8638 25.8145 73.1219 42.0859C73.1132 42.2234 73.1073 42.361 73.1073 42.4999C73.0976 44.149 73.7412 45.7343 74.8964 46.9072C76.0516 48.0802 77.6239 48.7445 79.2673 48.7542C80.9107 48.7639 82.4906 48.1182 83.6595 46.959C84.8283 45.7998 85.4904 44.2221 85.5001 42.5731V42.5248Z"
      fill={color ?? '#2EB722'}
    />
  </svg>
)

export default SpinnerIcon
