import React, { useEffect, useState } from 'react'

import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Image } from '../../ui/Image'

import type { PlpBannerCategorie } from '../../../types/CMS'

interface PlpBannerProps {
  plpBanner: PlpBannerCategorie
}

export const PlpBanner = ({ plpBanner }: PlpBannerProps) => {
  const [width, setWidth] = useState(0)
  const { width: widthHook } = useWindowDimensions()

  useEffect(() => {
    widthHook && setWidth(widthHook)
  }, [widthHook])

  return (
    <Image
      baseUrl={width <= 768 ? plpBanner.srcMobile : plpBanner.src}
      alt={plpBanner.alt}
      breakpoints={width <= 768 ? undefined : [768, 1280, 1920]}
      sourceWidth={width <= 768 ? 288 : 1141}
      sourceHeight={width <= 768 ? 102 : 175}
      layout="fullWidth"
      backgroundColor="var(--color-grayScale-faded)"
      loading="lazy"
    />
  )
}
