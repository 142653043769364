import React from 'react'

import { useStoreContext } from '../../contexts/StoreContext'
import { ClubProductsSection } from '../sections/Club/ClubProducts/ClubProducts'

interface ClubProductsProps {
  data?: {
    isActive: boolean
    title: string
    description: string
  }
}

export const ClubProducts = ({ data }: ClubProductsProps) => {
  const { hasClub, hasClubRegister } = useStoreContext()

  if (!data?.isActive || !hasClub || !hasClubRegister) {
    return null
  }

  return (
    <ClubProductsSection
      title={data?.title}
      description={data?.description}
      location="vitrine-ofertas-clube"
    />
  )
}
