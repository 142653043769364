import { CardActions as UICardActions } from '@faststore/ui'
import { ChangeOrderModal } from '@plurix/ecom-components'
import React, { useState } from 'react'

import { useRegionalizationContext } from '../../../../contexts/RegionalizationContext'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { cartStore } from '../../../../sdk/cart'
import { AddToCartButton } from '../../AddToCartButton'
import { QuantitySelectorContainer } from '../../QuantitySelectorContainer'

import type { CartItem } from '../../../../sdk/cart'
import type { ProductsQueryResponse } from '../../../../types/Product'

import '../product-card.scss'
import '@plurix/ecom-components/dist/styles/change-order-modal.global.css'

interface ProductCardActionsProps {
  product: ProductsQueryResponse['node']
  location: string
  setOpenClubModal: (openClubModal: boolean) => void
  hasPesavelUnd?: boolean
  isHorizontal?: boolean
  clubPrice?: number
  buyProps: CartItem
  cartProductId: string
  cartProductQty: number
}

export const ProductCardActions = ({
  product,
  location,
  setOpenClubModal,
  hasPesavelUnd,
  isHorizontal,
  clubPrice,
  buyProps,
  cartProductId,
  cartProductQty,
}: ProductCardActionsProps) => {
  const { hasClub, hasChangeOrder } = useStoreContext()
  const { unitMultiplier, measurementUnit, lowStock } = product
  const { postalCode } = useRegionalizationContext()
  const [modalChangeOrder, setModalChangeOrder] = useState(false)

  return (
    <>
      <UICardActions>
        {cartStore.inCart(cartProductId) ? (
          <QuantitySelectorContainer
            cartProductId={cartProductId}
            cartProductQty={cartProductQty}
            unitMultiplier={unitMultiplier}
            measurementUnit={measurementUnit}
            hasPesavelUnd={hasPesavelUnd}
            location={location}
            isHorizontal={isHorizontal}
          />
        ) : (
          <div className="wrapper-button-tabloid">
            <AddToCartButton
              buyProps={buyProps}
              setOpenClubModal={setOpenClubModal}
              hasClub={hasClub}
              clubPrice={clubPrice}
              location={location}
              lowStock={lowStock}
              setModalChangeOrder={setModalChangeOrder}
            />
          </div>
        )}
      </UICardActions>
      {modalChangeOrder && postalCode && Boolean(hasChangeOrder) && (
        <ChangeOrderModal
          postalCode={postalCode}
          isOpen={modalChangeOrder}
          setIsOpen={setModalChangeOrder}
          environment="faststore"
          selectedItem={{
            imageUrl: product.image[0].url,
            name: product.name,
            quantity: product.unitMultiplier,
            sellingPrice: product.offers.offers[0].price,
            measurementUnit: product.measurementUnit ?? '',
            unitMultiplier: product.unitMultiplier,
            productOriginId: product.id,
          }}
        />
      )}
    </>
  )
}
