import React from 'react'

import { topics } from '../defaultData'

import './faq-aside-menu.scss'
import { MenuItem } from './MenuItem'

export const FAQAsideMenu = () => {
  return (
    <aside className="faq-aside-menu">
      <p className="faq-aside-menu-title">Como podemos te ajudar?</p>
      {topics()?.map(({ icon, title, description, activeIcon }) => (
        <MenuItem
          key={title}
          icon={icon}
          title={title}
          description={description}
          activeIcon={activeIcon}
        />
      ))}
    </aside>
  )
}
