import './offer-card.scss'

import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { DynamicTitle } from '../../common/DynamicTitle'
import { Image } from '../Image'

import type { ImageWithAlt } from '../../../types/ClubCard/ClubCardBenefits'

interface PointsCardProps {
  text: string
  image: ImageWithAlt
  href: string
  buttonText: string
  tag?: ImageWithAlt
  className?: string
}

export const OfferCard = ({
  text,
  image,
  tag,
  href,
  buttonText,
  className = '',
}: PointsCardProps) => {
  const { width } = useWindowDimensions()
  const { genericTaggingEvent } = useAnalyticsEvents()

  if (!text || !image || !href || !buttonText) {
    return null
  }

  const handleClick = () => {
    genericTaggingEvent('see_all_offers', {
      name: text,
    })
  }

  return (
    <div className={`offerCard ${className}`}>
      <div className="offerCard-top">
        {tag && (
          <div className="offerCard-tag">
            <Image alt={tag?.alt} baseUrl={tag?.src} width={50} height={18} />
          </div>
        )}
        <div className="offerCard-image">
          <Image
            alt={image?.alt}
            baseUrl={image?.src}
            width={width && width > 768 ? 256 : 146}
            height={96}
          />
        </div>
      </div>
      <div className="offerCard-information">
        <DynamicTitle className="offerCard-text" titleLevel={3} title={text} />
      </div>
      <div className="offerCard-button">
        <Link to={href} onClick={handleClick}>
          {buttonText}
        </Link>
      </div>
    </div>
  )
}
