import React from 'react'

export const CartIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="cart-icon"
    >
      <mask
        id="mask0_3286_6881"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3286_6881)">
        <path
          d="M14.6667 12V8.00001H10.6667V5.33334H14.6667V1.33334H17.3334V5.33334H21.3334V8.00001H17.3334V12H14.6667ZM9.33337 29.3333C8.60004 29.3333 7.97226 29.0722 7.45004 28.55C6.92782 28.0278 6.66671 27.4 6.66671 26.6667C6.66671 25.9333 6.92782 25.3056 7.45004 24.7833C7.97226 24.2611 8.60004 24 9.33337 24C10.0667 24 10.6945 24.2611 11.2167 24.7833C11.7389 25.3056 12 25.9333 12 26.6667C12 27.4 11.7389 28.0278 11.2167 28.55C10.6945 29.0722 10.0667 29.3333 9.33337 29.3333ZM22.6667 29.3333C21.9334 29.3333 21.3056 29.0722 20.7834 28.55C20.2612 28.0278 20 27.4 20 26.6667C20 25.9333 20.2612 25.3056 20.7834 24.7833C21.3056 24.2611 21.9334 24 22.6667 24C23.4 24 24.0278 24.2611 24.55 24.7833C25.0723 25.3056 25.3334 25.9333 25.3334 26.6667C25.3334 27.4 25.0723 28.0278 24.55 28.55C24.0278 29.0722 23.4 29.3333 22.6667 29.3333ZM1.33337 5.33334V2.66668H5.70004L11.3667 14.6667H20.7L25.9 5.33334H28.9334L23.0667 15.9333C22.8223 16.3778 22.4945 16.7222 22.0834 16.9667C21.6723 17.2111 21.2223 17.3333 20.7334 17.3333H10.8L9.33337 20H25.3334V22.6667H9.33337C8.33337 22.6667 7.57226 22.2333 7.05004 21.3667C6.52782 20.5 6.51115 19.6222 7.00004 18.7333L8.80004 15.4667L4.00004 5.33334H1.33337Z"
          fill="var(--color-main)"
        />
      </g>
    </svg>
  )
}
