import { Link } from 'gatsby'
import React from 'react'

import { PercentageIcon } from '../../../assets/PercentageIcon'
import { textClubAccount } from '../../../constants'
import { useStoreContext } from '../../../contexts/StoreContext'
import { PriceCustom } from '../PriceCustom'

import './product-details-club-price-flag.scss'

interface ProductDetailsClubPriceFlagProps {
  clubPrice?: number | null
  clubRegistrationLink: string
  unitMultiplier?: number
  measurementUnit?: string
  hasUndQtd?: number | string
  hasUnd?: string
}

export const ProductDetailsClubPriceFlag = ({
  clubPrice,
  clubRegistrationLink,
  unitMultiplier,
  measurementUnit,
  hasUndQtd,
  hasUnd,
}: ProductDetailsClubPriceFlagProps) => {
  const { account } = useStoreContext()
  if (!clubPrice) {
    return null
  }

  const showMeasureInfos = !!(
    unitMultiplier &&
    measurementUnit &&
    measurementUnit !== 'un'
  )

  return (
    <>
      <div className="product-details-club-flag-container">
        <div className="product-details-club-flag-content-up">
          <PercentageIcon width={16} height={16} />
          <span className="product-details-club-flag-title">
            Promoção {textClubAccount(account)}
          </span>
        </div>
        <div className="product-details-club-flag-content-down">
          <span className="product-details-club-text">Se Cadastre e Pague</span>
          <PriceCustom
            className="product-details-club-price"
            price={clubPrice}
          />
          {showMeasureInfos && (
            <span className="product-details-club-price-measured">
              /{hasUndQtd}
              {hasUnd}
            </span>
          )}
          <Link to={clubRegistrationLink} className="product-details-club-link">
            Saber Mais
          </Link>
        </div>
      </div>
    </>
  )
}
