import type {
  SelectFacetFilter,
  CheckUncategorizedFilter,
} from '../types/FoodRestriction'

// adding or removing options set in the filter
export const selectFacetFilter = ({
  facets,
  setFacets,
  key,
  value,
  setOpenDesktopAccordion,
  categorieFacetsKey,
}: SelectFacetFilter) => {
  const facetIsSelected = !!facets.find((facet) => facet.value === value)
  const selectedCategory = facets.find(
    (facet) => facet.key === categorieFacetsKey
  )

  if (facetIsSelected) {
    const unifiedFacets = facets.filter((facet) => facet.value !== value)

    setOpenDesktopAccordion && setOpenDesktopAccordion(true)
    setFacets(unifiedFacets)

    return
  }

  if (selectedCategory && key === categorieFacetsKey) {
    const facetsWithoutCategory = facets.filter(
      (facet) => facet.key !== categorieFacetsKey
    )

    setOpenDesktopAccordion && setOpenDesktopAccordion(true)
    setFacets([...facetsWithoutCategory, { key, value }])

    return
  }

  setOpenDesktopAccordion && setOpenDesktopAccordion(true)
  setFacets([...facets, { key, value }])
}

// check for subcategory selected without its category on desktop
export const checkUncategorizedFilter = ({
  facets,
  setFacets,
  setOpenDesktopAccordion,
  allFacets,
}: CheckUncategorizedFilter) => {
  const subcategoryFacets = allFacets?.find(
    (facets) => facets.title === 'SubCategoria'
  )

  const categoriesFacets = allFacets?.find(
    (facets) => facets.title === 'Categoria'
  )

  if (!subcategoryFacets || !categoriesFacets) {
    setOpenDesktopAccordion && setOpenDesktopAccordion(false)

    return
  }

  if (setOpenDesktopAccordion && facets.length) {
    setOpenDesktopAccordion(true)
  }

  const selectedSubcategory = !!subcategoryFacets.options.find(
    (opt) => opt.selected === true
  )

  const unselectedCategory = !!categoriesFacets.options.find(
    (opt) => opt.selected === false
  )

  if (selectedSubcategory && unselectedCategory) {
    const formattedOptions = categoriesFacets.options.map((option) => {
      return { key: categoriesFacets.facet, value: option.value }
    })

    setFacets([...facets, ...formattedOptions])
  }
}
