import { Link } from 'gatsby'
import React from 'react'

import { defaultSort } from '../../../../../constants'
import { useMultipleSearchContext } from '../../../../../contexts/MultipleSearchContext'
import WhenVisible from '../../../../ui/WhenVisible/WhenVisible'

interface ShelfHeaderProps {
  itemName: string
}

export const ShelfHeader = ({ itemName }: ShelfHeaderProps) => {
  const { searchedTerms, menuAutoNavigation } = useMultipleSearchContext()
  const searchedTermsIndex = searchedTerms?.indexOf(itemName)

  return (
    <div className="grid-content multiple-search-shelf-header">
      <WhenVisible
        key={itemName}
        id={itemName}
        trigger={() =>
          (searchedTermsIndex ?? -1) >= 0 &&
          menuAutoNavigation?.(searchedTermsIndex ?? -1, itemName)
        }
        referenceId="carousel-menu-content"
      >
        <h2 id={itemName}>{itemName}</h2>
      </WhenVisible>
      <Link to={`/s/?q=${itemName}&sort=${defaultSort}&page=0`}>Ver Tudo</Link>
    </div>
  )
}
